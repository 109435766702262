import React from "react"; // , PropTypes
import { SUBSCRIPTION_EMAIL, getPlanDetails } from "app.constants/plans";
import { COMPANY_NAME } from "app.constants";
import Button from "app.components/Util/Button";
import { useTranslation } from "react-i18next";
import GroupButton from "app.components/Util/GroupButton";
import PlanCost from "./PlanCost";

const CARD_BUTTON_STYLE = "w-full justify-center mt-auto";

function PlanCard({
  plan,
  currentlyActivePlan,
  referrer,
  selectPlan,
  showPrice,
  selectPlanOptions,
  changeSelectedPlan,
  selectedPlan,
}) {
  const { t } = useTranslation();

  const planDetails = getPlanDetails(plan.planId);

  let cardButton = null;

  if (plan.planId === currentlyActivePlan.planId) {
    cardButton = (
      <Button
        buttonType="primary"
        customClasses={CARD_BUTTON_STYLE}
        disabled
        text={t("button.currentPlan")}
      />
    );
  } else if (plan.planId !== currentlyActivePlan.planId && plan.isDefaultPlan) {
    cardButton = (
      <Button
        buttonType="primary"
        customClasses={CARD_BUTTON_STYLE}
        disabled
        text={t("button.free")}
      />
    );
  } else if (planDetails.seatBased) {
    cardButton = (
      <Button
        asAnchor
        buttonType="primary"
        customClasses={CARD_BUTTON_STYLE}
        href={`mailto:${SUBSCRIPTION_EMAIL}`}
      >
        {t("button.emailUs", {
          company: COMPANY_NAME,
        })}
      </Button>
    );
  } else {
    cardButton = (
      <Button
        buttonType="primary"
        customClasses={CARD_BUTTON_STYLE}
        onClick={() => selectPlan(plan)}
        text={t("button.changePlan")}
      />
    );
  }

  return (
    <div className="mx-3 flex basis-1/3 flex-col rounded border border-blue-200 p-2">
      <h2 className="text-sm leading-none text-blue-700">
        {planDetails.title}
      </h2>
      <p className="mb-2 text-xs text-zinc-700">{planDetails.description}</p>
      <div className="flex basis-12 items-center">
        {showPrice && plan.amountDollars && (
          <div className="flex flex-1 items-center justify-between pr-2 text-sm">
            <div className="ml-1 text-sm">
              <PlanCost plan={plan} referrer={referrer} />
            </div>
            <GroupButton
              selectOptions={selectPlanOptions.map(({ planId }) => ({
                text: t(`plans.groupButton.${planId}`),
                id: planId,
              }))}
              selectedOption={selectedPlan}
              selectOption={(op) => changeSelectedPlan(op)}
              groupType="circle"
              customClasses="ml-3"
              buttonSize="sm"
            />
          </div>
        )}
        {showPrice && !plan.amountDollars && (
          <p className="flex items-center font-bold leading-none">
            {t("common.free")}
          </p>
        )}
      </div>
      <p className="mb-8">{planDetails.longDescription}</p>
      {cardButton}
    </div>
  );
}

PlanCard.displayName = "PlanCard";

export default PlanCard;
