import { call, put, select, all } from "redux-saga/effects";
import {
  requestRebuildExchangeLedgers,
  rebuildExchangeLedgersError,
  receiveRebuildExchangeLedgers,
  fetchLedgers,
} from "app.actions/exchanges";
import {
  startModalWorking,
  endModalWorking,
  dismissModal,
  receiveModalActionError,
  openApiKeyModal,
  openModal,
} from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

import { MODAL_EXCHANGE_OAUTH_POP_UP } from "app.constants/modals";

import { handleApiKeyError } from "app.actions/helpers";

function* rebuildExchangeLedgers(action) {
  const { exchangeId, linkGUID } = action;

  const { rebuildingLedgers, supportedExchanges } = yield select(
    (state) => state.exchanges
  );

  const exchange = supportedExchanges.find((e) => e.id === exchangeId);

  const ledgers = yield select((state) => state.exchanges.ledgers);

  // if the linkGUID in question is here, return
  if (rebuildingLedgers.includes(linkGUID)) return;

  yield all([put(requestRebuildExchangeLedgers()), put(startModalWorking())]);

  // store ledgerIds for clean up
  const ledgerIds = [];
  for (let i = 0; i < ledgers.length; i += 1) {
    if (ledgers[i].linked?.linkGUID === action.linkGUID) {
      ledgerIds.push(ledgers[i].id);
    }
  }

  const response = yield call(
    ExchangesAPI.rebuildLedgers,
    exchangeId,
    linkGUID
  );

  const { body, error } = response;

  if (error) {
    if (
      typeof body.metadata !== "undefined" &&
      typeof body.metadata.code !== "undefined"
    ) {
      switch (body.metadata.code) {
        case "oauthRequired":
          yield put(
            openModal(MODAL_EXCHANGE_OAUTH_POP_UP, undefined, {
              exchange,
              linkGUID,
              reLink: true,
            })
          );
          break;
        case "apiKeyInvalidSignedRequest":
        case "apiKeyInvalid":
        case "apiKeyOverScope":
          yield put(
            handleApiKeyError({
              errorCode: body.metadata.code, // errorCode
              exchange,
              linkGUID,
              openApiKeyModal,
              error,
            })
          );
          break;
        default:
          break;
      }
      yield all([
        put(receiveModalActionError({ error })),
        put(rebuildExchangeLedgersError(exchangeId, linkGUID, error)),
        put(endModalWorking()),
      ]);
    }
  } else {
    yield all([
      put(receiveRebuildExchangeLedgers(exchangeId, linkGUID, ledgerIds)),
      put(fetchLedgers()),
      put(dismissModal()),
    ]);
  }
}

export default rebuildExchangeLedgers;
