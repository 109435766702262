import { makeActionCreator } from "app.actions/helpers";
// EXCHANGE LEDGER SUMMARY
export const EXCHANGE_LEDGER_SUMMARY_BEGIN = "EXCHANGE_LEDGER_SUMMARY_BEGIN";
export const EXCHANGE_LEDGER_SUMMARY_REQUEST =
  "EXCHANGE_LEDGER_SUMMARY_REQUEST";
export const EXCHANGE_LEDGER_SUMMARY_RECEIVE =
  "EXCHANGE_LEDGER_SUMMARY_RECEIVE";

export const fetchExchangeLedgerSummary = makeActionCreator(
  EXCHANGE_LEDGER_SUMMARY_BEGIN,
  "ledgerId",
  "range",
  "key"
);

export const requestExchangeLedgerSummary = makeActionCreator(
  EXCHANGE_LEDGER_SUMMARY_REQUEST,
  "nextStatus"
);

export const receiveExchangeLedgerSummary = makeActionCreator(
  EXCHANGE_LEDGER_SUMMARY_RECEIVE,
  "response",
  "range",
  "key",
  "nextStatus"
);
