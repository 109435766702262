import { makeActionCreator } from "app.actions/helpers";

export const EXCHANGE_LEDGER_RELATED_BEGIN = "EXCHANGE_LEDGER_RELATED_BEGIN";
export const EXCHANGE_LEDGER_RELATED_REQUEST =
  "EXCHANGE_LEDGER_RELATED_REQUEST";
export const EXCHANGE_LEDGER_RELATED_RECEIVE =
  "EXCHANGE_LEDGER_RELATED_RECEIVE";
export const EXCHANGE_LEDGER_RELATED_ERROR = "EXCHANGE_LEDGER_RELATED_ERROR";

export const fetchRelatedTransactions = makeActionCreator(
  EXCHANGE_LEDGER_RELATED_BEGIN,
  "itemId"
);

export const requestRelatedTransactions = makeActionCreator(
  EXCHANGE_LEDGER_RELATED_REQUEST,
  "itemId",
  "nextStatus"
);

export const receiveRelatedTransactions = makeActionCreator(
  EXCHANGE_LEDGER_RELATED_RECEIVE,
  "itemId",
  "response",
  "nextStatus"
);

export const relatedTransactionsError = makeActionCreator(
  EXCHANGE_LEDGER_RELATED_ERROR,
  "itemId",
  "response"
);
