import { makeActionCreator } from "app.actions/helpers";

export const TIMELINE_METADATA_BEGIN = "TIMELINE_METADATA_BEGIN";
export const TIMELINE_METADATA_REQUEST = "TIMELINE_METADATA_REQUEST";
export const TIMELINE_METADATA_RECEIVE = "TIMELINE_METADATA_RECEIVE";
export const TIMELINE_METADATA_ERROR = "TIMELINE_METADATA_ERROR";

export const fetchTimelineMetadata = makeActionCreator(TIMELINE_METADATA_BEGIN);

export const requestTimelineMetadata = makeActionCreator(
  TIMELINE_METADATA_REQUEST,
  "nextStatus"
);

export const receiveTimelineMetadata = makeActionCreator(
  TIMELINE_METADATA_RECEIVE,
  "metadata",
  "nextStatus"
);

export const timelineMetadataError = makeActionCreator(
  TIMELINE_METADATA_ERROR,
  "response"
);
