import { call, put, all } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";

import OrganizationAPI from "app.api/OrganizationAPI";

function* undoDeleteProfile(action) {
  const { profileGUID } = action.payload;

  yield put(organizationActions.undoDeleteProfileRequest());

  const response = yield call(OrganizationAPI.undoDeleteProfile, profileGUID);

  if (response.error) {
    yield put(organizationActions.undoDeleteProfileError(response.error));
  } else {
    yield all([
      put(organizationActions.undoDeleteProfileReceive()),
      put(organizationActions.fetchOrganization()),
    ]);
  }
}

export default undoDeleteProfile;
