import { takeEvery, takeLeading, takeLatest } from "redux-saga/effects";

import * as exchangeLedgerActions from "app.actions/exchangeLedger";

import changeExchangeExemptStatus from "./changeExchangeExemptStatus";
import changeExchangePayableStatus from "./changeExchangePayableStatus";
import changeExchangeTransferStatus from "./changeExchangeTransferStatus";
import doExchangeLedgerImport from "./doExchangeLedgerImport";
import editEntryDescription from "./editExchangeDescription";
import editEntryValue from "./editExchangeValue";
import fetchExchangeLedger from "./fetchExchangeLedger";
import fetchTimeline from "./fetchTimeline";
import fetchExchangeLedgerImports from "./fetchExchangeLedgerImports";
import fetchExchangeLedgerRange from "./fetchExchangeLedgerRange";
import fetchExchangeLedgerSummary from "./fetchExchangeLedgerSummary";
import fetchExchangeTabbedLedgerData from "./fetchExchangeTabbedLedgerData";
import fetchExchangeWorksheet from "./fetchExchangeLedgerWorksheet";
import fetchExchangeMultiTokenWorksheet from "./fetchExchangeMultiTokenWorksheet";
import fetchNftWorksheet from "./fetchNftWorksheet";
import fetchRelatedTransactions from "./fetchRelatedTransactions";
import fetchTimelineMetadata from "./fetchTimelineMetadata";
import revertEntryValue from "./revertExchangeValue";
import clearLedgerItemReviewReason from "./clearLedgerItemReviewReason";
import createManualJournalTransaction from "./createManualJournalTransaction";
import deleteTransactionManualJournalEntry from "./deleteTransactionManualJournalEntry";
import favoriteLedger from "./favoriteLedger";
import archiveLedger from "./archiveLedger";
import updateLedgerCustomData from "./updateLedgerCustomData";
import changeTransactionTransferIn from "./changeTransactionTransferIn";
import changeTransferOutToSourceless from "./changeTransferOutToSourceless";
import createManualTransfer from "./createManualTransfer";
import removeManualTransfer from "./removeManualTransfer";

const exchangeLedgerSagas = [
  takeLeading(
    exchangeLedgerActions.EXCHANGE_TABBED_LEDGER_SECTION_ENTER,
    fetchExchangeTabbedLedgerData
  ),
  takeLeading(
    exchangeLedgerActions.EXCHANGE_TABBED_LEDGER_SECTION_CHANGE,
    fetchExchangeTabbedLedgerData
  ),
  takeLeading(
    exchangeLedgerActions.EXCHANGE_LEDGER_RANGE_BEGIN,
    fetchExchangeLedgerRange
  ),
  takeEvery(
    exchangeLedgerActions.EXCHANGE_CHANGE_EXEMPT_STATUS_BEGIN,
    changeExchangeExemptStatus
  ),
  takeEvery(
    exchangeLedgerActions.EXCHANGE_CHANGE_PAYABLE_STATUS_BEGIN,
    changeExchangePayableStatus
  ),
  takeEvery(
    exchangeLedgerActions.EXCHANGE_CHANGE_TRANSFER_STATUS_BEGIN,
    changeExchangeTransferStatus
  ),
  takeEvery(
    exchangeLedgerActions.EDIT_EXCHANGE_ENTRY_DESCR_BEGIN,
    editEntryDescription
  ),
  takeEvery(exchangeLedgerActions.EDIT_EXCHANGE_VALUE_BEGIN, editEntryValue),
  takeEvery(
    exchangeLedgerActions.REVERT_EXCHANGE_VALUE_BEGIN,
    revertEntryValue
  ),
  takeLatest(exchangeLedgerActions.EXCHANGE_LEDGER_BEGIN, fetchExchangeLedger),
  takeLatest(exchangeLedgerActions.TIMELINE_BEGIN, fetchTimeline),
  takeEvery(
    exchangeLedgerActions.EXCHANGE_LEDGER_IMPORTS_BEGIN,
    fetchExchangeLedgerImports
  ),
  takeEvery(
    exchangeLedgerActions.EXCHANGE_LEDGER_IMPORT_BEGIN,
    doExchangeLedgerImport
  ),
  takeLeading(
    exchangeLedgerActions.EXCHANGE_LEDGER_SUMMARY_BEGIN,
    fetchExchangeLedgerSummary
  ),
  takeLeading(
    exchangeLedgerActions.EXCHANGE_LEDGER_WORKSHEET_BEGIN,
    fetchExchangeWorksheet
  ),
  takeLeading(
    exchangeLedgerActions.EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_BEGIN,
    fetchExchangeMultiTokenWorksheet
  ),
  takeLeading(exchangeLedgerActions.NFT_WORKSHEET_BEGIN, fetchNftWorksheet),
  takeLeading(
    exchangeLedgerActions.EXCHANGE_LEDGER_RELATED_BEGIN,
    fetchRelatedTransactions
  ),
  takeEvery(
    exchangeLedgerActions.LEDGER_ITEM_CLEAR_REASON_BEGIN,
    clearLedgerItemReviewReason
  ),
  takeLeading(
    exchangeLedgerActions.TIMELINE_METADATA_BEGIN,
    fetchTimelineMetadata
  ),
  takeEvery(
    exchangeLedgerActions.EDIT_MANUAL_JOURNAL_BEGIN,
    createManualJournalTransaction
  ),
  takeEvery(
    exchangeLedgerActions.DELETE_MANUAL_TRANSACTION_ENTRY_BEGIN,
    deleteTransactionManualJournalEntry
  ),
  takeEvery(exchangeLedgerActions.FAVORITE_LEDGER_BEGIN, favoriteLedger),
  takeEvery(exchangeLedgerActions.ARCHIVE_LEDGER_BEGIN, archiveLedger),
  takeEvery(
    exchangeLedgerActions.UPDATE_LEDGER_CUSTOM_DATA,
    updateLedgerCustomData
  ),
  takeEvery(
    exchangeLedgerActions.TRANSFER_IN_FROM_SOURCELESS_STATUS_BEGIN,
    changeTransactionTransferIn
  ),
  takeEvery(
    exchangeLedgerActions.TRANSFER_OUT_TO_SOURCELESS_STATUS_BEGIN,
    changeTransferOutToSourceless
  ),
  takeEvery(
    exchangeLedgerActions.CREATE_MANUAL_TRANSFER_LINK_BEGIN,
    createManualTransfer
  ),
  takeEvery(
    exchangeLedgerActions.REMOVE_MANUAL_TRANSFER_LINK_BEGIN,
    removeManualTransfer
  ),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default exchangeLedgerSagas;
