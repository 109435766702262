import { call, put, select } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  updateProfileError,
  updateProfileRequest,
  updateProfileReceive,
} from "app.actions/account";
import { lookupTranslation } from "app.utils";
import {
  ACCOUNT_ERROR_PATH,
  ACCOUNT_UNKNOWN_ERROR,
} from "app.constants/errorMessages";

function* updateProfile(action) {
  const { userProfile } = yield select((state) => state.account);
  const { profile } = action;

  const { firstName, lastName, email } = userProfile;

  if (
    firstName === profile.firstName &&
    lastName === profile.lastName &&
    email === profile.email
  ) {
    yield put(
      updateProfileError(
        lookupTranslation(
          "must_update_account_info",
          ACCOUNT_ERROR_PATH,
          ACCOUNT_UNKNOWN_ERROR
        )
      )
    );
  } else {
    yield put(updateProfileRequest());
    const response = yield call(
      TellerAPI.sendProfileUpdate,
      profile.email,
      profile.firstName,
      profile.lastName
    );

    if (response.error) {
      yield put(
        updateProfileError(
          lookupTranslation(
            response.body.message,
            ACCOUNT_ERROR_PATH,
            ACCOUNT_UNKNOWN_ERROR
          )
        )
      );
    } else {
      yield put(updateProfileReceive(response.body));
    }
  }
}

export default updateProfile;
