import { call, put, all, delay } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import OrganizationAPI from "app.api/OrganizationAPI";
import {
  dismissModal,
  addFlashMessage,
  removeFlashMessage,
} from "app.reducers/ui";
import { generateGUID } from "app.utils";
import { STATUS_SUCCESS } from "app.components/Messages/FlashMessage";
import { TEN_SECONDS } from "app.constants";

import i18n from "i18next";

function* reinviteTeamMember(action) {
  const { profileGUID, email } = action.payload;

  yield put(organizationActions.reinviteTeamMemberRequest());

  const response = yield call(OrganizationAPI.reinviteTeamMember, profileGUID);

  if (response.error) {
    let errorMessage;

    switch (response.body.type) {
      case "INVALID_REQUEST":
      default:
        errorMessage =
          "There has been an error sending the invitation email to this user.";
        break;
    }

    yield put(
      organizationActions.reinviteTeamMemberError(profileGUID, errorMessage)
    );

    return;
  }

  const messageId = generateGUID();

  const flashMessage = addFlashMessage(
    i18n.t("messages.inviteSentMessage", { email }),
    messageId,
    STATUS_SUCCESS
  );

  yield all([
    put(dismissModal()),
    put(organizationActions.reinviteTeamMemberReceive(profileGUID)),
    put(flashMessage),
  ]);

  yield delay(TEN_SECONDS);

  yield put(removeFlashMessage(messageId));
}

export default reinviteTeamMember;
