export const WALLET_GENERIC_ERROR = "constants.error.generic";
export const WALLET_TIMELINE_ERROR = "constants.error.generic";

export const ACCOUNT_ERROR_PATH = "constants.error.account";
export const ACCOUNT_UNKNOWN_ERROR = "constants.error.account.unknown";
export const ACCOUNT_RESETING_ERROR =
  "constants.error.account.error_resetting_password";

export const BILLING_ERROR_PATH = "constants.error.billing";
export const BILLING_ERROR_DEFAULT = "constants.error.billing.default";

export const ORGANIZATION_ERROR_PATH = "constants.error.organization";

export const FORGOT_PASSWORD_ERROR_PATH = "constants.error.forgotPassword";
export const FORGOT_PASSWORD_ERROR_DEFAULT =
  "constants.error.forgotPassword.default";

export const RESET_PASSWORD_ERROR_PATH = "constants.error.resetPassword";
export const RESET_PASSWORD_ERROR_DEFAULT =
  "constants.error.resetPassword.default";

export const REMOVING_BILLING_ERROR_PATH = "constants.error.removeBilling";
export const REMOVING_BILLING_ERROR_DEFAULT =
  "constants.error.removeBilling.default";
