import { makeActionCreator } from "app.actions/helpers";

export const DELETE_MANUAL_TRANSACTION_ENTRY_BEGIN =
  "DELETE_MANUAL_TRANSACTION_ENTRY_BEGIN";
export const DELETE_MANUAL_TRANSACTION_ENTRY_REQUEST =
  "DELETE_MANUAL_TRANSACTION_ENTRY_REQUEST";
export const DELETE_MANUAL_TRANSACTION_ENTRY_RECEIVE =
  "DELETE_MANUAL_TRANSACTION_ENTRY_RECEIVE";
export const DELETE_MANUAL_TRANSACTION_ENTRY_ERROR =
  "DELETE_MANUAL_TRANSACTION_ENTRY_ERROR";

export const deleteTransactionManualJournalEntry = makeActionCreator(
  DELETE_MANUAL_TRANSACTION_ENTRY_BEGIN,
  "transactionId",
  "ledgerId",
  "isSourceless",
  "isTimeline"
);

export const requestDeleteTransactionManualJournalEntry = makeActionCreator(
  DELETE_MANUAL_TRANSACTION_ENTRY_REQUEST,
  "transactionId"
);

export const receiveDeleteTransactionManualJournalEntry = makeActionCreator(
  DELETE_MANUAL_TRANSACTION_ENTRY_RECEIVE,
  "transactionId"
);

export const errorDeleteTransactionManualJournalEntry = makeActionCreator(
  DELETE_MANUAL_TRANSACTION_ENTRY_ERROR,
  "transactionId"
);
