import { call, put, all, select, delay } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import {
  fetchLedgers,
  requestBulkDeleteExchangeLedgersByCurrency,
  receiveBulkDeleteExchangeLedgersByCurrency,
} from "app.actions/exchanges";

import {
  dismissModal,
  startModalWorking,
  endModalWorking,
  addFlashMessage,
  removeFlashMessage,
} from "app.reducers/ui";
import { generateGUID } from "app.utils";
import {
  STATUS_DANGER,
  STATUS_SUCCESS,
} from "app.components/Messages/FlashMessage";
import { ONE_MINUTE } from "app.constants";

function* bulkDeleteExchangeLedgers(ledgerIds) {
  if (ledgerIds.length === 0) return { error: false };

  const response = yield call(
    ExchangeLedgerAPI.bulkDeleteExchangeLedgers,
    ledgerIds
  );

  if (response.error) {
    return { error: true };
  }
  yield put(fetchLedgers());
  return { error: false };
}

function* bulkDeleteExchangeLedgersByCurrency(action) {
  const { currency } = action;

  const { ledgerIds } = yield select((state) => ({
    ledgerIds: state.exchanges.ledgers
      .filter((x) => x.digitalCurrency === currency)
      .map((x) => x.id),
  }));

  if (ledgerIds.length === 0) return;

  const [deleteLedgers] = yield all([
    call(bulkDeleteExchangeLedgers, ledgerIds),
    put(startModalWorking()),
    put(requestBulkDeleteExchangeLedgersByCurrency()),
  ]);

  if (deleteLedgers.error) {
    const messageId = generateGUID();
    yield all([
      put(endModalWorking()),
      put(dismissModal()),
      put(
        addFlashMessage(
          "There has been an error deleting your ledgers",
          messageId,
          STATUS_DANGER
        )
      ),
    ]);
    yield delay(ONE_MINUTE);
    // after one minute dismiss message
    yield put(removeFlashMessage(messageId));
  } else {
    yield all([
      put(
        receiveBulkDeleteExchangeLedgersByCurrency(STATUS_SUCCESS, ledgerIds)
      ),
      put(dismissModal()),
      put(endModalWorking()),
      put(fetchLedgers()),
    ]);
  }
}

export default bulkDeleteExchangeLedgersByCurrency;
