import { put, call } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import { userVerificationSent } from "app.reducers/user";

function* resendUserAccountVerification({ payload }) {
  const email = payload;
  const response = yield call(TellerAPI.resendUserAccountVerification, email);
  if (response.error) {
    console.error(response.error);
  } else {
    yield put(userVerificationSent());
  }
}

export default resendUserAccountVerification;
