import { makeActionCreator } from "app.actions/helpers";

export const CANCEL_SUBSCRIPTION_BEGIN = "CANCEL_SUBSCRIPTION_BEGIN";
export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_RECEIVE = "CANCEL_SUBSCRIPTION_RECEIVE";
export const CANCEL_SUBSCRIPTION_ERROR_RECEIVE =
  "CANCEL_SUBSCRIPTION_ERROR_RECEIVE";

export const cancelSubscription = makeActionCreator(
  CANCEL_SUBSCRIPTION_BEGIN,
  "subscriptionId",
  "planId"
);

export const requestCancelSubscription = makeActionCreator(
  CANCEL_SUBSCRIPTION_REQUEST
);
export const receiveCancelSubscription = makeActionCreator(
  CANCEL_SUBSCRIPTION_RECEIVE
);
export const receiveCancelSubscriptionError = makeActionCreator(
  CANCEL_SUBSCRIPTION_ERROR_RECEIVE,
  "error"
);
