/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { enableMFA } from "app.actions/account";

import { ACCOUNT_STATUS } from "app.constants/loadingStates";
import MFAQRCode from "app.components/Util/MFAQRCode";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import { Input, Label } from "app.components/Util/Form";

import ErrorMessage from "app.components/Util/ErrorMessage";
import Button from "app.components/Util/Button";

function VerifyForm({ state, handleUpdate, handleDismiss }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const { userProfile, securityStatus, totpSecret, securityError } =
    useSelector((s) => s.account);

  const { username } = userProfile;
  const { totp, currentPassword } = state;
  const disableForm = !totp || totp.length < 6;
  const blockSubmit = securityStatus === ACCOUNT_STATUS.UPDATING;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (blockSubmit) return;
    reduxDispatch(enableMFA(currentPassword, totp));
  };

  return (
    <>
      <ModalHeader
        title={t("account.twoFactorAuthentication.twoFactorAuthentication")}
        closeCallback={handleDismiss}
      />
      <form onSubmit={handleSubmit} data-testid="modal-mfa-disabled-form">
        <div className="m-4">
          <ErrorMessage message={securityError} type={securityError} />
          <ol className="mt-4 list-decimal pl-4 text-sm">
            <li className="mb-4">
              <p>
                {t("account.twoFactorAuthentication.scanTheFollowingQRCode")}
              </p>
              <div className="mt-4 flex">
                <MFAQRCode username={username} totpSecret={totpSecret} />
                <div className="ml-4 flex flex-col justify-between">
                  <p className="font-bold">
                    {t("account.twoFactorAuthentication.privateKeyManualCode")}
                  </p>
                  <pre className="max-w-fit rounded border border-zinc-300 px-4 py-2 shadow-inner">
                    {totpSecret}
                  </pre>
                  <p className="text-xxs mb-4">
                    {t(
                      "account.twoFactorAuthentication.problemScanningTheQRCodeEnterThisIntoTheApplication"
                    )}
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-4">
              <p>
                {t(
                  "account.twoFactorAuthentication.onceAuthenticatedEnterTheCodeReceived"
                )}
              </p>
            </li>
          </ol>
          <div className="mb-4 flex flex-col">
            <Label htmlFor="totp">
              {t("input.label.authenticatorVerificationCode")}
            </Label>
            <Input
              className="w-24 tracking-widest"
              value={totp}
              disabled={blockSubmit}
              type="text"
              id="totp"
              data-testid="totp"
              name="totp"
              placeholder="000 000"
              onChange={(e) => {
                e.preventDefault();
                handleUpdate(e.target.name, e.target.value);
              }}
            />
          </div>
        </div>

        <ModalControls>
          <Button
            type="submit"
            disabled={disableForm || blockSubmit}
            buttonType="primary"
            customClasses="ml-4"
            working={blockSubmit}
            text={t("button.enableTwoFactor")}
            workingText={t("button.working")}
          />
          <Button
            buttonType="text"
            disabled={blockSubmit}
            onClick={handleDismiss}
            text={t("button.cancel")}
          />
        </ModalControls>
      </form>
    </>
  );
}

VerifyForm.displayName = "VerifyForm";

export default VerifyForm;
