import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { dismissModal } from "app.reducers/ui";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

import { Trans, useTranslation } from "react-i18next";

const ModalReinvite = ({ email, callbackAction }) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const reinvitedError = useSelector(
    (state) => state.organizations.reinvitedError
  );

  return (
    <>
      <ModalHeader
        title={t("modals.sendInvitationAgain")}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          reduxDispatch({ ...callbackAction });
        }}
      >
        <div className="m-4">
          <p className="text-lg">
            <Trans i18nKey="modals.wouldYouLikeToSendAnotherInvitation">
              <strong>{{ email }}</strong>
            </Trans>
          </p>

          {reinvitedError ? (
            <p className="text-red-700">{reinvitedError}</p>
          ) : undefined}
        </div>
        <ModalControls>
          <Button
            type="submit"
            data-tabbable
            buttonType="primary"
            customClasses="ml-4"
            text={t("button.sendAgain")}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              reduxDispatch(dismissModal());
            }}
            data-tabbable
            buttonType="text"
            text={t("button.close")}
          />
        </ModalControls>
      </form>
    </>
  );
};

ModalReinvite.displayName = "ModalReinvite";

export default ModalReinvite;
