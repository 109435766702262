import React from "react";

const InputGroup = ({
  hasError,
  addOnContent,
  attach = "left",
  className,
  addOnClassName,
  inputClassName,
  disabled,
  ...htmlAttributes
}) => {
  const groupClass = ["flex items-center", className].filter(Boolean).join(" ");
  const addonClass = [
    "transition-all",
    "leading-7",
    "border",
    attach === "left" ? "rounded-l border-r-0" : "rouned-r border-l-0",
    "border-zinc-300",
    "bg-zinc-100",
    "px-2",
    "text-sm",
    "h-8",
    disabled ? "disabled:opacity-70" : "",
    hasError
      ? "border-red-700 bg-red-100 text-red-700"
      : "border-zinc-300 text-zinc-500",
    addOnClassName,
  ]
    .filter(Boolean)
    .join(" ");

  const inputClass = [
    "transition-all",
    "border",
    attach === "left" ? "rounded-r" : "rounded-l",
    "px-2",
    "shadow-inner",
    "leading-7",
    "h-8",
    "text-sm",
    "disabled:cursor-not-allowed",
    "disabled:opacity-70",
    "placeholder:text-sm",
    inputClassName,
    hasError ? "border-red-700 text-red-700" : "border-zinc-300",
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <div className={groupClass}>
      {attach === "left" ? (
        <span className={addonClass}>{addOnContent}</span>
      ) : null}
      <input className={inputClass} disabled={disabled} {...htmlAttributes} />
      {attach === "right" ? (
        <span className={addonClass}>{addOnContent}</span>
      ) : null}
    </div>
  );
};

InputGroup.displayName = "InputGroup";

export default InputGroup;
