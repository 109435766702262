import React from "react";

const ModalHeader = ({ noMargin = false, children }) => {
  return (
    <div
      className={[
        "flex h-12 flex-row-reverse items-center bg-zinc-100 px-4",
        noMargin ? "" : "mt-8",
      ].join(" ")}
    >
      {children}
    </div>
  );
};

export default ModalHeader;
