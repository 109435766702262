import { call, put } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import OrganizationAPI from "app.api/OrganizationAPI";

function* inviteUser(action) {
  const { userGUID, associatedEmailAddress, firstName, lastName } =
    action.payload;

  yield put(organizationActions.inviteUserRequest());

  const response = yield call(
    OrganizationAPI.inviteUser,
    userGUID,
    associatedEmailAddress,
    firstName,
    lastName
  );

  if (response.error) {
    const code = response.error;
    const { message } = response.body;
    const error = { code, message };

    yield put(organizationActions.inviteUserError(error));
    return;
  }

  yield put(organizationActions.inviteUserReceive());
}

export default inviteUser;
