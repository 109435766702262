export function sortByField(a, b, fieldName, asc) {
  if (a[fieldName] === b[fieldName]) {
    return 0;
  }
  if (a[fieldName] > b[fieldName]) {
    return asc ? 1 : -1;
  }
  if (a[fieldName] < b[fieldName]) {
    return asc ? -1 : 1;
  }
  return 0;
}
