export const SORT_DIRECTIONS = {
  DESCENDING: "DESC",
  ASCENDING: "ASC",
};

export const SORT_KEYS = {
  SORT: "sort",
  ORDER: "order",
};

export const SORT_TYPE = {
  DATE: "date",
};
