import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_BEGIN } from "app.actions/exchanges";
import { dismissModal, openModal } from "app.reducers/ui";

import {
  MODAL_CONFIRM_DIALOG,
  MODAL_SELECTIVE_DELETE_LEDGERS,
} from "app.constants/modals";
import { CANCEL_TYPES } from "app.constants";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import Button from "app.components/Util/Button";
import SelectableLedgersList from "app.components/Portfolio/SelectableLedgersList";
import { getLabelWithNickname } from "app.utils";

const ModalSelectiveDelete = ({
  exchange,
  nickname,
  ledgers,
  linkGUID,
  preSelectedLedgers,
}) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const { sourcesImporting } = useSelector((state) => state.computables);
  const isSourceImporting = typeof sourcesImporting[linkGUID] !== "undefined";

  const [selectedLedgers, setSelectedLedgers] = useState(
    preSelectedLedgers || []
  );

  const deleteLedgers = () => {
    const message = t("portfolio.areYouSureYouWantToDeleteLedgers", {
      amount: selectedLedgers.length,
      label: exchange,
    });

    const callbackCancel = {
      type: openModal,
      modalBody: MODAL_SELECTIVE_DELETE_LEDGERS,
      modalTitle: undefined,
      params: {
        exchange,
        nickname,
        ledgers,
        linkGUID,
        preSelectedLedgers: selectedLedgers,
      },
    };

    reduxDispatch(
      openModal(MODAL_CONFIRM_DIALOG, null, {
        message,
        okText: t("button.deleteLedgers"),
        okType: CANCEL_TYPES.DANGER,
        cancelText: t("common.cancel"),
        accidentPrevention: true,
        accidentPreventionMessage: t(
          "portfolio.deletingTheseLedgersCannotBeUndone",
          { count: selectedLedgers.length }
        ),
        callbackAction: {
          type: BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_BEGIN,
          ledgerIds: selectedLedgers,
        },
        callbackCancel,
      })
    );
  };

  return (
    <>
      <ModalHeader
        selectiveSync
        title={t("portfolio.selectiveDelete")}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />

      <div className="px-6">
        <div className="mb-2">
          {getLabelWithNickname(
            "portfolio.selectTheLedgersYouWishToDelete",
            exchange,
            nickname
          )}
        </div>

        <SelectableLedgersList
          ledgers={ledgers}
          selectedLedgers={selectedLedgers}
          setSelectedLedgers={setSelectedLedgers}
        />
      </div>

      <ModalControls>
        <Button
          type="submit"
          data-tabbable
          buttonType="primary"
          customClasses="ml-4"
          onClick={(e) => {
            e.preventDefault();
            deleteLedgers();
          }}
          disabled={!selectedLedgers.length || isSourceImporting}
          text={t("button.deleteLedgers")}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
          }}
          data-tabbable
          buttonType="text"
          text={t("button.close")}
        />
      </ModalControls>
    </>
  );
};

ModalSelectiveDelete.displayName = "ModalSelectiveDelete";

export default ModalSelectiveDelete;
