import { call, put, select, all } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import {
  requestDeleteTransactionManualJournalEntry,
  errorDeleteTransactionManualJournalEntry,
  receiveDeleteTransactionManualJournalEntry,
  fetchExchangeLedger,
  fetchTimeline,
} from "app.actions/exchangeLedger";
import {
  startModalWorking,
  endModalWorking,
  dismissModal,
  doRedirect,
} from "app.reducers/ui";

import { fetchLedgers } from "app.actions/exchanges";

function* deleteTransactionManualJournalEntry(action) {
  const {
    transactionId,
    ledgerId,
    isSourceless = false,
    isTimeline = false,
  } = action;

  const deletingTransaction = yield select((state) =>
    state.exchangeLedger.deletingTransaction.includes(transactionId)
  );
  if (deletingTransaction) return;

  // if the current ledger only has ONE transaction and we are deleting it,
  // redirect after delete

  const data = yield select((state) => state.exchangeLedger.data);

  const lastTransaction = data.items.length === 1;

  yield all([
    put(requestDeleteTransactionManualJournalEntry(transactionId)),
    put(startModalWorking()),
  ]);

  let response;
  if (isSourceless) {
    response = yield call(
      ExchangeLedgerAPI.deleteSourcelessLedgerTransaction,
      transactionId,
      ledgerId
    );
  } else {
    response = yield call(
      ExchangeLedgerAPI.manualTransactionDelete,
      transactionId
    );
  }

  if (response.error) {
    yield all([
      put(errorDeleteTransactionManualJournalEntry(transactionId)),
      put(endModalWorking()),
    ]);
  } else {
    const lastRequestParams = yield select(
      (state) => state.exchangeLedger.lastRequestParams
    );

    const noLoading = false;

    const itemsFetch = isTimeline
      ? fetchTimeline(...lastRequestParams, noLoading)
      : fetchExchangeLedger(...lastRequestParams, noLoading);

    yield all([
      put(receiveDeleteTransactionManualJournalEntry(transactionId)),
      // don't refresh the ledger it the last transaction
      lastTransaction ? put(doRedirect("/portfolio")) : put(itemsFetch),
      put(fetchLedgers(true)), // true for oneTime so this doesn't trigger a duplicate recurring call to fetch ledgers.
      put(dismissModal()),
    ]);
  }
}

export default deleteTransactionManualJournalEntry;
