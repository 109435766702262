import React from "react";

// width -> use a tailwind width class, such as w-4
// color -> use a tailwind color class, bg-zinc-200
// height -> a tailwind height class if you require a different height

const PlaceholderLine = ({
  width,
  color = "bg-zinc-200",
  innerClassName = undefined,
  height = "h-5",
}) => {
  if (typeof width !== "string") {
    console.error("PlaceholderLine: width is required");
  }

  const placeholderClass = [
    "c_placeholder-line",
    "rounded-full",
    "inline-block",
    "align-middle",
    "animate-pulse",
    width,
    height,
    color,
    typeof innerClassName !== "undefined" ? innerClassName : "",
  ]
    .filter(Boolean)
    .join(" ");

  return <span className={placeholderClass} data-testid="placeholder-line" />;
};

PlaceholderLine.displayName = "PlaceholderLine";

export default PlaceholderLine;
