import React from "react";

import Spacer from "./Spacer";
import NextButton from "./NextButton";
import PreviousButton from "./PreviousButton";
import PageLink from "./PageLink";

function PaginationPartitionedList({
  partitions,
  numPages,
  currentPage,
  location,
  type,
  onSelect,
  showPageLabel,
}) {
  const segmentSize = Math.floor(numPages / partitions);

  // used to track which page is the last page we render a button for
  // so we know if we need to show an ellipsis before the last page button
  let lastPageBeforeLastButton;

  // -2, because we want to always show first / last
  const segmentPages = [...Array(partitions - 2)].map((x, index) => {
    return (index + 1) * segmentSize;
  });

  const segmentsBeforePageCount = segmentPages
    .filter((x) => x < currentPage - 2)
    .map((x) => {
      return (
        <PageLink
          key={`page-${x}`}
          location={location}
          pageNumber={x}
          currentPage={currentPage}
          type={type}
          onSelect={onSelect}
        />
      );
    });

  const segmentsAfterPageCount = segmentPages
    .filter((x) => x > currentPage + 2)
    .map((x) => {
      lastPageBeforeLastButton = x;
      return (
        <PageLink
          key={`page-${x}`}
          location={location}
          pageNumber={x}
          currentPage={currentPage}
          type={type}
          onSelect={onSelect}
        />
      );
    });

  const pages = [
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2,
  ]
    .filter((x) => x > 0 && x < numPages - 1)
    .map((x) => {
      lastPageBeforeLastButton = x + 1;
      return (
        <PageLink
          key={`page-${x}`}
          location={location}
          pageNumber={x}
          currentPage={currentPage}
          type={type}
          onSelect={onSelect}
          showPageLabel={showPageLabel}
        />
      );
    });

  return (
    <div className="flex items-center justify-center">
      <PreviousButton
        location={location}
        currentPage={currentPage}
        type={type}
        onSelect={onSelect}
      />
      <PageLink
        location={location}
        pageNumber={0}
        currentPage={currentPage}
        type={type}
        onSelect={onSelect}
      />
      {currentPage > 3 ? <Spacer /> : undefined}
      {segmentsBeforePageCount}
      {segmentsBeforePageCount.length > 0 ? <Spacer /> : undefined}
      {pages}
      {segmentsAfterPageCount.length > 0 ? <Spacer /> : undefined}
      {segmentsAfterPageCount}
      {typeof lastPageBeforeLastButton !== "undefined" &&
      numPages - lastPageBeforeLastButton > 1 ? (
        <Spacer />
      ) : undefined}
      <PageLink
        location={location}
        pageNumber={numPages - 1}
        currentPage={currentPage}
        type={type}
        onSelect={onSelect}
      />
      <NextButton
        location={location}
        type={type}
        onSelect={onSelect}
        currentPage={currentPage}
        numPages={numPages}
      />
    </div>
  );
}

PaginationPartitionedList.displayName = "PaginationPartitionedList";
export default PaginationPartitionedList;
