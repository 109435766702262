import { all, select, put, fork, cancel, take } from "redux-saga/effects";
import { doRedirect, selectDateRange } from "app.reducers/ui";

import {
  fetchExchangeLedgerSummary,
  fetchExchangeLedgerRange,
  EXCHANGE_LEDGER_IMPORT_RECEIVE,
  EXCHANGE_CHANGE_EXEMPT_STATUS_RECEIVE,
  EDIT_EXCHANGE_VALUE_RECEIVE,
  EDIT_EXCHANGE_ENTRY_DESCR_RECEIVE,
  EXCHANGE_TABBED_LEDGER_SECTION_LEAVE,
  EXCHANGE_TABBED_LEDGER_SECTION_CHANGE,
} from "app.actions/exchangeLedger";

import { UPDATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE } from "app.actions/exchanges";

function* syncData(ledgerId) {
  while (true) {
    const { selectedDatePreset, selectedDateRange } = yield select(
      (state) => state.ui
    );

    yield all([
      put(
        fetchExchangeLedgerSummary(
          ledgerId,
          selectedDateRange,
          selectedDatePreset
        )
      ),
    ]);

    yield take([
      EXCHANGE_LEDGER_IMPORT_RECEIVE,
      UPDATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE,
      EXCHANGE_CHANGE_EXEMPT_STATUS_RECEIVE,
      EDIT_EXCHANGE_VALUE_RECEIVE,
      EDIT_EXCHANGE_ENTRY_DESCR_RECEIVE,
      selectDateRange,
    ]);
  }
}

function* fetchExchangeTabbedLedgerData(action) {
  const { ledgerId } = action;

  const { exchanges } = yield select();
  const lastPortfolioTab = yield select((state) => state.ui.lastPortfolioTab);

  const ledgerIndex = exchanges.ledgers.findIndex(
    (ledger) => ledger.id === parseInt(ledgerId, 10)
  );

  // if this wallet isn't found, now is a good time to bounce
  if (ledgerIndex === -1) {
    yield put(doRedirect(lastPortfolioTab));
    return;
  }

  const dateRangeSync = yield fork(syncData, ledgerId);

  yield put(fetchExchangeLedgerRange(ledgerId));

  yield take([
    EXCHANGE_TABBED_LEDGER_SECTION_LEAVE,
    EXCHANGE_TABBED_LEDGER_SECTION_CHANGE,
  ]);

  yield cancel(dateRangeSync);
}

export default fetchExchangeTabbedLedgerData;
