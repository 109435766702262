import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import FileUploadField from "app.components/Util/FileUploadField";
import ErrorMessage from "app.components/Util/ErrorMessage";

import DownloadButton from "app.components/Reports/Common/DownloadButton";

import { dismissModal, uploadBulkAddressLabels } from "app.reducers/ui";
import { useFlashMessage } from "app.components/Messages/FlashMessage";

import { useTranslation } from "react-i18next";

import { generateFile, downloadFile } from "app.reducers/files";

import { ADDRESS_LABEL_CSV, STATUS } from "app.constants";

const FILE_KEY = "complete";

const ModalUploadBulkAddressLabels = ({ canDismissCallback }) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const [file, setFile] = useState({});

  const files = Object.keys(file);

  const { sendErrorMessage } = useFlashMessage();

  const { bulkAddressLabelsStatus, bulkAddressLabelsError } = useSelector(
    (state) => state.ui
  );
  const { documents, filesStatus, filesError } = useSelector(
    (state) => state.files
  );

  const addressLabelsTemplate =
    typeof documents !== "undefined" ? documents?.addressLabelCsv : [];

  const addressLabelCsv =
    (addressLabelsTemplate || []).length > 0
      ? addressLabelsTemplate[0]
      : undefined;

  const working = bulkAddressLabelsStatus === STATUS.LOADING;
  const filesLoading = filesStatus === STATUS.LOADING;

  useEffect(() => {
    canDismissCallback(!working);
  }, [canDismissCallback, working]);

  const generateTemplate = () => {
    reduxDispatch(generateFile(ADDRESS_LABEL_CSV));
  };

  const downloadTemplate = (document) => {
    reduxDispatch(downloadFile(document.documentGUID));
  };

  const handleUpdateFile = (newFile, fileKey) => {
    if (newFile && newFile.size === 0) {
      sendErrorMessage(t("addLedger.fileUpload.fileEmpty"));
      return;
    }

    if (typeof fileKey === "undefined") {
      setFile(newFile);
    } else if (typeof newFile !== "undefined") {
      setFile({ ...file, [fileKey]: newFile });
    } else {
      const newFiles = {};

      Object.keys(file)
        .filter((key) => key !== fileKey)
        .forEach((key) => {
          newFiles[key] = file[key];
        });

      setFile(newFiles);
    }
  };

  const uploadLabels = () => {
    reduxDispatch(uploadBulkAddressLabels(file));
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (working || files.length < 1) return;
        uploadLabels();
      }}
    >
      <ModalHeader
        title={t("modals.addressLabel.bulkUploadAddressLabels")}
        closeCallback={(event) => {
          event.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />
      <div className="m-4">
        {filesStatus === STATUS.ERROR ? (
          <div className="mb-4">
            <ErrorMessage
              type={filesError?.type || "ANY"}
              message={filesError?.type || filesError?.message}
            />
          </div>
        ) : null}
        {bulkAddressLabelsStatus === STATUS.ERROR ? (
          <div className="mb-4">
            <ErrorMessage
              type={bulkAddressLabelsError?.type || "ANY"}
              message={
                bulkAddressLabelsError?.type ||
                t("modals.addressLabel.uploadError")
              }
            />
          </div>
        ) : null}
        <p className="lead">{t("modals.addressLabel.bulkUploadDescription")}</p>
        <div className="my-4 flex justify-end">
          <DownloadButton
            disabled={filesLoading}
            document={{ ...addressLabelCsv }}
            idLabel={t("modals.addressLabel.template")}
            downloadReadyLabel={t("modals.addressLabel.downloadTemplate")}
            generateLabel={t("modals.addressLabel.generateTemplate")}
            generateCallback={generateTemplate}
            downloadCallback={downloadTemplate}
            filesStatus={filesStatus}
          />
        </div>

        <FileUploadField
          key="bulk-address-label"
          id="bulk-address-label"
          description={t("modals.addressLabel.addressLabelsCsv")}
          file={file[FILE_KEY]}
          fileKey={FILE_KEY}
          fileType={["csv"]}
          disableButtons={working}
          handleUpdateFile={handleUpdateFile}
        />
      </div>

      <ModalControls>
        <Button
          type="submit"
          disabled={working || files.length < 1}
          data-tabbable
          buttonType="primary"
          customClasses="ml-4"
          working={working}
          text={t("button.upload")}
          workingText={t("button.uploading")}
        />
        <Button
          disabled={working}
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
          }}
          data-tabbable
          buttonType="text"
          text={t("button.close")}
        />
      </ModalControls>
    </form>
  );
};

ModalUploadBulkAddressLabels.displayName = "ModalUploadBulkAddressLabels";

export default ModalUploadBulkAddressLabels;
