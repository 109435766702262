import { put, select } from "redux-saga/effects";
import { setLedgersByCurrency, setTokenList } from "app.actions/computables";
import { setSmartWalletList } from "app.reducers/smartWallet";

import { NFT } from "app.constants";

function* computeLedgersByCurrency(allLedgers) {
  const { smartWalletList } = yield select((state) => state.smartWallet);

  const entriesByCurrency = allLedgers.reduce((byCurrency, entry) => {
    const tokenHash = entry.isNFT ? "NFT" : entry.tokenHash;

    const currencyList = byCurrency[tokenHash] || [];
    const newCurrencyList = [...currencyList, entry];

    return {
      ...byCurrency,
      [tokenHash]: newCurrencyList,
    };
  }, {});

  const rawTokenSet = allLedgers
    .reduce((byCurrency, entry) => {
      const currencyCodeNormalized = entry.currency.toUpperCase();
      const currencyName = entry.ledger?.tokenName || currencyCodeNormalized;

      const newToken = {
        symbol: currencyCodeNormalized,
        name: currencyName,
      };

      if (
        byCurrency.findIndex(
          (b) =>
            b !== null &&
            b.symbol === currencyCodeNormalized &&
            b.name === currencyName
        ) === -1
      ) {
        byCurrency.push(newToken);
      } else {
        byCurrency.push(null);
      }

      return byCurrency;
    }, [])
    .filter(Boolean);

  if (typeof smartWalletList !== "undefined") {
    // label smart groups as NFT
    // label a smart wallet at NFT if it contains an NFT ledger
    const smartWalletsComputed = smartWalletList.map((sw) => {
      const currencyLedgers = entriesByCurrency[NFT] || [];
      const isNFT =
        currencyLedgers.findIndex((l) => l.currency === sw.currencyType) > -1;
      return { ...sw, nft: isNFT };
    });
    // fake the response from the API
    yield put(setSmartWalletList({ smartWalletList: smartWalletsComputed }));
  }

  yield put(setTokenList([...rawTokenSet]));
  yield put(setLedgersByCurrency(entriesByCurrency));
}

export default computeLedgersByCurrency;
