import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { disableMFA } from "app.actions/account";

import { dismissModal } from "app.reducers/ui";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import { Input, Label, PasswordInput } from "app.components/Util/Form";
import ErrorMessage from "app.components/Util/ErrorMessage";
import Button from "app.components/Util/Button";
import { ACCOUNT_STATUS } from "app.constants/loadingStates";
import { useTranslation } from "react-i18next";

// actions
const UPDATE_FIELD = "update_field";
const CLEAR = "clear";

const initialState = {
  totp: "",
  currentPassword: "",
};

function disableMFAReducer(state, action) {
  switch (action.type) {
    case UPDATE_FIELD:
      return {
        ...state,
        [action.name]: action.value,
      };
    case CLEAR:
      return { ...initialState };
    default:
      throw new Error();
  }
}

function ModalMFAEnabled() {
  const { t } = useTranslation();

  const reduxDispatch = useDispatch();

  const [state, localDispatch] = useReducer(disableMFAReducer, initialState);

  const handleDismiss = (e) => {
    e?.preventDefault();
    reduxDispatch(dismissModal());
  };

  const { editableSecurity, securityStatus, securityError } = useSelector(
    (s) => s.account
  );

  useEffect(() => {
    if (securityStatus === ACCOUNT_STATUS.UPDATED) {
      reduxDispatch(dismissModal());
    }
  }, [reduxDispatch, securityStatus, editableSecurity]);

  const { currentPassword, totp } = state;
  const isWorking = securityStatus === ACCOUNT_STATUS.UPDATING;
  const blockSubmit = isWorking || !currentPassword || !totp || totp.length < 6;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (blockSubmit) return;
    reduxDispatch(disableMFA(currentPassword, totp));
  };

  const handleUpdate = (name, value) => {
    localDispatch({
      type: UPDATE_FIELD,
      name,
      value,
    });
  };

  const handleClear = (e) => {
    e.preventDefault();
    localDispatch({
      type: CLEAR,
    });
  };

  return (
    <>
      <ModalHeader
        title={t("account.twoFactorAuthentication.twoFactorAuthentication")}
        closeCallback={handleDismiss}
      />
      <form onSubmit={handleSubmit} data-testid="modal-mfa-enabled-form">
        <div className="m-4">
          <ErrorMessage message={securityError} type={securityError} />
          <p className="my-4">
            {t("account.twoFactorAuthentication.authDisableInfo")}
          </p>

          <PasswordInput
            currentPassword={{
              value: currentPassword,
              label: t("input.label.currentPassword"),
              disabled: isWorking,
              callback: handleUpdate,
            }}
          />

          <div className="mb-4 flex flex-col">
            <Label htmlFor="totp">{t("input.label.verificationCode")}</Label>
            <Input
              value={totp}
              className="w-24 tracking-widest"
              type="text"
              id="totp"
              data-testid="totp"
              name="totp"
              placeholder="000 000"
              disabled={isWorking}
              onChange={(e) => {
                e.preventDefault();
                handleUpdate(e.target.name, e.target.value);
              }}
            />
          </div>
        </div>
        <ModalControls>
          <Button
            type="submit"
            customClasses="ml-4"
            buttonType="danger"
            disabled={blockSubmit}
            working={isWorking}
            text={t("button.disable")}
            workingText={t("button.working")}
          />
          <Button
            buttonType="text"
            disabled={blockSubmit}
            onClick={handleClear}
            text={t("button.cancel")}
          />
        </ModalControls>
      </form>
    </>
  );
}

ModalMFAEnabled.displayName = "ModalMFAEnabled";

export default ModalMFAEnabled;
