import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* connect exchange api */
/** *************************************************************************** */
export const MIGRATE_SOURCES_BEGIN = "MIGRATE_SOURCES_BEGIN";
export const MIGRATE_SOURCES_RECEIVE = "MIGRATE_SOURCES_RECEIVE";
export const MIGRATE_SOURCES_REQUEST = "MIGRATE_SOURCES_REQUEST";
export const MIGRATE_SOURCES_ERROR = "MIGRATE_SOURCES_ERROR";

export const migrateSourcesViaUpload = makeActionCreator(
  MIGRATE_SOURCES_BEGIN,
  "exchange",
  "responseId",
  "sources"
);

export const requestMigrateSourcesViaUpload = makeActionCreator(
  MIGRATE_SOURCES_REQUEST
);
export const receiveMigrateSourcesViaUpload = makeActionCreator(
  MIGRATE_SOURCES_RECEIVE,
  "exchangeId",
  "response"
);
export const receiveMigrateSourcesViaUploadError = makeActionCreator(
  MIGRATE_SOURCES_ERROR,
  "response"
);
