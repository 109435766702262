import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* address transaction history */
/** *************************************************************************** */

export const ADDRESS_TRANSACTION_HISTORY_BEGIN =
  "ADDRESS_TRANSACTION_HISTORY_BEGIN";
export const ADDRESS_TRANSACTION_HISTORY_REQUEST =
  "ADDRESS_TRANSACTION_HISTORY_REQUEST";
export const ADDRESS_TRANSACTION_HISTORY_RECEIVE =
  "ADDRESS_TRANSACTION_HISTORY_RECEIVE";

export const fetchAddressTransactionHistory = makeActionCreator(
  ADDRESS_TRANSACTION_HISTORY_BEGIN,
  "ledgerId",
  "address"
);
export const requestAddressTransactionHistory = makeActionCreator(
  ADDRESS_TRANSACTION_HISTORY_REQUEST
);
export const receiveAddressTransactionHistory = makeActionCreator(
  ADDRESS_TRANSACTION_HISTORY_RECEIVE,
  "response"
);
