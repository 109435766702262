import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* create exchange ledgers */
/** *************************************************************************** */
export const CREATE_EXCHANGE_LEDGERS_BEGIN = "CREATE_EXCHANGE_LEDGERS_BEGIN";
export const CREATE_EXCHANGE_LEDGERS_REQUEST =
  "CREATE_EXCHANGE_LEDGERS_REQUEST";

export const CREATE_EXCHANGE_LEDGERS_RECEIVE =
  "CREATE_EXCHANGE_LEDGERS_RECEIVE";
export const CREATE_EXCHANGE_LEDGERS_ERROR = "CREATE_EXCHANGE_LEDGERS_ERROR";

export const createExchangeLedgers = makeActionCreator(
  CREATE_EXCHANGE_LEDGERS_BEGIN,
  "exchangeId",
  "linkGUID",
  "accountIds",
  "accountingStrategy"
);
export const requestCreateExchangeLedgers = makeActionCreator(
  CREATE_EXCHANGE_LEDGERS_REQUEST
);
export const receiveCreateExchangeLedgers = makeActionCreator(
  CREATE_EXCHANGE_LEDGERS_RECEIVE,
  "response",
  "exchangeId"
);
export const createExchangeLedgersError = makeActionCreator(
  CREATE_EXCHANGE_LEDGERS_ERROR,
  "response",
  "error"
);
