import { call, put, all } from "redux-saga/effects";
import {
  requestUpdateExchangeApiKey,
  receiveUpdateExchangeApiKeyError,
  receiveUpdateExchangeApiKey,
  fetchLinkedExchanges,
} from "app.actions/exchanges";
import { fetchDoExchangeLedgerImport } from "app.actions/exchangeLedger";
import { completeApiKeyModal } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

function* updateExchangeAPI(action) {
  const {
    exchange,
    linkGUID,
    apiKey,
    secretKey,
    passphrase,
    callLedgerImportAfterHandleKey,
    ledgerIds,
    exchangeId,
  } = action;

  yield put(requestUpdateExchangeApiKey());

  const response = yield call(
    ExchangesAPI.updateExchangeApiKey,
    exchange.id,
    linkGUID,
    apiKey,
    secretKey,
    passphrase
  );

  const { body, error } = response;
  if (error) {
    if (typeof body.metadata !== "undefined") {
      yield put(
        receiveUpdateExchangeApiKeyError(
          { type: "bad-key", message: body.metadata.message },
          error
        )
      );
    } else {
      yield put(receiveUpdateExchangeApiKeyError(body, error));
    }
  } else {
    yield all([
      put(completeApiKeyModal()),
      put(receiveUpdateExchangeApiKey(body)),
      put(fetchLinkedExchanges()),
    ]);

    if (callLedgerImportAfterHandleKey) {
      yield put(fetchDoExchangeLedgerImport(ledgerIds, exchangeId, linkGUID));
    }
  }
}

export default updateExchangeAPI;
