import { testLocalStorage } from "./testLocalStorage";
import { testSessionStorage } from "./testSessionStorage";

const LOCAL = "local";
const SESSION = "session";

function retrievePersistedState(baseState, storageKey, storageLocation) {
  try {
    let storedState;
    if (storageLocation === SESSION) {
      storedState = sessionStorage.getItem(storageKey);
    } else {
      storedState = localStorage.getItem(storageKey);
    }

    if (typeof storedState !== "undefined" && storedState !== null) {
      const savedState = JSON.parse(storedState) || {};
      return {
        ...baseState,
        ...savedState,
      };
    }
  } catch (e) {
    console.error(e);
  }
  return baseState;
}

export function retrieveFromSession(baseState, storageKey) {
  if (testSessionStorage() && storageKey) {
    return retrievePersistedState(baseState, storageKey, SESSION);
  }
  return baseState;
}

export function retrieveFromLocal(baseState, storageKey) {
  if (testLocalStorage() && storageKey) {
    return retrievePersistedState(baseState, storageKey, LOCAL);
  }
  return baseState;
}
