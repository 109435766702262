import { call, put } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import { getLoadingState } from "app.utils/selectors";
import OrganizationAPI from "app.api/OrganizationAPI";

function* fetchAccountingPreferences() {
  const { isLoading } = yield* getLoadingState(
    (state) => state.organizations.preferencesStatus
  );

  if (isLoading) return;

  yield put(organizationActions.requestOrgAccountingPreferences());

  const response = yield call(OrganizationAPI.getOrgAccountingPreferences);

  if (response.error) {
    console.error(
      "Error in fetchAccountingPreferences",
      response.body,
      response.error
    );
  } else {
    yield put(
      organizationActions.receiveOrgAccountingPreferences(response.body)
    );
  }
}

export default fetchAccountingPreferences;
