import { makeActionCreator } from "app.actions/helpers";

export const BILLING_INFO_RECEIVE = "BILLING_INFO_RECEIVE";
export const USER_PLAN_SUBSCRIPTION_RECEIVE = "USER_PLAN_SUBSCRIPTION_RECEIVE";

export const SUBSCRIPTION_ENTER = "SUBSCRIPTION_ENTER";

export const SUBSCRIPTION_LOADED = "SUBSCRIPTION_LOADED";
export const SUBSCRIPTION_LEAVE = "SUBSCRIPTION_LEAVE";

export const SUBSCRIPTION_QUERYING = "SUBSCRIPTION_QUERYING";

export const subscriptionQuerying = makeActionCreator(SUBSCRIPTION_QUERYING);

export const enterSubscriptionSection = makeActionCreator(SUBSCRIPTION_ENTER);
export const subscriptionSectionLoaded = makeActionCreator(
  SUBSCRIPTION_LOADED,
  "plans",
  "activePlan"
);
export const leaveSubscriptionSection = makeActionCreator(SUBSCRIPTION_LEAVE);

export const receiveUserBillingInfo = makeActionCreator(
  BILLING_INFO_RECEIVE,
  "billingInformation"
);

export const receiveUserPlanSubscription = makeActionCreator(
  USER_PLAN_SUBSCRIPTION_RECEIVE,
  "currentSubscription"
);
