import { makeActionCreator } from "app.actions/helpers";
// EXCHANGE_LEDGER_DO_IMPORT
export const EXCHANGE_LEDGER_IMPORT_BEGIN = "EXCHANGE_LEDGER_IMPORT_BEGIN";
export const EXCHANGE_LEDGER_IMPORT_REQUEST = "EXCHANGE_LEDGER_IMPORT_REQUEST";
export const EXCHANGE_LEDGER_IMPORT_RECEIVE = "EXCHANGE_LEDGER_IMPORT_RECEIVE";
export const EXCHANGE_LEDGER_IMPORT_ERROR = "EXCHANGE_LEDGER_IMPORT_ERROR";

export const fetchDoExchangeLedgerImport = makeActionCreator(
  EXCHANGE_LEDGER_IMPORT_BEGIN,
  "ledgerIds",
  "exchangeId",
  "linkGUID"
);

export const requestDoExchangeLedgerImport = makeActionCreator(
  EXCHANGE_LEDGER_IMPORT_REQUEST,
  "ledgerIds",
  "source"
);
export const receiveDoExchangeLedgerImport = makeActionCreator(
  EXCHANGE_LEDGER_IMPORT_RECEIVE,
  "response",
  "ledgerIds",
  "source"
);
export const doExchangeLedgerImportError = makeActionCreator(
  EXCHANGE_LEDGER_IMPORT_ERROR,
  "response"
);
