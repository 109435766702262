import { makeActionCreator } from "app.actions/helpers";

export const SUPPORTED_LANGUAGES_BEGIN = "SUPPORTED_LANGUAGES_BEGIN";
export const SUPPORTED_LANGUAGES_REQUEST = "SUPPORTED_LANGUAGES_REQUEST";
export const SUPPORTED_LANGUAGES_RECEIVE = "SUPPORTED_LANGUAGES_RECEIVE";
export const SUPPORTED_LANGUAGES_ERROR = "SUPPORTED_LANGUAGES_ERROR";

export const fetchSupportedLanguages = makeActionCreator(
  SUPPORTED_LANGUAGES_BEGIN
);
export const requestSupportedLanguages = makeActionCreator(
  SUPPORTED_LANGUAGES_REQUEST,
  "nextStatus"
);
export const receiveSupportedLanguages = makeActionCreator(
  SUPPORTED_LANGUAGES_RECEIVE,
  "languages",
  "nextStatus"
);
export const supportedLanguagesError = makeActionCreator(
  SUPPORTED_LANGUAGES_ERROR,
  "error"
);
