import { makeActionCreator } from "app.actions/helpers";

export const ACCOUNT_MFA_ENABLE_BEGIN = "ACCOUNT_MFA_ENABLE_BEGIN";
export const ACCOUNT_MFA_ENABLE_REQUEST = "ACCOUNT_MFA_ENABLE_REQUEST";
export const ACCOUNT_MFA_ENABLE_RECEIVE = "ACCOUNT_MFA_ENABLE_RECEIVE";
export const ACCOUNT_MFA_ENABLE_ERROR = "ACCOUNT_MFA_ENABLE_ERROR";

export const enableMFA = makeActionCreator(
  ACCOUNT_MFA_ENABLE_BEGIN,
  "currentPassword",
  "totp"
);
export const enableMFARequest = makeActionCreator(ACCOUNT_MFA_ENABLE_REQUEST);
export const enableMFAError = makeActionCreator(
  ACCOUNT_MFA_ENABLE_ERROR,
  "error"
);
export const enableMFAReceive = makeActionCreator(ACCOUNT_MFA_ENABLE_RECEIVE);
