import { call, put } from "redux-saga/effects";
import { getSortBy } from "app.utils";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestExchangeMultiTokenWorksheet,
  exchangeMultiTokenWorksheetError,
  receiveExchangeMultiTokenWorksheet,
} from "app.actions/exchangeLedger";

function* fetchExchangeMultiTokenWorksheet(action) {
  const { ledgerId, transactionId, entryId } = action;

  if (typeof transactionId === "undefined") return;

  yield put(requestExchangeMultiTokenWorksheet(entryId));

  const response = yield call(
    ExchangeLedgerAPI.getExchangeMultiTokenWorksheet,
    ledgerId,
    transactionId
  );

  if (response.error) {
    yield put(
      exchangeMultiTokenWorksheetError(entryId, response.body, response.error)
    );
  } else {
    // ensure applied order is respected on worksheets
    const sortByTokenNumber = getSortBy((x) => x.tokenNumber, true);
    response.body.sort(sortByTokenNumber);
    yield put(receiveExchangeMultiTokenWorksheet(entryId, response.body));
  }
}

export default fetchExchangeMultiTokenWorksheet;
