import { call, put, all, select } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import OrganizationAPI from "app.api/OrganizationAPI";

import {
  requestUpdateCustomFieldsData,
  receiveUpdateCustomFieldsData,
  fetchCustomFieldsData,
} from "app.reducers/ui";

function* updateCustomFieldsData(action) {
  const { customData, updatingField, isOrganizationAdmin } = action.payload;

  const isUpdatingFields = yield select(
    (state) => state.ui.isUpdatingFields[updatingField]
  );
  if (isUpdatingFields) return;
  yield put(requestUpdateCustomFieldsData(updatingField));

  let response;
  if (isOrganizationAdmin && isOrganizationAdmin === true) {
    response = yield call(OrganizationAPI.setCustomFieldsData, customData);
  } else {
    response = yield call(ExchangeLedgerAPI.setCustomFieldsData, customData);
  }

  const { error, body } = response;
  if (error) {
    console.error({ error, body });
  } else {
    yield all([
      put(fetchCustomFieldsData()),
      put(receiveUpdateCustomFieldsData(updatingField)),
    ]);
  }
}

export default updateCustomFieldsData;
