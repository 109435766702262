import { call, put, select } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import {
  requestCreateManualTransferLink,
  receiveCreateManualTransferLink,
  errorCreateManualTransferLink,
  fetchTimeline,
  fetchExchangeLedger,
} from "app.actions/exchangeLedger";

import * as smartWalletActions from "app.reducers/smartWallet";

import { STATUS, TIMELINE } from "app.constants";

function* createManualTransfer(action) {
  const { itemIds } = action;

  const { dataLedgerId } = yield select((state) => state.exchangeLedger);

  yield put(requestCreateManualTransferLink(STATUS.LOADING));

  const response = yield call(ExchangeLedgerAPI.createManualTransfer, itemIds);

  if (response.error) {
    yield put(
      errorCreateManualTransferLink(response.body.message, STATUS.ERROR)
    );
  } else {
    yield put(receiveCreateManualTransferLink(STATUS.SUCCESS));

    let itemsFetch;
    if (typeof dataLedgerId !== "undefined") {
      const lastRequestParams = yield select(
        (state) => state.exchangeLedger.lastRequestParams
      );

      itemsFetch =
        dataLedgerId === TIMELINE
          ? fetchTimeline(...lastRequestParams, true)
          : fetchExchangeLedger(...lastRequestParams, true);
    }

    if (itemsFetch) {
      yield put(itemsFetch);
    }
    yield put(smartWalletActions.setSmartRunning());
  }
}

export default createManualTransfer;
