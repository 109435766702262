import { makeActionCreator } from "app.actions/helpers";

export const SUPPORTED_FIATS_BEGIN = "SUPPORTED_FIATS_BEGIN";
export const SUPPORTED_FIATS_REQUEST = "SUPPORTED_FIATS_REQUEST";
export const SUPPORTED_FIATS_RECEIVE = "SUPPORTED_FIATS_RECEIVE";
export const SUPPORTED_FIATS_ERROR = "SUPPORTED_FIATS_ERROR";

export const fetchSupportedFiats = makeActionCreator(SUPPORTED_FIATS_BEGIN);
export const requestSupportedFiats = makeActionCreator(
  SUPPORTED_FIATS_REQUEST,
  "nextStatus"
);
export const receiveSupportedFiats = makeActionCreator(
  SUPPORTED_FIATS_RECEIVE,
  "fiats",
  "nextStatus"
);
export const supportedFiatsError = makeActionCreator(
  SUPPORTED_FIATS_ERROR,
  "error"
);
