export const EXCHANGE_IDS = {
  ASCENDEX: "ASCENDEX",
  BINANCE_US: "BINANCE_US",
  BINANCE: "BINANCE",
  BITFINEX: "BITFINEX",
  BITGO: "BITGO",
  BITGO_WALLET: "BITGO_WALLET",
  BITMART: "BITMART",
  BITMEX: "BITMEX",
  BITSO: "BITSO",
  BITSTAMP: "BITSTAMP",
  BITTREX_UPLOAD: "BITTREX_UPLOAD",
  BITTREX: "BITTREX",
  BTSE: "BTSE",
  BYBIT: "BYBIT",
  CASHAPP: "CASHAPP",
  CEX_IO: "CEX_IO",
  COINBASE_API_KEY: "COINBASE_API_KEY",
  COINBASE_UPLOAD: "COINBASE_UPLOAD",
  COINBASE: "COINBASE",
  COINBASE_EXCHANGE: "COINBASE_EXCHANGE",
  COINBASEPRIME: "COINBASEPRIME",
  COINBASEPRO: "COINBASEPRO",
  CRYPTOCOM: "CRYPTOCOM",
  CRYPTOPIA: "CRYPTOPIA",
  DERIBIT: "DERIBIT",
  ETHERSCAN_UPLOAD: "ETHERSCAN_UPLOAD",
  EXODUS: "EXODUS",
  FIREBLOCKS: "FIREBLOCKS",
  FTX_US: "FTX_US",
  FTX: "FTX",
  GATEIO: "GATEIO",
  GDAX: "GDAX",
  GEMINI_UPLOAD: "GEMINI_UPLOAD",
  GEMINI: "GEMINI",
  GENERIC: "GENERIC",
  HITBTC: "HITBTC",
  HOTBIT: "HOTBIT",
  HUOBI: "HUOBI",
  KRAKEN: "KRAKEN",
  KUCOIN: "KUCOIN",
  KOINLY: "KOINLY",
  OKX: "OKX",
  NETCOINS: "NETCOINS",
  POLONIEX: "POLONIEX",
  ROBINHOOD: "ROBINHOOD",
  UPHOLD: "UPHOLD",
  YOBIT: "YOBIT",
};

export const SHADOW_EXCHANGE = "SHADOW";
export const COLLATERAL_SUBTYPE = "COLLATERAL";
export const SOURCELESS = "SOURCELESS";
export const BRIDGE = "BRIDGE";

export const LEDGER_TYPE_PATH = "ledgers.typeLabel";
