import { call, put } from "redux-saga/effects";
import { getLoadingState } from "app.utils/selectors";
import { sortExchanges, applySourceOverrides } from "app.utils";
import {
  requestSupportedExchanges,
  receiveSupportedExchangesError,
  receiveSupportedExchanges,
} from "app.actions/exchanges";
import ExchangesAPI from "app.api/ExchangesAPI";

import {
  STATUS,
  API_INTEGRATION_TYPES,
  SOURCE_TYPES,
  CUSTODIAL_WALLETS,
} from "app.constants";

function* fetchSupportedExchanges() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchanges.supportedExchangesStatus
  );

  if (isLoading) return;

  yield put(requestSupportedExchanges(nextStatus));

  const response = yield call(ExchangesAPI.getSupportedExchanges);

  if (response.error) {
    yield put(receiveSupportedExchangesError(response.body, response.error));
  } else {
    const sortedExchanges = sortExchanges(response.body);

    const sortedExchangesOverridden = applySourceOverrides(sortedExchanges);

    // give ALL exchanges a groupKey
    const sortedExchangesWithGroupKey = sortedExchangesOverridden.map(
      (exchange) => {
        const modifiedExchange = { ...exchange };
        if (typeof modifiedExchange.groupKey === "undefined") {
          modifiedExchange.groupKey = exchange.name;
        }
        return modifiedExchange;
      }
    );

    const wallets = sortedExchangesWithGroupKey.filter(
      (x) =>
        x.type === SOURCE_TYPES.WALLET &&
        CUSTODIAL_WALLETS.findIndex((w) => w === x.name) < 0 &&
        x.evm !== true &&
        x.smartContractPlatform !== true
    );

    const custodialWallets = sortedExchangesWithGroupKey.filter(
      (x) => CUSTODIAL_WALLETS.findIndex((w) => w === x.name) > -1
    );

    const evmWallets = sortedExchangesWithGroupKey.filter(
      (x) => x.evm === true || x.smartContractPlatform
    );

    const currencies = new Set();
    wallets.forEach((exchange) => {
      if (exchange.hidden !== true) {
        currencies.add(exchange.groupKey);
      }
    });

    const supportedCurrencies = [...currencies];

    const apiBasedExchanges = sortedExchangesWithGroupKey.filter(
      (x) => API_INTEGRATION_TYPES.indexOf(x.integrationType) >= 0
    );

    yield put(
      receiveSupportedExchanges(
        sortedExchangesWithGroupKey,
        supportedCurrencies,
        custodialWallets,
        evmWallets,
        apiBasedExchanges,
        STATUS.LOADED
      )
    );
  }
}

export default fetchSupportedExchanges;
