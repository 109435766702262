import React from "react";
import CashMoney from "app.components/Util/CashMoney";
import TableListCell from "app.components/TableList/TableListCell";
import { useTranslation } from "react-i18next";

function BasePricingRows({ amountDollars, title, plan, description }) {
  const { t } = useTranslation();

  const isProrated =
    plan.adjustedAmountDollars !== amountDollars &&
    plan.prorationType === "upgrade";

  const tierBasedPriced = isProrated ? (
    <div className="flex justify-end">
      <CashMoney
        size="none"
        align="right"
        className="text-zinc-400 line-through"
        dollars={amountDollars}
        fiatOverride="USD"
      />
      &nbsp;
      <CashMoney
        size="none"
        align="right"
        boldText
        dollars={plan.adjustedAmountDollars || 10}
        fiatOverride="USD"
      />
    </div>
  ) : (
    <CashMoney
      size="none"
      align="right"
      dollars={amountDollars}
      fiatOverride="USD"
      boldText
    />
  );

  return (
    <>
      <tr data-testid="base-price-row">
        <TableListCell custom="pl-0" size="sm">
          <p className="font-bold">{title}</p>
          <p className="text-xs text-zinc-500">{description}</p>
        </TableListCell>
        <TableListCell custom="pr-0 align-top" size="sm" right>
          {tierBasedPriced}
        </TableListCell>
      </tr>
      {isProrated && (
        <tr>
          <TableListCell custom="pl-0 pr-0" size="sm" colSpan="2" center>
            <small>{t("modals.priceHasBeenProrated")}</small>
          </TableListCell>
        </tr>
      )}
    </>
  );
}

export default BasePricingRows;
