import { makeActionCreator } from "app.actions/helpers";

export const AUTO_REFRESH_ORGANIZATION_TOKEN_START =
  "AUTO_REFRESH_ORGANIZATION_TOKEN_START";
export const AUTO_REFRESH_ORGANIZATION_TOKEN_END =
  "AUTO_REFRESH_ORGANIZATION_TOKEN_END";

export const autoRefreshOrganizationToken = makeActionCreator(
  AUTO_REFRESH_ORGANIZATION_TOKEN_START
);
export const stopRefreshingOrganizationToken = makeActionCreator(
  AUTO_REFRESH_ORGANIZATION_TOKEN_END
);
