import {
  setBasicLocalStorageSettings,
  setUiLocalStorageKey,
} from "app.reducers/ui";

import { LOCAL_STORAGE_UI_PREFIX } from "app.constants";
import { ALL } from "app.components/Util/DatePicker";
import { testLocalStorage } from "app.utils";
import { put, select } from "redux-saga/effects";

function* getBasicLocalStorageConfig() {
  const user = yield select((state) => state.user);
  const userGUID = user.user?.userGUID || undefined;
  const localStorageKey = `${LOCAL_STORAGE_UI_PREFIX}-${userGUID}`;
  yield put(setUiLocalStorageKey(localStorageKey));

  if (userGUID && testLocalStorage()) {
    try {
      const localStorageState = localStorage[localStorageKey]
        ? JSON.parse(localStorage[localStorageKey])
        : {};

      const uiState = yield select((state) => state.ui);

      const nextUiState = { ...uiState };

      if (nextUiState.selectedDatePreset === ALL.id) {
        nextUiState.selectedDateRange = ALL.range;
      }

      // make sure we are using the right date range when "ALL" is selected
      let localStorageDateRange = localStorageState.selectedDateRange;

      if (localStorageState.selectedDatePreset === ALL.id) {
        localStorageDateRange = ALL.range;
      }

      if (!Array.isArray(localStorageState.recentlyViewedLedgers)) {
        localStorageState.recentlyViewedLedgers = [];
      }

      yield put(
        setBasicLocalStorageSettings({
          ...nextUiState,
          ...localStorageState,
          selectedDateRange: localStorageDateRange,
          setUiLocalStorageKey: localStorageKey,
        })
      );
    } catch (e) {
      console.error(e);
    }
  }
}

export default getBasicLocalStorageConfig;
