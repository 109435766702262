import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "app.components/Util/LoadingSpinner";
import { fetchExchangeLedgerImports } from "app.actions/exchangeLedger";
import { STATUS } from "app.constants";
import Button from "app.components/Util/Button";
import { useTranslation } from "react-i18next";
import ImportHistory from "./Import/ImportHistory/ImportHistory";

const SHOW = 10;

const LedgerImports = ({ ledgerId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [numToShow, setNumToShow] = useState(SHOW);

  const { imports, importsStatus } = useSelector(
    (state) => state.exchangeLedger
  );

  useEffect(() => {
    dispatch(fetchExchangeLedgerImports(ledgerId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeof imports === "undefined") return null;

  return (
    <>
      {importsStatus === STATUS.LOADING ||
      importsStatus === STATUS.UNINITIALIZED ? (
        <div className="my-4 flex justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <ImportHistory imports={imports.slice(0, numToShow)} />
      )}

      {numToShow < imports.length ? (
        <p className="mt-4 flex justify-center">
          <Button
            buttonSize="sm"
            buttonType="text"
            onClick={(e) => {
              e.preventDefault();
              setNumToShow(numToShow + SHOW);
            }}
            text={t("button.showMore")}
          />
        </p>
      ) : null}
    </>
  );
};

LedgerImports.displayName = "LedgerImports";

export default LedgerImports;
