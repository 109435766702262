import React from "react";

import { formatCurrencyValue } from "app.utils/currencies";

const BalanceColumn = ({ balance, row }) => {
  return (
    <div className="flex items-center justify-end gap-1 whitespace-nowrap">
      <span>{formatCurrencyValue(balance)}</span>

      <span className="inline-block max-w-[200px] truncate">
        {row.currency}
      </span>
    </div>
  );
};

export default BalanceColumn;
