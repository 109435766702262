import { makeActionCreator } from "app.actions/helpers";

// ENTRY DESCRIPTION
export const ARCHIVE_LEDGER_BEGIN = "ARCHIVE_LEDGER_BEGIN";
export const ARCHIVE_LEDGER_REQUEST = "ARCHIVE_LEDGER_REQUEST";
export const ARCHIVE_LEDGER_RECEIVE = "ARCHIVE_LEDGER_RECEIVE";

// ENTRY DESCRIPTION
export const archiveLedger = makeActionCreator(
  ARCHIVE_LEDGER_BEGIN,
  "ledgerId",
  "userArchive",
  "redirectAfterArchive"
);

export const requestArchiveLedger = makeActionCreator(
  ARCHIVE_LEDGER_REQUEST,
  "ledgerId",
  "userArchive"
);

export const receiveArchiveLedger = makeActionCreator(
  ARCHIVE_LEDGER_RECEIVE,
  "ledgerId"
);
