import React, { useState } from "react";
import SortIndicator from "app.components/SortIndicator";
import SvgLoader from "app.components/Util/SvgLoader";

const SIZE = {
  sm: "px-2.5 py-1",
  md: "px-4 py-1.5",
};

const TableListHeader = (props) => {
  const {
    custom,
    size = "md",
    left,
    right,
    center,
    children,
    sticky = false,
    sortCallback,
    sortColumn,
    sortedBy,
    sortDirection,
    onlyShowWhenSorted = false,
    basicBackground = false,
    simpleText = false,
    expandCallback = undefined,
    overrideHeaderClass,
    ...htmlAttributes
  } = props;

  const [expanded, setExpanded] = useState(false);

  function expand(e) {
    e.stopPropagation();
    e.preventDefault();
    const nextState = !expanded;
    setExpanded(nextState);
    expandCallback(nextState);
  }

  const hideSort = onlyShowWhenSorted && sortColumn !== sortedBy;

  const thClassName =
    typeof overrideHeaderClass !== "undefined"
      ? overrideHeaderClass
      : [
          "c_table-list-header",
          basicBackground ? "border-b bg-white" : "bg-zinc-100",
          typeof sortCallback !== "undefined" ? "hover:bg-zinc-200/70" : "",
          simpleText
            ? "font-normal text-zinc-500"
            : "font-bold uppercase text-zinc-500",
          "text-xs",
          "transition-all",
          "group",
          "whitespace-nowrap",
          sticky ? "sticky top-[-1px] z-10" : null,
          SIZE[size],
          left === true ? "text-left" : null,
          center === true ? "text-center" : null,
          right === true ? "text-right" : null,
          custom || "",
        ]
          .filter(Boolean)
          .join(" ");

  const sortClass = [
    "flex",
    "items-center",
    "cursor-pointer",
    left === true ? "justify-start" : null,
    center === true ? "justify-center" : null,
    right === true ? "justify-end" : null,
  ]
    .filter(Boolean)
    .join(" ");

  const cellContent =
    typeof sortCallback !== "undefined" ? (
      <div className={sortClass}>
        {children}
        <SortIndicator asc={sortDirection} hideChevron={hideSort} />
        {expandCallback ? (
          <button type="button" className="ml-auto" onClick={expand}>
            <SvgLoader
              id="ChevronDouble"
              className={
                expanded
                  ? "h-4 w-4 rotate-180 transform stroke-zinc-400/70 opacity-0 transition-all hover:stroke-zinc-600 group-hover:opacity-100"
                  : "h-4 w-4 stroke-zinc-400/70 opacity-0 transition-all hover:stroke-zinc-600 group-hover:opacity-100"
              }
            />
          </button>
        ) : null}
      </div>
    ) : (
      children
    );

  return (
    <th
      className={thClassName}
      onClick={typeof sortCallback !== "undefined" ? sortCallback : undefined}
      {...htmlAttributes}
    >
      {cellContent}
    </th>
  );
};
export default TableListHeader;
