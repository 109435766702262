import React from "react";
import { useSelector } from "react-redux";
import { ACCOUNT_STATUS, getPlanDetails } from "app.constants";
import { isFromReferral } from "app.utils";

import { useTranslation } from "react-i18next";
import OrderBreakdown from "./OrderBreakdown";

function PaymentSummary({ newPlan, zipCode, applyCoupon }) {
  const { t } = useTranslation();

  const { upgradeQuote, referrer, upgradeQuoteStatus, upgradeQuoteError } =
    useSelector((state) => state.account);

  const planDetails = getPlanDetails(newPlan.planId);

  const fromReferral = isFromReferral(newPlan, referrer);

  // any upgrade quote error that is coupon related
  const couponError =
    upgradeQuoteError?.indexOf("coupon") > -1 ? upgradeQuoteError : undefined;

  const availableCouponId = upgradeQuote?.availableCouponId;

  const isUpgradeQuoteLoaded = upgradeQuoteStatus === ACCOUNT_STATUS.LOADED;

  return (
    <div>
      <h3 className="mb-2 font-bold uppercase text-blue-500">
        {t("modals.purchaseSummary")}
      </h3>
      <OrderBreakdown
        plan={upgradeQuote}
        isUpgradeQuoteLoaded={isUpgradeQuoteLoaded}
        availableCouponId={availableCouponId}
        applyCoupon={applyCoupon}
        couponError={couponError}
        finalCost={upgradeQuote?.subtotalAmountDollars}
        fromReferral={fromReferral ? referrer : undefined}
        planDetails={planDetails}
        zipCode={zipCode}
      />
    </div>
  );
}

export default PaymentSummary;
