import React from "react";
import { lookupTranslation, makeKey } from "app.utils";

export const ADDRESS_LABEL_CSV = "ADDRESS_LABEL_CSV";
export const AGG_TAX_FORM_8949 = "AGG_TAX_FORM_8949";
export const BALANCE_SHEET = "BALANCE_SHEET";
export const BSR_GAIN_LOSS = "BSR_GAIN_LOSS";
export const CLOSING_POSITION = "CLOSING_POSITION";
export const DAILY_BALANCE = "DAILY_BALANCE";
export const LIKE_KIND = "LIKE_KIND";
export const ROLL_FORWARD_EXAMPLE = "ROLL_FORWARD_EXAMPLE";
export const UNREALIZED_GAINS = "UNREALIZED_GAINS";

export const DOWNLOAD_DESCRIPTIONS = {
  [ADDRESS_LABEL_CSV]: ["addressLabelCsv"],
  [BALANCE_SHEET]: ["balanceSheet"],
  [BSR_GAIN_LOSS]: ["gainAndLossReport"],
  [UNREALIZED_GAINS]: ["unrealizedGainsReport"],
  [CLOSING_POSITION]: ["closingPositionReport"],
  [DAILY_BALANCE]: ["exchangeDailyBalanceReport"],
  [ROLL_FORWARD_EXAMPLE]: ["rollforwardExample"],
  [AGG_TAX_FORM_8949]: ["irs8949"],
};

export const DOWNLOAD_DESCRIPTIONS_SHORT = {
  [ADDRESS_LABEL_CSV]: ["addressLabelCsv"],
  [BALANCE_SHEET]: ["short.balanceSheet"],
  [BSR_GAIN_LOSS]: ["gainAndLossReport"],
  [UNREALIZED_GAINS]: ["unrealizedGainsReport"],
  [CLOSING_POSITION]: ["closingPositionReport"],
  [DAILY_BALANCE]: ["exchangeDailyBalanceReport"],
  [ROLL_FORWARD_EXAMPLE]: ["rollforwardExample"],
  [AGG_TAX_FORM_8949]: ["irs8949"],
};

export const DOWNLOAD_DESCRIPTIONS_PATH = "constants.downloads";

export const getDownloadDescription = (id) => {
  return DOWNLOAD_DESCRIPTIONS[id]?.map((key, index) => (
    <p key={makeKey(id, index)}>
      {lookupTranslation(key, DOWNLOAD_DESCRIPTIONS_PATH)}
    </p>
  ));
};

export const getDownloadDescriptionShort = (id) => {
  return DOWNLOAD_DESCRIPTIONS_SHORT[id]?.map((key) =>
    lookupTranslation(key, DOWNLOAD_DESCRIPTIONS_PATH)
  );
};
