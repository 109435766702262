import { useSelector } from "react-redux";
import { isOrganizationUser } from "app.utils";

function useIsOrganizationUser() {
  const organizations = useSelector((state) => state.organizations);

  return isOrganizationUser(organizations);
}

export default useIsOrganizationUser;
