import { call, put } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  updatePasswordError,
  updatePasswordRequest,
  updatePasswordReceive,
} from "app.actions/account";
import { lookupTranslation } from "app.utils";

import {
  ACCOUNT_ERROR_PATH,
  ACCOUNT_RESETING_ERROR,
} from "app.constants/errorMessages";

function* handlePasswordChanges(action) {
  const { currentPassword, newPassword, confirmNewPassword, totpToken } =
    action;

  yield put(updatePasswordRequest());

  const response = yield call(
    TellerAPI.sendPasswordUpdate,
    currentPassword,
    newPassword,
    confirmNewPassword,
    totpToken
  );

  if (response.error) {
    const message =
      response.body.message === "invalid_credentials"
        ? "invalid_current_password"
        : response.body.message;

    yield put(
      updatePasswordError(
        lookupTranslation(message, ACCOUNT_ERROR_PATH, ACCOUNT_RESETING_ERROR)
      )
    );
  } else {
    yield put(updatePasswordReceive(response.body));
  }
}

export default handlePasswordChanges;
