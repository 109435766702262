import { put, all } from "redux-saga/effects";
import { removeViewedLedger } from "app.reducers/ui";

export default function curryRemoveRecentlyViewed(ledgerType, selectId) {
  return function* removeFromRecentlyViewed(action) {
    const idData = selectId(action);

    if (Array.isArray(idData)) {
      yield all(
        idData.map((id) =>
          put(removeViewedLedger(parseInt(id, 10), ledgerType))
        )
      );
    } else {
      yield put(removeViewedLedger(parseInt(idData, 10), ledgerType));
    }
  };
}
