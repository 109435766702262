import { makeActionCreator } from "app.actions/helpers";

export const ACCOUNT_PASSWORD_UPDATE_BEGIN = "ACCOUNT_PASSWORD_UPDATE_BEGIN";
export const ACCOUNT_PASSWORD_UPDATE_REQUEST =
  "ACCOUNT_PASSWORD_UPDATE_REQUEST";
export const ACCOUNT_PASSWORD_UPDATE_RECEIVE =
  "ACCOUNT_PASSWORD_UPDATE_RECEIVE";
export const ACCOUNT_PASSWORD_UPDATE_ERROR = "ACCOUNT_PASSWORD_UPDATE_ERROR";

export const updatePassword = makeActionCreator(
  ACCOUNT_PASSWORD_UPDATE_BEGIN,
  "currentPassword",
  "newPassword",
  "confirmNewPassword",
  "totpToken"
);
export const updatePasswordRequest = makeActionCreator(
  ACCOUNT_PASSWORD_UPDATE_REQUEST
);
export const updatePasswordReceive = makeActionCreator(
  ACCOUNT_PASSWORD_UPDATE_RECEIVE,
  "userPassword"
);
export const updatePasswordError = makeActionCreator(
  ACCOUNT_PASSWORD_UPDATE_ERROR,
  "error"
);
