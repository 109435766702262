import React from "react";
import SvgLoader from "app.components/Util/SvgLoader";
import { useTranslation } from "react-i18next";

const FeatureList = () => {
  const { t } = useTranslation();

  return (
    <ul>
      <li className="my-2 flex items-center">
        <SvgLoader id="Checkmark" className="h-4 w-4 stroke-blue-800" />
        <p className="ml-2 flex-1 text-sm">
          {t("upgrade.createUnlimitedLedgers")}
        </p>
      </li>
      <li className="my-2 flex items-center">
        <SvgLoader id="Checkmark" className="h-4 w-4 stroke-blue-800" />
        <p className="ml-2 flex-1 text-sm">
          {t("upgrade.generateTaxDocumentsAndForms")}
        </p>
      </li>
    </ul>
  );
};

FeatureList.displayName = "FeatureList";

export default FeatureList;
