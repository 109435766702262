import * as connect from "./connect";
import * as exchange from "./exchange";
import * as exchanges from "./exchanges";
import * as importData from "./importData";
import * as ledger from "./ledger";
import * as ledgers from "./ledgers";

const ExchangesAPI = {
  ...connect,
  ...exchange,
  ...exchanges,
  ...importData,
  ...ledger,
  ...ledgers,
};

export default ExchangesAPI;
