import React, { useEffect, useState, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Route, Routes } from "react-router";
import { checkAuthenticationStatus } from "app.actions/authentication";
import { clearRedirect } from "app.reducers/ui";
import { isLoaded, isLoading } from "app.utils";
import PlaceholderRouter from "app.components/Placeholder/PlaceholderRouter";
import HeaderPlaceholder from "app.components/Placeholder/HeaderPlaceholder";
import GenericPlaceholder from "app.components/Placeholder/GenericPlaceholder";

import RouterErrorBoundary from "app.components/Util/RouterErrorBoundary";

const AppContainer = lazy(() => import("app.components/AppContainer"));

const AuthenticatedApp = (props) => {
  const reduxDispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const { authenticationStatus } = useSelector((state) => state.authentication);
  const { pushTo } = useSelector((state) => state.ui);
  const navigate = useNavigate();
  const isAuthenticated = isLoaded(authenticationStatus);
  const isAuthenticating = isLoading(authenticationStatus);

  // handle redirects from within a SAGA
  useEffect(() => {
    if (pushTo) {
      navigate(pushTo);
      reduxDispatch(clearRedirect());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushTo]);

  useEffect(() => {
    if (mounted === false) {
      setMounted(true);
      if (!isAuthenticating) {
        reduxDispatch(checkAuthenticationStatus());
      }
    }
  }, [mounted, isAuthenticating, reduxDispatch]);

  const placeholder = (
    <>
      <HeaderPlaceholder />
      <Routes>
        <Route path="portfolio" errorElement={<RouterErrorBoundary />}>
          <Route index path="*" element={<PlaceholderRouter />} />
        </Route>

        <Route
          path="organization"
          errorElement={<RouterErrorBoundary returnToOrgs />}
        >
          <Route index path="*" element={<GenericPlaceholder />} />
        </Route>

        <Route
          index
          path="*"
          errorElement={<RouterErrorBoundary />}
          element={<PlaceholderRouter />}
        />
      </Routes>
    </>
  );

  return isAuthenticated ? (
    <Suspense fallback={placeholder}>
      <AppContainer {...props} />
    </Suspense>
  ) : (
    placeholder
  );
};

AuthenticatedApp.displayName = "AuthenticatedApp";
export default AuthenticatedApp;
