import { select } from "redux-saga/effects";

import { getNextStatus, isLoading } from "app.utils";

export function* getLoadingState(selector) {
  if (typeof selector !== "function")
    throw new Error("expected a selector function in getLoadingState");

  const currentStatus = yield select(selector);

  return {
    isLoading: isLoading(currentStatus),
    nextStatus: getNextStatus(currentStatus),
  };
}
