export const shortenString = (longString, maxLength, tailCharactersToShow) => {
  if (typeof longString === "undefined") return "";
  if (longString === null) return "";
  let shortenedString = longString;
  if (longString.length > maxLength) {
    shortenedString = `${longString.substr(
      0,
      maxLength - 2
    )}\u2026${longString.substr(
      longString.length - tailCharactersToShow,
      tailCharactersToShow
    )}`;
  }
  return shortenedString;
};
