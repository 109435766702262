import api from "app.api/clients/exchange";
/**
 * Submits a request to override the value of an exchange entry
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} itemId - The id of the transaction
 * @param {number} valueOverride - The value to override with
 * @param {string} valueOverrideReason - The field to override
 * @returns {object}  response - The Response or Error message
 */
export const overrideValue = (
  ledgerId,
  itemId,
  valueOverride,
  valueOverrideReason
) => {
  const data = { itemId, valueOverride, valueOverrideReason };

  return api.put(`/ledger/${ledgerId}/itemOverride`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to revert the override value of an exchange entry
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} itemId - The id of the transaction
 * @returns {object}  response - The Response or Error message
 */
export const revertValueOverride = (ledgerId, itemId) => {
  const data = { itemId, revertValueOverride: true };

  return api.put(`/ledger/${ledgerId}/itemOverride`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to edit the exemption status of an exchange ledger
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} transactionId - The transaction id
 * @param {boolean} exempt - The exemption status
 * @returns {object}  response - The Response or Error message
 */
export const editExchangeExempt = (ledgerId, transactionId, exempt) => {
  const data = { transactionId, exempt };
  return api.put(`/ledger/${ledgerId}/exemption`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to edit the transfer status of an exchange ledger item
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} transactionId - The transaction id
 * @param {boolean} transfer - The exemption status
 * @returns {object}  response - The Response or Error message
 */
export const editExchangeTransfer = (
  ledgerId,
  transactionId,
  externalTransfer
) => {
  const data = { transactionId, externalTransfer };
  return api.put(`/ledger/${ledgerId}/externalTransfer`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to edit the payable status of an exchange ledger
 * @async
 * @function
 * @param {number} ledgerItemId - The id of the transaction/item
 * @param {number} toLedgerId - The id of the sourceless ledger (when this value is null a new sourceless ledger will be created)
 * @returns {object}  response - The Response or Error message
 */
export const setTransferOut = (ledgerItemId, toLedgerId, newLedgerName) => {
  const data = { ledgerItemId, toLedgerId };
  // only include newLedgerName if toLedgerId is null
  if (toLedgerId === null && newLedgerName) {
    data.newLedgerName = newLedgerName;
  }
  return api.post(`/ledger/item/transfer/out`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to edit the payable status of an exchange ledger
 * @async
 * @function
 * @param {number} ledgerItemId - The id of the transaction/item
 * @param {number} toLedgerId - The id of the sourceless ledger
 * @returns {object}  response - The Response or Error message
 */
export const setTransferIn = (ledgerItemId, toLedgerId) => {
  const data = { ledgerItemId, toLedgerId };
  return api.post(`/ledger/item/transfer/in`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to edit the payable status of an exchange ledger
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} transactionId - The transaction id
 * @param {boolean} payable - The payable status
 * @returns {object}  response - The Response or Error message
 */
export const editExchangePayable = (ledgerId, transactionId, payable) => {
  const data = { transactionId, payable };
  return api.put(`/ledger/${ledgerId}/payable`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to edit the user description on an exchange transaction
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} transactionId - The transaction id
 * @param {string} userDescription - The user description
 * @returns {object}  response - The Response or Error message
 */
export const editExchangeUserDescription = (
  ledgerId,
  transactionId,
  userDescription
) => {
  const data = { transactionId, userDescription };
  return api.put(`/ledger/${ledgerId}/description`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to clear a review reason
 * @async
 * @function
 * @param {number} itemId - The id of the transaction/item
 * @param {string} reason - The reason to clear
 * @returns {object}  response - The Response or Error message
 */
export const clearReviewReason = (itemId, reason) => {
  const data = { reasons: [reason] };
  return api.put(`/ledger/item/${itemId}/reviewItems/clear`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to override the value of an exchange entry
 * @async
 * @function
 * @param {number} itemId - The id of the transaction
 * @returns {object}  response - The Response or Error message
 */
export const getRelatedItems = (itemId) => {
  return api.get(`/ledger/related/${itemId}`, {
    authenticated: true,
  });
};
