import { call, put } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";

import OrganizationAPI from "app.api/OrganizationAPI";

function* saveAccountingPreferences(action) {
  const { preferences } = action.payload;

  yield put(organizationActions.requestSaveOrgAccountingPreferences());

  const response = yield call(
    OrganizationAPI.setOrgAccountingPreferences,
    preferences
  );

  if (response.error) {
    console.error(
      "Error in saveAccountingPreferences",
      response.body,
      response.error
    );
  } else {
    yield put(organizationActions.receiveSaveOrgAccountingPreferences());
    yield put(organizationActions.fetchOrgAccountingPreferences());
  }
}

export default saveAccountingPreferences;
