import { call, put } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestTimelineMetadata,
  receiveTimelineMetadata,
  timelineMetadataError,
} from "app.actions/exchangeLedger";

import { getLoadingState } from "app.utils/selectors";

import { STATUS } from "app.constants";

function* fetchTimelineMetadata() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchangeLedger.timelineMetadataStatus
  );

  if (isLoading) return;

  yield put(requestTimelineMetadata(nextStatus));

  const response = yield call(ExchangeLedgerAPI.getTimelineMetadata);

  if (response.error) {
    yield put(timelineMetadataError(response.body, response.error));
  } else {
    yield put(receiveTimelineMetadata(response.body, STATUS.LOADED));
  }
}
export default fetchTimelineMetadata;
