import { select, put, call, all } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  requestForgotPassword,
  receiveForgotPasswordError,
  receiveForgotPassword,
} from "app.reducers/user";
import { doRedirect } from "app.reducers/ui";
import { lookupTranslation } from "app.utils";
import {
  FORGOT_PASSWORD_ERROR_PATH,
  FORGOT_PASSWORD_ERROR_DEFAULT,
} from "app.constants";

function* forgotPassword({ payload }) {
  const email = payload;
  const { requestingForgotPassword } = yield select((state) => state.user);

  if (requestingForgotPassword) return;

  yield put(requestForgotPassword());

  const response = yield call(TellerAPI.sendForgotPassword, email);

  if (response.error) {
    const message = lookupTranslation(
      response.body.message,
      FORGOT_PASSWORD_ERROR_PATH,
      FORGOT_PASSWORD_ERROR_DEFAULT
    );

    yield put(receiveForgotPasswordError(message));
  } else {
    yield all([
      put(receiveForgotPassword(response.body)),
      put(doRedirect("/forgotpassword/done")),
    ]);
  }
}

export default forgotPassword;
