import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import {
  changeExchangeTransferStatus,
  changeTransferOutToSourceless,
  clearTransferError,
} from "app.actions/exchangeLedger";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import MessageBanner from "app.components/Util/MessageBanner";
import { Input, Label } from "app.components/Util/Form";
import { LEDGER_TYPES, LEDGER_NAME_MAX_LENGTH } from "app.constants";

import { ledgerNameExists } from "app.utils";

export const MAKE_TAXABLE = "make-taxable";
export const MAKE_NON_TAXABLE = "make-non-taxable";

const NEW_LEDGER = "new-ledger";

function ModalExternalTransfer({ entry, ledgerId, mode }) {
  const [selectedLedger, setSelectedLedger] = useState("");
  const [ledgerName, setLedgerName] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { transferWorking, transferErrorMessage } = useSelector(
    (state) => state.exchangeLedger
  );

  const { ledgers } = useSelector((state) => state.exchanges);
  const { ledgersByCurrency } = useSelector((state) => state.computables);
  const nameError =
    selectedLedger === NEW_LEDGER &&
    ledgerNameExists(ledgerName, ledgersByCurrency);

  const sourcelessLedgers = useMemo(
    () =>
      ledgers.filter(
        ({ ledgerType, digitalCurrency }) =>
          ledgerType === LEDGER_TYPES.SOURCELESS &&
          digitalCurrency === entry.digitalCurrency
      ),
    [ledgers, entry.digitalCurrency]
  );

  const handleDismiss = (e) => {
    e.preventDefault();
    dispatch(dismissModal());
  };

  useEffect(() => {
    dispatch(clearTransferError());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (mode === MAKE_TAXABLE) {
    return (
      <>
        <ModalHeader
          title={t("modals.payableTransaction.markTransactionAsTaxableEvent")}
          closeCallback={handleDismiss}
        />

        <div className="m-4">
          <p className="text-lg">
            {t("modals.payableTransaction.transactionTaxableDescription")}
          </p>
        </div>
        <ModalControls>
          <Button
            type="submit"
            disabled={transferWorking}
            buttonType="primary"
            customClasses="ml-4"
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                changeExchangeTransferStatus(
                  entry.transactionId,
                  false,
                  ledgerId,
                  mode
                )
              );
            }}
            text={t("ledgers.transactionTaxOptions.markAsTaxable")}
          />
          <Button
            onClick={handleDismiss}
            disabled={transferWorking}
            buttonType="text"
            text={t("common.close")}
          />
        </ModalControls>
      </>
    );
  }
  return (
    <>
      <ModalHeader
        title={t("modals.payableTransaction.unmarkTransactionAsTaxableEvent")}
        closeCallback={handleDismiss}
      />
      <div className="mx-4">
        {transferErrorMessage ? (
          <MessageBanner type="danger" customClass="mb-4">
            {transferErrorMessage}
          </MessageBanner>
        ) : null}
        <p>{t("modals.payableTransaction.transactionTransferDescription")}</p>
        <div className="flex items-center pt-4">
          <p>{t("modals.payableTransaction.whereWasThisAssetTransferedTo")}</p>

          <select
            className="ml-2 rounded border border-zinc-100 bg-white px-2 py-1 text-sm shadow"
            name="select-ledger"
            id="select-ledger"
            value={selectedLedger}
            onChange={(e) => {
              e.preventDefault();
              setSelectedLedger(e.target.value);
            }}
          >
            <option value="" disabled>
              {t("modals.payableTransaction.selectLedger")}
            </option>
            <option value={NEW_LEDGER}>
              {t("modals.payableTransaction.newLedger")}
            </option>
            {sourcelessLedgers?.length ? (
              <optgroup label={t("modals.payableTransaction.existingLedgers")}>
                {sourcelessLedgers.map(({ id, userLedgerName }) => (
                  <option key={id} value={id}>
                    {userLedgerName}
                  </option>
                ))}
              </optgroup>
            ) : null}
          </select>
        </div>
        {selectedLedger === NEW_LEDGER ? (
          <div className="mt-2">
            <Label htmlFor="ledgerName">{t("common.name")}</Label>
            <span className="text-xxs ml-1 text-zinc-500">
              {t("addLedger.optionalMayBeChangedLater")}
            </span>

            <Input
              maxLength={LEDGER_NAME_MAX_LENGTH}
              data-tabbable
              hasError={nameError}
              className="w-full"
              type="text"
              id="ledgerName"
              name="ledgerName"
              value={ledgerName}
              onChange={(e) => {
                e.preventDefault();
                setLedgerName(e.target.value);
              }}
            />
            {nameError ? (
              <div className="text-xxs mt-1 text-red-700">
                {t("addLedger.aLedgerWithThisNameAlreadyExists")}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <ModalControls>
        <Button
          type="submit"
          disabled={nameError || transferWorking || !selectedLedger}
          buttonType="primary"
          customClasses="ml-4"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              changeTransferOutToSourceless(
                entry.id,
                selectedLedger === NEW_LEDGER ? null : Number(selectedLedger),
                ledgerName
              )
            );
          }}
          text={t("ledgers.transactionTaxOptions.markAsTransfer")}
        />
        <Button
          onClick={handleDismiss}
          disabled={transferWorking}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </>
  );
}

ModalExternalTransfer.displayName = "ModalExternalTransfer";

export default ModalExternalTransfer;
