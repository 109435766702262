import api from "../clients/exchange";

/**
 * Submits a request to get all existing API keys for a user
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getApiKeys = () => {
  return api.get(`/napi/keys`, {
    authenticated: true,
  });
};

/**
 * Submits a request to create an API key
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const createApiKey = (profileGuid, readOnly, label) => {
  return api.post(`/napi/create`, {
    authenticated: true,
    data: { profileGuid, readOnly, label },
  });
};

/**
 * Submits a request to create an API key
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const deleteApiKey = (key) => {
  return api.delete(`/napi/remove`, {
    authenticated: true,
    params: new URLSearchParams({ key }),
  });
};
