import { makeActionCreator } from "app.actions/helpers";

export const ACCOUNT_LANGUAGE_UPDATE_BEGIN = "ACCOUNT_LANGUAGE_UPDATE_BEGIN";
export const ACCOUNT_LANGUAGE_UPDATE_REQUEST =
  "ACCOUNT_LANGUAGE_UPDATE_REQUEST";
export const ACCOUNT_LANGUAGE_UPDATE_RECEIVE =
  "ACCOUNT_LANGUAGE_UPDATE_RECEIVE";
export const ACCOUNT_LANGUAGE_UPDATE_ERROR = "ACCOUNT_LANGUAGE_UPDATE_ERROR";

export const updateLanguage = makeActionCreator(
  ACCOUNT_LANGUAGE_UPDATE_BEGIN,
  "language"
);
export const updateLanguageRequest = makeActionCreator(
  ACCOUNT_LANGUAGE_UPDATE_REQUEST
);
export const updateLanguageReceive = makeActionCreator(
  ACCOUNT_LANGUAGE_UPDATE_RECEIVE
);
export const updateLanguageError = makeActionCreator(
  ACCOUNT_LANGUAGE_UPDATE_ERROR,
  "error"
);
