export function testFileType(file, fileType, extension) {
  const { name, type } = file;

  if (typeof fileType === "undefined" && typeof extension === "undefined") {
    // take any file I guess
    return true;
  }

  if (fileType)
    if (typeof type !== "undefined") {
      if (type === "") {
        if (Array.isArray(extension)) {
          let pass = false;
          extension.map((ext) => {
            const regEx = new RegExp(`\\.${ext}$`, "i");
            const extensionTest = name.match(regEx);
            if (extensionTest !== null) {
              pass = true;
            }
            return null;
          });
          return pass;
        }
        const regEx = new RegExp(`\\.${extension}$`, "i");
        const extensionTest = name.match(regEx);
        if (extensionTest !== null) {
          return true;
        }
        // if there is a file type, we'll enforce CSV
      } else if (fileType.indexOf(type) > -1) {
        return true;
      }
    }

  return false;
}
