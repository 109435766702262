import React from "react";

import PortfolioSummaryLoading from "app.components/Portfolio/PortfolioSummary/PortfolioSummaryLoading";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";
import LedgerDataPlaceholder from "app.components/Placeholder/LedgerDataPlaceholder";

const TimelinePlaceholder = () => {
  return (
    <div className="p-4">
      <div className="c_timeline_placeholder">
        <div className="border border-zinc-200">
          <PortfolioSummaryLoading />

          <div className="my-2.5 flex h-8 items-center justify-end gap-2 px-2.5">
            <PlaceholderLine width="w-24" />
            <PlaceholderLine width="w-24" />
          </div>

          <div className="m-2.5 flex flex-col justify-start rounded bg-zinc-50 px-2.5 first:mt-0">
            <div className="flex h-[50px] items-center justify-center">
              <PlaceholderLine width="w-96" />
            </div>
            <div className="flex h-[50px] items-center gap-2">
              <PlaceholderLine width="w-20" />
              <PlaceholderLine width="w-32" />
              <PlaceholderLine width="w-32" />
              <PlaceholderLine width="w-32" />
            </div>
          </div>
          <LedgerDataPlaceholder />
        </div>
      </div>
    </div>
  );
};

TimelinePlaceholder.displayName = "TimelinePlaceholder";

export default TimelinePlaceholder;
