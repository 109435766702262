import { parseISO, isValid } from "date-fns";
import { fromZonedTime } from "date-fns-tz";

export const parseRangeToISO = (range, timeZone = "UTC") => {
  // trim any part of the ISO string that isn't the date so we know we
  // are only dealing with the date part
  // this is because timezones suck
  const sd = range?.startDate || "";
  const ed = range?.endDate || "";

  const startDate = parseISO(sd.substring(0, 10));
  const endDate = parseISO(ed.substring(0, 10));

  const parsedRange = { startDate: undefined, endDate: undefined };

  if (isValid(startDate)) {
    const tzStartDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0,
      0,
      0
    );

    const tzAdjustedStartDate = fromZonedTime(tzStartDate, timeZone);

    parsedRange.startDate = isValid(tzAdjustedStartDate)
      ? tzAdjustedStartDate.toISOString()
      : undefined;
  }

  if (isValid(endDate)) {
    const tzEndDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59
    );

    const tzAdjustedEndDate = fromZonedTime(tzEndDate, timeZone);

    parsedRange.endDate = isValid(tzAdjustedEndDate)
      ? tzAdjustedEndDate.toISOString()
      : undefined;
  }

  return parsedRange;
};
