async function formatResponse(response) {
  let body = "";
  // the body may be empty, or not JSON, catch that here and swallow any
  // parsing error
  try {
    // the server response for download indicates that the response body is JSON
    // this is incorrect, it is actually plain text. We are checking for the
    // file download endpoint and if it is, we are handling it as text since
    // parsing JSON will fail.
    if (
      response.url.includes("/exchange/document/download/") ||
      response.url.includes("/exchange/document/share/")
    ) {
      body = await response.text();
      return { body, _meta: response };
    }

    if (
      response.headers.has("content-type") &&
      response.headers.get("content-type").toLowerCase() === "application/json"
    ) {
      body = await response.json();
    } else {
      if (response.headers.has("content-length")) {
        const contentLength = response.headers.get("content-length");
        if (Number.isFinite(contentLength) && parseInt(contentLength, 10) > 0) {
          if (import.meta.env.VITE_NODE40_ENV !== "production") {
            console.error("DEVELOPMENT ONLY: Not JSON", { response });
          }
        }
      }
      // pass back the ticket as the response..
      if (response.headers.has("x-node40-st")) {
        return { body: response.headers.get("x-node40-st"), _meta: response };
      }
    }
    return { body, _meta: response };
  } catch (e) {
    if (import.meta.env.VITE_NODE40_ENV !== "production") {
      console.error("DEVELOPMENT ONLY: Error processing response", {
        e,
        response,
      });
    }
    return { body, _meta: response };
  }
}

export default formatResponse;
