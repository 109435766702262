import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* update exchange ledgers via address groupiongs */
/** *************************************************************************** */
export const UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_BEGIN =
  "UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_BEGIN";
export const UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_REQUEST =
  "UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_REQUEST";
export const UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_RECEIVE =
  "UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_RECEIVE";
export const UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_ERROR =
  "UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_ERROR";

export const updateExchangeLedgersViaAddressGroupings = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_BEGIN,
  "addressGroupings",
  "exchangeId",
  "ledgerIds"
);

export const requestUpdateExchangeLedgersViaAddressGroupings =
  makeActionCreator(UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_REQUEST);
export const receiveUpdateExchangeLedgersViaAddressGroupings =
  makeActionCreator(
    UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_RECEIVE,
    "response",
    "exchangeId"
  );
export const updateExchangeLedgersViaAddressGroupingsError = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_ERROR,
  "response",
  "error"
);
