import { call, put } from "redux-saga/effects";
import * as userActions from "app.reducers/user";

import SettingsAPI from "app.api/SettingsAPI";

function* saveAccountingPreferences(action) {
  const preferences = action.payload;

  yield put(userActions.requestSaveAccountingPreferences());

  const response = yield call(
    SettingsAPI.setAccountingPreferences,
    preferences
  );

  if (response.error) {
    console.error(
      "Error in saveAccountingPreferences",
      response.body,
      response.error
    );
  } else {
    yield put(userActions.receiveSaveAccountingPreferences());
    yield put(userActions.fetchAccountingPreferences());
  }
}

export default saveAccountingPreferences;
