import { makeActionCreator } from "app.actions/helpers";

export const ENROLLMENT_ENTER = "ENROLLMENT_ENTER";

export const ENROLLMENT_LOADED = "ENROLLMENT_LOADED";
export const ENROLLMENT_LEAVE = "ENROLLMENT_LEAVE";

export const ENROLLMENT_QUERYING = "ENROLLMENT_QUERYING";

export const enrollmentQuerying = makeActionCreator(ENROLLMENT_QUERYING);

export const enterEnrollmentSection = makeActionCreator(ENROLLMENT_ENTER);
export const enrollmentSectionLoaded = makeActionCreator(
  ENROLLMENT_LOADED,
  "isLoggedIn",
  "referral",
  "plans"
);
export const leaveEnrollmentSection = makeActionCreator(ENROLLMENT_LEAVE);
