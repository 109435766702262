export const ledgerNameExists = (ledgerName, ledgersByCurrency) => {
  if (ledgerName !== "") {
    const currencies = Object.keys(ledgersByCurrency);
    for (let i = 0; i < currencies.length; i += 1) {
      const ledgers = ledgersByCurrency[currencies[i]];
      for (let j = 0; j < ledgers.length; j += 1) {
        const ledger = ledgers[j];
        if (ledger?.name?.trim() === ledgerName.trim()) {
          return true;
        }
      }
    }
  }
  return false;
};
