import { takeEvery } from "redux-saga/effects";
import * as filesActions from "app.reducers/files";

import fetchFiles from "./fetchFiles";
import generateFile from "./generateFile";
import downloadFile from "./downloadFile";
import getShareLink from "./getShareLink";

// WATCHERS
const filesSagas = [
  takeEvery(filesActions.fetchFiles, fetchFiles),
  takeEvery(filesActions.generateFile, generateFile),
  takeEvery(filesActions.downloadFile, downloadFile),
  takeEvery(filesActions.shareFile, getShareLink),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default filesSagas;
