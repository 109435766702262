import React from "react";
import SvgLoader from "app.components/Util/SvgLoader";
import { useTranslation } from "react-i18next";

const Square = ({ active, disabled, alt }) => {
  const squareClassName = [
    "w-6",
    "h-6",
    "relative",
    "border border-zinc-300",
    disabled ? "bg-zinc-300" : "",
    "flex",
    "justify-center",
    "items-center",
    "bg-white",
  ].join(" ");

  return (
    <div className={squareClassName}>
      {active && !disabled ? (
        <SvgLoader
          id="Checkmark"
          className="absolute h-5 w-5 stroke-green-400"
          title={alt}
        />
      ) : null}
    </div>
  );
};

const Round = ({ active, disabled }) => {
  const roundClassName = [
    "w-6",
    "h-6",
    "rounded-full",
    "relative",
    active && !disabled ? "bg-green-400" : "bg-zinc-200",
    disabled ? "opacity-50" : "",
  ].join(" ");

  const spanClassName = [
    "w-5",
    "h-5",
    "absolute",
    "rounded-full",
    "border-2",
    "border-white",
    "top-0.5",
    "left-0.5",
    active && !disabled ? "bg-green-400" : "bg-zinc-200",
  ].join(" ");

  return (
    <div className={roundClassName}>
      <div className={spanClassName} />
    </div>
  );
};

const CardIndicator = ({
  type = "round",
  active = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  if (type === "square") {
    return (
      <Square disabled={disabled} active={active} alt={t("common.selected")} />
    );
  }
  return <Round disabled={disabled} active={active} />;
};

CardIndicator.displayName = "CardIndicator";

export default CardIndicator;
