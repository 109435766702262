import { makeActionCreator } from "app.actions/helpers";

export const REACTIVATE_SUBSCRIPTION_BEGIN = "REACTIVATE_SUBSCRIPTION_BEGIN";
export const REACTIVATE_SUBSCRIPTION_REQUEST =
  "REACTIVATE_SUBSCRIPTION_REQUEST";
export const REACTIVATE_SUBSCRIPTION_RECEIVE =
  "REACTIVATE_SUBSCRIPTION_RECEIVE";
export const REACTIVATE_SUBSCRIPTION_ERROR_RECEIVE =
  "REACTIVATE_SUBSCRIPTION_ERROR_RECEIVE";

export const reactivateSubscription = makeActionCreator(
  REACTIVATE_SUBSCRIPTION_BEGIN,
  "subscriptionId"
);

export const requestReactivateSubscription = makeActionCreator(
  REACTIVATE_SUBSCRIPTION_REQUEST
);
export const receiveReactivateSubscription = makeActionCreator(
  REACTIVATE_SUBSCRIPTION_RECEIVE
);
export const receiveReactivateSubscriptionError = makeActionCreator(
  REACTIVATE_SUBSCRIPTION_ERROR_RECEIVE,
  "error"
);
