export const makeTokenHash = (symbol, name) => {
  const input = `${typeof symbol === "string" ? symbol : ""}${
    typeof name === "string" ? name : ""
  }`;

  let hash = 0;
  if (input.length === 0) return `${hash}`;
  for (let i = 0; i < input.length; i += 1) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char; // eslint-disable-line no-bitwise
    hash &= hash; // eslint-disable-line no-bitwise
  }

  return `${symbol}-${hash}`;
};
