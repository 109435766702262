export const GAIN_SHORT = "short";
export const GAIN_LONG = "long";

export const CREDIT = "CREDIT";
export const DEBIT = "DEBIT";
export const TRANSFER = "TRANSFER";
export const MINED = "MINED";
export const SELF = "SELF";

export const SPECID = "SPECID";
export const FIFO = "FIFO";

export const SIBLING = "SIBLING";
export const SOURCE = "SOURCE";
export const DESTINATION = "DESTINATION";
export const LINK = "LINK";
export const CONTRACT_LINK_DESTINATION = "CONTRACT_LINK_DESTINATION";
export const CONTRACT_LINK = "CONTRACT_LINK";
export const CONTRACT_LINK_SOURCE = "CONTRACT_LINK_SOURCE";
export const CONTRACT_LINK_SOURCE_FEE = "CONTRACT_LINK_SOURCE_FEE";
export const TRADE_LINK_SOURCE = "TRADE_LINK_SOURCE";
export const TRADE_LINK_DESTINATION = "TRADE_LINK_DESTINATION";
export const TRADE_LINK_FEE = "TRADE_LINK_FEE";

export const TIMELINE = "TIMELINE";

export const CANCEL_TYPES = {
  PRIMARY: "primary",
  DANGER: "danger",
};
