import { takeLatest, takeLeading } from "redux-saga/effects";
import * as accountActions from "app.actions/account";
import cancelDowngrade from "./cancelDowngrade";
import cancelSubscription from "./cancelSubscription";
import enterSubscriptionSection from "./enterSubscriptionSection";
import fetchSupportedFiats from "./fetchSupportedFiats";
import fetchSupportedLanguages from "./fetchSupportedLanguages";
import fetchUpgradeQuote from "./fetchUpgradeQuote";
import handlePasswordChanges from "./handlePasswordChanges";
import handleSecurityVerification from "./handleSecurityVerification";
import handleEnableMFA from "./handleEnableMFA";
import handleDisableMFA from "./handleDisableMFA";
import handleUserFiat from "./handleUserFiat";
import handleUserLanguage from "./handleUserLanguage";
import handleUserTimeZone from "./handleUserTimeZone";
import reactivateSubscription from "./reactivateSubscription";
import renewSubscription from "./renewSubscription";
import updateBillingInformation from "./updateBillingInformation";
import updateProfile from "./updateProfile";
import upgradePlan from "./upgradePlan";
import removeBillingInformation from "./removeBillingInformation";

// WATCHERS
const accountSagas = [
  takeLatest(accountActions.ACCOUNT_FIAT_UPDATE_BEGIN, handleUserFiat),
  takeLatest(accountActions.ACCOUNT_LANGUAGE_UPDATE_BEGIN, handleUserLanguage),
  takeLatest(
    accountActions.ACCOUNT_PASSWORD_UPDATE_BEGIN,
    handlePasswordChanges
  ),
  takeLatest(
    accountActions.ACCOUNT_MFA_VERIFY_BEGIN,
    handleSecurityVerification
  ),
  takeLatest(accountActions.ACCOUNT_MFA_ENABLE_BEGIN, handleEnableMFA),
  takeLatest(accountActions.ACCOUNT_MFA_DISABLE_BEGIN, handleDisableMFA),
  takeLatest(accountActions.ACCOUNT_PROFILE_UPDATE_BEGIN, updateProfile),
  takeLatest(accountActions.ACCOUNT_TIMEZONE_UPDATE_BEGIN, handleUserTimeZone),
  takeLatest(accountActions.CANCEL_DOWNGRADE_BEGIN, cancelDowngrade),
  takeLatest(accountActions.CANCEL_SUBSCRIPTION_BEGIN, cancelSubscription),
  takeLatest(
    accountActions.REACTIVATE_SUBSCRIPTION_BEGIN,
    reactivateSubscription
  ),
  takeLatest(accountActions.RENEW_SUBSCRIPTION_BEGIN, renewSubscription),
  takeLatest(accountActions.SUPPORTED_FIATS_BEGIN, fetchSupportedFiats),
  takeLatest(accountActions.SUPPORTED_LANGUAGES_BEGIN, fetchSupportedLanguages),
  takeLatest(accountActions.UPDATE_BILLING_BEGIN, updateBillingInformation),
  takeLatest(accountActions.UPGRADE_PLAN_BEGIN, upgradePlan),
  takeLatest(accountActions.UPGRADE_QUOTE_BEGIN, fetchUpgradeQuote),
  takeLeading(accountActions.SUBSCRIPTION_ENTER, enterSubscriptionSection),
  takeLatest(accountActions.REMOVE_BILLING_BEGIN, removeBillingInformation),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default accountSagas;
