import { makeActionCreator } from "app.actions/helpers";

export const EXCHANGE_TABBED_LEDGER_SECTION_CHANGE =
  "EXCHANGE_TABBED_LEDGER_SECTION_CHANGE";
export const EXCHANGE_TABBED_LEDGER_SECTION_ENTER =
  "EXCHANGE_TABBED_LEDGER_SECTION_ENTER";
export const EXCHANGE_TABBED_LEDGER_SECTION_LEAVE =
  "EXCHANGE_TABBED_LEDGER_SECTION_LEAVE";
export const EXCHANGE_TABBED_LEDGER_SECTION_RESET =
  "EXCHANGE_TABBED_LEDGER_SECTION_RESET";

export const enterExchangeTabbedLedgerSection = makeActionCreator(
  EXCHANGE_TABBED_LEDGER_SECTION_ENTER,
  "ledgerId"
);

export const changeExchangeTabbedLedgerSection = makeActionCreator(
  EXCHANGE_TABBED_LEDGER_SECTION_CHANGE,
  "ledgerId"
);
export const leaveExchangeTabbedLedgerSection = makeActionCreator(
  EXCHANGE_TABBED_LEDGER_SECTION_LEAVE,
  "ledgerId"
);
export const resetExchangeTabbedLedgerSection = makeActionCreator(
  EXCHANGE_TABBED_LEDGER_SECTION_RESET
);
