import { call, put } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestEditEntryDescription,
  editEntryDescriptionError,
  receiveEditEntryDescription,
} from "app.actions/exchangeLedger";

import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";

import { STATUS } from "app.constants";

function* editEntryDescription(action) {
  const { transactionId, ledgerId, description } = action;

  yield put(requestEditEntryDescription(transactionId, STATUS.WORKING));
  yield put(startModalWorking());
  const response = yield call(
    ExchangeLedgerAPI.editExchangeUserDescription,
    ledgerId,
    transactionId,
    description
  );

  if (response.error) {
    yield put(
      editEntryDescriptionError(response.body, response.error, ledgerId)
    );
  } else {
    yield put(receiveEditEntryDescription(transactionId, description));
    yield put(dismissModal());
  }

  yield put(endModalWorking());
}

export default editEntryDescription;
