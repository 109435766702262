import api from "../clients/teller";

export const login = (username, password) => {
  return api.post("/login/authenticate", {
    credentials: "include",
    data: {
      username,
      password,
    },
    mode: "cors",
    redirect: "manual",
  });
};

export const getTokenFromTicket = (ticket) => {
  return api.get("/checkpoint", {
    authenticated: false,
    params: new URLSearchParams({
      ticket,
    }),
  });
};
