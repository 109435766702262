import { call, put, select } from "redux-saga/effects";
import SettingsAPI from "app.api/SettingsAPI";
import OrganizationAPI from "app.api/OrganizationAPI";

import { getLoadingState, getIsOrganizationUser } from "app.utils/selectors";

import { STATUS } from "app.constants";

import { requestApiKeys, receiveApiKeys, apiKeysError } from "app.reducers/ui";

function* fetchApiKeys(action) {
  const { reload = false } = action.payload;

  const { apiKeysStatus } = yield select((state) => state.ui);
  const { isLoading } = yield* getLoadingState(
    (state) => state.ui.addressKeysStatus
  );

  const isOrganizationUser = yield* getIsOrganizationUser();

  // if we already have apiKeys, don't fetch them again
  // if we are currently fetching them, don't fetch them again
  if ((apiKeysStatus === STATUS.LOADED && reload === false) || isLoading)
    return;

  yield put(requestApiKeys());

  let response;

  if (isOrganizationUser) {
    response = yield call(OrganizationAPI.getApiKeys);
  } else {
    response = yield call(SettingsAPI.getApiKeys);
  }

  const { body, error } = response;
  if (error) {
    yield put(apiKeysError(body, error));
  } else {
    yield put(receiveApiKeys(body));
  }
}

export default fetchApiKeys;
