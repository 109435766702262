import { makeActionCreator } from "app.actions/helpers";

// ENTRY DESCRIPTION
export const ARCHIVED_LEDGERS_BEGIN = "ARCHIVED_LEDGERS_BEGIN";
export const ARCHIVED_LEDGERS_REQUEST = "ARCHIVED_LEDGERS_REQUEST";
export const ARCHIVED_LEDGERS_RECEIVE = "ARCHIVED_LEDGERS_RECEIVE";
export const ARCHIVED_LEDGERS_ERROR = "ARCHIVED_LEDGERS_ERROR";

// ENTRY DESCRIPTION
export const fetchArchivedLedgers = makeActionCreator(ARCHIVED_LEDGERS_BEGIN);

export const requestArchivedLedgers = makeActionCreator(
  ARCHIVED_LEDGERS_REQUEST
);

export const receiveArchivedLedgers = makeActionCreator(
  ARCHIVED_LEDGERS_RECEIVE,
  "response"
);

export const archivedLedgersError = makeActionCreator(
  ARCHIVED_LEDGERS_ERROR,
  "response",
  "error"
);
