import React from "react";
import { TIERS, FEATURES } from "app.constants";
import { numToWord } from "app.utils";
import { Trans } from "react-i18next";

const UpgradeCommonLedgers = ({ type, limit }) => {
  return (
    <div>
      {type === FEATURES.WALLETS ? (
        <p>
          <Trans i18nKey="upgrade.reachedWalletLedgerLimit" count={limit}>
            {{ limit: numToWord(limit) }}
            <strong />
            <strong>{{ tierBasic: TIERS.valueAt(TIERS.BASIC) }}</strong>
            <strong>
              {{ tierEssential: TIERS.valueAt(TIERS.ESSENTIALS) }}
            </strong>
          </Trans>
        </p>
      ) : null}
      {type === FEATURES.EXCHANGES ? (
        <p>
          <Trans i18nKey="upgrade.reachedExchangeLimit" count={limit - 1}>
            {{ limit: numToWord(limit - 1) }}
            <strong />
            <strong>{{ tierBasic: TIERS.valueAt(TIERS.BASIC) }}</strong>
            <strong>
              {{ tierEssential: TIERS.valueAt(TIERS.ESSENTIALS) }}
            </strong>
          </Trans>
        </p>
      ) : null}
    </div>
  );
};

UpgradeCommonLedgers.displayName = "UpgradeCommonLedgers";

export default UpgradeCommonLedgers;
