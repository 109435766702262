import { all, call, put } from "redux-saga/effects";

import SmartWalletAPI from "app.api/SmartWalletAPI";

import * as smartWalletActions from "app.reducers/smartWallet";
import * as uiActions from "app.reducers/ui";

import { getLoadingState } from "app.utils/selectors";

function* disableSmartWallet() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.smartWallet.smartWalletDisableStatus
  );

  if (isLoading) return;

  yield all([
    put(uiActions.startModalWorking()),
    put(smartWalletActions.requestSmartWalletDisable(nextStatus)),
  ]);

  const response = yield call(SmartWalletAPI.disableSmartWallet);
  // handle the API response
  if (response) {
    if (response.error) {
      console.error(
        "Error in disableSmartWallet",
        response.body,
        response.error
      );
      yield all([
        put(smartWalletActions.receiveSmartWalletDisableError()),
        put(uiActions.endModalWorking()),
      ]);
    } else {
      yield all([
        put(uiActions.dismissModal()),
        put(smartWalletActions.receiveSmartWalletDisable()),
      ]);
    }
  }
}

export default disableSmartWallet;
