import { call, put, all, fork, delay } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  receiveRemoveBillingError,
  receiveRemoveBilling,
} from "app.actions/account";
import {
  addFlashMessage,
  removeFlashMessage,
  dismissModal,
} from "app.reducers/ui";
import { generateGUID, lookupTranslation } from "app.utils";
import { STATUS_DANGER } from "app.components/Messages/FlashMessage";
import {
  REMOVING_BILLING_ERROR_PATH,
  REMOVING_BILLING_ERROR_DEFAULT,
  ONE_MINUTE,
} from "app.constants";
import enterSubscriptionSection from "./enterSubscriptionSection";

function* removeBillingInformation() {
  const response = yield call(TellerAPI.deleteBillingInfo);

  if (response.error) {
    const messageId = generateGUID();

    const errorMessage = lookupTranslation(
      response.message,
      REMOVING_BILLING_ERROR_PATH,
      REMOVING_BILLING_ERROR_DEFAULT
    );

    yield all([
      put(receiveRemoveBillingError(errorMessage)),
      put(dismissModal()),
      put(addFlashMessage(errorMessage, messageId, STATUS_DANGER)),
    ]);
    yield delay(ONE_MINUTE);
    yield put(removeFlashMessage(messageId));
  } else {
    yield all([
      put(dismissModal()),
      fork(enterSubscriptionSection),
      put(receiveRemoveBilling()),
    ]);
  }
}

export default removeBillingInformation;
