import { call, put, select } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  receiveDeleteTag,
  requestDeleteTag,
  receiveDeleteTagError,
} from "app.reducers/ui";

function* deleteTag(action) {
  const { ledgerItemId, tag, tags } = action.payload;

  const updatedTags = tags?.filter((label) => label !== tag);

  const { data } = yield select((state) => state.exchangeLedger);

  const updatedData = data.items.map((entry) =>
    entry.id === ledgerItemId ? { ...entry, customTags: updatedTags } : entry
  );

  yield put(requestDeleteTag(updatedData));

  const response = yield call(
    ExchangeLedgerAPI.deleteLedgerCustomTag,
    ledgerItemId,
    tag
  );

  yield put(receiveDeleteTag(updatedData));

  const { error } = response;
  if (error) {
    yield put(receiveDeleteTagError(data));
  }
}

export default deleteTag;
