import { makeActionCreator } from "app.actions/helpers";
// REVERT VALUE OVERRIDES
export const REVERT_EXCHANGE_VALUE_BEGIN = "REVERT_EXCHANGE_VALUE_BEGIN";
export const REVERT_EXCHANGE_VALUE_REQUEST = "REVERT_EXCHANGE_VALUE_REQUEST";
export const REVERT_EXCHANGE_VALUE_RECEIVE = "REVERT_EXCHANGE_VALUE_RECEIVE";
export const REVERT_EXCHANGE_VALUE_ERROR = "REVERT_EXCHANGE_VALUE_ERROR";

// REVERT ENTRY
export const revertEntryValue = makeActionCreator(
  REVERT_EXCHANGE_VALUE_BEGIN,
  "itemId",
  "ledgerId",
  "transactionId",
  "isTimeline"
);
export const requestRevertEntryValue = makeActionCreator(
  REVERT_EXCHANGE_VALUE_REQUEST,
  "itemId",
  "nextStatus"
);
export const receiveRevertEntryValue = makeActionCreator(
  REVERT_EXCHANGE_VALUE_RECEIVE,
  "itemId",
  "nextStatus"
);
export const revertEntryValueError = makeActionCreator(
  REVERT_EXCHANGE_VALUE_ERROR,
  "response",
  "error"
);
