import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { TOS_URL } from "app.constants";
import { dismissModal } from "app.reducers/ui";
import ModalControls from "app.components/Modal/ModalControls";
import Button from "app.components/Util/Button";

const ModalAcceptTOS = () => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <iframe className="h-96 w-full" id="tos" title="tos" src={TOS_URL} />
      <ModalControls noMargin>
        <Button
          buttonType="text"
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
          }}
          data-tabbable
          text={t("button.close")}
        />
      </ModalControls>
    </>
  );
};

export default ModalAcceptTOS;
