import { NEW_TICKET_URL_BALANCE } from "app.constants";
import api from "../clients/tellerBalance";
import { getNewTicket, filterEmptyParams } from "../core/util";

export const getTicket = async () => {
  const result = await getNewTicket(NEW_TICKET_URL_BALANCE);
  return result.ticket;
};

/**
 * Submits a request to get a user's token
 * @async
 * @function
 * @param {string} ticket - SSO ticket
 * @returns {object}  response - The Response or Error message
 */
export const getTokenFromTicket = (ticket, profileGUID) => {
  return api.get("/checkpoint/checkTicket", {
    authenticated: false,
    params: new URLSearchParams(
      filterEmptyParams({
        ticket,
        profileGUID,
      })
    ),
  });
};
