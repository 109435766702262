import api from "app.api/clients/exchange";

/**
 * Submits a request to create a tag
 *
 * @async
 * @function
 * @param {string} ledgerItemId - The id of the ledger item
 * @param {string} tag - The id of the transaction/item
 * @returns {object}  response - The Response or Error message
 */
export const createLedgerTag = (ledgerItemId, tags) => {
  return api.post(`/tag/ledgerItem/create`, {
    authenticated: true,
    data: {
      ledgerItemId,
      tags,
    },
  });
};

/**
 * Submits a request to create a tag
 *
 * @async
 * @function
 * @param {array} ledgerItemIds - The ids of the ledger items
 * @param {string} tag - The id of the transaction/item
 * @returns {object}  response - The Response or Error message
 */
export const bulkCreateLedgerTag = (ledgerItemIds, tag) => {
  return api.post(`/tag/bulk/create`, {
    authenticated: true,
    data: {
      ledgerItemIds,
      tag,
    },
  });
};

/**
 * Submits a request to delete a tag off of a ledger item.
 *
 * @async
 * @function
 * @param {string} ledgerItemId - The id of the ledger item
 * @param {string} tag - The id of the transaction/item
 * @returns {object}  response - The Response or Error message
 */
export const deleteLedgerCustomTag = (ledgerItemId, tag) => {
  return api.delete(`/tag/ledgerItem/${ledgerItemId}/${tag}`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get the list of known tags for user.
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getUserRecentlyTags = () => {
  return api.get(`/tag`, {
    authenticated: true,
  });
};
