import { useSelector } from "react-redux";
import { getLabelByAddress } from "app.utils/labels";

function useGetLabelByAddress() {
  const { addressLabels } = useSelector((state) => state.ui);

  return (address) => {
    return getLabelByAddress(addressLabels, address);
  };
}

export default useGetLabelByAddress;
