export const LEDGER_TYPES = {
  EXCHANGE: "EXCHANGE",
  WALLET: "WALLET",
  SMART: "SMART",
  SHADOW: "SHADOW",
  SOURCED: "SOURCED",
  SOURCELESS: "SOURCELESS",
  BRIDGE: "BRIDGE",
  TEZOS: "TEZOS",
};
