import api from "../clients/exchange";

/**
 * Submits a request to get the list of supported exchanges
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getSupportedExchanges = () => {
  return api.get("/supported", { authenticated: true });
};

/**
 * Submits a request to get the list of linked exchanges
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getLinkedExchanges = () => {
  return api.get(`/linked`, {
    authenticated: true,
  });
};
