import React, { lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import HeaderLogo from "app.components/Header/HeaderLogo";

import { dismissModal } from "app.reducers/ui";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import ModalControls from "app.components/Modal/ModalControls";

import SvgLoader from "app.components/Util/SvgLoader";

import LoadingSpinner from "app.components/Util/LoadingSpinner";

const Attribution = lazy(() => import("app.components/Attribution"));

function ModalAbout() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleDismiss = (e) => {
    e.preventDefault();
    dispatch(dismissModal());
  };

  return (
    <>
      <div className="bg-header-primary mb-4 flex h-16 items-center pl-2 pr-4">
        <HeaderLogo noLink />
        <button
          type="button"
          id="modal-close"
          className="group ml-auto rounded bg-blue-50 p-1 hover:bg-blue-100"
          onClick={handleDismiss}
        >
          <SvgLoader
            id="Close"
            className="h-5 w-5 stroke-blue-600 group-hover:stroke-blue-700"
          />
          <span className="sr-only">{t("button.close")}</span>
        </button>
      </div>
      <div className="m-4">
        <Suspense fallback={<LoadingSpinner />}>
          <Attribution />
        </Suspense>
      </div>
      <ModalControls>
        <Button
          data-testid="close-modal"
          onClick={handleDismiss}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </>
  );
}

ModalAbout.displayName = "ModalAbout";

export default ModalAbout;
