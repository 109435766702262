import React from "react";
import SvgLoader from "app.components/Util/SvgLoader";

export const BANNER_BASE_CLASS = "bg-amber-50 py-3 px-3";
const MESSAGE_BASE_CLASS = "flex-initial ml-2 text-amber-800 text-sm";
export const ICON_BASE_CLASS = "w-6 h-6 fill-amber-400";

const BANNER_TYPE_STYLES = {
  danger: "bg-red-50 py-3 px-3",
  info: "bg-blue-50 py-3 px-3",
};

const ICON_TYPE_STYLES = {
  danger: "w-6 h-6 fill-red-400",
  info: "w-6 h-6 fill-blue-400",
};

const MESSAGE_TYPE_STYLES = {
  danger: "flex-initial ml-3 text-red-800 text-sm",
  info: "flex-initial ml-3 text-blue-800 text-sm",
};

const MessageBanner = ({
  children,
  title = undefined,
  text,
  closeBanner = false,
  type,
  customClass,
  flat,
}) => {
  let iconType;
  switch (type) {
    case "info":
    case "danger":
      iconType = "CircleInfo";
      break;
    default:
      iconType = "Warning";
      break;
  }

  const bannerClass = [
    flat ? "" : "shadow rounded",
    BANNER_TYPE_STYLES[type] || BANNER_BASE_CLASS,
  ]
    .filter(Boolean)
    .join(" ");

  const containerClass = [
    "c_warning-banner",
    typeof customClass === "string" ? customClass : "px-3 py-1",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={containerClass}>
      <div className={bannerClass}>
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center">
            <div className="flex-1">
              <SvgLoader
                id={iconType}
                className={ICON_TYPE_STYLES[type] || ICON_BASE_CLASS}
              />
            </div>
            <div>
              {typeof title !== "undefined" ? (
                <div
                  className={MESSAGE_TYPE_STYLES[type] || MESSAGE_BASE_CLASS}
                >
                  <p className="font-bold">{title}</p>
                  {text && <p className="opacity-80">{text}</p>}
                  {children && children}
                </div>
              ) : (
                <div
                  className={MESSAGE_TYPE_STYLES[type] || MESSAGE_BASE_CLASS}
                >
                  {children || text}
                </div>
              )}
            </div>
          </div>

          {closeBanner ? <div>{closeBanner}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default MessageBanner;
