import { makeActionCreator } from "app.actions/helpers";

export const RENEW_SUBSCRIPTION_BEGIN = "RENEW_SUBSCRIPTION_BEGIN";
export const RENEW_SUBSCRIPTION_REQUEST = "RENEW_SUBSCRIPTION_REQUEST";
export const RENEW_SUBSCRIPTION_RECEIVE = "RENEW_SUBSCRIPTION_RECEIVE";
export const RENEW_SUBSCRIPTION_ERROR_RECEIVE =
  "RENEW_SUBSCRIPTION_ERROR_RECEIVE";

export const renewSubscription = makeActionCreator(
  RENEW_SUBSCRIPTION_BEGIN,
  "planId"
);

export const requestRenewSubscription = makeActionCreator(
  RENEW_SUBSCRIPTION_REQUEST
);
export const receiveRenewSubscription = makeActionCreator(
  RENEW_SUBSCRIPTION_RECEIVE
);
export const receiveRenewSubscriptionError = makeActionCreator(
  RENEW_SUBSCRIPTION_ERROR_RECEIVE,
  "error"
);
