import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isValidEmail, isLoading } from "app.utils";
import { dismissModal } from "app.reducers/ui";
import { addProfile } from "app.reducers/organizations";
import OrganizationProfileEditorForm from "app.components/Organization/Profiles/OrganizationProfileEditorForm";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

const ModalAddManagedAccount = () => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();

  const { upsertProfileError, upsertProfileStatus } = useSelector(
    (state) => state.organizations
  );

  const upsertProfileLoading = useMemo(() => {
    return isLoading(upsertProfileStatus);
  }, [upsertProfileStatus]);

  const [shareAccess, setShareAccess] = useState(false);
  const [assumeOnCreation, setAssumeOnCreation] = useState(true);
  const [profileInfo, setProfileInfo] = useState({
    profileDisplayName: "",
    associatedEmailAddress: "",
    firstName: "",
    lastName: "",
  });

  const handleUpdate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setProfileInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const disableSubmit = () => {
    const { profileDisplayName, associatedEmailAddress } = profileInfo;

    if (upsertProfileLoading || !profileDisplayName) return true;
    if (shareAccess) {
      if (!associatedEmailAddress) return true;
      if (!isValidEmail(associatedEmailAddress)) return true;
    }
    return false;
  };

  const submit = async (e) => {
    e.preventDefault();

    const { profileDisplayName, associatedEmailAddress, firstName, lastName } =
      profileInfo;

    if (!profileDisplayName) return;

    if (shareAccess) {
      reduxDispatch(
        addProfile(
          assumeOnCreation,
          profileDisplayName,
          associatedEmailAddress,
          firstName,
          lastName
        )
      );
    } else {
      reduxDispatch(addProfile(assumeOnCreation, profileDisplayName));
    }
  };

  return (
    <>
      <ModalHeader
        title={t("modals.managedAccount.createNewManagedAccount")}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />

      <div className="m-4">
        <OrganizationProfileEditorForm
          upsertProfileError={upsertProfileError}
          profileDisplayName={profileInfo.profileDisplayName}
          upsertProfileLoading={upsertProfileLoading}
          handleUpdate={handleUpdate}
          shareAccess={shareAccess}
          changeShareAccess={() => setShareAccess((prev) => !prev)}
          associatedEmailAddress={profileInfo.associatedEmailAddress}
          firstName={profileInfo.firstName}
          lastName={profileInfo.lastName}
          assumeOnCreation={assumeOnCreation}
          setAssumeOnCreation={setAssumeOnCreation}
        />
      </div>

      <ModalControls>
        <Button
          type="submit"
          disabled={disableSubmit()}
          buttonType="primary"
          customClasses="ml-4"
          onClick={submit}
          text={t("modals.managedAccount.addManagedAccount")}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
          }}
          buttonType="text"
          text={t("button.close")}
        />
      </ModalControls>
    </>
  );
};

export default ModalAddManagedAccount;
