import { useSelector } from "react-redux";
import { getExchangeById } from "app.utils";

function useGetExchangeById() {
  const supportedExchanges = useSelector(
    (state) => state.exchanges.supportedExchanges
  );

  return (exchangeId) => {
    return getExchangeById(exchangeId, supportedExchanges);
  };
}

export default useGetExchangeById;
