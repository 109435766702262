import api from "app.api/clients/exchange";

/**
 * Submits a request to manually create a transfer link
 * @async
 * @function
 * @param {array} itemIds - The ids of the transactions to link
 * @returns {object}  response - The Response or Error message
 */
export const createManualTransfer = (itemIds) => {
  return api.post(`/smart/manage/manualOverrideLink`, {
    data: { itemIds },
    authenticated: true,
  });
};

/**
 * Submits a request to manually create a transfer link
 * @async
 * @function
 * @param {array} itemId - The ids of the transactions
 * @returns {object}  response - The Response or Error message
 */
export const deleteManualTransfer = (itemId) => {
  return api.delete(`/smart/manage/manualOverrideLink/${itemId}`, {
    authenticated: true,
  });
};
