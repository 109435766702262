import { makeActionCreator } from "app.actions/helpers";

export const EDIT_MANUAL_JOURNAL_BEGIN = "EDIT_MANUAL_JOURNAL_BEGIN";
export const EDIT_MANUAL_JOURNAL_REQUEST = "EDIT_MANUAL_JOURNAL_REQUEST";
export const EDIT_MANUAL_JOURNAL_RECEIVE = "EDIT_MANUAL_JOURNAL_RECEIVE";
export const EDIT_MANUAL_JOURNAL_ERROR = "EDIT_MANUAL_JOURNAL_ERROR";
export const EDIT_MANUAL_JOURNAL_RESET = "EDIT_MANUAL_JOURNAL_RESET";

export const createManualJournalTransaction = makeActionCreator(
  EDIT_MANUAL_JOURNAL_BEGIN,
  "ledgerId",
  "state"
);

export const requestManualJournalTransaction = makeActionCreator(
  EDIT_MANUAL_JOURNAL_REQUEST,
  "nextStatus"
);

export const receiveManualJournalTransaction = makeActionCreator(
  EDIT_MANUAL_JOURNAL_RECEIVE,
  "nextStatus"
);

export const receiveManualJournalTransactionError = makeActionCreator(
  EDIT_MANUAL_JOURNAL_ERROR,
  "message",
  "nextStatus"
);

export const resetManualJournalTransaction = makeActionCreator(
  EDIT_MANUAL_JOURNAL_RESET
);
