import { HELP_INFO, HAS_SUPPORT_LINKS } from "app.constants";

const HelpInfo = ({ source, render }) => {
  if (typeof render === "undefined")
    throw new Error("must provide a render method to HelpInfo component");
  return render(HELP_INFO[source] || undefined);
};

// an empty component to return if an account does not use zendesk
const NoHelpInfo = () => {
  return null;
};

HelpInfo.displayName = "HelpInfo";

const EXPORT_COMPONENT = HAS_SUPPORT_LINKS ? HelpInfo : NoHelpInfo;

export default EXPORT_COMPONENT;
