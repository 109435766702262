import { call, put } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import OrganizationAPI from "app.api/OrganizationAPI";

import {
  receiveCustomFieldsData,
  receiveCustomFieldsDataError,
  requestCustomFieldsData,
} from "app.reducers/ui";

function* fetchCustomFieldsData(action) {
  const isOrganizationAdmin = action.payload;
  yield put(requestCustomFieldsData());

  let response;
  if (isOrganizationAdmin && isOrganizationAdmin === true) {
    response = yield call(OrganizationAPI.getCustomFieldsData);
  } else {
    response = yield call(ExchangeLedgerAPI.getCustomFieldsData);
  }

  const { body, error } = response;
  if (error) {
    yield put(receiveCustomFieldsDataError({ response: body, error }));
  } else {
    yield put(receiveCustomFieldsData({ response: body }));
  }
}

export default fetchCustomFieldsData;
