/* eslint-disable jsx-a11y/anchor-has-content */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import SvgLoader from "app.components/Util/SvgLoader";
import Button from "app.components/Util/Button";

import { testFileType, humanFileSize, shortenString } from "app.utils";
import { Trans, useTranslation } from "react-i18next";

const MIME_TYPES = {
  csv: ["text/csv", "text/comma-separated-values", "application/vnd.ms-excel"],
  xlsx: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  xls: ["application/vnd.ms-excel"],
  txt: ["text/plain"],
};

const ACCEPT = {
  csv: "text/csv,text/comma-separated-values,application/vnd.ms-excel,.csv",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx",
  xls: "application/vnd.ms-excel,.xls",
  txt: "text/plain",
};

const DROP_ZONE_ID = "DropZone";

const getMimeTypes = (fileType, mimeTypes) => {
  if (typeof fileType === "undefined") {
    return undefined;
  }

  if (typeof fileType === "string") {
    return mimeTypes[fileType] || undefined;
  }

  if (Array.isArray(fileType)) {
    const allMimeTypes = fileType.map((type) => {
      return getMimeTypes(type, mimeTypes);
    });
    return allMimeTypes.join(",");
  }
  return undefined;
};

const getAccept = (fileType, accept) => {
  if (typeof fileType === "undefined") {
    return "";
  }
  if (typeof fileType === "string") {
    return accept[fileType] || "";
  }
  if (Array.isArray(fileType)) {
    const allAccept = fileType.map((type) => {
      return getMimeTypes(type, accept);
    });
    return allAccept.join(",");
  }
  return undefined;
};

const FileUploadField = ({
  file,
  description,
  fileType,
  fileKey,
  optional,
  error,
  id,
  disableButtons,
  handleUpdateFile,
}) => {
  const { t } = useTranslation();

  const [isDroppingFile, setIsDroppingFile] = useState(false);
  const [fileError, setFileError] = useState();

  const fileIn = useRef(null);
  const dropZoneRef = useRef(null);

  const message = useMemo(() => {
    const FILE_TYPE_NAMES = {
      csv: t("utils.fileUpload.typeNames.csv"),
      xlsx: t("utils.fileUpload.typeNames.xlsx"),
      xls: t("utils.fileUpload.typeNames.xls"),
      txt: t("utils.fileUpload.typeNames.txt"),
    };

    if (typeof fileType === "undefined") return "";

    if (typeof fileType === "string") {
      const typeName = FILE_TYPE_NAMES[fileType] || undefined;
      if (typeof typeName === "undefined") {
        return t("utils.fileUpload.fileMustBeType", {
          fileType,
        });
      }
      return t("utils.fileUpload.fileMustBeTypeForFileInTypeNames", {
        typeName,
        fileType,
      });
    }

    if (Array.isArray(fileType) && fileType.length === 1) {
      const typeName = FILE_TYPE_NAMES[fileType[0]] || undefined;
      if (typeof typeName === "undefined") {
        return t("utils.fileUpload.arrayFileMustBeType", {
          fileType: fileType[0],
        });
      }
      return t("utils.fileUpload.arrayFileMustBeTypeForFileInTypeNames", {
        typeName,
        fileType: fileType[0],
      });
    }

    if (Array.isArray(fileType) && fileType.length > 1) {
      // group xls, and xlsx if both are present
      if (
        fileType.findIndex((item) => item === "xls") > -1 &&
        fileType.findIndex((item) => item === "xlsx") > -1
      ) {
        // remove them from the array, so we can continue processing other extensions if there are any left
        const fileTypeCopy = [...fileType];
        const xlsIdx = fileTypeCopy.findIndex((item) => item === "xls");
        fileTypeCopy.splice(xlsIdx, 1);
        const xlsxIdx = fileTypeCopy.findIndex((item) => item === "xlsx");
        fileTypeCopy.splice(xlsxIdx, 1);

        if (fileTypeCopy.length === 0) {
          return t("utils.fileUpload.mustBeAnExcelSpreadsheet");
        }
        const fileTypes = fileTypeCopy.map(
          (ft) => `${FILE_TYPE_NAMES[ft]} (.${ft})`
        );
        fileTypes.push(`Excel Spreadsheet (.xlsx, .xls)`);
        return t("utils.fileUpload.mustBeOneOfTheMultipleTypes", {
          types: fileTypes.join(", "),
        });
      }
      const fileTypes = fileType.map((ft) => `${FILE_TYPE_NAMES[ft]} (.${ft})`);
      return t("utils.fileUpload.mustBeOneOfTheMultipleTypes", {
        types: fileTypes.join(", "),
      });
    }

    return "";
  }, [fileType, t]);

  const accept = useMemo(() => {
    return getAccept(fileType, ACCEPT);
  }, [fileType]);

  const handleStageFile = useCallback(
    (newFile) => {
      const mimeTypes = getMimeTypes(fileType, MIME_TYPES);
      if (testFileType(newFile, mimeTypes, fileType)) {
        handleUpdateFile(newFile, fileKey);
        setFileError(false);
      } else {
        setFileError(true);
      }
    },
    [fileKey, fileType, handleUpdateFile]
  );

  const handleAddFile = (event) => {
    const { target } = event;
    const newFile = event.target.files;
    if (newFile.length !== 0) {
      handleStageFile(newFile[0]);
      target.file = undefined;
    }
  };

  const handleRemoveFile = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleUpdateFile(undefined, fileKey);
  };

  useEffect(() => {
    const handleDragEnter = (event) => {
      event.preventDefault();
      event.stopPropagation();

      const { dataTransfer } = event;

      setIsDroppingFile(true);
      dataTransfer.dropEffect = "copy";
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDroppingFile(false);
    };

    const handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();

      const { dataTransfer } = event;
      const types = dataTransfer?.types;

      if (
        types &&
        (types.indexOf
          ? types.indexOf("Files") !== -1
          : types.contains("Files"))
      ) {
        setIsDroppingFile(true);

        dataTransfer.dropEffect = "copy";
      } else {
        dataTransfer.dropEffect = "none";
      }
    };

    const handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const newFile = event.target.files || event.dataTransfer.files;
      if (newFile?.length) {
        handleStageFile(newFile[0]);
      }
      setIsDroppingFile(false);
    };

    const preventDrop = (event) => {
      event.preventDefault();
      const { dataTransfer } = event;
      dataTransfer.effectAllowed = "none";
      dataTransfer.dropEffect = "none";
    };

    // prevent dropping just anywhere...
    window.addEventListener("dragenter", preventDrop, false);
    window.addEventListener("dragover", preventDrop);
    window.addEventListener("drop", preventDrop);

    // Adding the drag and drop only to dropzone area
    const tempZoneRef = dropZoneRef?.current;
    if (tempZoneRef) {
      tempZoneRef.addEventListener("dragenter", handleDragEnter);
      tempZoneRef.addEventListener("dragleave", handleDragLeave);
      tempZoneRef.addEventListener("dragover", handleDragOver);
      tempZoneRef.addEventListener("drop", handleDrop);
    }

    return () => {
      tempZoneRef?.removeEventListener("dragenter", handleDragEnter);
      tempZoneRef?.removeEventListener("dragleave", handleDragLeave);
      tempZoneRef?.removeEventListener("dragover", handleDragOver);
      tempZoneRef?.removeEventListener("drop", handleDrop);

      window.removeEventListener("dragenter", preventDrop, false);
      window.removeEventListener("dragover", preventDrop);
      window.removeEventListener("drop", preventDrop);
    };
  }, [file, handleStageFile]);

  return (
    <div
      className="c_file-upload relative mb-4 h-16"
      data-testid={DROP_ZONE_ID}
      ref={dropZoneRef}
    >
      {file ? (
        <div className="absolute top-0 w-full">
          <div
            className={[
              "relative flex h-16 items-center justify-between rounded border px-3 shadow-sm",
              error ? "border-red-200 bg-red-50" : "",
            ].join(" ")}
          >
            <div className="flex basis-1/3 items-center">
              <SvgLoader
                id="DocumentText"
                className={[
                  "h-8 w-6",
                  error ? "stroke-red-400" : "stroke-green-400",
                ].join(" ")}
              />
              <div
                className={[
                  "ml-1 text-xs",
                  error ? "text-red-400" : "text-green-400",
                ].join(" ")}
              >
                {file.name.split(".").pop()}
              </div>
              {description ? (
                <p className="ml-3 text-sm font-bold">{description}</p>
              ) : null}
            </div>

            <div className="flex basis-1/3 items-center justify-center whitespace-nowrap">
              <p className={["text-sm", error ? "text-red-700" : ""].join(" ")}>
                {shortenString(file.name, 50, 10)}
                {error ? (
                  <span className="ml-0.5 font-bold">({error})</span>
                ) : null}
              </p>
            </div>

            <div className="flex basis-1/3 items-center justify-end">
              <p className="mr-2 text-sm">{humanFileSize(file.size, true)}</p>
              <Button
                text={t("button.remove")}
                buttonType="text"
                buttonSize="xs"
                customClasses="z-20"
                disabled={disableButtons}
                onClick={handleRemoveFile}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={[
            "absolute top-0 w-full",
            file ? "opacity-0" : "",
            isDroppingFile ? "cursor-copy bg-blue-100 opacity-90" : "",
            error ? "border-red-200 bg-red-50" : "",
          ].join(" ")}
        >
          <div
            className={[
              "relative flex h-16 items-center justify-between rounded border px-3 shadow-sm",
              isDroppingFile ? "border-blue-400" : "",
              error ? "border-red-200 bg-red-50" : "",
            ].join(" ")}
          >
            <div className="flex items-center">
              <SvgLoader id="CloudArrowUp" className="h-7 w-7 fill-blue-600" />
              <p className="ml-2 text-sm">
                {description ? (
                  <Trans i18nKey="utils.fileUpload.dropFileOrBrowseWithDescription">
                    <span className="font-bold">{{ description }}</span>
                    <button
                      type="button"
                      className="text-linkBlue hover:text-linkBlueActive hover:underline"
                      data-tabbable
                      href="#browse"
                      onClick={(e) => {
                        e.preventDefault();
                        fileIn.current?.click();
                      }}
                    >
                      label
                    </button>
                  </Trans>
                ) : (
                  <Trans i18nKey="utils.fileUpload.dropFileOrBrowse">
                    <a
                      className="text-linkBlue hover:text-linkBlueActive hover:underline"
                      data-tabbable
                      href="#browse"
                      onClick={(e) => {
                        e.preventDefault();
                        fileIn.current?.click();
                      }}
                    >
                      label
                    </a>
                  </Trans>
                )}
              </p>
            </div>

            <div className="flex flex-col items-end text-sm text-zinc-500">
              <p className={fileError ? "text-red-700" : null}>
                {message}
                {typeof optional !== "undefined" ? (
                  <span className="italic">
                    {" "}
                    {optional ? (
                      t("common.optional")
                    ) : (
                      <span className="text-red-600">
                        {t("common.required")}
                      </span>
                    )}
                  </span>
                ) : null}
              </p>
            </div>

            <input
              data-testid="file-upload"
              ref={fileIn}
              className="hidden"
              key={id}
              id={id}
              type="file"
              accept={accept}
              onChange={handleAddFile}
              multiple={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

FileUploadField.displayName = "FileUploadField";

export default FileUploadField;
