export function humanFileSize(bytes, si) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;

  let newBytes = bytes;
  do {
    newBytes /= thresh;
    u += 1;
  } while (Math.abs(newBytes) >= thresh && u < units.length - 1);
  return `${newBytes.toFixed(1)} ${units[u]}`;
}
