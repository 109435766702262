import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* fetch user settings */
/** *************************************************************************** */
export const SETTINGS_BEGIN = "SETTINGS_BEGIN";
export const SETTINGS_REQUEST = "SETTINGS_REQUEST";
export const SETTINGS_RECEIVE = "SETTINGS_RECEIVE";
export const SETTINGS_ERROR = "SETTINGS_ERROR";

export const fetchSettings = makeActionCreator(SETTINGS_BEGIN);

export const requestSettings = makeActionCreator(
  SETTINGS_REQUEST,
  "nextStatus"
);

export const receiveSettings = makeActionCreator(
  SETTINGS_RECEIVE,
  "body",
  "nextStatus"
);

export const settingsError = makeActionCreator(
  SETTINGS_ERROR,
  "body",
  "nextStatus"
);
