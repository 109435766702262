import { put, call } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";

import { refreshUserToken } from "app.actions/authentication";
import {
  updateTimeZoneRequest,
  updateTimeZoneReceive,
  updateTimeZoneError,
} from "app.actions/account";

function* handleUserTimeZone(action) {
  const { timeZone } = action;
  yield put(updateTimeZoneRequest());

  const response = yield call(TellerAPI.updateUserTimeZone, timeZone);
  if (response.error) {
    yield put(updateTimeZoneError());
  } else {
    yield put(updateTimeZoneReceive(timeZone));
    yield put(refreshUserToken()); // ensure the cache version in local storage is properly updated
  }
}

export default handleUserTimeZone;
