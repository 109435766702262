import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "app.actions/account";
import { ACCOUNT_STATUS } from "app.constants/loadingStates";
import ErrorMessage from "app.components/Util/ErrorMessage";
import Button from "app.components/Util/Button";
import { useTranslation } from "react-i18next";
import { dismissModal } from "app.reducers/ui";
import ModalControls from "app.components/Modal/ModalControls";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";
import { isValidEmail } from "app.utils";

const ModalProfileSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userProfileStatus, userProfile, userProfileError } = useSelector(
    (state) => state.account
  );

  const [profile, setProfile] = useState({
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    email: userProfile.email,
  });

  const profileUpdating = userProfileStatus === ACCOUNT_STATUS.UPDATING;

  const blockSubmit = useMemo(() => {
    if (profileUpdating) return true;
    if (typeof userProfile === "undefined") return true;

    const { firstName, lastName, email } = profile;

    if (email && !isValidEmail(email)) return true;

    if (firstName && firstName !== userProfile.firstName) return false;
    if (lastName && lastName !== userProfile.lastName) return false;
    if (email && email !== userProfile.email) return false;

    return true;
  }, [profile, profileUpdating, userProfile]);

  useEffect(() => {
    if (userProfileStatus === ACCOUNT_STATUS.UPDATED) {
      dispatch(dismissModal());
    }
  }, [userProfileStatus, dispatch]);

  const handleUpdate = (e) => {
    e.preventDefault();
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (blockSubmit) return;
    dispatch(updateProfile(profile));
  };

  const emailError = !isValidEmail(profile.email)
    ? t("constants.error.account.invalid_email_format")
    : null;

  return (
    <form onSubmit={handleSubmit}>
      <div className="m-4">
        <ErrorMessage message={userProfileError} type={userProfileError} />
        <div className="my-4 flex flex-col">
          <Label error={emailError} htmlFor="email">
            {t("common.email")}
          </Label>
          <Input
            type="email"
            data-testid="email"
            id="email"
            name="email"
            hasError={emailError !== null}
            value={profile.email}
            readOnly={profileUpdating}
            onChange={handleUpdate}
          />
        </div>

        <div className="mb-4 flex">
          <div className="mr-4 flex basis-1/2 flex-col">
            <Label htmlFor="firstName">{t("common.firstName")}</Label>
            <Input
              type="text"
              name="firstName"
              data-testid="firstName"
              id="firstName"
              value={profile.firstName || ""}
              onChange={handleUpdate}
              readOnly={profileUpdating}
            />
          </div>
          <div className="flex basis-1/2 flex-col">
            <Label htmlFor="lastName">{t("common.lastName")}</Label>
            <Input
              type="text"
              name="lastName"
              data-testid="lastName"
              id="lastName"
              value={profile.lastName || ""}
              onChange={handleUpdate}
              readOnly={profileUpdating}
            />
          </div>
        </div>
      </div>
      <ModalControls>
        <Button
          disabled={blockSubmit}
          type="submit"
          buttonType="primary"
          customClasses="ml-4"
          text={t("account.updateProfile")}
        />
        <Button
          buttonType="text"
          onClick={(e) => {
            e.preventDefault();
            dispatch(dismissModal());
          }}
          text={t("button.cancel")}
        />
      </ModalControls>
    </form>
  );
};

ModalProfileSettings.displayName = "ModalProfileSettings";

export default ModalProfileSettings;
