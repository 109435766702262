import React, { memo } from "react";
import SvgLoader from "app.components/Util/SvgLoader";
import WalletGroupLogo from "app.components/Images/WalletGroupLogo";

const WalletGroup = memo(
  ({ shadowLedger = false, source, alt, className = "h-7 w-7" }) => {
    const svgClassName = ["stroke-blue-600", className]
      .filter(Boolean)
      .join(" ");

    if (shadowLedger) {
      return (
        <div className="relative flex items-center">
          <SvgLoader title={alt} id="TrackingLedger" className={className} />
        </div>
      );
    }

    return (
      <div className="relative flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={svgClassName}
        >
          {alt ? <title>{alt}</title> : null}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
          />
          <WalletGroupLogo group={source} embed />
        </svg>
      </div>
    );
  }
);

WalletGroup.displayName = "WalletGroup";

export default WalletGroup;
