import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dismissModal } from "app.reducers/ui";

import { CANCEL_TYPES } from "app.constants";
import ErrorMessage from "app.components/Util/ErrorMessage";
import Button from "app.components/Util/Button";
import { getRandomIntInclusive } from "app.utils";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import Input from "app.components/Util/Form/Input";

const ModalConfirmDialog = ({
  callbackAction,
  callbackCancel,
  okType = CANCEL_TYPES.PRIMARY,
  accidentPrevention = false,
  title = "",
  centerText = true,
  helpMessage = "",
  accidentPreventionMessage = "",
  modalError,
  message,
  okText,
  cancelText,
  canDismissCallback,
}) => {
  const itemsRef = useRef([]);
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const [accidentPreventionTest, setAccidentPreventionTest] = useState([
    "",
    "",
    "",
    "",
  ]);

  const accidentPreventionCode = useMemo(() => {
    if (accidentPrevention) {
      return [
        getRandomIntInclusive(0, 9),
        getRandomIntInclusive(0, 9),
        getRandomIntInclusive(0, 9),
        getRandomIntInclusive(0, 9),
      ].join("");
    }
    return "";
  }, [accidentPrevention]);

  const modalWorking = useSelector((state) => state.ui.modalWorking);

  const disableButtons = useMemo(() => {
    return false;
  }, []);

  useEffect(() => {
    canDismissCallback(!disableButtons && modalWorking === false);
  }, [
    canDismissCallback,
    disableButtons,
    modalWorking,
    accidentPreventionTest,
  ]);

  const handleChange = (index, e) => {
    e.preventDefault();
    const updatedAccidentPreventionTest = [...accidentPreventionTest];
    updatedAccidentPreventionTest[index] = e.target.value;

    setAccidentPreventionTest(updatedAccidentPreventionTest);
  };

  const previousFocus = (index, e) => {
    if (e.key === "Backspace") {
      if (e.target.value === "") {
        itemsRef.current?.[index - 1]?.focus();
        return;
      }
    }
    if (e.key === "ArrowLeft") {
      e.preventDefault();
      itemsRef.current?.[index - 1]?.focus();
      itemsRef.current?.[index - 1]?.setSelectionRange(0, 1);
    }
  };

  const nextFocus = (index, e) => {
    e.preventDefault();

    if (e.key === "ArrowRight") {
      itemsRef.current?.[index + 1]?.focus();
      itemsRef.current?.[index + 1]?.setSelectionRange(0, 1);
      return;
    }
    if (e.target.value !== "" && e.target.value <= 9 && e.target.value >= 0) {
      if (e.key >= 0 && e.key <= 9) {
        itemsRef.current?.[index + 1]?.focus();
      }
    }
  };

  const accidentPreventionTestStatus =
    accidentPrevention === true
      ? accidentPreventionCode !== accidentPreventionTest.join("")
      : false;

  let buttonType = "";

  switch (okType) {
    case CANCEL_TYPES.DANGER:
      buttonType = "danger";
      break;
    case CANCEL_TYPES.PRIMARY:
      buttonType = "primary";
      break;
    default:
      break;
  }

  return (
    <>
      {title ? (
        <ModalHeader
          title={title}
          closeCallback={(event) => {
            event.preventDefault();
            reduxDispatch(dismissModal());
          }}
        />
      ) : undefined}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (disableButtons || modalWorking || accidentPreventionTestStatus)
            return;
          if (typeof callbackAction !== "undefined") {
            reduxDispatch({ ...callbackAction });
          }
        }}
      >
        <div className="m-4">
          <p className="text-lg">
            {message || t("modals.youShouldConsiderSendingInAMessageHere")}
          </p>

          {helpMessage ? (
            <p className={centerText ? "text-center" : "text--left"}>
              {helpMessage}
            </p>
          ) : undefined}

          {modalError !== null ? (
            <div className="m-4">
              <ErrorMessage {...modalError} />
            </div>
          ) : null}

          {accidentPrevention === true ? (
            <>
              <p className="mb-4 text-sm text-red-700">
                {accidentPreventionMessage}
              </p>
              <div className="flex">
                <div className="basis-full">
                  <label
                    className="block text-center text-sm"
                    htmlFor="accidentPreventionTest0"
                  >
                    {t("modals.enterTheCodeBelowToConfirm")}
                  </label>
                  <p className="my-4 text-center font-mono text-lg font-bold tracking-[.5em]">
                    {accidentPreventionCode}
                  </p>
                  <div className="flex basis-full justify-center">
                    <Input
                      disabled={modalWorking}
                      ref={(el) => {
                        itemsRef.current[0] = el;
                      }}
                      onKeyUp={(e) => nextFocus(0, e)}
                      onChange={(e) => handleChange(0, e)}
                      maxLength="1"
                      className="mx-2 w-8 text-center"
                      data-tabbable
                      data-testid="accidentPreventionTest0"
                      id="accidentPreventionTest0"
                      name="accidentPreventionTest0"
                      type="text"
                      autoComplete="off"
                      value={accidentPreventionTest[0]}
                    />
                    <Input
                      disabled={modalWorking}
                      onKeyDown={(e) => previousFocus(1, e)}
                      onKeyUp={(e) => nextFocus(1, e)}
                      onChange={(e) => handleChange(1, e)}
                      ref={(el) => {
                        itemsRef.current[1] = el;
                      }}
                      maxLength="1"
                      className="mx-2 w-8 text-center"
                      data-testid="accidentPreventionTest1"
                      id="accidentPreventionTest1"
                      data-tabbable
                      name="accidentPreventionTest1"
                      type="text"
                      autoComplete="off"
                      value={accidentPreventionTest[1]}
                    />
                    <Input
                      disabled={modalWorking}
                      onKeyDown={(e) => previousFocus(2, e)}
                      onKeyUp={(e) => nextFocus(2, e)}
                      onChange={(e) => handleChange(2, e)}
                      ref={(el) => {
                        itemsRef.current[2] = el;
                      }}
                      maxLength="1"
                      className="mx-2 w-8 text-center"
                      data-testid="accidentPreventionTest2"
                      id="accidentPreventionTest2"
                      data-tabbable
                      name="accidentPreventionTest2"
                      type="text"
                      autoComplete="off"
                      value={accidentPreventionTest[2]}
                    />
                    <Input
                      disabled={modalWorking}
                      ref={(el) => {
                        itemsRef.current[3] = el;
                      }}
                      onKeyDown={(e) => previousFocus(3, e)}
                      onChange={(e) => handleChange(3, e)}
                      maxLength="1"
                      className="mx-2 w-8 text-center"
                      data-testid="accidentPreventionTest3"
                      id="accidentPreventionTest3"
                      data-tabbable
                      name="accidentPreventionTest"
                      type="text"
                      autoComplete="off"
                      value={accidentPreventionTest[3]}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : undefined}
        </div>

        <ModalControls>
          <Button
            type="submit"
            disabled={
              disableButtons || modalWorking || accidentPreventionTestStatus
            }
            data-tabbable
            buttonType={buttonType}
            working={modalWorking}
            workingText={t("button.working")}
            customClasses="ml-4"
            text={okText || t("button.ok")}
          />
          <Button
            disabled={modalWorking}
            onClick={(e) => {
              e.preventDefault();
              if (typeof callbackCancel !== "undefined") {
                reduxDispatch({ ...callbackCancel });
              } else {
                reduxDispatch(dismissModal());
              }
            }}
            data-tabbable
            buttonType="text"
            text={cancelText || t("common.cancel")}
          />
        </ModalControls>
      </form>
    </>
  );
};

ModalConfirmDialog.displayName = "ModalConfirmDialog";

export default ModalConfirmDialog;
