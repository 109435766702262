export const initialState = {
  totpSecret: "",
  totp: "",
  currentPassword: "",
};

export const UPDATE_FIELD = "update_field";
export const CLEAR = "clear";
export const CLEAR_TOTP = "clear-totp";

export function updateSecurityReducer(state, action) {
  switch (action.type) {
    case UPDATE_FIELD:
      return {
        ...state,
        [action.name]: action.value,
      };
    case CLEAR_TOTP:
      return {
        ...state,
        totp: "",
      };
    case CLEAR:
      return { ...initialState };
    default:
      throw new Error();
  }
}
