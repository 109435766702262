import { call, put, delay } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import { couponMatchesInputCoupon } from "app.utils";
import {
  receiveUpgradeQuote,
  receiveUpgradeQuoteError,
} from "app.actions/account";

function* fetchUpgradeQuote(action) {
  const { planId, zipCode, profileLimit, couponCode, referrerCode } = action;

  if (typeof planId === "undefined" || planId === null) return;

  // This is to allow a debounce and takeLatest ot work nicely together;
  // So basically, it's like adding a 250ms debounce
  // but this whole call will be cancelled each time we
  // get a new quote, even if we're in progress.
  yield delay(250);

  const response = yield call(
    TellerAPI.getPaymentProductsForUser,
    couponCode || referrerCode,
    zipCode,
    profileLimit
  );

  if (response.error) {
    yield put(receiveUpgradeQuoteError(response.body.message));
  } else {
    const matchingPlan = response.body
      .reduce((acc, product) => [...acc, ...product.plans], [])
      .find((x) => x.planId === planId);

    if (
      couponCode &&
      !couponMatchesInputCoupon(matchingPlan.availableCouponId, couponCode)
    ) {
      // set an error
      yield put(receiveUpgradeQuoteError(response.body.message));
    } else {
      yield put(receiveUpgradeQuote(matchingPlan));
    }
  }
}

export default fetchUpgradeQuote;
