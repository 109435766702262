import { call, put, select } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  securityVerificationRequest,
  securityVerificationReceive,
  securityVerificationError,
} from "app.actions/account";
import { lookupTranslation } from "app.utils";

import {
  ACCOUNT_ERROR_PATH,
  ACCOUNT_RESETING_ERROR,
} from "app.constants/errorMessages";

function* handleSecurityVerification(action) {
  const { currentPassword } = action;

  const { username } = yield select((state) => state.account.userProfile);

  yield put(securityVerificationRequest());

  const response = yield call(
    TellerAPI.associateMFA,
    username,
    currentPassword
  );

  if (response.error) {
    yield put(
      securityVerificationError(
        lookupTranslation(
          "invalid_password",
          ACCOUNT_ERROR_PATH,
          ACCOUNT_RESETING_ERROR
        )
      )
    );
  } else {
    yield put(securityVerificationReceive(response.body.totpSecret));
  }
}

export default handleSecurityVerification;
