import { makeActionCreator } from "app.actions/helpers";

export const UPDATE_BILLING_BEGIN = "UPDATE_BILLING_BEGIN";
export const UPDATE_BILLING_REQUEST = "UPDATE_BILLING_REQUEST";
export const UPDATE_BILLING_RECEIVE = "UPDATE_BILLING_RECEIVE";
export const UPDATE_BILLING_ERROR_RECEIVE = "UPDATE_BILLING_ERROR_RECEIVE";

export const updateBilling = makeActionCreator(
  UPDATE_BILLING_BEGIN,
  "stripeInstance",
  "cardInstance",
  "cardholderName",
  "zipCode"
);

export const requestUpdateBilling = makeActionCreator(UPDATE_BILLING_REQUEST);
export const receiveUpdateBilling = makeActionCreator(UPDATE_BILLING_RECEIVE);
export const receiveUpdateBillingError = makeActionCreator(
  UPDATE_BILLING_ERROR_RECEIVE,
  "error"
);
