import React from "react";
import { Route, Routes, Link } from "react-router";
import { APP_NAME, ASSETS_BASE, HEADER_IMG } from "app.constants";

const logoURL = `${ASSETS_BASE}/${
  import.meta.env.VITE_BUILD_CUSTOMER
}/images/${HEADER_IMG}`;

const HeaderLogo = ({ noLink = false }) => {
  const logoImg = (
    <img
      className="h-logo w-logo aspect-logo bg-transparent text-transparent"
      alt={APP_NAME}
      src={logoURL}
    />
  );

  if (noLink) {
    return (
      <p className="c_header-logo mx-3.5 flex h-12 flex-initial items-center">
        {logoImg}
      </p>
    );
  }

  return (
    <Routes>
      <Route
        path="organization/*"
        element={
          <Link
            className="c_header-logo mx-3.5 flex h-12 min-w-fit flex-initial items-center"
            to="/organization"
          >
            {logoImg}
          </Link>
        }
      />
      <Route
        path="*"
        element={
          <Link
            className="c_header-logo mx-3.5 flex h-12 min-w-fit flex-initial items-center"
            to="/"
          >
            {logoImg}
          </Link>
        }
      />
    </Routes>
  );
};

HeaderLogo.displayName = "HeaderLogo";

export default HeaderLogo;
