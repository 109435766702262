import { takeLatest, takeLeading } from "redux-saga/effects";
import * as authenticationActions from "app.actions/authentication";

import autoRefreshOrganizationToken from "./autoRefreshOrganizationToken";
import autoRefreshUserToken from "./autoRefreshUserToken";
import checkAuthenticationStatus from "./checkAuthenticationStatus";
import goToLogin from "./goToLogin";
import refreshOrganizationToken from "./refreshOrganizationToken";
import refreshUserToken from "./refreshUserToken";

// WATCHERS
const authenticationSagas = [
  takeLeading(
    authenticationActions.AUTHENTICATION_STATUS_CHECK_BEGIN,
    checkAuthenticationStatus
  ),
  takeLatest(
    authenticationActions.AUTO_REFRESH_USER_TOKEN_START,
    autoRefreshUserToken
  ),
  takeLatest(authenticationActions.GO_TO_LOGIN, goToLogin),
  takeLatest(
    authenticationActions.AUTO_REFRESH_ORGANIZATION_TOKEN_START,
    autoRefreshOrganizationToken
  ),
  takeLatest(
    authenticationActions.ORGANIZATION_TOKEN_REFRESH,
    refreshOrganizationToken
  ),
  takeLatest(authenticationActions.REFRESH_USER_TOKEN, refreshUserToken),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default authenticationSagas;
