import { makeActionCreator } from "app.actions/helpers";

/** *************************************************************************** */
/* reset add exchange ledger upload data */
/** *************************************************************************** */

export const RESET_ADD_EXCHANGE_UPLOAD = "RESET_ADD_EXCHANGE_UPLOAD";

export const resetAddExchangeUpload = makeActionCreator(
  RESET_ADD_EXCHANGE_UPLOAD
);
