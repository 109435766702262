import { useSelector } from "react-redux";
import { checkPasswordsMatch, checkPasswordLength } from "app.utils";

function useIsPasswordUpdateFormValid(
  currentPassword,
  newPassword,
  confirmNewPassword,
  totpToken
) {
  const result = {
    blockSubmit: false,
    allRequiredFields: false,
    passwordLength: false,
    passwordMatch: false,
    totpTokenMissing: false,
  };

  const { userProfile } = useSelector((state) => state.account);

  const { mfaEnabled } = userProfile;

  if (!currentPassword || !newPassword || !confirmNewPassword) {
    result.blockSubmit = true;
    result.allRequiredFields = true;
  }

  if (!checkPasswordLength(newPassword)) {
    result.blockSubmit = true;
    result.passwordLength = true;
  }
  if (!checkPasswordsMatch(newPassword, confirmNewPassword)) {
    result.blockSubmit = true;
    result.passwordMatch = true;
  }
  if (mfaEnabled && !totpToken) {
    result.blockSubmit = true;
    result.totpTokenMissing = true;
  }

  return result;
}

export default useIsPasswordUpdateFormValid;
