import React, { useState } from "react";
import Button from "app.components/Util/Button";
import { useTranslation } from "react-i18next";

const ReadMore = ({ children, label }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <>
      {show ? children : null}
      <Button
        onClick={(e) => {
          e.preventDefault();
          setShow(!show);
        }}
        buttonType="link"
        text={
          show
            ? (label && label[1]) || t("button.readLess")
            : (label && label[0]) || t("button.readMore")
        }
      />
    </>
  );
};

ReadMore.displayName = "ReadMore";

export default ReadMore;
