import { makeActionCreator } from "app.actions/helpers";

export const ACCOUNT_PROFILE_RECEIVE = "ACCOUNT_PROFILE_RECEIVE";

export const ACCOUNT_PROFILE_UPDATE_BEGIN = "ACCOUNT_PROFILE_UPDATE_BEGIN";
export const ACCOUNT_PROFILE_UPDATE_REQUEST = "ACCOUNT_PROFILE_UPDATE_REQUEST";
export const ACCOUNT_PROFILE_UPDATE_RECEIVE = "ACCOUNT_PROFILE_UPDATE_RECEIVE";
export const ACCOUNT_PROFILE_UPDATE_ERROR = "ACCOUNT_PROFILE_UPDATE_ERROR";

export const receiveAccountProfile = makeActionCreator(
  ACCOUNT_PROFILE_RECEIVE,
  "userProfile"
);

export const updateProfile = makeActionCreator(
  ACCOUNT_PROFILE_UPDATE_BEGIN,
  "profile"
);

export const updateProfileRequest = makeActionCreator(
  ACCOUNT_PROFILE_UPDATE_REQUEST
);

export const updateProfileReceive = makeActionCreator(
  ACCOUNT_PROFILE_UPDATE_RECEIVE,
  "userProfile"
);

export const updateProfileError = makeActionCreator(
  ACCOUNT_PROFILE_UPDATE_ERROR,
  "error"
);
