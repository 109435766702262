import { call, put } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import OrganizationAPI from "app.api/OrganizationAPI";
import { getLoadingState } from "app.utils/selectors";
import { STATUS } from "app.constants";

function* fetchTeamMemberList() {
  const { nextStatus } = yield* getLoadingState(
    (state) => state.organizations.teamMemberStatus
  );

  yield put(organizationActions.requestTeamMemberList(nextStatus));

  const response = yield call(OrganizationAPI.listCoworkers);

  if (response.error) {
    console.error(
      "Error in fetchTeamMemberList",
      response.body,
      response.error
    );
    yield put(
      organizationActions.receiveTeamMemberListError(
        response.body,
        STATUS.LOADED
      )
    );
  } else {
    yield put(
      organizationActions.receiveTeamMemberList(response.body, STATUS.LOADED)
    );
  }
}

export default fetchTeamMemberList;
