import { call, put } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestSetAddressLabel,
  receiveSetAddressLabel,
  setAddressLabelError,
  dismissModal,
  fetchAddressLabels,
} from "app.reducers/ui";

function* setAddressLabel(action) {
  const { address, label } = action.payload;

  yield put(requestSetAddressLabel());

  const response = yield call(
    ExchangeLedgerAPI.saveAddressLabel,
    address,
    label
  );

  const { body, error } = response;
  if (error) {
    yield put(setAddressLabelError(body));
  } else {
    yield put(receiveSetAddressLabel());
    yield put(dismissModal());
    yield put(fetchAddressLabels(true));
  }
}

export default setAddressLabel;
