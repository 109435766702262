import { call, put } from "redux-saga/effects";
import SettingsAPI from "app.api/SettingsAPI";
import OrganizationAPI from "app.api/OrganizationAPI";

import { getIsOrganizationUser } from "app.utils/selectors";

import {
  receiveDeleteApiKey,
  requestDeleteApiKey,
  deleteApiKeyError,
  fetchApiKeys,
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";

function* deleteApiKey(action) {
  const { key } = action.payload;

  yield put(requestDeleteApiKey());
  yield put(startModalWorking());

  const isOrganizationUser = yield* getIsOrganizationUser();

  const response = isOrganizationUser
    ? yield call(OrganizationAPI.deleteApiKey, key)
    : yield call(SettingsAPI.deleteApiKey, key);

  yield put(receiveDeleteApiKey());

  const { error } = response;
  if (error) {
    yield put(deleteApiKeyError());
  } else {
    yield put(fetchApiKeys(true));
    yield put(dismissModal());
  }
  yield put(endModalWorking());
}

export default deleteApiKey;
