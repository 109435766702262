import { ASSETS_BASE } from "./urls";

export const COIN_ICONS_BLACK_PATH = `/images/cryptocurrency-icons/svg/black/`;
export const COIN_ICONS_COLOR_PATH = `/images/cryptocurrency-icons/svg/color/`;
export const COIN_NFT_PATH = `/images/cryptocurrency-icons/svg/nft.svg`;
export const NFT = "NFT";
export const STABLECOIN = "STABLECOIN";

export const GENERIC_ICON_FILENAME = "generic.svg";
export const GENERIC_ICON_PATH = [
  ASSETS_BASE,
  COIN_ICONS_BLACK_PATH,
  GENERIC_ICON_FILENAME,
].join("");
