import { makeActionCreator } from "app.actions/helpers";
// EXCHANGE_LEDGER_DO_IMPORT
export const LEDGER_ITEM_CLEAR_REASON_BEGIN = "LEDGER_ITEM_CLEAR_REASON_BEGIN";
export const LEDGER_ITEM_CLEAR_REASON_REQUEST =
  "LEDGER_ITEM_CLEAR_REASON_REQUEST";
export const LEDGER_ITEM_CLEAR_REASON_RECEIVE =
  "LEDGER_ITEM_CLEAR_REASON_RECEIVE";
export const LEDGER_ITEM_CLEAR_REASON_ERROR = "LEDGER_ITEM_CLEAR_REASON_ERROR";

export const clearLedgerItemReviewReason = makeActionCreator(
  LEDGER_ITEM_CLEAR_REASON_BEGIN,
  "itemId",
  "ledgerId",
  "reason",
  "isTimeline"
);

export const requestClearLedgerItemReviewReason = makeActionCreator(
  LEDGER_ITEM_CLEAR_REASON_REQUEST
);
export const receiveClearLedgerItemReviewReason = makeActionCreator(
  LEDGER_ITEM_CLEAR_REASON_RECEIVE,
  "response"
);
export const clearLedgerItemReviewReasonError = makeActionCreator(
  LEDGER_ITEM_CLEAR_REASON_ERROR,
  "response"
);
