import api from "../clients/exchange";

/**
 * Turn on smart wallets for the current user
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const enableSmartWallet = () => {
  return api.post(`/smart/manage/enable`, { authenticated: true });
};

/**
 * Turn off smart wallets for the current user
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const disableSmartWallet = () => {
  return api.post(`/smart/manage/disable`, { authenticated: true });
};

/**
 * Gets a list of smart wallets for the current user
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getSmartWalletList = () => {
  return api.get(`/smart/ledger/list`, { authenticated: true });
};

/**
 * Get the summary data for the specified smart wallet
 * @async
 * @function
 * @param {string} smartWalletGUID - smart wallet id
 * @param {ISOString} startDate - ISOString date for start of range
 * @param {ISOString} endDate - ISOString date for end of range
 * @returns {object}  response - The Response or Error message
 */
export const getSmartWalletSummary = (smartWalletGUID, startDate, endDate) => {
  const query = {};
  if (typeof startDate !== "undefined" && typeof endDate !== "undefined") {
    query.startDate = startDate;
    query.endDate = endDate;
  }

  return api.get(`/smart/ledger/summary/${smartWalletGUID}`, {
    params: new URLSearchParams(query),
    authenticated: true,
  });
};

/**
 * Gets the dates of the earliest and latest transaction in a group used to
 * cusomtize date filter
 * @async
 * @function
 * @param {string} smartWalletGUID - smart wallet id
 * @returns {object}  response - The Response or Error message
 */
export const getSmartWalletRange = (smartWalletGUID) => {
  return api.get(`/smart/ledger/transaction-date-bounds/${smartWalletGUID}`, {
    authenticated: true,
  });
};
