import { call, put } from "redux-saga/effects";
import SettingsAPI from "app.api/SettingsAPI";
import OrganizationAPI from "app.api/OrganizationAPI";

import { getIsOrganizationUser } from "app.utils/selectors";

import {
  requestCreateApiKey,
  receiveCreateApiKey,
  createApiKeyError,
  fetchApiKeys,
} from "app.reducers/ui";

function* createApiKey(action) {
  const { readOnly, label, profileGUID, orgGUID } = action.payload;
  const isOrganizationUser = yield* getIsOrganizationUser();

  yield put(requestCreateApiKey());

  let response;

  if (isOrganizationUser) {
    if (orgGUID) {
      response = yield call(
        OrganizationAPI.createApiKeyForOrg,
        orgGUID,
        readOnly,
        label
      );
    } else {
      response = yield call(
        OrganizationAPI.createApiKey,
        profileGUID,
        readOnly,
        label
      );
    }
  } else {
    response = yield call(
      SettingsAPI.createApiKey,
      profileGUID,
      readOnly,
      label
    );
  }

  const { body, error } = response;

  if (error) {
    yield put(createApiKeyError(body));
  } else {
    yield put(receiveCreateApiKey(body));
    yield put(fetchApiKeys(true));
  }
}

export default createApiKey;
