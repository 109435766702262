import { select, put, call, all } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  requestResetPassword,
  receiveResetPasswordError,
  receiveResetPassword,
} from "app.reducers/user";
import { lookupTranslation } from "app.utils";
import { doRedirect } from "app.reducers/ui";
import {
  RESET_PASSWORD_ERROR_PATH,
  RESET_PASSWORD_ERROR_DEFAULT,
} from "app.constants";

function* resetPassword({ payload }) {
  const { email, token, newPassword } = payload;

  const savingPassword = yield select((state) => state.user.savingPassword);
  if (savingPassword) return;
  yield put(requestResetPassword());

  const response = yield call(
    TellerAPI.sendResetPassword,
    email,
    token,
    newPassword
  );
  if (response.error) {
    const message = lookupTranslation(
      response.body.message,
      RESET_PASSWORD_ERROR_PATH,
      RESET_PASSWORD_ERROR_DEFAULT
    );

    const errorRecoverable = response.body.message !== "invalid_token";

    yield put(receiveResetPasswordError({ message, errorRecoverable }));
  } else {
    yield all([
      put(receiveResetPassword(response.body)),
      put(doRedirect("/resetpassword/done")),
    ]);
  }
}

export default resetPassword;
