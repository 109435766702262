import React from "react";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";

const PortfolioTabsPlaceholder = () => {
  return (
    <div className="c_navigation-bar container flex h-10 max-w-none items-center gap-2 bg-blue-50 px-4 transition-all">
      <PlaceholderLine width="w-24" color="bg-zinc-300" />
      <PlaceholderLine width="w-24" color="bg-zinc-300" />
      <PlaceholderLine width="w-32" color="bg-zinc-300" />
      <PlaceholderLine width="w-36" color="bg-zinc-300" />
    </div>
  );
};

PortfolioTabsPlaceholder.displayName = "PortfolioTabsPlaceholder";

export default PortfolioTabsPlaceholder;
