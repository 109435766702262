import api from "../clients/organization";

/**
 * Submits a request to get details about an organization
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getOrganizationDetails = () => {
  return api.get(`/getOrganizationDetails`, {
    authenticated: true,
  });
};
