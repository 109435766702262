import api from "../clients/exchange";

/**
 * Submits a request to enable link kind support on an account
 * @async
 * @function
 * @param string rollForwardDate
 * @returns {object}  response - The Response or Error message
 */
export const updateRollForward = (rollForwardDate) => {
  return api.put(`/user/updateRollForward`, {
    authenticated: true,
    data: { rollForwardDate },
  });
};
