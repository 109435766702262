import api from "../clients/exchangeOrganization";
/**
 * Submits a request to get the custom fields names.
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getCustomFieldsData = () => {
  return api.get(`/profile/customData`, {
    authenticated: true,
  });
};

/**
 * Submits a request to set the custom fields names.
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const setCustomFieldsData = (body) => {
  return api.put(`/profile/customData`, {
    data: body,
    authenticated: true,
  });
};
