import { call, put, delay } from "redux-saga/effects";
import * as filesActions from "app.reducers/files";
import FilesAPI from "app.api/FilesAPI";
import { ONE_SECOND } from "app.constants";

function* generateFile(action) {
  const { fileType, requestParams } = action.payload;

  yield put(filesActions.requestGenerateFile());

  const response = yield call(FilesAPI.generateFile, fileType, requestParams);

  if (response.error) {
    console.error("Error in generateFile", response.body, response.error);
    yield put(filesActions.generateFileError(response.body));
  } else {
    yield put(filesActions.receiveGenerateFile());
    yield delay(ONE_SECOND / 2);
    yield put(filesActions.fetchFiles());
  }
}

export default generateFile;
