import api from "../clients/organization";

export const updateProfile = (profileGUID, displayName) => {
  return api.put(`/updateProfile/${profileGUID}`, {
    data: { displayName },
    authenticated: true,
  });
};

/**
 * Submits a request to create a new profile in an organization
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const addProfile = (displayName) => {
  return api.post(`/createProfile`, {
    data: { displayName },
    authenticated: true,
  });
};

/**
 * Submits a request to reinvite a profile
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const reinviteUser = (invitedUserGUIDString) => {
  return api.post(`/reInviteUser/${invitedUserGUIDString}`, {
    authenticated: true,
  });
};

/**
 * Submits a request to invite a user to a newly created profile
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const inviteUser = (
  invitedUserGUIDString,
  email,
  firstName,
  lastName
) => {
  return api.post(`/inviteUser/${invitedUserGUIDString}`, {
    data: { email, first: firstName, last: lastName },
    authenticated: true,
  });
};

export const deleteProfile = (profileGUID) => {
  return api.delete(`/deleteProfile/${profileGUID}`, {
    authenticated: true,
  });
};

export const undoDeleteProfile = (profileGUID) => {
  return api.put(`/cancelProfileDelete/${profileGUID}`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get the JWT of a profile in your organization
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getProfileJWT = (profileGUID) => {
  return api.post(`/assumeProfile/${profileGUID}`, {
    authenticated: true,
  });
};
