import { makeActionCreator } from "app.actions/helpers";
// VALUE OVERRIDES
export const EDIT_EXCHANGE_VALUE_BEGIN = "EDIT_EXCHANGE_VALUE_BEGIN";
export const EDIT_EXCHANGE_VALUE_REQUEST = "EDIT_EXCHANGE_VALUE_REQUEST";
export const EDIT_EXCHANGE_VALUE_RECEIVE = "EDIT_EXCHANGE_VALUE_RECEIVE";
export const EDIT_EXCHANGE_VALUE_ERROR = "EDIT_EXCHANGE_VALUE_ERROR";

// VALUE OVERRIDES
export const editEntryValue = makeActionCreator(
  EDIT_EXCHANGE_VALUE_BEGIN,
  "itemId",
  "ledgerId",
  "transactionId",
  "value",
  "reason",
  "isTimeline"
);

export const requestEditEntryValue = makeActionCreator(
  EDIT_EXCHANGE_VALUE_REQUEST,
  "itemId",
  "nextStatus"
);

export const receiveEditEntryValue = makeActionCreator(
  EDIT_EXCHANGE_VALUE_RECEIVE,
  "itemId",
  "value",
  "nextStatus"
);

export const editEntryValueError = makeActionCreator(
  EDIT_EXCHANGE_VALUE_ERROR,
  "response",
  "error",
  "itemId"
);
