import { makeActionCreator } from "app.actions/helpers";

export const ACCOUNT_MFA_DISABLE_BEGIN = "ACCOUNT_MFA_DISABLE_BEGIN";
export const ACCOUNT_MFA_DISABLE_REQUEST = "ACCOUNT_MFA_DISABLE_REQUEST";
export const ACCOUNT_MFA_DISABLE_RECEIVE = "ACCOUNT_MFA_DISABLE_RECEIVE";
export const ACCOUNT_MFA_DISABLE_ERROR = "ACCOUNT_MFA_DISABLE_ERROR";

export const disableMFA = makeActionCreator(
  ACCOUNT_MFA_DISABLE_BEGIN,
  "currentPassword",
  "totp"
);
export const disableMFARequest = makeActionCreator(ACCOUNT_MFA_DISABLE_REQUEST);
export const disableMFAError = makeActionCreator(
  ACCOUNT_MFA_DISABLE_ERROR,
  "error"
);
export const disableMFAReceive = makeActionCreator(ACCOUNT_MFA_DISABLE_RECEIVE);
