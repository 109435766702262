import { makeActionCreator } from "app.actions/helpers";
// LEDGER
export const EXCHANGE_LEDGER_BEGIN = "EXCHANGE_LEDGER_BEGIN";
export const EXCHANGE_LEDGER_REQUEST = "EXCHANGE_LEDGER_REQUEST";
export const EXCHANGE_LEDGER_RECEIVE = "EXCHANGE_LEDGER_RECEIVE";
export const EXCHANGE_LEDGER_ERROR = "EXCHANGE_LEDGER_ERROR";

export const fetchExchangeLedger = makeActionCreator(
  EXCHANGE_LEDGER_BEGIN,
  "ledgerId",
  "offset",
  "max",
  "curSort",
  "curOrder",
  "range",
  "rangeKey",
  "filter",
  "noLoading"
);

export const requestExchangeLedger = makeActionCreator(
  EXCHANGE_LEDGER_REQUEST,
  "ledgerId",
  "noLoading",
  "lastRequestParams"
);

export const receiveExchangeLedger = makeActionCreator(
  EXCHANGE_LEDGER_RECEIVE,
  "ledgerId",
  "response",
  "range",
  "rangeKey",
  "page"
);

export const exchangeLedgerError = makeActionCreator(
  EXCHANGE_LEDGER_ERROR,
  "response",
  "range",
  "rangeKey"
);
