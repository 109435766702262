import { PASSWORD_MIN_LENGTH } from "app.constants";

export const checkPasswordsMatch = (password, passwordConfirm) => {
  if (typeof password === "undefined" || typeof passwordConfirm === "undefined")
    return false;

  if (password.length > 0 && passwordConfirm.length > 0) {
    if (password.trim() === passwordConfirm.trim()) {
      return true;
    }
    return false;
  }
  return undefined;
};

export const checkPasswordLength = (password) => {
  if (
    password === null ||
    typeof password === "undefined" ||
    password.length < PASSWORD_MIN_LENGTH
  ) {
    return false;
  }
  return true;
};
