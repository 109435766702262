import { call, put, all } from "redux-saga/effects";
import { getLoadingState } from "app.utils/selectors";
import {
  requestVerifyExchangeLink,
  receiveVerifyExchangeLink,
  verifyExchangeLinkError,
} from "app.actions/exchanges";
import ExchangesAPI from "app.api/ExchangesAPI";
import { openApiKeyModal, openModal } from "app.reducers/ui";
import { handleApiKeyError } from "app.actions/helpers";

import { MODAL_EXCHANGE_OAUTH_POP_UP } from "app.constants/modals";

function* verifyExchangeLink(action) {
  const {
    exchange,
    linkGUID,
    apiLinkSuccessCallback,
    apiDismissCallback,
    oauthRequiredCallback,
  } = action;
  const { isLoading } = yield* getLoadingState(
    (state) => state.exchanges.verifyingExchangeLink[linkGUID]
  );

  if (isLoading) return;

  yield put(requestVerifyExchangeLink(linkGUID));

  const response = yield call(
    ExchangesAPI.verifyExchangeLink,
    exchange.id,
    linkGUID
  );
  const { body, error } = response;

  if (error) {
    if (
      typeof body.metadata !== "undefined" &&
      typeof body.metadata.code !== "undefined"
    ) {
      switch (body.metadata.code) {
        case "oauthRequired":
          yield put(
            openModal(MODAL_EXCHANGE_OAUTH_POP_UP, undefined, {
              exchange,
              linkGUID,
              reLink: true,
              oauthRequiredCallback,
            })
          );
          break;
        case "apiKeyInvalidSignedRequest":
        case "apiKeyInvalid":
        case "apiKeyOverScope":
          yield put(
            handleApiKeyError({
              errorCode: body.metadata.code, // errorCode
              exchange,
              linkGUID,
              openApiKeyModal,
              errorCallback: verifyExchangeLinkError,
              error,
              apiLinkSuccessCallback,
              apiDismissCallback,
            })
          );
          break;
        default:
          break;
      }
      yield all([put(verifyExchangeLinkError(linkGUID, response))]);
    }
  } else {
    yield all([put(receiveVerifyExchangeLink(linkGUID, response))]);
  }
}

export default verifyExchangeLink;
