import React from "react";
import TableListCell from "app.components/TableList/TableListCell";
import { useTranslation } from "react-i18next";

function CouponRow({
  couponError,
  availableCouponId,
  availableDiscountDollars,
  availableDiscountPercent,
  fromReferral,
}) {
  const { t } = useTranslation();
  if (typeof couponError !== "undefined") return null;
  if (!fromReferral && !availableCouponId) return null;

  // temporary hack
  let couponFormLabel;

  if (fromReferral) {
    couponFormLabel = t("modals.coupon.referrerDiscount", {
      referrer: fromReferral.referrerName,
    });
  } else if (availableCouponId === "ESSENTIALS_DISCOUNT") {
    couponFormLabel = t("modals.coupon.promotionSavings");
  } else {
    couponFormLabel = t("modals.coupon.couponSavings");
  }

  return (
    <tr data-testid="coupon-row">
      <TableListCell custom="pl-0" size="sm">
        <span>{couponFormLabel}</span>
        &nbsp;
        <span>
          {availableDiscountPercent > 0 ? (
            <small>{availableDiscountPercent}%</small>
          ) : null}
        </span>
      </TableListCell>
      <TableListCell custom="pr-0" size="sm" right>
        <strong>-&nbsp;${availableDiscountDollars.toFixed(2)}</strong>
      </TableListCell>
    </tr>
  );
}

export default CouponRow;
