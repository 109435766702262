import { call, put, all } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import { dismissModal } from "app.reducers/ui";
import OrganizationAPI from "app.api/OrganizationAPI";
import { getSagaResult } from "app.sagas/helpers";
import { STATUS } from "app.constants";

function* addProfile(action) {
  const {
    displayName,
    associatedEmailAddress,
    firstName,
    lastName,
    assumeOnCreation,
  } = action.payload;

  yield put(organizationActions.addProfileRequest(STATUS.LOADING));

  const response = yield call(OrganizationAPI.addProfile, displayName);

  if (response.error) {
    const code = response.error;
    const { message } = response.body;
    const error = { code, message };

    yield put(organizationActions.addProfileError(error, STATUS.ERROR));
    return;
  }

  const { userGUID } = response.body;
  if (userGUID && associatedEmailAddress) {
    try {
      yield* getSagaResult(
        organizationActions.inviteUser(
          userGUID,
          associatedEmailAddress,
          firstName,
          lastName
        ),
        organizationActions.inviteUserReceive,
        organizationActions.inviteUserError
      );
    } catch (error) {
      // ToDo: figure out what to do here
      yield put(organizationActions.addProfileError(error, STATUS.ERROR));

      return;
    }
  }

  yield all(
    [
      put(organizationActions.addProfileReceive(response.body, STATUS.LOADED)),
      !assumeOnCreation ? put(organizationActions.fetchOrganization()) : null,
      !assumeOnCreation ? put(dismissModal()) : null,
      assumeOnCreation
        ? put(organizationActions.assumeProfile(response.body.profileGUID))
        : null,
    ].filter(Boolean)
  );
}

export default addProfile;
