import i18n from "i18next";

export const getLabelWithNickname = (i18nKey, label, nickname, props = {}) => {
  const p = { ...props };
  p.label = label;

  if (nickname && nickname !== label) {
    p.nickname = nickname;

    return i18n.t(i18nKey, {
      context: "nickname",
      ...p,
    });
  }

  return i18n.t(i18nKey, { ...p });
};
