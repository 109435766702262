import React from "react";
import CardIndicator from "app.components/Util/CardIndicator";

const SelectedColumn = ({ isSelected }) => {
  return (
    <span className="flex justify-end">
      <CardIndicator disabled={false} type="square" active={isSelected} />
    </span>
  );
};
export default SelectedColumn;
