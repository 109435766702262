import { useSelector } from "react-redux";

function useGetUserConfirmed() {
  const { user } = useSelector((state) => state.user);
  const { organizationUser } = useSelector((state) => state.organizations);

  // WARNING - this hook is meant to be used inside a profile, NOT for org
  // admins - this will also return true for an org admin

  // WHY?

  // when an org admin assumes a profile, the confirmed flag on the user
  // is for the org admin, not the assumed profile, we cannot know if an
  // assumed profile is confirmed or not, so we always return true

  if (typeof organizationUser !== "undefined") {
    return true;
  }
  return user.confirmed;
}

export default useGetUserConfirmed;
