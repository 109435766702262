export const couponMatchesInputCoupon = (availableCouponId, userCoupon) => {
  if (
    typeof availableCouponId === "undefined" &&
    typeof userCoupon === "undefined"
  )
    return true;
  if (
    typeof availableCouponId === "undefined" ||
    typeof userCoupon === "undefined"
  )
    return false;

  if (availableCouponId === null || userCoupon === null) return false;

  return availableCouponId.toLowerCase() === userCoupon.toLowerCase();
};

export const isFromReferral = (plan, referral) => {
  if (typeof referral === "undefined") return false;

  return plan.availableCouponId === referral.code;
};

export const isOnNormalSale = (plan, referral) => {
  if (!plan.availableCouponId) return false;
  if (isFromReferral(plan, referral)) return false;

  return true;
};

// ToDo: Assess whether this is easy to centralize and generate here.
export const extractLineItems = (plan) => {
  return {
    basePrice: { amount: plan.amountDollars },
    costPerSeat: {},
    couponDiscount: {},
    referralDiscount: {},
    promotionalDiscount: {},
    finalCost: { amount: plan.subtotalAmountDollars },
  };
};
