import { makeActionCreator } from "app.actions/helpers";

export const CANCEL_DOWNGRADE_BEGIN = "CANCEL_DOWNGRADE_BEGIN";
export const CANCEL_DOWNGRADE_REQUEST = "CANCEL_DOWNGRADE_REQUEST";
export const CANCEL_DOWNGRADE_RECEIVE = "CANCEL_DOWNGRADE_RECEIVE";
export const CANCEL_DOWNGRADE_ERROR_RECEIVE = "CANCEL_DOWNGRADE_ERROR_RECEIVE";

export const cancelDowngrade = makeActionCreator(
  CANCEL_DOWNGRADE_BEGIN,
  "subscriptionId"
);

export const requestCancelDowngrade = makeActionCreator(
  CANCEL_DOWNGRADE_REQUEST
);
export const receiveCancelDowngrade = makeActionCreator(
  CANCEL_DOWNGRADE_RECEIVE
);
export const receiveCancelDowngradeError = makeActionCreator(
  CANCEL_DOWNGRADE_ERROR_RECEIVE,
  "error"
);
