// essentially reproduces event.path since that is not widely supported.
export function getElementPath(target) {
  const path = [];
  let t = target;
  while (t) {
    path.push(t);
    t = t.parentElement;
  }
  if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
    path.push(document);
  if (path.indexOf(window) === -1) path.push(window);
  return path;
}
