import i18n from "i18next";
import { store } from "app.config/configureStore";
import { initReactI18next } from "react-i18next";

const DEFAULT_LANGUAGE = "en-US";

export const getCurrentLanguage = () => {
  const { user } = store.getState();
  const userLanguage = user?.user?.preference?.languageTag;

  if (userLanguage) {
    return userLanguage;
  }

  // because Safari is ...different
  const navLang =
    navigator.language === "en" || navigator.language === DEFAULT_LANGUAGE
      ? DEFAULT_LANGUAGE
      : navigator.language;

  const language = navLang || DEFAULT_LANGUAGE;

  return language;
};

export const setHTMLLang = (language) => {
  const htmlTag = document.getElementsByTagName("html")[0];
  htmlTag.setAttribute("lang", language);
};

const options = {
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: { escapeValue: false },
  debug: import.meta.env.VITE_NODE_ENV === "development",
  resources: {},
  lng: getCurrentLanguage(),
  ns: ["common"],
  defaultNS: "common",
  react: {
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
    useSuspense: true,
  },
};

const loadLanguageAsync = async (language) => {
  if (language === i18n.language) return;

  const loadLanguage = language || i18n.language;

  const { json, isDefaultFile } = await import(
    `../../locales/${loadLanguage}.json`
  )
    .then((res) => ({ json: res.default }))
    .catch(async () => {
      console.error(`Unable to find ${loadLanguage} file`);

      const defaultFile = await import(
        `../../locales/${DEFAULT_LANGUAGE}.json`
      ).then((res) => res.default);

      return { json: defaultFile, isDefaultFile: true };
    });

  i18n.addResourceBundle(
    isDefaultFile ? DEFAULT_LANGUAGE : loadLanguage,
    "common",
    json
  );
};

i18n.use(initReactI18next).init(options);
i18n.addResourceBundle("en-US", "common", {
  common: {
    loading: "Loading",
    loading_withEllipsis: "Loading…",
  },
});

setHTMLLang(getCurrentLanguage());
loadLanguageAsync();

export default i18n;

export const changeLanguage = async (language) => {
  await loadLanguageAsync(language);
  i18n.changeLanguage(language);
};
