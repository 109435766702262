import React from "react";

const SvgLoader = ({ id, className = "", title = "", ...props }) => (
  <svg className={className} {...props}>
    {title !== "" ? <title>{title}</title> : null}
    <use href={`/sprite.min.svg#${id}`} />
  </svg>
);

SvgLoader.displayName = "SvgLoader";

export default SvgLoader;
