import { put, race, take, call } from "redux-saga/effects";
import {
  dismissModal,
  openModal,
  openWalletTokenModal,
  completeWalletTokenModal,
  dismissWalletTokenModal,
} from "app.reducers/ui";

import { MODAL_EXCHANGE_WALLET_TOKEN_POP_UP } from "app.constants/modals";

function* showWalletTokenModal() {
  while (true) {
    const action = yield take(openWalletTokenModal);

    const { params, tokenDismissCallback, tokenLinkSuccessCallback } =
      action.payload;

    yield put(openModal(MODAL_EXCHANGE_WALLET_TOKEN_POP_UP, undefined, params));

    const { dismiss, success } = yield race({
      dismiss: take([dismissWalletTokenModal, dismissModal]),
      success: take(completeWalletTokenModal),
    });

    if (dismiss) {
      if (typeof apiDismissCallback === "function") {
        yield call(tokenDismissCallback);
      }
    }

    if (success) {
      const { linkGUID } = success.payload;
      if (typeof tokenLinkSuccessCallback === "function") {
        yield call(tokenLinkSuccessCallback, linkGUID);
      }
    }

    yield put(dismissModal());
  }
}

export default showWalletTokenModal;
