import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { parse, format } from "date-fns";
import { getElementPath } from "app.utils";
import { getDateLocalePattern } from "app.utils/dates";
import { useGetUserLocale } from "app.hooks";
import SvgLoader from "app.components/Util/SvgLoader";

import { GENESIS_DATE } from "app.components/Util/DatePicker/rangePresets";

import CalendarPicker from "./CalendarPicker";

const BUTTON_BASE_CLASS =
  "flex items-center justify-between rounded border px-2 py-[1px] text-sm leading-7 text-zinc-600 shadow-inner transition-all";

const DISABLED_BUTTON_CLASS = "cursor-not-allowed bg-zinc-50 opacity-70";

const DatePicker = ({
  id = "dropdownButton",
  selectedDate,
  selectDateCallback,
  buttonWithNoMargin = false,
  blockFutureDates = false,
  disabled = false,
  className = "",
  invalidDate,
  invalidDay,
  minSelectableDate = GENESIS_DATE,
}) => {
  const { t } = useTranslation();
  const container = useRef();
  const dateInput = useRef();
  const [open, setOpen] = useState(false);
  const [firstRun, setFirstRun] = useState(true);

  const userLocale = useGetUserLocale();

  const formatString = getDateLocalePattern(userLocale);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const typeDate = (e) => {
    const parsedDate = parse(e.target.value, formatString, new Date());
    selectDateCallback(parsedDate);
  };

  // whenever the picker opens, set focus.
  useEffect(() => {
    if (open) {
      dateInput.current.focus();
    }
  }, [open, dateInput]);

  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
    }
    if (open) {
      // if a date is chosen, the field will close, and we want to get the cursor back to the button for tabbing
      dateInput.current.focus();
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate && !invalidDay) {
      dateInput.current.value = format(selectedDate, formatString);
    }
  }, [selectedDate, invalidDay, formatString]);

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target) if (!open) return;
      // normally, we check to see if the element is in the html
      // element path of the container to see if we should close
      // but here, we are ALSO checking to see if a particular button was clicked
      // because if that button was clicked, the component will be unmounted,
      // and it will appear as though the unmounted component is not in the path
      // so a bit of a hack, but we'll detect if this button was pressed by
      // inspecting the ID
      if (e.target.id === "select-year" || e.target.id === "select-year-cancel")
        return;
      const path = getElementPath(e.target);
      // if we clicked outside the container
      if (path.indexOf(container.current) === -1) {
        setOpen(false);
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };

    window.addEventListener("click", handleClick, false);
    window.addEventListener("keydown", handleKeyDown, false);
    return () => {
      window.removeEventListener("click", handleClick, false);
      window.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [open]);

  const datePickerBodyClassName = [
    "c_date-picker",
    open ? "opacity-1 scale-x-1 scale-y-1" : "opacity-0 scale-x-1 scale-y-0",
    "absolute",
    "shadow-menu",
    "w-60",
    "rounded",
    "py-2",
    "px-1",
    "mt-1",
    "transition-all",
    "origin-top",
    "bg-white",
    "overflow-hidden",
    "z-[11]",
    "flex",
    "justify-center",
    buttonWithNoMargin ? "m-0" : "ml-2",
  ]
    .filter(Boolean)
    .join(" ");

  const buttonClass = [
    BUTTON_BASE_CLASS,
    className,
    disabled ? DISABLED_BUTTON_CLASS : "",
    invalidDate ? "border-red-700" : "",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div ref={container}>
      <button
        id={id}
        data-dropdown-toggle="dropdown"
        className={buttonClass}
        disabled={disabled}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          toggleOpen();
        }}
      >
        <input
          ref={dateInput}
          placeholder={
            open ? t("utils.datePicker.selectDate") : formatString.toUpperCase()
          }
          className={[
            "focus:outline-none",
            invalidDate ? "text-red-700" : "",
          ].join(" ")}
          disabled={disabled}
          autoComplete="off"
          onChange={typeDate}
        />
        <SvgLoader id="Calendar" className="h-5 w-5 fill-blue-600" />
        <div
          className={[
            "caret ml-1.5",
            selectedDate ? "border-t-blue-600" : "border-t-zinc-400",
          ].join(" ")}
        />
      </button>

      <div id="dropdown" className={datePickerBodyClassName}>
        <CalendarPicker
          noRange
          open={open}
          selectedDate={invalidDate ? new Date() : selectedDate}
          setSelectedDate={selectDateCallback}
          blockFutureDates={blockFutureDates}
          minSelectableDate={minSelectableDate}
        />
      </div>
    </div>
  );
};

DatePicker.displayName = "DatePicker";

export default DatePicker;
