import { makeActionCreator } from "app.actions/helpers";

export const ACCOUNT_FIAT_UPDATE_BEGIN = "ACCOUNT_FIAT_UPDATE_BEGIN";
export const ACCOUNT_FIAT_UPDATE_REQUEST = "ACCOUNT_FIAT_UPDATE_REQUEST";
export const ACCOUNT_FIAT_UPDATE_RECEIVE = "ACCOUNT_FIAT_UPDATE_RECEIVE";
export const ACCOUNT_FIAT_UPDATE_ERROR = "ACCOUNT_FIAT_UPDATE_ERROR";

export const updateFiat = makeActionCreator(ACCOUNT_FIAT_UPDATE_BEGIN, "fiat");
export const updateFiatRequest = makeActionCreator(ACCOUNT_FIAT_UPDATE_REQUEST);
export const updateFiatReceive = makeActionCreator(ACCOUNT_FIAT_UPDATE_RECEIVE);
export const updateFiatError = makeActionCreator(
  ACCOUNT_FIAT_UPDATE_ERROR,
  "error"
);
