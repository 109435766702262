import { makeActionCreator } from "app.actions/helpers";
// LEDGER
export const TIMELINE_BEGIN = "TIMELINE_BEGIN";
export const TIMELINE_REQUEST = "TIMELINE_REQUEST";
export const TIMELINE_RECEIVE = "TIMELINE_RECEIVE";
export const TIMELINE_ERROR = "TIMELINE_ERROR";
export const TIMELINE_STALE = "TIMELINE_STALE";

export const fetchTimeline = makeActionCreator(
  TIMELINE_BEGIN,
  "offset",
  "max",
  "curSort",
  "curOrder",
  "range",
  "rangeKey",
  "filter",
  "timelineFilters",
  "showAccrualTransactions",
  "noLoading"
);

export const requestTimeline = makeActionCreator(
  TIMELINE_REQUEST,
  "noLoading",
  "lastRequestParams"
);

export const receiveTimeline = makeActionCreator(
  TIMELINE_RECEIVE,
  "response",
  "range",
  "rangeKey",
  "page"
);

export const timelineError = makeActionCreator(
  TIMELINE_ERROR,
  "response",
  "range",
  "rangeKey"
);

export const setTimelineStale = makeActionCreator(TIMELINE_STALE, "stale");
