import { put } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";

import { STATUS } from "app.constants";

function* assumeProfile(action) {
  const { profileGUID } = action.payload;

  yield put(organizationActions.assumeProfileRequest(STATUS.WORKING));
  yield put(
    organizationActions.assumeProfileReceive(profileGUID, STATUS.LOADED)
  );

  // when assuming a profile, we'll save the profile to assume and reload the
  // app, this is not super efficient, but it ensures we are not polluting
  // the app space with effects or data from priorly assumed profile.

  window.location.assign("/");
}

export default assumeProfile;
