import * as auth from "./auth";
import * as enroll from "./enroll";
import * as lookup from "./lookup";
import * as mfa from "./mfa";
import * as subscription from "./subscription";
import * as user from "./user";

const TellerAPI = {
  ...auth,
  ...enroll,
  ...lookup,
  ...mfa,
  ...subscription,
  ...user,
};

export default TellerAPI;
