import React from "react";
import LoadingSpinner from "app.components/Util/LoadingSpinner";

const PortfolioSummaryLoading = () => {
  const portfolioSummaryClass = [
    "c_PortfolioSummaryLoading",
    "border-zinc-200",
    "border-0",
    "border-b",
    "flex",
    "justify-between",
    "py-4",
    "transition-opacity",
    "flex-col",
    "h-64",
    "items-center",
    "jusitify-center",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={portfolioSummaryClass}>
      <LoadingSpinner className="flex flex-1" />
    </div>
  );
};

PortfolioSummaryLoading.displayName = "PortfolioSummaryLoading";

export default PortfolioSummaryLoading;
