import api from "app.api/clients/exchange";
/**
 * Submits a request to label an address
 * @async
 * @function
 * @param {string} address - the address to label
 * @param {string} label - The label for the address
 * @returns {object}  response - The Response or Error message
 */
export const saveAddressLabel = (address, label) => {
  const data = { address, label };

  return api.post(`/tag/address/create`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to get a list of a users saved address labels
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getAddressLabels = () => {
  return api.get(`/tag/labels`, {
    authenticated: true,
  });
};

/**
 * Submits a request to upload address labels
 * @async
 * @function
 * @param {number} addressFile - The file containing the transactions
 * @returns {object}  response - The Response or Error message
 */
export const uploadBulkAddressLabels = (files) => {
  const formData = new FormData();
  const fileMap = {};

  const fileKeys = Object.keys(files);

  for (let i = 0; i < fileKeys.length; i += 1) {
    const key = fileKeys[i];
    formData.append("files", files[key], key);
    fileMap[key] = files[key].name;
  }

  formData.append("fileMap", JSON.stringify(fileMap));

  return api.post(`/document/bulk-address-label`, {
    data: formData,
    authenticated: true,
  });
};
