import { call, put, all, fork } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import { dismissModal } from "app.reducers/ui";
import {
  receiveUpdateBillingError,
  receiveUpdateBilling,
} from "app.actions/account";

import { lookupTranslation } from "app.utils";
import { BILLING_ERROR_PATH, BILLING_ERROR_DEFAULT } from "app.constants";
import enterSubscriptionSection from "./enterSubscriptionSection";

function* updateBillingInformation(action) {
  const { stripeInstance, cardInstance, cardholderName, zipCode } = action;

  if (!cardholderName || !zipCode) {
    yield put(
      receiveUpdateBillingError(
        lookupTranslation(
          "cardholder_name_and_zip_required",
          BILLING_ERROR_PATH,
          BILLING_ERROR_DEFAULT
        )
      )
    );
    return;
  }

  const tokenOptions = {
    name: cardholderName,
    address_zip: zipCode,
  };

  const tokenResult = yield call(
    stripeInstance.createToken,
    cardInstance,
    tokenOptions
  );

  if (tokenResult.error) {
    yield put(receiveUpdateBillingError(tokenResult.error.message));
    return;
  }

  const { token } = tokenResult;

  const response = yield call(TellerAPI.setPaymentInfo, token.id);

  if (response.error) {
    const errorMessage = lookupTranslation(
      response.message,
      BILLING_ERROR_PATH,
      BILLING_ERROR_DEFAULT
    );

    yield put(receiveUpdateBillingError(errorMessage));
  } else {
    yield all([
      put(receiveUpdateBilling()),
      put(dismissModal()),
      fork(enterSubscriptionSection),
    ]);
  }
}

export default updateBillingInformation;
