import React from "react";
import CashMoney from "app.components/Util/CashMoney";
import TableListCell from "app.components/TableList/TableListCell";
import { useTranslation } from "react-i18next";

function SubtotalRow({ plan }) {
  const { t } = useTranslation();

  const isProrated =
    plan.adjustedAmountDollars !== plan.amountDollars &&
    plan.prorationType === "upgrade";
  return (
    <tr data-testid="subtotal-row">
      <TableListCell custom="pl-0" size="sm">
        {t("common.subtotal")}
      </TableListCell>
      <TableListCell custom="pr-0" right size="sm">
        <CashMoney
          size="none"
          align="right"
          dollars={isProrated ? plan.adjustedAmountDollars : plan.amountDollars}
          fiatOverride="USD"
        />
      </TableListCell>
    </tr>
  );
}

export default SubtotalRow;
