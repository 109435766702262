import * as accountActions from "app.actions/account";
import { receiveAuthorizeUser } from "app.reducers/user";
import { openModal, dismissModal } from "app.reducers/ui";
import { ACCOUNT_STATUS } from "app.constants/loadingStates";

const initialState = {
  userProfile: undefined,
  userProfileStatus: ACCOUNT_STATUS.UNINITIALIZED,
  userProfileError: undefined,
  passwordStatus: ACCOUNT_STATUS.UNINITIALIZED,
  passwordError: undefined,
  securityStatus: ACCOUNT_STATUS.UNINITIALIZED,
  securityError: undefined,
  totpSecret: undefined,
  subscriptionStatus: ACCOUNT_STATUS.UNINITIALIZED,
  currentSubscription: undefined,
  currentlyActivePlan: undefined,
  billingInformation: undefined,
  upgradeQuote: undefined,
  upgradeQuoteError: undefined,
  upgradeQuoteStatus: ACCOUNT_STATUS.UNINITIALIZED,
  upgradePlanStatus: ACCOUNT_STATUS.UNINITIALIZED,
  upgradePlanError: undefined,
  referrer: undefined,
  plans: [],
  updateBillingStatus: ACCOUNT_STATUS.UNINITIALIZED,
  updateBillingError: undefined,
  changingLanguage: false,
  langaugeWaitingOnToken: false,
  supportedLanguages: undefined,
  supportedLanguagesStatus: ACCOUNT_STATUS.UNINITIALIZED,
  changingFiat: false,
  fiatWaitingOnToken: false,
  changingTimeZone: false,
  supportedFiats: undefined,
  supportedFiatsStatus: ACCOUNT_STATUS.UNINITIALIZED,
  removeBillingStatus: ACCOUNT_STATUS.UNINITIALIZED,
  removeBillingError: undefined,
};

// eslint-disable-next-line default-param-last
const Account = (state = initialState, action) => {
  switch (action.type) {
    case accountActions.ACCOUNT_PROFILE_RECEIVE: {
      return {
        ...state,
        userProfileStatus: ACCOUNT_STATUS.LOADED,
        userProfile: action.userProfile,
      };
    }
    case accountActions.ACCOUNT_PROFILE_UPDATE_BEGIN: {
      return {
        ...state,
        userProfileStatus: ACCOUNT_STATUS.UPDATING,
        userProfileError: action.error,
      };
    }

    case accountActions.ACCOUNT_PROFILE_UPDATE_ERROR: {
      return {
        ...state,
        userProfileStatus: ACCOUNT_STATUS.ERROR,
        userProfileError: action.error,
      };
    }

    case accountActions.ACCOUNT_PROFILE_UPDATE_RECEIVE: {
      return {
        ...state,
        userProfile: action.userProfile,
        userProfileStatus: ACCOUNT_STATUS.UPDATED,
        userProfileError: undefined,
      };
    }

    case accountActions.ACCOUNT_PASSWORD_UPDATE_REQUEST: {
      return {
        ...state,
        passwordError: undefined,
        passwordStatus: ACCOUNT_STATUS.UPDATING,
      };
    }

    case accountActions.ACCOUNT_PASSWORD_UPDATE_ERROR: {
      return {
        ...state,
        passwordStatus: ACCOUNT_STATUS.ERROR,
        passwordError: action.error,
      };
    }

    case accountActions.ACCOUNT_PASSWORD_UPDATE_RECEIVE: {
      return {
        ...state,
        passwordStatus: ACCOUNT_STATUS.UPDATED,
        passwordError: undefined,
      };
    }

    case dismissModal.type: {
      return {
        ...state,
        passwordStatus: ACCOUNT_STATUS.UNINITIALIZED,
        securityStatus: ACCOUNT_STATUS.UNINITIALIZED,
        securityError: undefined,
        passwordError: undefined,
        totpSecret: undefined,
      };
    }

    case accountActions.ACCOUNT_MFA_VERIFY_REQUEST:
      return {
        ...state,
        securityStatus: ACCOUNT_STATUS.UPDATING,
        securityError: undefined,
      };
    case accountActions.ACCOUNT_MFA_VERIFY_RECEIVE:
      return {
        ...state,
        totpSecret: action.totpSecret,
        securityStatus: ACCOUNT_STATUS.LOADED,
        securityError: undefined,
      };
    case accountActions.ACCOUNT_MFA_VERIFY_ERROR:
      return {
        ...state,
        totpSecret: undefined,
        securityStatus: ACCOUNT_STATUS.LOADED,
        securityError: action.error,
      };

    case accountActions.ACCOUNT_MFA_ENABLE_REQUEST:
      return {
        ...state,
        securityStatus: ACCOUNT_STATUS.UPDATING,
        securityError: undefined,
      };
    case accountActions.ACCOUNT_MFA_ENABLE_RECEIVE:
      return {
        ...state,
        totpSecret: undefined,
        securityStatus: ACCOUNT_STATUS.UPDATED,
        securityError: undefined,
      };
    case accountActions.ACCOUNT_MFA_ENABLE_ERROR:
      return {
        ...state,
        securityStatus: ACCOUNT_STATUS.LOADED,
        securityError: action.error,
      };

    case accountActions.ACCOUNT_MFA_DISABLE_REQUEST:
      return {
        ...state,
        securityStatus: ACCOUNT_STATUS.UPDATING,
        securityError: undefined,
      };

    case accountActions.ACCOUNT_MFA_DISABLE_RECEIVE:
      return {
        ...state,
        securityStatus: ACCOUNT_STATUS.UPDATED,
        securityError: undefined,
      };

    case accountActions.ACCOUNT_MFA_DISABLE_ERROR:
      return {
        ...state,
        securityStatus: ACCOUNT_STATUS.LOADED,
        securityError: action.error,
      };

    case accountActions.BILLING_INFO_RECEIVE: {
      return {
        ...state,
        billingInformation: action.billingInformation,
      };
    }

    case accountActions.SET_REFERRER: {
      return { ...state, referrer: action.referrer };
    }

    case accountActions.SUBSCRIPTION_LOADED: {
      return {
        ...state,
        plans: action.plans,
        currentlyActivePlan: action.activePlan,
        subscriptionStatus: ACCOUNT_STATUS.LOADED,
      };
    }

    case accountActions.UPGRADE_QUOTE_REQUEST:
    case accountActions.UPGRADE_QUOTE_BEGIN: {
      return {
        ...state,
        upgradeQuoteError: undefined,
        upgradePlanError: undefined,
        upgradeQuoteStatus: ACCOUNT_STATUS.LOADING,
      };
    }

    case accountActions.UPGRADE_QUOTE_RECEIVE: {
      return {
        ...state,
        upgradeQuoteError: undefined,
        upgradeQuote: action.upgradeQuote,
        upgradeQuoteStatus: ACCOUNT_STATUS.LOADED,
      };
    }
    case accountActions.CLEAR_UPGRADE_QUOTE: {
      return {
        ...state,
        upgradeQuoteError: undefined,
        upgradeQuote: undefined,
        upgradeQuoteStatus: ACCOUNT_STATUS.LOADED,
      };
    }
    case accountActions.UPGRADE_QUOTE_ERROR_RECEIVE: {
      return {
        ...state,
        upgradeQuoteError: action.error,
        upgradeQuoteStatus: ACCOUNT_STATUS.LOADED,
      };
    }
    case accountActions.USER_PLAN_SUBSCRIPTION_RECEIVE: {
      return { ...state, currentSubscription: action.currentSubscription };
    }

    case accountActions.UPDATE_BILLING_BEGIN: {
      return {
        ...state,
        updateBillingStatus: ACCOUNT_STATUS.LOADING,
        updateBillingError: undefined,
      };
    }

    case accountActions.UPDATE_BILLING_RECEIVE: {
      return {
        ...state,
        updateBillingStatus: ACCOUNT_STATUS.UPDATED,
        updateBillingError: undefined,
      };
    }

    case accountActions.UPDATE_BILLING_ERROR_RECEIVE: {
      return {
        ...state,
        updateBillingStatus: ACCOUNT_STATUS.ERROR,
        updateBillingError: action.error,
      };
    }

    case accountActions.UPGRADE_PLAN_BEGIN: {
      return {
        ...state,
        upgradePlanError: undefined,
        upgradePlanStatus: ACCOUNT_STATUS.LOADING,
      };
    }

    case accountActions.UPGRADE_PLAN_RECEIVE: {
      return {
        ...state,
        upgradePlanError: undefined,
        upgradePlanStatus: ACCOUNT_STATUS.UPDATED,
      };
    }
    case accountActions.UPGRADE_PLAN_ERROR_RECEIVE: {
      return {
        ...state,
        upgradePlanStatus: ACCOUNT_STATUS.ERROR,
        upgradePlanError: action.error,
      };
    }
    case openModal.type: {
      return {
        ...state,
        userProfileStatus: ACCOUNT_STATUS.LOADED,
        passwordStatus: ACCOUNT_STATUS.UNINITIALIZED,
        securityStatus: ACCOUNT_STATUS.UNINITIALIZED,
      };
    }
    case accountActions.ACCOUNT_LANGUAGE_UPDATE_REQUEST: {
      return {
        ...state,
        changingLanguage: true,
        langaugeWaitingOnToken: false,
      };
    }
    case accountActions.ACCOUNT_LANGUAGE_UPDATE_RECEIVE: {
      return {
        ...state,
        langaugeWaitingOnToken: true,
      };
    }
    case receiveAuthorizeUser.type: {
      return {
        ...state,
        langaugeWaitingOnToken: state.langaugeWaitingOnToken
          ? false
          : state.langaugeWaitingOnToken,
        changingLanguage: state.langaugeWaitingOnToken
          ? false
          : state.changingLanguage,
        fiatWaitingOnToken: state.fiatWaitingOnToken
          ? false
          : state.fiatWaitingOnToken,
        changingFiat: state.fiatWaitingOnToken ? false : state.changingFiat,
      };
    }
    case accountActions.ACCOUNT_LANGUAGE_UPDATE_ERROR: {
      return {
        ...state,
        langaugeWaitingOnToken: false,
        changingLanguage: false,
      };
    }
    case accountActions.SUPPORTED_LANGUAGES_REQUEST:
      return {
        ...state,
        supportedLanguagesStatus: action.nextStatus,
      };
    case accountActions.SUPPORTED_LANGUAGES_RECEIVE:
      return {
        ...state,
        supportedLanguages: action.languages,
        supportedLanguagesStatus: action.nextStatus,
      };
    case accountActions.ACCOUNT_FIAT_UPDATE_REQUEST: {
      return {
        ...state,
        changingFiat: true,
        fiatWaitingOnToken: false,
      };
    }
    case accountActions.ACCOUNT_FIAT_UPDATE_RECEIVE: {
      return {
        ...state,
        fiatWaitingOnToken: true,
        changingFiat: false,
      };
    }
    case accountActions.ACCOUNT_FIAT_UPDATE_ERROR: {
      return {
        ...state,
        fiatWaitingOnToken: false,
        changingFiat: false,
      };
    }
    case accountActions.SUPPORTED_FIATS_REQUEST:
      return {
        ...state,
        supportedFiatsStatus: action.nextStatus,
      };
    case accountActions.SUPPORTED_FIATS_RECEIVE:
      return {
        ...state,
        supportedFiats: action.fiats,
        supportedFiatsStatus: action.nextStatus,
      };

    case accountActions.ACCOUNT_TIMEZONE_UPDATE_REQUEST: {
      return {
        ...state,
        changingTimeZone: true,
      };
    }
    case accountActions.ACCOUNT_TIMEZONE_UPDATE_RECEIVE: {
      return {
        ...state,
        changingTimeZone: false,
      };
    }
    case accountActions.ACCOUNT_TIMEZONE_UPDATE_ERROR: {
      return {
        ...state,
        changingTimeZone: false,
      };
    }

    case accountActions.REMOVE_BILLING_BEGIN: {
      return {
        ...state,
        removeBillingStatus: ACCOUNT_STATUS.LOADING,
        removeBillingError: undefined,
      };
    }

    case accountActions.REMOVE_BILLING_RECEIVE: {
      return {
        ...state,
        removeBillingStatus: ACCOUNT_STATUS.UPDATED,
        removeBillingError: undefined,
      };
    }

    case accountActions.REMOVE_BILLING_ERROR_RECEIVE: {
      return {
        ...state,
        removeBillingStatus: ACCOUNT_STATUS.ERROR,
        removeBillingError: action.error,
      };
    }

    default:
      return state;
  }
};

export default Account;
