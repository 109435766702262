import { call, put, delay, select, all } from "redux-saga/effects";
import * as filesActions from "app.reducers/files";
import FilesAPI from "app.api/FilesAPI";
import { getLoadingState } from "app.utils/selectors";
import {
  STATUS,
  FILES_STATUS,
  FIVE_SECONDS,
  ADDRESS_LABEL_CSV,
} from "app.constants";

import {
  STATUS_INFO,
  STATUS_DANGER,
} from "app.components/Messages/FlashMessage";
import { MESSAGE_TYPES } from "app.components/Messages/MessageGenerator";

import { addFlashMessage } from "app.reducers/ui";
import { generateGUID } from "app.utils";

function* fetchFiles(action) {
  const oneTime = action.payload.oneTime || false;

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.files.filesStatus
  );

  if (isLoading) return;

  const subscription = yield select((state) => state.user.subscription);

  // use to keep track of when a document goes from
  // generating to ready
  const previousDocumentsComplete = yield select(
    (state) => state.files.documentsComplete
  );

  yield put(filesActions.requestFiles(nextStatus));

  let response = { body: { documents: [], metadata: [] } };
  if (subscription.doc8949 === true) {
    response = yield call(FilesAPI.listAvailableFiles);
  }

  if (response.error) {
    console.error("Error in fetchFiles", response.body, response.error);
    yield put(filesActions.filesError(response.body, STATUS.ERROR));
  } else {
    // check each group to see if any file is generating
    const { documents, metadata } = response.body;
    const documentsGenerating = (
      documents ? Object.values(documents).flat() : []
    ).filter((i) => i.status === FILES_STATUS.GENERATING);

    const documentsComplete = (
      documents ? Object.values(documents).flat() : []
    ).filter(
      (i) =>
        i.status === FILES_STATUS.COMPLETE || i.status === FILES_STATUS.ERROR
    );

    // check the list of documents complete, if there is a single new entry,
    // trigger a download, if there are multiple, we'll pop up notifcations
    // for them
    const documentsReady = [];

    if (typeof previousDocumentsComplete !== "undefined") {
      documentsComplete.forEach((d) => {
        if (
          previousDocumentsComplete.findIndex((pd) => pd === d.documentGUID) ===
          -1
        ) {
          // this is a newly completed document, add it to the list
          documentsReady.push(d);
        }
      });
    }
    // if (documentsReady.length === 1) {
    //   //yield put(filesActions.downloadFile(documentsReady[0].documentGUID));
    // } else {
    //   if (documentsReady.length > 1) {
    const documentMessages = [];
    documentsReady.forEach((document) => {
      const messageId = generateGUID();
      if (
        document.status === FILES_STATUS.COMPLETE &&
        document.fileType !== ADDRESS_LABEL_CSV
      ) {
        const message = {
          type: MESSAGE_TYPES.DOWNLOAD_READY,
          document,
          messageId,
        };

        documentMessages.push(
          // eslint-disable-next-line redux-saga/yield-effects
          put(addFlashMessage(message, messageId, STATUS_INFO))
        );
      }
      if (document.status === FILES_STATUS.ERROR) {
        const message = {
          type: MESSAGE_TYPES.DOWNLOAD_ERROR,
          document,
          messageId,
        };
        documentMessages.push(
          // eslint-disable-next-line redux-saga/yield-effects
          put(addFlashMessage(message, messageId, STATUS_DANGER))
        );
      }
    });

    yield all(documentMessages);
    //   }
    // }

    yield put(
      filesActions.receiveFiles(
        documents,
        documentsComplete.map((d) => d.documentGUID),
        metadata,
        STATUS.LOADED
      )
    );
    if (documentsGenerating.length > 0 && oneTime === false) {
      yield delay(FIVE_SECONDS);
      yield put(filesActions.fetchFiles());
    }
  }
}

export default fetchFiles;
