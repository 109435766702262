import * as exchangeLedgerActions from "app.actions/exchangeLedger";
import * as exchangesActions from "app.actions/exchanges";
import { reinitializePortfolio } from "app.reducers/portfolios";
import {
  receiveCreateTag,
  receiveCreateTagError,
  receiveDeleteTag,
  receiveDeleteTagError,
  requestBulkCreateTag,
  receiveBulkCreateTag,
  bulkCreateTagError,
  removeItemFromManualTransfer,
  addItemToManualTransfer,
} from "app.reducers/ui";
import * as computablesActions from "app.actions/computables";

import { STATUS, SORT_TYPE, SORT_DIRECTIONS, TIMELINE } from "app.constants";
import { formatISO } from "date-fns";

// intial state, eventually we'll restore this from local storage (per user)
const initialState = {
  ledgerSummary: undefined,
  ledgerSummaryStatus: STATUS.UNINITIALIZED,
  ledgerDates: undefined,
  ledgerDatesStatus: STATUS.UNINITIALIZED,
  updatingLedger: [],
  data: undefined,
  dataLedgerId: undefined,
  range: undefined,
  page: {
    sort: SORT_TYPE.DATE, // type // amount
    order: SORT_DIRECTIONS.DESCENDING,
    max: 50,
    offset: 0,
    totalAvailable: undefined,
  },
  isEmpty: undefined,
  loadingLedger: false,
  lastRequestParams: undefined,
  worksheets: {},
  worksheetsStatus: {},
  worksheetErrors: {},
  multiTokenWorksheets: {},
  multiTokenWorksheetsStatus: {},
  multiTokenWorksheetErrors: {},
  nftWorksheets: {},
  nftWorksheetsStatus: {},
  nftWorksheetErrors: {},
  relatedTransactions: {},
  relatedTransactionsLoading: {},
  relatedTransactionsErrors: {},
  imports: undefined,
  importsStatus: STATUS.UNINITIALIZED,
  valueOverride: {},
  entryDescription: {},
  payableWorking: false,
  bulkTagsWorking: false,
  transferWorking: false,
  transferErrorMessage: undefined,
  clearingReason: false,
  manualJournalEntryError: undefined,
  manualJournalEntryStatus: STATUS.UNINITIALIZED,
  deletingTransaction: [],
  userFavoriteLedgers: [],
  userArchiveLedgers: [],
  archivingLedgers: STATUS.UNINITIALIZED,
  isUpdatingFields: {},
  timelineMetadata: undefined,
  timelineMetadataStatus: STATUS.UNINITIALIZED,
  manualTransferError: undefined,
  manualTransferStatus: STATUS.UNINITIALIZED,
  timelineStale: false,
};

// eslint-disable-next-line default-param-last
const exchangeLedger = (state = initialState, action) => {
  switch (action.type) {
    case exchangeLedgerActions.EXCHANGE_TABBED_LEDGER_SECTION_RESET: {
      return {
        ...state,
        ledgerSummaryStatus: STATUS.UNINITIALIZED,
        ledgerSummary: undefined,
        ledgerDatesStatus: STATUS.UNINITIALIZED,
        ledgerDates: undefined,
        gainOverride: {},
        valueOverride: {},
        entryDescription: {},
        imports: undefined,
        data: undefined,
        dataLedgerId: undefined,
        importsStatus: STATUS.UNINITIALIZED,
      };
    }
    case exchangeLedgerActions.EXCHANGE_TABBED_LEDGER_SECTION_LEAVE: {
      return {
        ...state,
        ledgerDatesStatus: STATUS.UNINITIALIZED,
        ledgerDates: undefined,
        page: {
          sort: "date", // type // amount
          order: SORT_DIRECTIONS.DESCENDING,
          max: 50,
          offset: 0,
          totalAvailable: undefined,
        },
      };
    }
    case exchangeLedgerActions.EXCHANGE_LEDGER_IMPORTS_REQUEST:
      return {
        ...state,
        importsStatus: action.nextStatus,
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_IMPORTS_RECEIVE:
      return {
        ...state,
        imports: action.response,
        importsStatus: action.nextStatus,
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_IMPORT_REQUEST:
    case exchangesActions.UPDATE_EXCHANGE_LEDGERS_REQUEST:
      return { ...state, updatingLedger: action.ledgerIds || [] };
    case exchangeLedgerActions.EXCHANGE_LEDGER_IMPORT_ERROR:
    case exchangesActions.UPDATE_EXCHANGE_LEDGERS_ERROR:
    case computablesActions.SET_SOURCES_IMPORTING: // use sources importing being set to clear temporary updatingLedger
      return {
        ...state,
        updatingLedger: [],
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_RANGE_REQUEST:
      return {
        ...state,
        ledgerDatesStatus: action.status,
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_RANGE_RECEIVE:
      return {
        ...state,
        ledgerDatesStatus: action.nextStatus,
        ledgerDates: {
          earliest: action.response.earliestSeconds,
          latest: action.response.latestSeconds,
          earliestAsISO: formatISO(
            new Date(action.response.earliestSeconds * 1000),
            { representation: "date" }
          ),
          latestAsISO: formatISO(
            new Date(action.response.latestSeconds * 1000),
            { representation: "date" }
          ),
        },
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_SUMMARY_REQUEST:
      return {
        ...state,
        ledgerSummaryStatus: action.nextStatus,
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_SUMMARY_RECEIVE:
      return {
        ...state,
        ledgerSummaryStatus: action.nextStatus,
        ledgerSummary: action.response,
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_REQUEST: {
      let { data } = state;
      // if the last data set we have is for another wallet, clear it when a new ledger is
      // requested.
      if (action.ledgerId !== state.dataLedgerId) {
        data = undefined;
      }
      return {
        ...state,
        data,
        loadingLedger: !action.noLoading,
        lastRequestParams: action.lastRequestParams,
      };
    }
    case exchangeLedgerActions.EXCHANGE_LEDGER_RECEIVE:
      return {
        ...state,
        dataLedgerId: action.ledgerId,
        loadingLedger: false,
        range: action.range,
        page: action.page,
        data: action.response,
        offset: action.offset,
        isEmpty: action.response.itemCountWithoutSelf === 0,
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_ERROR: {
      return {
        ...state,
        loadingLedger: false,
      };
    }
    case exchangeLedgerActions.TIMELINE_REQUEST: {
      let { data } = state;
      // if the last data set we have is for another wallet, clear it when a new ledger is
      // requested.
      if (TIMELINE !== state.dataLedgerId) {
        data = undefined;
      }
      return {
        ...state,
        data,
        loadingLedger: !action.noLoading,
        lastRequestParams: action.lastRequestParams,
      };
    }
    case exchangeLedgerActions.TIMELINE_RECEIVE:
      return {
        ...state,
        dataLedgerId: TIMELINE,
        loadingLedger: false,
        range: action.range,
        page: action.page,
        data: action.response,
        offset: action.offset,
        isEmpty: action.response.itemCountWithoutSelf === 0,
      };
    case exchangeLedgerActions.TIMELINE_ERROR: {
      return {
        ...state,
        loadingLedger: false,
      };
    }
    case exchangeLedgerActions.TIMELINE_STALE: {
      return {
        ...state,
        timelineStale: action.stale,
      };
    }
    case exchangeLedgerActions.EXCHANGE_LEDGER_WORKSHEET_REQUEST:
      return {
        ...state,
        worksheetsStatus: {
          ...state.worksheetsStatus,
          [action.entryId]: STATUS.LOADING,
        },
        worksheetErrors: {
          ...state.worksheetErrors,
          [action.entryId]: undefined,
        },
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_WORKSHEET_RECEIVE:
      return {
        ...state,
        worksheetsStatus: {
          ...state.worksheetsStatus,
          [action.entryId]: STATUS.LOADED,
        },
        worksheets: {
          ...state.worksheets,
          [action.entryId]: action.response,
        },
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_WORKSHEET_ERROR: {
      return {
        ...state,
        worksheetsStatus: {
          ...state.worksheetsStatus,
          [action.entryId]: STATUS.ERROR,
        },
        worksheets: {
          ...state.worksheets,
          [action.entryId]: null,
        },
        worksheetErrors: {
          ...state.worksheetErrors,
          [action.entryId]: action.response,
        },
      };
    }
    case exchangeLedgerActions.RESET_WORKSHEET_CACHE: {
      const worksheetsStatus = { ...state.worksheetsStatus };
      const worksheetErrors = { ...state.worksheetErrors };
      const worksheets = { ...state.worksheets };

      const worksheetIds = Object.keys(worksheetsStatus);

      worksheetIds.forEach((e) => {
        worksheetsStatus[e] = STATUS.EXPIRED;
        worksheets[e] = undefined;
        worksheetErrors[e] = undefined;
      });

      const multiTokenWorksheetsStatus = {
        ...state.multiTokenWorksheetsStatus,
      };
      const multiTokenWorksheetErrors = { ...state.multiTokenWorksheetErrors };
      const multiTokenWorksheets = { ...state.multiTokenWorksheets };

      const multiTokenWorksheetIds = Object.keys(multiTokenWorksheetsStatus);

      multiTokenWorksheetIds.forEach((e) => {
        multiTokenWorksheetsStatus[e] = STATUS.EXPIRED;
        multiTokenWorksheets[e] = undefined;
        multiTokenWorksheetErrors[e] = undefined;
      });

      return {
        ...state,
        worksheetsStatus,
        worksheets,
        worksheetErrors,
        multiTokenWorksheetsStatus,
        multiTokenWorksheets,
        multiTokenWorksheetErrors,
      };
    }
    case exchangeLedgerActions.NO_EXCHANGE_LEDGER_WORKSHEET:
      return {
        ...state,
        worksheetsStatus: {
          ...action.worksheetsStatus,
          [action.entryId]: STATUS.LOADED,
        },
        worksheets: {
          ...state.worksheets,
          [action.entryId]: null,
        },
        worksheetErrors: {
          ...state.worksheetErrors,
          [action.entryId]: undefined,
        },
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_REQUEST:
      return {
        ...state,
        multiTokenWorksheetsStatus: {
          ...state.multiTokenWorksheetsStatus,
          [action.entryId]: STATUS.LOADING,
        },
        multitokenWorksheetErrors: {
          ...state.multiTokenWorksheetErrors,
          [action.entryId]: undefined,
        },
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_RECEIVE:
      return {
        ...state,
        multiTokenWorksheetsStatus: {
          ...state.multiTokenWorksheetsStatus,
          [action.entryId]: STATUS.LOADED,
        },
        multiTokenWorksheets: {
          ...state.multiTokenWorksheets,
          [action.entryId]: action.response,
        },
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_ERROR: {
      return {
        ...state,
        multiTokenWorksheetsStatus: {
          ...state.multiTokenWorksheetsStatus,
          [action.entryId]: STATUS.ERROR,
        },
        multiTokenWorksheets: {
          ...state.multiTokenWorksheets,
          [action.entryId]: null,
        },
        multitokenWorksheetErrors: {
          ...state.multiTokenWorksheetErrors,
          [action.entryId]: action.response,
        },
      };
    }
    case exchangeLedgerActions.NO_EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET:
      return {
        ...state,
        multiTokenworksheetsStatus: {
          ...action.multiTokenworksheetsStatus,
          [action.entryId]: STATUS.LOADED,
        },
        multiTokenWorksheets: {
          ...state.multiTokenWorksheets,
          [action.entryId]: null,
        },
        multitokenWorksheetErrors: {
          ...state.multiTokenWorksheetErrors,
          [action.entryId]: undefined,
        },
      };
    case exchangeLedgerActions.NFT_WORKSHEET_REQUEST:
      return {
        ...state,
        nftWorksheetsStatus: {
          ...state.nftWorksheetsStatus,
          [action.tokenId]: STATUS.LOADING,
        },
        nftWorksheetErrors: {
          ...state.nftWorksheetErrors,
          [action.tokenId]: undefined,
        },
      };
    case exchangeLedgerActions.NFT_WORKSHEET_RECEIVE:
      return {
        ...state,
        nftWorksheetsStatus: {
          ...state.nftWorksheetsStatus,
          [action.tokenId]: STATUS.LOADED,
        },
        nftWorksheets: {
          ...state.nftWorksheets,
          [action.tokenId]: action.response.nftMetadata[action.tokenId],
        },
      };
    case exchangeLedgerActions.NFT_WORKSHEET_ERROR: {
      return {
        ...state,
        nftWorksheetsStatus: {
          ...state.nftWorksheetsStatus,
          [action.tokenId]: STATUS.ERROR,
        },
        nftWorksheets: {
          ...state.nftWorksheets,
          [action.tokenId]: null,
        },
        nftWorksheetErrors: {
          ...state.nftWorksheetErrors,
          [action.tokenId]: action.response,
        },
      };
    }
    case exchangeLedgerActions.NO_NFT_WORKSHEET:
      return {
        ...state,
        nftworksheetsStatus: {
          ...action.nftworksheetsStatus,
          [action.tokenId]: STATUS.LOADED,
        },
        nftWorksheets: {
          ...state.nftWorksheets,
          [action.tokenId]: null,
        },
        nftWorksheetErrors: {
          ...state.nftWorksheetErrors,
          [action.tokenId]: undefined,
        },
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_RELATED_REQUEST:
      return {
        ...state,
        relatedTransactionsLoading: {
          ...state.relatedTransactionsLoading,
          [action.itemId]: true,
        },
        relatedTransactionsErrors: {
          ...state.relatedTransactionsErrors,
          [action.itemId]: undefined,
        },
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_RELATED_RECEIVE:
      return {
        ...state,
        relatedTransactionsLoading: {
          ...state.relatedTransactionsLoading,
          [action.itemId]: false,
        },
        relatedTransactions: {
          ...state.relatedTransactions,
          [action.itemId]: action.response,
        },
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_RELATED_ERROR: {
      return {
        ...state,
        relatedTransactionsLoading: {
          ...state.relatedTransactionsLoading,
          [action.itemId]: false,
        },
        relatedTransactions: {
          ...state.relatedTransactions,
          [action.itemId]: null,
        },
        relatedTransactionsErrors: {
          ...state.relatedTransactionsErrors,
          [action.itemId]: action.response,
        },
      };
    }

    case exchangeLedgerActions.EXCHANGE_CHANGE_EXEMPT_STATUS_REQUEST:
      return { ...state, transferWorking: true };
    case exchangeLedgerActions.EXCHANGE_CHANGE_EXEMPT_STATUS_RECEIVE: {
      const entryIdx = state.data.items.findIndex(
        (item) => item.transactionId === action.transactionId
      );
      const entry = state.data.items.slice(entryIdx, entryIdx + 1)[0];

      return {
        ...state,
        transferWorking: false,
        data: {
          ...state.data,
          items: [
            ...state.data.items.slice(0, entryIdx),
            { ...entry, exempt: action.exempt },
            ...state.data.items.slice(entryIdx + 1),
          ],
        },
      };
    }
    case exchangeLedgerActions.EXCHANGE_CHANGE_TRANSFER_STATUS_REQUEST:
    case exchangeLedgerActions.TRANSFER_IN_FROM_SOURCELESS_STATUS_REQUEST:
    case exchangeLedgerActions.TRANSFER_OUT_TO_SOURCELESS_STATUS_REQUEST:
      return { ...state, transferWorking: true };
    case exchangeLedgerActions.EXCHANGE_CHANGE_TRANSFER_STATUS_RECEIVE: {
      const entryIdx = state.data.items.findIndex(
        (item) => item.transactionId === action.transactionId
      );
      const entry = state.data.items.slice(entryIdx, entryIdx + 1)[0];
      return {
        ...state,
        transferWorking: false,
        data: {
          ...state.data,
          items: [
            ...state.data.items.slice(0, entryIdx),
            { ...entry, externalTransfer: action.externalTransfer },
            ...state.data.items.slice(entryIdx + 1),
          ],
        },
      };
    }
    case exchangeLedgerActions.TRANSFER_IN_FROM_SOURCELESS_STATUS_RECEIVE_ERROR:
    case exchangeLedgerActions.TRANSFER_OUT_TO_SOURCELESS_STATUS_RECEIVE_ERROR:
      return {
        ...state,
        transferWorking: false,
        transferErrorMessage: action.message,
      };
    case exchangeLedgerActions.EXCHANGE_TRANSFER_CLEAR_ERROR:
      return {
        ...state,
        transferErrorMessage: undefined,
      };
    case exchangeLedgerActions.TRANSFER_IN_FROM_SOURCELESS_STATUS_RECEIVE:
    case exchangeLedgerActions.TRANSFER_OUT_TO_SOURCELESS_STATUS_RECEIVE: {
      const newItems = state.data.items.map((entry) =>
        entry.id === action.ledgerItemId
          ? { ...entry, sourcelessTransfer: true }
          : entry
      );

      return {
        ...state,
        transferWorking: false,
        transferErrorMessage: undefined,
        data: { ...state.data, items: newItems },
      };
    }
    case exchangeLedgerActions.EXCHANGE_CHANGE_PAYABLE_STATUS_REQUEST:
      return { ...state, payableWorking: true };
    case exchangeLedgerActions.EXCHANGE_CHANGE_PAYABLE_STATUS_RECEIVE: {
      const txIdx = state.data.items.findIndex(
        (item) => item.transactionId === action.transactionId
      );
      const tx = state.data.items.slice(txIdx, txIdx + 1)[0];
      return {
        ...state,
        payableWorking: false,
        data: {
          ...state.data,
          items: [
            ...state.data.items.slice(0, txIdx),
            { ...tx, payable: action.payable },
            ...state.data.items.slice(txIdx + 1),
          ],
        },
      };
    }
    case exchangeLedgerActions.REVERT_EXCHANGE_VALUE_REQUEST:
    case exchangeLedgerActions.EDIT_EXCHANGE_VALUE_REQUEST:
      return {
        ...state,
        valueOverride: {
          ...state.valueOverride,
          [action.itemId]: { status: action.nextStatus },
        },
      };
    case exchangeLedgerActions.REVERT_EXCHANGE_VALUE_RECEIVE:
    case exchangeLedgerActions.EDIT_EXCHANGE_VALUE_RECEIVE:
      return {
        ...state,
        valueOverride: {
          ...state.valueOverride,
          [action.itemId]: {
            value: action.value,
            status: action.nextStatus,
          },
        },
      };
    case exchangeLedgerActions.LEDGER_ITEM_CLEAR_REASON_REQUEST:
      return {
        ...state,
        clearingReason: true,
      };
    case exchangeLedgerActions.LEDGER_ITEM_CLEAR_REASON_RECEIVE:
    case exchangeLedgerActions.LEDGER_ITEM_CLEAR_REASON_ERROR:
      return {
        ...state,
        clearingReason: false,
      };
    case exchangeLedgerActions.EDIT_EXCHANGE_ENTRY_DESCR_REQUEST:
      return {
        ...state,
        entryDescription: {
          ...state.entryDescription,
          [action.transactionId]: { status: action.nextStatus },
        },
      };
    case exchangeLedgerActions.EDIT_EXCHANGE_ENTRY_DESCR_RECEIVE: {
      // find entry
      const entryIndex = state.data.items.findIndex(
        (entry) => action.transactionId === entry.transactionId
      );
      // replace description
      const entryData = { ...state.data.items[entryIndex] };
      entryData.userProvidedDescription = action.description;

      return {
        ...state,
        entryDescription: {
          ...state.entryDescription,
          [action.transactionId]: {
            status: undefined,
          },
        },
        // insert new description
        data: {
          ...state.data,
          items: [
            ...state.data.items.slice(0, entryIndex),
            entryData,
            ...state.data.items.slice(entryIndex + 1),
          ],
        },
      };
    }
    case exchangeLedgerActions.EDIT_MANUAL_JOURNAL_REQUEST:
    case exchangeLedgerActions.EDIT_MANUAL_JOURNAL_RECEIVE:
      return {
        ...state,
        manualJournalEntryError: undefined,
        manualJournalEntryStatus: action.nextStatus,
      };
    case exchangeLedgerActions.EDIT_MANUAL_JOURNAL_ERROR:
      return {
        ...state,
        manualJournalEntryError: action.message,
        manualJournalEntryStatus: action.nextStatus,
      };
    case exchangeLedgerActions.EDIT_MANUAL_JOURNAL_RESET:
      return {
        ...state,
        manualJournalEntryError: undefined,
        manualJournalEntryStatus: STATUS.UNINITIALIZED,
      };
    case exchangeLedgerActions.DELETE_MANUAL_TRANSACTION_ENTRY_REQUEST:
      return {
        ...state,
        deletingTransaction: [
          ...state.deletingTransaction,
          action.transactionId,
        ],
      };
    case exchangeLedgerActions.DELETE_MANUAL_TRANSACTION_ENTRY_RECEIVE:
    case exchangeLedgerActions.DELETE_MANUAL_TRANSACTION_ENTRY_ERROR:
      return {
        ...state,
        deletingTransaction: state.deletingTransaction.filter(
          (item) => item !== action.transactionId
        ),
      };
    case exchangeLedgerActions.FAVORITE_LEDGER_REQUEST: {
      return {
        ...state,
        userFavoriteLedgers: [
          ...state.userFavoriteLedgers.filter(
            (item) => !item.id === action.ledgerId
          ),
          { id: action.ledgerId, userFavorite: action.userFavorite },
        ],
      };
    }
    case exchangeLedgerActions.FAVORITE_LEDGER_RECEIVE:
    case exchangeLedgerActions.FAVORITE_LEDGER_ERROR: {
      return {
        ...state,
        userFavoriteLedgers: [
          ...state.userFavoriteLedgers.filter(
            (item) => item.id === action.ledgerId
          ),
        ],
      };
    }
    case exchangeLedgerActions.ARCHIVE_LEDGER_REQUEST: {
      return {
        ...state,
        archivingLedgers: STATUS.WORKING,
        userArchiveLedgers: [
          ...state.userArchiveLedgers.filter(
            (item) => !item.id === action.ledgerId
          ),
          { id: action.ledgerId, userArchive: action.userArchive },
        ],
      };
    }
    case exchangeLedgerActions.ARCHIVE_LEDGER_RECEIVE: {
      return {
        ...state,
        archivingLedgers: STATUS.SUCCESS,
        userArchiveLedgers: [
          ...state.userArchiveLedgers.filter(
            (item) => item.id !== action.ledgerId
          ),
        ],
      };
    }
    case exchangeLedgerActions.UPDATE_LEDGER_CUSTOM_DATA_REQUEST:
      return {
        ...state,
        isUpdatingFields: {
          [action.itemId]: action.updatingField,
        },
      };
    case exchangeLedgerActions.UPDATE_LEDGER_CUSTOM_DATA_RECEIVE:
      return {
        ...state,
        data: { ...state.data, items: action.data },
        isUpdatingFields: {
          [action.itemId]: "",
        },
      };
    case exchangeLedgerActions.UPDATE_LEDGER_CUSTOM_DATA_ERROR:
      return {
        ...state,
        isUpdatingFields: {
          [action.itemId]: "",
        },
      };
    case exchangeLedgerActions.TIMELINE_METADATA_REQUEST:
      return { ...state, timelineMetadataStatus: action.nextStatus };
    case exchangeLedgerActions.TIMELINE_METADATA_RECEIVE:
      return {
        ...state,
        timelineMetadata: action.metadata,
        timelineMetadataStatus: action.nextStatus,
      };
    case exchangeLedgerActions.CREATE_MANUAL_TRANSFER_LINK_REQUEST:
    case exchangeLedgerActions.CREATE_MANUAL_TRANSFER_LINK_RECEIVE:
      return {
        ...state,
        manualTransferError: undefined,
        manualTransferStatus: action.nextStatus,
      };
    case exchangeLedgerActions.CREATE_MANUAL_TRANSFER_LINK_ERROR:
      return {
        ...state,
        manualTransferError: action.message,
        manualTransferStatus: action.nextStatus,
      };
    case exchangeLedgerActions.REMOVE_MANUAL_TRANSFER_LINK_REQUEST:
    case exchangeLedgerActions.REMOVE_MANUAL_TRANSFER_LINK_RECEIVE:
      return {
        ...state,
        manualTransferError: undefined,
        manualTransferStatus: action.nextStatus,
      };
    case exchangeLedgerActions.REMOVE_MANUAL_TRANSFER_LINK_ERROR:
      return {
        ...state,
        manualTransferError: action.message,
        manualTransferStatus: action.nextStatus,
      };
    // external reducer cases
    case receiveCreateTag.type:
      return { ...state, data: { ...state.data, items: action.payload.data } };
    case receiveCreateTagError.type:
      return { ...state, data: { ...state.data, items: action.payload.data } };
    case receiveDeleteTag.type: {
      return { ...state, data: { ...state.data, items: action.payload.data } };
    }
    case receiveDeleteTagError.type:
      return { ...state, data: { ...state.data, items: action.payload.data } };
    case requestBulkCreateTag.type:
      return { ...state, bulkTagsWorking: true };
    case receiveBulkCreateTag.type:
      return { ...state, bulkTagsWorking: false };
    case bulkCreateTagError.type:
      return { ...state, bulkTagsWorking: false };
    case removeItemFromManualTransfer.type:
    case addItemToManualTransfer.type:
      return {
        ...state,
        manualTransferError: undefined,
      };
    case reinitializePortfolio.type: {
      return initialState;
    }
    default:
      return state;
  }
};

export default exchangeLedger;
