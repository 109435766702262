import { AM, PM } from "app.constants";

export function getConvertedHour(hour, amPm) {
  if (parseInt(hour, 10) === 12) {
    if (amPm === AM) {
      return parseInt(hour, 10) - 12;
    }
    return parseInt(hour, 10);
  }
  if (amPm === PM) {
    return parseInt(hour, 10) + 12;
  }
  return parseInt(hour, 10);
}
