import { takeEvery, takeLeading } from "redux-saga/effects";

import * as userActions from "app.reducers/user";

import getTokenFromTicket from "./getTokenFromTicket";
import fetchUser from "./fetchUser";
import setRollForward from "./setRollForward";
import forgotPassword from "./forgotPassword";
import resetPassword from "./resetPassword";
import resendUserAccountVerification from "./resendUserAccountVerification";
import fetchAccountingPreferences from "./fetchAccountingPreferences";
import saveAccountingPreferences from "./saveAccountingPreferences";

// WATCHERS
const userSagas = [
  takeLeading(userActions.authorizeUser, getTokenFromTicket),
  takeEvery(userActions.fetchUser, fetchUser),
  takeEvery(userActions.updateRollForward, setRollForward),
  takeEvery(userActions.forgotPassword, forgotPassword),
  takeEvery(userActions.resetPassword, resetPassword),
  takeEvery(
    userActions.resendUserAccountVerification,
    resendUserAccountVerification
  ),
  takeEvery(userActions.fetchAccountingPreferences, fetchAccountingPreferences),
  takeEvery(userActions.saveAccountingPreferences, saveAccountingPreferences),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default userSagas;

// ToDo: Add a 'go-back-to-org' saga here;
// This saga will clear the session, and go back to login so that you can go back to the org page? Or maybe something else
// Because that's effectively just logging out if we do the org page properly
