import { makeActionCreator } from "app.actions/helpers";

/** *************************************************************************** */
/* bulk delete exchange ledgers */
/** *************************************************************************** */
export const BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_BEGIN =
  "BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_BEGIN";
export const BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_REQUEST =
  "BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_REQUEST";
export const BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_RECEIVE =
  "BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_RECEIVE";
export const BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_ERROR =
  "BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_ERROR";

export const bulkDeleteExchangeLedgersByIds = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_BEGIN,
  "ledgerIds"
);

export const requestBulkDeleteExchangeLedgersByIds = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_REQUEST,
  "nextStatus"
);

export const receiveBulkDeleteExchangeLedgersByIds = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_RECEIVE,
  "nextStatus",
  "ledgerIds"
);

export const bulkDeleteExchangeLedgersByIdsError = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_ERROR,
  "nextStatus"
);
