import React from "react";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";

const LedgerRangePlaceholder = () => (
  <div className="mb-2.5 flex h-8 items-center justify-between">
    <PlaceholderLine width="w-52" />
    <PlaceholderLine width="w-52" />
  </div>
);

LedgerRangePlaceholder.displayName = "LedgerRangePlaceholder";

export default LedgerRangePlaceholder;
