import React from "react";
import { TIERS } from "app.constants";
import { Trans } from "react-i18next";

const UpgradeCommonDocuments = () => {
  return (
    <div>
      <Trans i18nKey="upgrade.taxDocumentsAndFormsAreAvailableOnPlan">
        <strong>{{ tierEssential: TIERS.valueAt(TIERS.ESSENTIALS) }}</strong>
      </Trans>
    </div>
  );
};

UpgradeCommonDocuments.displayName = "UpgradeCommonDocuments";

export default UpgradeCommonDocuments;
