import { call, put } from "redux-saga/effects";
import * as userActions from "app.reducers/user";
import { getLoadingState } from "app.utils/selectors";
import SettingsAPI from "app.api/SettingsAPI";

function* fetchAccountingPreferences() {
  const { isLoading } = yield* getLoadingState(
    (state) => state.user.preferencesStatus
  );

  if (isLoading) return;

  yield put(userActions.requestAccountingPreferences());

  const response = yield call(SettingsAPI.getAccountingPreferences);

  if (response.error) {
    console.error(
      "Error in fetchAccountingPreferences",
      response.body,
      response.error
    );
  } else {
    yield put(userActions.receiveAccountingPreferences(response.body));
  }
}

export default fetchAccountingPreferences;
