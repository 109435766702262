import { call, put, all } from "redux-saga/effects";

import {
  requestRenameLedger,
  renameLedgerError,
  receiveRenameLedger,
} from "app.actions/exchanges";
import { dismissModal } from "app.reducers/ui";

import ExchangesAPI from "app.api/ExchangesAPI";

function* renameLedger(action) {
  const { name, ledgerId } = action;

  yield put(requestRenameLedger());

  const response = yield call(ExchangesAPI.editLedger, name, ledgerId);
  if (response.error) {
    yield put(renameLedgerError(response.body, response.error));
  } else {
    yield all([put(receiveRenameLedger(name, ledgerId)), put(dismissModal())]);
  }
}

export default renameLedger;
