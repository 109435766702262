/* eslint-disable no-param-reassign */
// this rule is disabled in reducers because the redux toolkit
// explicitly supports direct mutation of the state param
// https://redux-toolkit.js.org/usage/immer-reducers
import { createSlice } from "@reduxjs/toolkit";

import { reinitializePortfolio } from "app.reducers/portfolios";
import { dismissModal } from "app.reducers/ui";

import { STATUS } from "app.constants";

const initialState = {
  documents: undefined,
  documentsComplete: undefined,
  metadata: undefined,
  filesStatus: STATUS.UNINITIALIZED,
  filesError: undefined,
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    generateFile: {
      reducer: (state) => {
        state.filesError = undefined;
        state.filesStatus = STATUS.UNINITIALIZED;
      },
      prepare: (fileType, requestParams) => {
        return { payload: { fileType, requestParams } };
      },
    },
    requestGenerateFile: () => {},
    receiveGenerateFile: () => {},
    generateFileError: {
      reducer: (state, action) => {
        state.filesError = action.payload.response;
        state.filesStatus = STATUS.ERROR;
      },
      prepare: (response) => {
        return { payload: { response } };
      },
    },

    fetchFiles: {
      reducer: (state) => {
        state.filesError = undefined;
        state.filesStatus = STATUS.UNINITIALIZED;
      },
      prepare: (oneTime) => {
        return { payload: { oneTime } };
      },
    },
    requestFiles: {
      reducer: (state, action) => {
        state.filesStatus = action.payload.nextStatus;
        state.filesError = undefined;
      },
      prepare: (nextStatus) => {
        return { payload: { nextStatus } };
      },
    },
    receiveFiles: {
      reducer: (state, action) => {
        state.documents = action.payload.documents;
        state.documentsComplete = action.payload.documentsComplete;
        state.metadata = action.payload.metadata;
        state.filesStatus = action.payload.nextStatus;
      },
      prepare: (documents, documentsComplete, metadata, nextStatus) => {
        return {
          payload: { documents, documentsComplete, metadata, nextStatus },
        };
      },
    },
    filesError: {
      reducer: (state, action) => {
        state.filesError = action.payload.response;
        state.filesStatus = STATUS.ERROR;
      },
      prepare: (response, nextStatus) => {
        return { payload: { response, nextStatus } };
      },
    },
    downloadFile: {
      reducer: () => {},
      prepare: (documentGUID) => {
        return { payload: { documentGUID } };
      },
    },
    requestDownloadFile: {
      reducer: () => {},
      prepare: (documentGUID) => {
        return { payload: { documentGUID } };
      },
    },
    receiveDownloadFile: {
      reducer: () => {},
      prepare: (documentGUID) => {
        return { payload: { documentGUID } };
      },
    },
    downloadFileError: {
      reducer: () => {},
      prepare: (response) => {
        return { payload: { response } };
      },
    },

    shareFile: {
      reducer: () => {},
      prepare: (documentGUID) => {
        return { payload: { documentGUID } };
      },
    },
    requestShareFile: {
      reducer: () => {},
      prepare: (documentGUID) => {
        return { payload: { documentGUID } };
      },
    },
    receiveShareFile: {
      reducer: () => {},
      prepare: (documentGUID) => {
        return { payload: { documentGUID } };
      },
    },

    shareFileError: {
      reducer: () => {},
      prepare: (response) => {
        return { payload: { response } };
      },
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(reinitializePortfolio, () => {
        return initialState;
      })
      .addCase(dismissModal, (state) => {
        state.filesError = undefined;
        state.filesStatus = STATUS.UNINITIALIZED;
      });
  },
});

// all actions
export const { actions } = filesSlice;

// individual actions
export const {
  generateFile,
  requestGenerateFile,
  receiveGenerateFile,
  generateFileError,
  fetchFiles,
  requestFiles,
  receiveFiles,
  filesError,
  downloadFile,
  requestDownloadFile,
  receiveDownloadFile,
  downloadFileError,
  shareFile,
  requestShareFile,
  receiveShareFile,
  shareFileError,
} = filesSlice.actions;

export default filesSlice.reducer;
