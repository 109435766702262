import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* delete exchange ledger */
/** *************************************************************************** */
export const DELETE_EXCHANGE_LEDGER_BEGIN = "DELETE_EXCHANGE_LEDGER_BEGIN";
export const DELETE_EXCHANGE_LEDGER_RECEIVE = "DELETE_EXCHANGE_LEDGER_RECEIVE";
export const DELETE_EXCHANGE_LEDGER_REQUEST = "DELETE_EXCHANGE_LEDGER_REQUEST";
export const DELETE_EXCHANGE_LEDGER_ERROR = "DELETE_EXCHANGE_LEDGER_ERROR";

export const requestDeleteExchangeLedger = makeActionCreator(
  DELETE_EXCHANGE_LEDGER_REQUEST
);
export const receiveDeleteExchangeLedger = makeActionCreator(
  DELETE_EXCHANGE_LEDGER_RECEIVE,
  "ledgerId"
);
export const deleteExchangeLedgerError = makeActionCreator(
  DELETE_EXCHANGE_LEDGER_ERROR,
  "response",
  "error"
);

export const deleteExchangeLedger = makeActionCreator(
  DELETE_EXCHANGE_LEDGER_BEGIN,
  "ledgerId",
  "redirectAfterDelete"
);
