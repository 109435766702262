import { all, call, put } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestExchangeChangePayableStatus,
  receiveExchangeChangePayableStatus,
} from "app.actions/exchangeLedger";

import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";

function* changeExchangePayableStatus(action) {
  const { transactionId, payable, ledgerId } = action;

  yield put(requestExchangeChangePayableStatus(transactionId));
  yield put(startModalWorking());

  const response = yield call(
    ExchangeLedgerAPI.editExchangePayable,
    ledgerId,
    transactionId,
    payable
  );

  if (response.error) {
    yield put(endModalWorking());
    console.error(
      "Error in changeExchangePayableStatus",
      response.body,
      response.error
    );
  } else {
    yield all([
      put(
        receiveExchangeChangePayableStatus(
          response.body,
          transactionId,
          payable
        )
      ),
      put(dismissModal()),
    ]);
  }
}

export default changeExchangePayableStatus;
