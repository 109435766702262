import { put, call } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";

import { refreshUserToken } from "app.actions/authentication";
import {
  updateLanguageRequest,
  updateLanguageReceive,
  updateLanguageError,
} from "app.actions/account";

function* handleUserLanguage(action) {
  const { language } = action;
  yield put(updateLanguageRequest());

  const response = yield call(TellerAPI.updateUserLanguage, language);
  if (response.error) {
    yield put(updateLanguageError());
  } else {
    yield put(updateLanguageReceive(language));
    yield put(refreshUserToken()); // ensure the cache version in local storage is properly updated
  }
}

export default handleUserLanguage;
