import React, { Suspense, lazy } from "react";
import GenericPlaceholder from "app.components/Placeholder/GenericPlaceholder";

const ResetPasswordContainer = lazy(
  () => import("app.components/Account/ResetPasswordContainer")
);

const ResetPassword = () => {
  return (
    <Suspense fallback={<GenericPlaceholder />}>
      <ResetPasswordContainer />
    </Suspense>
  );
};

export default ResetPassword;
