import { makeActionCreator } from "app.actions/helpers";

export const EXCHANGE_LEDGER_RANGE_BEGIN = "EXCHANGE_LEDGER_RANGE_BEGIN";
export const EXCHANGE_LEDGER_RANGE_REQUEST = "EXCHANGE_LEDGER_RANGE_REQUEST";
export const EXCHANGE_LEDGER_RANGE_RECEIVE = "EXCHANGE_LEDGER_RANGE_RECEIVE";
export const EXCHANGE_LEDGER_RANGE_ERROR = "EXCHANGE_LEDGER_RANGE_ERROR";

export const fetchExchangeLedgerRange = makeActionCreator(
  EXCHANGE_LEDGER_RANGE_BEGIN,
  "ledgerId"
);

export const requestExchangeLedgerRange = makeActionCreator(
  EXCHANGE_LEDGER_RANGE_REQUEST,
  "nextStatus"
);

export const receiveExchangeLedgerRange = makeActionCreator(
  EXCHANGE_LEDGER_RANGE_RECEIVE,
  "response",
  "nextStatus"
);

export const exchangeLedgerRangeError = makeActionCreator(
  EXCHANGE_LEDGER_RANGE_ERROR,
  "response"
);
