import React from "react";
import { useTranslation } from "react-i18next";
import SvgLoader from "app.components/Util/SvgLoader";

const SPINNER_TYPE_BASE_STYLES = "fill-blue-600";

const SPINNER_TYPE_STYLES = {
  primary: "fill-white",
  danger: "fill-white",
  "less-danger": "fill-red-700",
  reconnect: "fill-green-600",
  secondary: "fill-white",
};

const LoadingSpinner = ({
  className = "",
  screenReaderText,
  xxsmall = false,
  small = false,
  large = false,
  xsmall = false,
  spinnerType,
}) => {
  const { t } = useTranslation();
  const finalClassName = ["c_loading-spinner", "animate-spin", className];

  if ((small && large) || (small && xsmall) || (xsmall && large)) {
    console.error(
      "Spinner can only have one of the following attributes: small, xsmall, or large"
    );
    return null;
  }

  if (!small && !large && !xsmall && !xxsmall) {
    finalClassName.push("w-24 h-24");
  }

  if (small) {
    finalClassName.push("w-10 h-10");
  }
  if (large) {
    finalClassName.push("w-52 h-52");
  }
  if (xsmall) {
    finalClassName.push("w-5 h-5");
  }
  if (xxsmall) {
    finalClassName.push("w-3 h-3");
  }

  return (
    <span
      className={finalClassName.filter(Boolean).join(" ")}
      data-testid="loading-spinner"
    >
      <SvgLoader
        id="LoadingSpinner"
        data-testid="spinner"
        title={
          screenReaderText !== ""
            ? screenReaderText || t("common.loading")
            : null
        }
        className={[
          SPINNER_TYPE_STYLES[spinnerType] || SPINNER_TYPE_BASE_STYLES,
          "h-full w-full",
        ].join(" ")}
      />
    </span>
  );
};

export default LoadingSpinner;
