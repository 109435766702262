import { makeActionCreator } from "app.actions/helpers";
// EXCHANGE_LEDGER_DO_IMPORT
export const BULK_UPDATE_BY_SOURCE_BEGIN = "BULK_UPDATE_BY_SOURCE_BEGIN";
export const BULK_UPDATE_BY_SOURCE_REQUEST = "BULK_UPDATE_BY_SOURCE_REQUEST";
export const BULK_UPDATE_BY_SOURCE_RECEIVE = "BULK_UPDATE_BY_SOURCE_RECEIVE";

export const bulkUpdateLedgersBySource = makeActionCreator(
  BULK_UPDATE_BY_SOURCE_BEGIN,
  "sources"
);

export const requestBulkUpdateLedgersBySource = makeActionCreator(
  BULK_UPDATE_BY_SOURCE_REQUEST
);
export const receiveBulkUpdateLedgersBySource = makeActionCreator(
  BULK_UPDATE_BY_SOURCE_RECEIVE,
  "syncList"
);
