import { call, put, all } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import { dismissModal } from "app.reducers/ui";
import OrganizationAPI from "app.api/OrganizationAPI";
import { getSagaResult } from "app.sagas/helpers";

function* deleteTeamMember(action) {
  const { teamMemberId } = action.payload;

  yield put(organizationActions.deleteTeamMemberRequest());

  const response = yield call(OrganizationAPI.removeCoworker, teamMemberId);

  if (response.error) {
    yield put(organizationActions.deleteTeamMemberError(response.error));
  } else {
    try {
      yield* getSagaResult(
        organizationActions.fetchTeamMemberList(),
        organizationActions.requestTeamMemberList,
        organizationActions.receiveTeamMemberListError
      );
    } catch (error) {
      // ToDo: figure out what to do here
      console.error(error);
      return;
    }

    yield all([
      put(organizationActions.deleteTeamMemberReceive()),
      put(dismissModal()),
    ]);
  }
}

export default deleteTeamMember;
