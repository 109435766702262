import { call, put, select } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import { getLoadingState } from "app.utils/selectors";

import { STATUS } from "app.constants";

import {
  requestAddressLabels,
  receiveAddressLabels,
  addressLabelsError,
} from "app.reducers/ui";

function* fetchAddressLabels(action) {
  const { reload = false } = action.payload;

  const { addressLabelsStatus } = yield select((state) => state.ui);
  const { isLoading } = yield* getLoadingState(
    (state) => state.ui.addressLabelsStatus
  );

  // if we already have address labels, don't fetch them again
  // if we are currently fetching them, don't fetch them again
  if ((addressLabelsStatus === STATUS.LOADED && reload === false) || isLoading)
    return;

  yield put(requestAddressLabels());

  const response = yield call(ExchangeLedgerAPI.getAddressLabels);

  const { body, error } = response;
  if (error) {
    yield put(addressLabelsError(body, error));
  } else {
    yield put(receiveAddressLabels(body));
  }
}

export default fetchAddressLabels;
