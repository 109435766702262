import api from "../clients/teller";

export const subscribeToPlan = (
  planId,
  couponId,
  zipCode,
  organizationName,
  profileLimit
) => {
  return api.post("/payment/subscribe", {
    data: {
      planId,
      zipCode,
      couponId,
      organizationName,
      profileLimit,
    },
    authenticated: true,
  });
};

export const removeCustomerSubscription = (subscriptionId, planId) => {
  return api.delete("/payment/customer-subscription", {
    authenticated: true,
    data: { subscriptionId, planId },
  });
};

export const cancelCustomerDowngrade = (subscriptionId) => {
  return api.put("/payment/customer-subscription/cancel-downgrade", {
    data: { subscriptionId },
    authenticated: true,
  });
};

export const reactivateCustomerSubscription = (subscriptionId) => {
  return api.put("/payment/customer-subscription/reactivate", {
    data: { subscriptionId },
    authenticated: true,
  });
};

export const getCustomerSubscriptions = () => {
  return api.get("/payment/customer-subscription", { authenticated: true });
};

export const getCurrentPaymentInfo = () => {
  return api.get("/payment", { authenticated: true });
};
