import { takeLatest } from "redux-saga/effects";

import {
  RENAME_EXCHANGE_LEDGER_RECEIVE,
  LEDGERS_RECEIVE,
  SUPPORTED_EXCHANGES_RECEIVE,
  DELETE_EXCHANGE_LEDGER_RECEIVE,
  BULK_DELETE_EXCHANGE_LEDGERS_RECEIVE,
  BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_RECEIVE,
  BULK_UPDATE_BY_SOURCE_RECEIVE,
  UPDATE_EXCHANGE_LEDGERS_RECEIVE,
  REBUILD_EXCHANGE_LEDGERS_RECEIVE,
} from "app.actions/exchanges";

import {
  EXCHANGE_LEDGER_IMPORT_RECEIVE,
  FAVORITE_LEDGER_RECEIVE,
} from "app.actions/exchangeLedger";

import { receiveSmartWalletList } from "app.reducers/smartWallet";

import runLedgerComputations from "./runLedgerComputations";
import runComputeChartData from "./runComputeChartData";

const recomputeLedgers = [
  RENAME_EXCHANGE_LEDGER_RECEIVE,
  LEDGERS_RECEIVE,
  SUPPORTED_EXCHANGES_RECEIVE,
  DELETE_EXCHANGE_LEDGER_RECEIVE,
  REBUILD_EXCHANGE_LEDGERS_RECEIVE,
  BULK_DELETE_EXCHANGE_LEDGERS_RECEIVE,
  BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_RECEIVE,
  EXCHANGE_LEDGER_IMPORT_RECEIVE,
  BULK_UPDATE_BY_SOURCE_RECEIVE,
  UPDATE_EXCHANGE_LEDGERS_RECEIVE,
  FAVORITE_LEDGER_RECEIVE,
];

const computeChartData = [receiveSmartWalletList];

// WATCHERS
const computableSagas = [
  takeLatest([...recomputeLedgers], runLedgerComputations),
  takeLatest([...computeChartData], runComputeChartData),
];

export default computableSagas;
