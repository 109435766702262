import { call, put } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import {
  requestTimelineLedgersRange,
  receiveTimelineLedgersRange,
  receiveTimelineLedgersRangeError,
} from "app.reducers/ui";
import { getLoadingState } from "app.utils/selectors";
import { STATUS } from "app.constants";

function* fetchTimelineLedgersRange() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.ui.timelineRangeStatus
  );

  if (isLoading) return;

  yield put(requestTimelineLedgersRange(nextStatus));

  const response = yield call(ExchangeLedgerAPI.getTimelineDateRange);

  if (response.error) {
    yield put(
      receiveTimelineLedgersRangeError({
        response: response.body,
        error: response.error,
      })
    );
  } else {
    yield put(
      receiveTimelineLedgersRange({
        response: response.body,
        status: STATUS.LOADED,
      })
    );
  }
}

export default fetchTimelineLedgersRange;
