import { makeActionCreator } from "app.actions/helpers";
// NFT_WORKSHEET
export const NFT_WORKSHEET_BEGIN = "NFT_WORKSHEET_BEGIN";
export const NFT_WORKSHEET_REQUEST = "NFT_WORKSHEET_REQUEST";
export const NFT_WORKSHEET_RECEIVE = "NFT_WORKSHEET_RECEIVE";
export const NFT_WORKSHEET_ERROR = "NFT_WORKSHEET_ERROR";

export const NO_NFT_WORKSHEET = "NO_NFT_WORKSHEET";

export const fetchNftWorksheet = makeActionCreator(
  NFT_WORKSHEET_BEGIN,
  "exchangeId",
  "tokenId",
  "contract"
);

export const requestNftWorksheet = makeActionCreator(
  NFT_WORKSHEET_REQUEST,
  "tokenId"
);
export const receiveNftWorksheet = makeActionCreator(
  NFT_WORKSHEET_RECEIVE,
  "tokenId",
  "response"
);
export const nftWorksheetError = makeActionCreator(
  NFT_WORKSHEET_ERROR,
  "tokenId",
  "response",
  "error"
);

export const noNftWorksheetToLoad = makeActionCreator(
  NO_NFT_WORKSHEET,
  "tokenId"
);
