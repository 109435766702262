import React, { useReducer } from "react";
import { useDispatch } from "react-redux";
import AddressGroupings from "app.components/AddLedger/WalletTypeForms/AddressGroupings";
import Button from "app.components/Util/Button";

import { updateExchangeLedgersViaAddressGroupings } from "app.actions/exchanges";
import { useTranslation } from "react-i18next";

const initialState = {
  addressGroupings: [],
};

const UPDATE_ADDRESS_GROUPINGS = "update-address-groupings";

// reducer for local state
function selectWalletReducer(state, action) {
  switch (action.type) {
    case UPDATE_ADDRESS_GROUPINGS:
      return {
        ...state,
        addressGroupings: action.addressGroupings,
      };
    default:
      throw new Error();
  }
}

const UpdateAddressGroupings = ({ updating, exchange, ledgerIds }) => {
  const [state, localDispatch] = useReducer(selectWalletReducer, initialState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateAddressGroupingCallback = (addressGroupings) => {
    localDispatch({
      type: UPDATE_ADDRESS_GROUPINGS,
      addressGroupings,
    });
  };

  const submit = (e) => {
    e.preventDefault();
    dispatch(
      updateExchangeLedgersViaAddressGroupings(
        state.addressGroupings,
        exchange.id,
        ledgerIds
      )
    );
  };

  return (
    <form onSubmit={submit} data-testid="form-address-grouping">
      <AddressGroupings
        addressGroupings={state.addressGroupings}
        selectedCurrency={undefined}
        updateAddressGroupingCallback={updateAddressGroupingCallback}
      />

      <div className="mt-4 flex">
        <Button
          type="submit"
          disabled={updating}
          buttonType="primary"
          customClasses="ml-auto"
          working={updating}
          workingText={t("ledgers.exchangeLedger.importing")}
          text={t("ledgers.exchangeLedger.importTransactions")}
        />
      </div>
    </form>
  );
};

UpdateAddressGroupings.displayName = "UpdateAddressGroupings";
export default UpdateAddressGroupings;
