import { takeLatest } from "redux-saga/effects";

import { actions as portfoliosActions } from "app.reducers/portfolios";

import enterPortfolio from "./enterPortfolio";
import leavePortfolio from "./leavePortfolio";

// WATCHERS
const portfolioSagas = [
  takeLatest(portfoliosActions.enterPortfolioSection, enterPortfolio),
  takeLatest(portfoliosActions.leavePortfolioSection, leavePortfolio),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default portfolioSagas;
