import React from "react";
import CashMoney from "app.components/Util/CashMoney";
import TableListCell from "app.components/TableList/TableListCell";
import { useTranslation, Trans } from "react-i18next";

function TaxRow({ isUpgradeQuoteLoaded, taxPercent, taxDollars, zipCode }) {
  const { t } = useTranslation();

  let tax;
  if (zipCode?.trim() === "") {
    tax = <small>{t("modals.calculatedAtNextStep")}</small>;
  } else if (!isUpgradeQuoteLoaded) {
    tax = <small>{t("modals.calculating")}</small>;
  } else {
    tax = (
      <CashMoney
        size="none"
        align="right"
        dollars={taxDollars}
        fiatOverride="USD"
      />
    );
  }

  return (
    <tr data-testid="tax-row">
      <TableListCell custom="pl-0" size="sm">
        {taxPercent === 0 || !isUpgradeQuoteLoaded ? (
          t("modals.salesTax")
        ) : (
          <Trans i18nKey="modals.salesTaxWithPercent">
            <small>{{ tax: taxPercent.toFixed(2) }}</small>
          </Trans>
        )}
      </TableListCell>
      <TableListCell custom="pr-0" size="sm" right>
        {tax}
      </TableListCell>
    </tr>
  );
}

export default TaxRow;
