/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { addDays, isSameDay, isBefore, isAfter, isFuture } from "date-fns";

const CalendarWeek = ({
  firstDayOfWeek,
  firstDayOfMonth,
  setDate,
  selectedDate,
  otherDate,
  isEnd,
  isStart,
  open,
  today,
  circleToday = true,
  blockFutureDates = false,
  minSelectableDate,
  maxSelectableDate,
}) => {
  const days = [];
  for (let d = 0; d < 7; d += 1) {
    const thisDay = addDays(firstDayOfWeek, d);
    if (thisDay.getMonth() !== firstDayOfMonth.getMonth()) {
      days.push(
        <td
          key={`${d}`}
          aria-label="empty cell"
          data-testid={`calendar-day-${d}`}
        >
          &nbsp;
        </td>
      );
    } else {
      const isThisDaySelected = isSameDay(selectedDate, thisDay);
      const isBeforeThisDay = isBefore(thisDay, selectedDate);
      const isAfterThisDay = isAfter(thisDay, selectedDate);
      const isOutsideOtherDate = isStart
        ? isAfter(thisDay, otherDate)
        : isBefore(thisDay, otherDate);
      const isDateToday = isSameDay(thisDay, today);

      const isBeforeMinimumDate =
        minSelectableDate && isBefore(thisDay, minSelectableDate);
      const isAfterMaximumDate =
        maxSelectableDate && isAfter(thisDay, maxSelectableDate);
      const isAfterFuture = blockFutureDates && isFuture(thisDay);

      const isDisabled =
        !open || isAfterFuture || isBeforeMinimumDate || isAfterMaximumDate;

      const dayClass = [
        "text-center",
        "cursor-pointer",
        "hover:bg-blue-100",
        "text-sm",

        isDateToday && circleToday && !isThisDaySelected
          ? "border border-blue-100"
          : null,
        isDisabled
          ? "text-zinc-400 opacity-70 hover:bg-transparent pointer-events-none"
          : null,
        isThisDaySelected ? "bg-blue-200 hover:bg-blue-200" : "",
        isBeforeThisDay && isEnd && !isOutsideOtherDate ? "bg-blue-50" : null,
        isAfterThisDay && isStart && !isOutsideOtherDate ? "bg-blue-50" : null,
      ]
        .filter(Boolean)
        .join(" ");

      const buttonClass = ["w-8", "h-8", isThisDaySelected ? "font-bold" : ""]
        .filter(Boolean)
        .join(" ");

      days.push(
        <td
          id="select-calendar-day"
          key={`${d}`}
          className={dayClass}
          data-testid={`calendar-day-${d}`}
        >
          <button
            type="button"
            disabled={!open}
            className={buttonClass}
            id="select-calendar-day"
            onClick={(e) => {
              e.preventDefault();
              setDate(thisDay);
            }}
          >
            {thisDay.getDate()}
          </button>
        </td>
      );
    }
  }
  return <tr>{days}</tr>;
};

CalendarWeek.displayName = "CalendarWeek";
export default CalendarWeek;
