import { makeActionCreator } from "app.actions/helpers";

export const REMOVE_BILLING_BEGIN = "REMOVE_BILLING_BEGIN";
export const REMOVE_BILLING_RECEIVE = "REMOVE_BILLING_RECEIVE";
export const REMOVE_BILLING_ERROR_RECEIVE = "REMOVE_BILLING_ERROR_RECEIVE";

export const removeBillingInformation = makeActionCreator(REMOVE_BILLING_BEGIN);
export const receiveRemoveBilling = makeActionCreator(REMOVE_BILLING_RECEIVE);
export const receiveRemoveBillingError = makeActionCreator(
  REMOVE_BILLING_ERROR_RECEIVE,
  "error"
);
