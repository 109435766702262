import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dismissModal } from "app.reducers/ui";
import OrganizationProfileEditorForm from "app.components/Organization/Profiles/OrganizationProfileEditorForm";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import { isValidEmail, isLoading } from "app.utils";
import { useTranslation } from "react-i18next";
import { updateProfile, exitProfileEditor } from "app.reducers/organizations";

const ModalEditManagedAccount = ({ profileGUID, canDismissCallback }) => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();

  const { organization, upsertProfileError, upsertProfileStatus } = useSelector(
    (state) => state.organizations
  );

  const upsertProfileLoading = useMemo(() => {
    return isLoading(upsertProfileStatus);
  }, [upsertProfileStatus]);

  const profile = organization?.profiles?.find(
    (x) => x.profileGUID === profileGUID
  );

  const sharingAccess = profile?.email;

  const [shareAccess, setShareAccess] = useState(!!profile?.email);

  const [profileInfo, setProfileInfo] = useState({
    profileDisplayName: profile?.profileDisplayName || "",
    associatedEmailAddress: profile?.email || "",
    firstName: profile?.firstName || "",
    lastName: profile?.lastName || "",
  });

  const disableSubmit = useMemo(() => {
    const { profileDisplayName, associatedEmailAddress } = profileInfo;

    if (!shareAccess) {
      if (upsertProfileLoading || !profileDisplayName || !profile) return true;
    } else {
      if (
        upsertProfileLoading ||
        !profileDisplayName ||
        !profile ||
        !associatedEmailAddress
      )
        return true;

      if (!isValidEmail(associatedEmailAddress)) return true;
    }

    return false;
  }, [profile, shareAccess, profileInfo, upsertProfileLoading]);

  useEffect(() => {
    canDismissCallback(!disableSubmit);
  }, [canDismissCallback, disableSubmit]);

  const handleUpdate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setProfileInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = (e) => {
    e.preventDefault();

    const { profileDisplayName, associatedEmailAddress, firstName, lastName } =
      profileInfo;

    if (!profileDisplayName) return;

    if (shareAccess) {
      const shouldInvite =
        !profile.invited && !profile.email && associatedEmailAddress;

      reduxDispatch(
        updateProfile(
          profileGUID,
          profileDisplayName,
          profile.userGUID,
          associatedEmailAddress,
          firstName,
          lastName,
          shouldInvite
        )
      );
    } else {
      reduxDispatch(updateProfile(profileGUID, profileDisplayName));
    }
  };

  const closeModal = () => {
    reduxDispatch(dismissModal());
    reduxDispatch(exitProfileEditor());
  };

  return (
    <>
      <ModalHeader
        title={t("modals.managedAccount.editManagedAccount")}
        closeCallback={closeModal}
      />
      <div className="m-4">
        <OrganizationProfileEditorForm
          upsertProfileError={upsertProfileError}
          profileDisplayName={profileInfo.profileDisplayName}
          upsertProfileLoading={upsertProfileLoading}
          handleUpdate={handleUpdate}
          sharingAccess={sharingAccess}
          profile={profile}
          shareAccess={shareAccess}
          changeShareAccess={() => setShareAccess((prev) => !prev)}
          associatedEmailAddress={profileInfo.associatedEmailAddress}
          firstName={profileInfo.firstName}
          lastName={profileInfo.lastName}
        />
      </div>

      <ModalControls>
        <Button
          type="submit"
          disabled={disableSubmit}
          buttonType="primary"
          customClasses="ml-4"
          onClick={submit}
          text={t("modals.managedAccount.updateManagedAccount")}
        />
        <Button
          onClick={closeModal}
          buttonType="text"
          text={t("button.close")}
        />
      </ModalControls>
    </>
  );
};

export default ModalEditManagedAccount;
