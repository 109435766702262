import { call, put, select, all } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import {
  requestRemoveManualTransferLink,
  receiveRemoveManualTransferLink,
  errorRemoveManualTransferLink,
  fetchExchangeLedger,
  fetchTimeline,
} from "app.actions/exchangeLedger";

import * as smartWalletActions from "app.reducers/smartWallet";

import { STATUS } from "app.constants";

function* removeManualTransfer(action) {
  const { itemId, isTimeline } = action;

  yield put(requestRemoveManualTransferLink(STATUS.LOADING));

  const response = yield call(ExchangeLedgerAPI.deleteManualTransfer, itemId);

  if (response.error) {
    yield put(
      errorRemoveManualTransferLink(response.body.message, STATUS.ERROR)
    );
  } else {
    yield put(receiveRemoveManualTransferLink(STATUS.SUCCESS));

    const lastRequestParams = yield select(
      (state) => state.exchangeLedger.lastRequestParams
    );

    const itemsFetch = isTimeline
      ? fetchTimeline(...lastRequestParams, true)
      : fetchExchangeLedger(...lastRequestParams, true);

    yield all([put(itemsFetch)]);
    yield put(smartWalletActions.setSmartRunning());
  }
}

export default removeManualTransfer;
