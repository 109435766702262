import { call, put, all, delay } from "redux-saga/effects";
import i18n from "i18next";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestBulkUpdateLedgersBySource,
  receiveBulkUpdateLedgersBySource,
  fetchLedgers,
} from "app.actions/exchanges";

import {
  dismissModal,
  startModalWorking,
  addFlashMessage,
  removeFlashMessage,
} from "app.reducers/ui";
import { generateGUID } from "app.utils";
import { TEN_SECONDS, WALLET } from "app.constants";

import { STATUS_WARNING } from "app.components/Messages/FlashMessage";

function* bulkUpdateLedgersBySource(action) {
  const { sources } = action;

  yield all([
    put(startModalWorking()),
    put(requestBulkUpdateLedgersBySource()),
  ]);

  // lets figure out how to make each call
  // exchange calls:
  const exchangeSources = sources.filter((s) => s.type !== WALLET);
  const walletSources = sources.filter((s) => s.type === WALLET);

  const toDoListActions = [];
  const toDoListIds = [];

  exchangeSources.forEach((exchangeSource) => {
    toDoListActions.push(
      // eslint-disable-next-line redux-saga/yield-effects
      call(
        ExchangeLedgerAPI.updateExchangeAllLedgers,
        exchangeSource.exchange.id,
        exchangeSource.linkGUID
      )
    );
    toDoListIds.push({
      source: {
        id: exchangeSource.exchange.id,
        linkGUID: exchangeSource.linkGUID,
      },
    });
  });

  // probably don't need this forEach, because should only ever be one wallet
  // source but you never know...
  walletSources.forEach((walletSource) => {
    if (walletSource.syncableWallets.length > 0) {
      toDoListActions.push(
        // eslint-disable-next-line redux-saga/yield-effects
        call(
          ExchangeLedgerAPI.doExchangeLedgerImport,
          walletSource.syncableWallets
        )
      );
      toDoListIds.push({ syncedLedgerIds: walletSource.syncableWallets });
    }
  });

  const syncEverything = yield all(toDoListActions);
  const errorToDo = [];

  syncEverything.forEach((sync) => {
    if (sync.error) {
      const { linkGUID } = sync.body.metadata;
      const source = exchangeSources.find((es) => es.linkGUID === linkGUID);
      if (source) {
        const messageId = generateGUID();

        errorToDo.push({
          // eslint-disable-next-line redux-saga/yield-effects
          removeFlashMessageAction: put(removeFlashMessage(messageId)),
        });

        errorToDo.push({
          // eslint-disable-next-line redux-saga/yield-effects
          putAction: put(
            addFlashMessage(
              i18n.t("modals.syncAll.thereWasAnErrorConnectingTo", {
                label: source.exchange.label,
              }),
              messageId,
              STATUS_WARNING
            )
          ),
        });
      }
    }
  });

  yield all(errorToDo.map((x) => x.putAction));

  yield all([
    put(receiveBulkUpdateLedgersBySource(toDoListIds)),
    put(fetchLedgers()),
    put(dismissModal()),
  ]);

  yield delay(TEN_SECONDS);
  yield all(errorToDo.map((x) => x.removeFlashMessageAction));
}

export default bulkUpdateLedgersBySource;
