import React from "react";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";

import TableList from "app.components/TableList/TableList";
import TableListHeader from "app.components/TableList/TableListHeader";
import TableListCell from "app.components/TableList/TableListCell";

const LedgerTablePlaceholder = () => {
  const rows = [];
  for (let i = 0; i < 10; i += 1) {
    rows.push(
      <tr key={i}>
        <TableListCell>
          <PlaceholderLine width="w-full" />
        </TableListCell>
        <TableListCell>
          <PlaceholderLine width="w-full" />
        </TableListCell>
        <TableListCell>
          <PlaceholderLine width="w-full" />
        </TableListCell>
        <TableListCell>
          <PlaceholderLine width="w-full" />
        </TableListCell>
        <TableListCell>
          <PlaceholderLine width="w-full" />
        </TableListCell>
        <TableListCell>
          <PlaceholderLine width="w-full" />
        </TableListCell>
        <TableListCell lastDisplay={false}>
          <PlaceholderLine width="w-full" />
        </TableListCell>
      </tr>
    );
  }

  return (
    <TableList zebra>
      <thead>
        <tr>
          <TableListHeader custom="w-4" />
          <TableListHeader>
            <PlaceholderLine width="w-full" height="h-2" />
          </TableListHeader>
          <TableListHeader>
            <PlaceholderLine width="w-full" height="h-2" />
          </TableListHeader>
          <TableListHeader>
            <PlaceholderLine width="w-full" height="h-2" />
          </TableListHeader>
          <TableListHeader>
            <PlaceholderLine width="w-full" height="h-2" />
          </TableListHeader>
          <TableListHeader>
            <PlaceholderLine width="w-full" height="h-2" />
          </TableListHeader>
          <TableListHeader>
            <PlaceholderLine width="w-full" height="h-2" />
          </TableListHeader>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </TableList>
  );
};

LedgerTablePlaceholder.displayName = "LedgerTablePlaceholder";

export default LedgerTablePlaceholder;
