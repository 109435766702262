export const getDateLocalePattern = (lang = "en-US") => {
  const formatObj = new Intl.DateTimeFormat(lang).formatToParts();

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case "day":
          return "dd";
        case "month":
          return "MM";
        case "year":
          return "yyyy";
        default:
          return obj.value;
      }
    })
    .join("");
};
