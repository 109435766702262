export const WALLET_TYPES = {
  DASH_CORE: "Dash Core",
  ELECTRUM: "Electrum",
  BTC_ELECTRUM: "Electrum",
  DASH_BIP32: "Generic HD/SPV Wallet",
  BTC_BIP32: "Generic HD/SPV Wallet",
  LTC_BIP32: "Generic HD/SPV Wallet",
  ETHEREUM: "Ethereum",
  AUTHEREUM_ADDRESS: "Authereum",
  BTC_BIP32_MULTISIG: "Multi-Sig",
  TROX: "Generic HD/SPV Wallet",
  TROX_SEGWIT: "Generic HD/SPV Wallet (Segwit)",
  TROX_MULTISIG: "Multi-Sig",
  TROX_MULTISIG_SEGWIT: "Multi-Sig (Segwit)",
  WALLET_XPUB: "xPub",
  WALLET_ADDRESS: "Address",
  WALLET_MULTI_SIG: "Multi-Sig",
  WALLET_TOKEN: "Custodial Wallet",
  FILE_UPLOAD: "Wallet Upload",
  WALLET_ADDRESS_GROUPS: "Wallet Address Groupings",
};

export const AUTHEREUM_ADDRESS = "AUTHEREUM_ADDRESS";
export const ETH_ADDRESS = "ETH_ADDRESS";

// sources we can pull nft metadata from
export const NFT_SUPPORT = [
  "ETH_ADDRESS",
  "ETHERSCAN",
  "ETHERSCAN_UPLOAD",
  "POLYGON",
  "ARBISCAN",
  "ARBITRUM",
  "AVALANCHEC",
  "OPTIMISM",
];

export const GROUP_LOGO = {
  ARBISCAN: "/images/wallet-group-icons/svg/arbitrum.svg",
  ARBITRUM: "/images/wallet-group-icons/svg/arbitrum.svg",
  AVALANCHEC: "/images/wallet-group-icons/svg/avalanche.svg",
  BASECHAIN: "/images/wallet-group-icons/svg/base.svg",
  BNBSC: "/images/wallet-group-icons/svg/binance-chain.svg",
  BSCSCAN: "/images/wallet-group-icons/svg/binance-chain.svg",
  BSC: "/images/wallet-group-icons/svg/binance-chain.svg",
  CARDANO: "/images/wallet-group-icons/svg/cardano.svg",
  ETH_ADDRESS_SHADOW: "/images/wallet-group-icons/svg/ethereum.svg",
  ETH_ADDRESS: "/images/wallet-group-icons/svg/ethereum.svg",
  ETH: "/images/wallet-group-icons/svg/ethereum.svg",
  FANTOM: "/images/wallet-group-icons/svg/fantom.svg",
  GNOSIS: "/images/wallet-group-icons/svg/gnosis.svg",
  HARMONY: "/images/wallet-group-icons/svg/harmony.svg",
  MOONBEAM: "/images/wallet-group-icons/svg/moonbeam.svg",
  NEAR: "/images/wallet-group-icons/svg/near.svg",
  OPTIMISM: "/images/wallet-group-icons/svg/optimism.svg",
  POLKADOT: "/images/wallet-group-icons/svg/polkadot.svg",
  POLYGON: "/images/wallet-group-icons/svg/polygon.svg",
  SOLANA_V2: "/images/wallet-group-icons/svg/solana.svg",
  SOLANA: "/images/wallet-group-icons/svg/solana.svg",
  STELLAR: "/images/wallet-group-icons/svg/stellar.svg",
  TEZOS: "/images/wallet-group-icons/svg/tezos.svg",
  TRON: "/images/wallet-group-icons/svg/tron.svg",
  XTZ: "/images/wallet-group-icons/svg/tezos.svg",
};

export const WALLET_STATUS = {
  AWAITING_ADDRESSES: "Awaiting Addresses",
  COMPLETE: "Complete",
  EMPTY: "Empty",
  ERROR: "We found a problem with your import.",
  PROCESSING: "Processing",
  READY: "Ready",
};

export const CUSTODIAL_WALLETS = ["BITGO_WALLET", "EXODUS"];

export const WALLETS_LABEL = "Wallets";
