export const STATUS = {
  UNINITIALIZED: "uninitialized",
  INITIAL: "initial",
  LOADING: "loading",
  RELOADING: "reloading",
  LOADED: "loaded",
  WORKING: "working",
  SUCCESS: "success",
  ERROR: "error",
  EXPIRED: "expired",
};

export const PROCESSING_STATUS = {
  ACTIVE: "PROCESSING",
  COMPLETE: "COMPLETE",
};

export const FILES_STATUS = {
  AVAILABLE: "AVAILABLE",
  GENERATING: "GENERATING",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR",
};
