// intake all of the request parameters and build a Request
// object for fetch()

function makeUrl(url, params) {
  const hasParams =
    typeof params !== "undefined" &&
    params instanceof URLSearchParams &&
    params.toString() !== "";

  // this block is here to help debug scenarios where a developer may be sending
  // a query parameter which is undefined
  if (hasParams && import.meta.env.VITE_NODE40_ENV !== "production") {
    params.entries().forEach(([k, v]) => {
      if (typeof v === "undefined") {
        console.error(`DEVELOPMENT ONLY: Sending undefined URL param ${k}`, {
          url,
        });
      }
    });
  }

  const finalUrl = [
    url,
    hasParams ? "?" : "",
    hasParams ? params.toString() : "",
  ].join("");
  return finalUrl;
}

function makeRequest(method, url, options, config) {
  const headers = new Headers();
  const { authenticated, data, params, ...rest } = options;

  // use of rest allows us to pass any arbitrary Request option from
  // the API layer to make a request

  const requestOptions = {
    headers,
    method,
    ...rest,
  };

  if (authenticated) {
    headers.append("Authorization", `Bearer ${config.getBearerToken()}`); // }
  }

  if (typeof data !== "undefined") {
    if (data instanceof FormData) {
      requestOptions.body = data;
    } else {
      headers.append("Content-Type", "application/json");
      requestOptions.body = JSON.stringify(data);
    }
  }

  // make the request object
  return new Request(makeUrl(url, params), requestOptions);
}

export default makeRequest;
