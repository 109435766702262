// durations
export const ONE_SECOND = 1000;
export const FIVE_SECONDS = 5 * 1000;
export const TEN_SECONDS = 10 * 1000;
export const HALF_MINUTE = 30 * 1000;
export const ONE_MINUTE = 60 * 1000;
export const FIVE_MINUTES = 1000 * 60 * 5;

export const COMMON_DATE_STRING = "MMM\xa0d,\xa0yyyy";

export const HOUR_RANGE = Array.from(new Array(12).keys()).map((item) => {
  const setHour = item + 1;
  return {
    label: setHour > 9 ? setHour.toString() : `0${setHour}`,
    value: setHour,
  };
});

export const MINUTES_RANGE = Array.from(new Array(60).keys()).map((item) => ({
  label: item > 9 ? item.toString() : `0${item}`,
  value: item,
}));

const tzRangeArray = [];
for (let i = -16; i <= 16; i += 1) {
  tzRangeArray.push(i);
}

export const UTCOFFSET_RANGE = tzRangeArray;

export const AM = "AM";
export const PM = "PM";

export const DAY_PERIOD = [
  { label: AM, value: AM },
  { label: PM, value: PM },
];
