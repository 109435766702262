import { store } from "app.config/configureStore";
import { receiveAuthorizeOrganization } from "app.reducers/organizations";
import { ORGS_BASE, TELLER_BASE, NEW_TICKET_URL_BALANCE } from "app.constants";
import configureApi from "../core/configureApi";

export const REFRESH_TOKEN_URL = [TELLER_BASE, "/checkpoint/checkTicket"].join(
  ""
);

export function getBearerToken() {
  const { organizations } = store.getState();
  if (organizations) {
    const { organizationAuthToken } = organizations;
    return organizationAuthToken;
  }

  return undefined;
}

export function setBearerToken(body) {
  store.dispatch(receiveAuthorizeOrganization(body.access_token, body));
}

const api = configureApi({
  baseUrl: ORGS_BASE,
  refreshTokenUrl: REFRESH_TOKEN_URL,
  newTicketURL: NEW_TICKET_URL_BALANCE,
  getBearerToken,
  setBearerToken,
});

export default api;
