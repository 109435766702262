import { makeActionCreator } from "app.actions/helpers";

export const SET_ENROLLMENT_COUPON = "SET_ENROLLMENT_COUPON";

export const UPDATE_ENROLLMENT_PROFILE_LIMIT =
  "UPDATE_ENROLLMENT_PROFILE_LIMIT";

export const UPDATE_ENROLLMENT_ZIP_CODE = "UPDATE_ENROLLMENT_ZIP_CODE";

export const setEnrollmentCoupon = makeActionCreator(
  SET_ENROLLMENT_COUPON,
  "couponCode",
  "onSuccess",
  "onError"
);

export const runEnrollmentZipCodeUpdate = makeActionCreator(
  UPDATE_ENROLLMENT_ZIP_CODE,
  "zipCode"
);

export const runEnrollmentProfileLimitUpdate = makeActionCreator(
  UPDATE_ENROLLMENT_PROFILE_LIMIT,
  "profileLimit",
  "onSuccess"
);
