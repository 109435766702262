import { makeActionCreator } from "app.actions/helpers";

/** *************************************************************************** */
/* bulk delete wallet ledgers */
/** *************************************************************************** */
export const BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_BEGIN =
  "BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_BEGIN";
export const BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_REQUEST =
  "BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_REQUEST";
export const BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_RECEIVE =
  "BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_RECEIVE";
export const BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_ERROR =
  "BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_ERROR";

export const bulkDeleteExchangeLedgersByCurrency = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_BEGIN,
  "currency"
);

export const requestBulkDeleteExchangeLedgersByCurrency = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_REQUEST,
  "nextStatus"
);

export const receiveBulkDeleteExchangeLedgersByCurrency = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_RECEIVE,
  "nextStatus",
  "ledgerIds"
);

export const bulkDeleteExchangeLedgersByCurrencyError = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_ERROR,
  "nextStatus"
);
