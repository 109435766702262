import { makeActionCreator } from "app.actions/helpers";

export const ENROLL_BEGIN = "ENROLL_BEGIN";
export const ENROLLING_REQUEST = "ENROLLING_REQUEST";
export const ENROLLING_RECEIVE = "ENROLLING_RECEIVE";
export const ENROLLING_RECEIVE_ERROR = "ENROLLING_RECEIVE_ERROR";

export const runEnrollment = makeActionCreator(ENROLL_BEGIN, "cardInstance");
export const requestEnrollment = makeActionCreator(ENROLLING_REQUEST);
export const receiveEnrollment = makeActionCreator(ENROLLING_RECEIVE);
export const receiveEnrollmentError = makeActionCreator(
  ENROLLING_RECEIVE_ERROR,
  "error"
);
