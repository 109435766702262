import { all, call, put } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestTransferInFromSourceless,
  receiveTransferInFromSourceless,
  receiveTransferInFromSourcelessError,
} from "app.actions/exchangeLedger";
import {
  dismissModal,
  endModalWorking,
  startModalWorking,
} from "app.reducers/ui";

function* changeTransactionTransferIn(action) {
  const { ledgerItemId, toLedgerId } = action;

  yield all([put(startModalWorking()), put(requestTransferInFromSourceless())]);

  const response = yield call(
    ExchangeLedgerAPI.setTransferIn,
    ledgerItemId,
    toLedgerId
  );

  if (response.error) {
    yield all([
      put(endModalWorking()),
      put(receiveTransferInFromSourcelessError(response.body.message)),
    ]);
  } else {
    yield all([
      put(receiveTransferInFromSourceless(ledgerItemId)),
      put(dismissModal()),
    ]);
  }
}

export default changeTransactionTransferIn;
