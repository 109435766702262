export const API_OAUTH = "API_OAUTH";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_MIGRATION = "FILE_UPLOAD_MIGRATION";
export const API_KEY_FILE_UPLOAD_MIX = "API_KEY_FILE_UPLOAD_MIX";
export const API_KEY_PASSPHRASE = "API_KEY_PASSPHRASE";
export const API_KEY = "API_KEY";
export const API_KEY_VAULTS = "API_KEY_VAULTS";
export const WALLET_XPUB = "WALLET_XPUB";
export const WALLET_TOKEN = "WALLET_TOKEN";
export const WALLET_ADDRESS = "WALLET_ADDRESS";
export const WALLET_MULTI_SIG = "WALLET_MULTI_SIG";
export const WALLET_ADDRESS_GROUPS = "WALLET_ADDRESS_GROUPS";

export const API_INTEGRATION_TYPES = [
  API_OAUTH,
  API_KEY_FILE_UPLOAD_MIX,
  API_KEY_PASSPHRASE,
  API_KEY,
  API_KEY_VAULTS,
];

export const WALLET_INTEGRATION_TYPES = [
  WALLET_XPUB,
  WALLET_ADDRESS,
  WALLET_MULTI_SIG,
  WALLET_TOKEN,
];

export const WALLET = "WALLET";
export const SHADOW = "SHADOW";
export const ETH_ADDRESS_SHADOW = "ETH_ADDRESS_SHADOW";

export const SUPPORTED_SYNC_INTEGRATIONS = [
  WALLET_XPUB,
  WALLET_ADDRESS,
  WALLET_MULTI_SIG,
];

export const UNSUPPORTED_SYNC_INTEGRATIONS = [WALLET_ADDRESS_GROUPS];
