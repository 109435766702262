import {
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  formatISO,
  parseISO,
} from "date-fns";

function format(inDate) {
  return formatISO(inDate, { representation: "date" });
}

export const GENESIS_DAY = "2009-01-01";
export const GENESIS_DATE = parseISO(GENESIS_DAY);

export const THIS_MONTH = {
  id: "THIS_MONTH",
  label: "This Month",
};

export const THIS_YEAR = {
  id: "THIS_YEAR",
  label: "This Year",
};

export const LAST_YEAR = {
  id: "LAST_YEAR",
  label: "Last Year",
};

export const ALL = {
  id: "ALL",
  label: "ALL",
  range: {
    startDate: GENESIS_DAY,
    endDate: format(new Date()),
  },
};

export const getRangePresets = ({
  now,
  selectedPresset,
  minimumYear,
  maximumYear,
}) => {
  const RANGE_PRESETS = {
    THIS_MONTH: {
      ...THIS_MONTH,
      range: {
        startDate: format(startOfMonth(now)),
        endDate: format(endOfMonth(now)),
      },
    },
    THIS_YEAR: {
      ...THIS_YEAR,
      range: {
        startDate: format(startOfYear(now)),
        endDate: format(endOfYear(now)),
      },
    },
    LAST_YEAR: {
      ...LAST_YEAR,
      range: {
        startDate: format(startOfYear(addYears(now, -1))),
        endDate: format(endOfYear(addYears(now, -1))),
      },
    },
    ALL: {
      ...ALL,
      range: {
        startDate: GENESIS_DAY,
        endDate: format(now),
      },
    },
  };

  if (selectedPresset) {
    return RANGE_PRESETS[selectedPresset];
  }

  const YEARS = [];

  const minYear = minimumYear || parseISO(ALL.range.startDate).getFullYear();
  const maxYear = maximumYear || parseISO(ALL.range.endDate).getFullYear();

  for (let i = maxYear; i >= minYear; i -= 1) {
    YEARS.push(i);
  }

  const QUARTERS = [
    { id: 1, label: "Q1", sd: "01-01", ed: "03-31" },
    { id: 2, label: "Q2", sd: "04-01", ed: "06-30" },
    { id: 3, label: "Q3", sd: "07-01", ed: "09-30" },
    { id: 4, label: "Q4", sd: "10-01", ed: "12-31" },
  ];

  return {
    ...RANGE_PRESETS,
    YEARS,
    QUARTERS,
  };
};
