import { put, race, take, call } from "redux-saga/effects";
import {
  dismissModal,
  openModal,
  openApiKeyModal,
  completeApiKeyModal,
  dismissApiKeyModal,
} from "app.reducers/ui";
import { MODAL_EXCHANGE_API_KEY_POP_UP } from "app.constants/modals";

function* showApiKeyModal() {
  while (true) {
    const action = yield take(openApiKeyModal);

    const { params, apiDismissCallback, apiLinkSuccessCallback } =
      action.payload;

    yield put(openModal(MODAL_EXCHANGE_API_KEY_POP_UP, undefined, params));

    const { dismiss, success } = yield race({
      dismiss: take([dismissApiKeyModal, dismissModal]),
      success: take(completeApiKeyModal),
    });

    if (dismiss) {
      if (typeof apiDismissCallback === "function") {
        yield call(apiDismissCallback);
      }
    }

    if (success) {
      const { linkGUID, autoImport } = success.payload;
      if (typeof apiLinkSuccessCallback === "function") {
        yield call(apiLinkSuccessCallback, linkGUID, autoImport);
      }
    }

    yield put(dismissModal());
  }
}

export default showApiKeyModal;
