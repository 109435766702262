import { store } from "app.config/configureStore";

import { addFlashMessage, removeFlashMessage } from "app.reducers/ui";
import { STATUS_DANGER } from "app.components/Messages/FlashMessage";

import { ONE_MINUTE } from "app.constants";

const DATAFAUCETDOWN = "datafaucetDown";

function handleMaintenanceError(response, data) {
  if (response.status === 400) {
    if (data.datafaucetDown && data.message) {
      store.dispatch(
        addFlashMessage(data.message, DATAFAUCETDOWN, STATUS_DANGER)
      );
      setTimeout(() => {
        store.dispatch(removeFlashMessage(DATAFAUCETDOWN));
      }, ONE_MINUTE);
    }
  }
}

export default handleMaintenanceError;
