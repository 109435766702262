import { select, put, call, all } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import { requestUser, receiveUser } from "app.reducers/user";
import { receiveAccountProfile } from "app.actions/account";

function* fetchUser() {
  // if we are already trying to get a new token, don't try again
  const userLoading = yield select((state) => state.user.userLoading);
  if (userLoading) return;
  yield put(requestUser());

  const response = yield call(TellerAPI.getUser);

  // TODO: ADD ERROR HANDLING
  if (response.error) {
    console.error("error in fetchUser...", response.error);
  } else {
    yield all([
      put(receiveUser(response.body)),
      put(receiveAccountProfile(response.body)),
    ]);
  }
}

export default fetchUser;
