import React from "react";
import Button from "app.components/Util/Button";

function Spacer() {
  return (
    <Button disabled buttonSize="sm" buttonType="pagination">
      &hellip;
    </Button>
  );
}

Spacer.displayName = "Spacer";

export default Spacer;
