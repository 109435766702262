import React from "react";

import { useTranslation } from "react-i18next";

import TableList from "app.components/TableList/TableList";
import TableListHeader from "app.components/TableList/TableListHeader";
import ImportCount from "./ImportCount";
import ImportDate from "./ImportDate";

const ExchangeImportList = ({ imports }) => {
  const { t } = useTranslation();

  if (typeof imports === "undefined") {
    return null;
  }

  return (
    <div className="overflow-hidden rounded border">
      <TableList>
        {imports.length > 0 ? (
          <thead>
            <tr>
              <TableListHeader left>{t("common.date")}</TableListHeader>
              <TableListHeader left>{t("common.records")}</TableListHeader>
            </tr>
          </thead>
        ) : null}
        <tbody>
          {imports.map((imp, index) => {
            const key = `import-${index}`;
            return (
              <tr key={key} data-testid={key}>
                <ImportDate importDate={imp.initiationDateSeconds} />
                <ImportCount
                  importDate={imp.initiationDateSeconds}
                  status={imp.status}
                  statusAddresses={imp.statusAddresses}
                  records={imp.ledgerItemsAdded}
                  pending={false}
                />
              </tr>
            );
          })}
        </tbody>
      </TableList>
    </div>
  );
};

ExchangeImportList.displayName = "ExchangeImportList";

export default ExchangeImportList;
