import { call, put } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestExchangeLedgerImports,
  receiveExchangeLedgerImports,
} from "app.actions/exchangeLedger";

import { getLoadingState } from "app.utils/selectors";

import { STATUS } from "app.constants";

function* fetchExchangeLedgerImports(action) {
  const { ledgerId } = action;

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchangeLedger.importsStatus
  );

  if (isLoading) return;

  yield put(requestExchangeLedgerImports(ledgerId, nextStatus));

  const response = yield call(
    ExchangeLedgerAPI.getExchangeLedgerImportHistory,
    ledgerId
  );

  if (response.error) {
    console.error(
      "Error in fetchExchangeLedgerImport",
      response.body,
      response.error
    );
  } else {
    yield put(
      receiveExchangeLedgerImports(response.body, ledgerId, STATUS.LOADED)
    );
  }
}

export default fetchExchangeLedgerImports;
