import React from "react";
import { useTranslation } from "react-i18next";
import SvgLoader from "app.components/Util/SvgLoader";

const ModalHeader = ({ title, closeCallback, disabled = false }) => {
  const { t } = useTranslation();
  const closeClass = [
    "group ml-auto bg-blue-50 hover:bg-blue-100 rounded p-1",
    disabled ? "opacity-70 cursor-not-allowed" : null,
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <div className="mb-4 flex h-12 items-center px-4">
      <h3 className="text-xl font-normal uppercase text-blue-700">{title}</h3>
      <button
        type="button"
        id="modal-close"
        disabled={disabled}
        className={closeClass}
        onClick={closeCallback}
      >
        <SvgLoader
          id="Close"
          className="h-5 w-5 stroke-blue-600 group-hover:stroke-blue-700"
        />
        <span className="sr-only">{t("button.close")}</span>
      </button>
    </div>
  );
};

export default ModalHeader;
