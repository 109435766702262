import React, { useState, useEffect, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { EXCHANGE_IDS, HAS_SUPPORT_LINKS, HELP_INFO } from "app.constants";
import { useFlashMessage } from "app.components/Messages/FlashMessage";
import { updateExchangeLedgersViaUpload } from "app.actions/exchanges";
import ErrorMessage from "app.components/Util/ErrorMessage";
import FileUploadField from "app.components/Util/FileUploadField";
import ReadMore from "app.components/Util/ReadMore";
import Button from "app.components/Util/Button";
import { makeKey } from "app.utils";

const AddExchangeImportForm = ({
  exchange,
  ledgerIds,
  linkGUID,
  canDismissCallback,
}) => {
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();

  const { sendErrorMessage } = useFlashMessage();

  const [file, setFile] = useState({});
  const { uploading, uploadError, failedCurrencies, userReadableErrors } =
    useSelector((state) => state.exchanges);

  const disableButtons = useMemo(() => {
    if (!file || !Object.keys(file).length) return true;
    if (uploading) return true;
    return false;
  }, [uploading, file]);

  useEffect(() => {
    // if a user is actively uploading, disable close
    canDismissCallback(disableButtons);
  }, [disableButtons, canDismissCallback]);

  const handleUpdateFile = (newFile, fileKey) => {
    if (newFile && newFile.size === 0) {
      sendErrorMessage(t("addLedger.fileUpload.fileEmpty"));
      return;
    }

    if (typeof fileKey === "undefined") {
      setFile(newFile);
    } else if (typeof newFile !== "undefined") {
      const currentFiles = Object.values({ ...file });
      const matchingFiles = currentFiles.filter(
        (x) =>
          typeof x !== "undefined" &&
          x.name === newFile.name &&
          x.lastModified === newFile.lastModified &&
          x.size === newFile.size
      );

      if (matchingFiles.length > 0) {
        sendErrorMessage(
          t("addLedger.fileUpload.fileAlreadyExists", {
            filename: newFile.name,
          })
        );
        return;
      }

      setFile({ ...file, [fileKey]: newFile });
    } else {
      const newFiles = {};

      Object.keys(file)
        .filter((key) => key !== fileKey)
        .forEach((key) => {
          newFiles[key] = file[key];
        });

      setFile(newFiles);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    reduxDispatch(
      updateExchangeLedgersViaUpload(file, exchange.id, linkGUID, ledgerIds)
    );
  };

  const { expectedFiles } = exchange;

  let error = null;

  if (typeof failedCurrencies !== "undefined") {
    if (typeof userReadableErrors !== "undefined") {
      error = (
        <div className="my-2 rounded bg-red-50 p-2 text-xs text-red-700">
          <p className="text-md text-red-700">
            {t("ledgers.exchangeLedger.fileWithErrorBlockUpload")}
          </p>
          {userReadableErrors.length > 0 ? (
            <ReadMore
              label={[t("common.showDetails"), t("common.hideDetails")]}
            >
              <ul className="my-2 list-disc break-all pl-8">
                {userReadableErrors.map((x) => (
                  <li className="text-red-700" key={x}>
                    {x}
                  </li>
                ))}
              </ul>
            </ReadMore>
          ) : undefined}
        </div>
      );
    } else if (failedCurrencies.length === 0) {
      error = (
        <div className="my-4 rounded bg-red-50 p-4">
          <p className="text-md text-red-700">
            {t("ledgers.exchangeLedger.uploadedFileWithoutLedgers")}
          </p>
        </div>
      );
    } else {
      error = (
        <div className="my-4 rounded bg-red-50 p-4">
          <p className="text-md text-red-700">
            {t("ledgers.exchangeLedger.unableToCreateLedger", {
              count: failedCurrencies.length,
            })}
          </p>
          <ul className="my-2 list-disc break-all pl-8">
            {failedCurrencies.map((item, index) => {
              const currency = Object.keys(item)[0];
              const key = `${currency}-${index}`;
              return (
                <li className="text-red-700" key={key}>
                  {currency}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }

  const isGenericUpload = exchange.name === EXCHANGE_IDS.GENERIC;
  let uploadFileDescription;

  if (isGenericUpload) {
    if (HAS_SUPPORT_LINKS && HELP_INFO[exchange.name]) {
      uploadFileDescription = (
        <Trans i18nKey="ledgers.exchangeLedger.afterYouveAddedAllYourTransactionDataToOurTemplate_withHelp">
          <a
            rel="noopener noreferrer"
            className="text-linkBlue text-linkBlueActive hover:underline"
            href={HELP_INFO[exchange.name]}
            target="_blank"
          >
            CSV Template
          </a>
          <strong />
        </Trans>
      );
    } else {
      uploadFileDescription = (
        <Trans i18nKey="ledgers.exchangeLedger.afterYouveAddedAllYourTransactionDataToOurTemplate">
          <strong />
        </Trans>
      );
    }
  } else if (HAS_SUPPORT_LINKS && HELP_INFO[exchange.name]) {
    uploadFileDescription = (
      <Trans
        i18nKey="ledgers.exchangeLedger.getYourTransactionFilesFromExchange_withHelp"
        count={expectedFiles.length}
      >
        {{ label: exchange.label }}
        <span>
          <a
            rel="noopener noreferrer"
            className="text-linkBlue text-linkBlueActive hover:underline"
            href={HELP_INFO[exchange.name]}
            target="_blank"
          >
            help
          </a>
        </span>
      </Trans>
    );
  } else {
    uploadFileDescription = t(
      "ledgers.exchangeLedger.getYourTransactionFilesFromExchange",
      {
        label: exchange.label,
        count: expectedFiles.length,
      }
    );
  }

  return (
    <form onSubmit={submit}>
      {error}
      <ErrorMessage {...uploadError} />
      <p className="text-md my-4">{uploadFileDescription}</p>
      {expectedFiles.map((expectedFile, index) => {
        // clean file extensions
        const expectedExtensions = expectedFile.extensions.map((ext) => {
          return ext.replace(".", "");
        });

        const currentFile = file[expectedFile.key];

        return (
          <FileUploadField
            key={makeKey(
              "file-upload-enchange-import",
              expectedFile.key,
              index
            )}
            id={makeKey("file-upload-enchange-import", expectedFile.key, index)}
            description={expectedFile.description}
            file={currentFile}
            fileKey={expectedFile.key}
            optional={expectedFile.optional}
            fileType={expectedExtensions}
            disableButtons={uploading}
            handleUpdateFile={handleUpdateFile}
          />
        );
      })}
      <div className="flex">
        <Button
          type="submit"
          disabled={disableButtons}
          buttonType="primary"
          working={uploading}
          customClasses="ml-auto"
          text={t("ledgers.exchangeLedger.importTransactions")}
          workingText={t("ledgers.exchangeLedger.importing")}
        />
      </div>
    </form>
  );
};
AddExchangeImportForm.displayName = "AddExchangeImportForm";

export default AddExchangeImportForm;
