import { makeActionCreator } from "app.actions/helpers";

// ENTRY DESCRIPTION
export const FAVORITE_LEDGER_BEGIN = "FAVORITE_LEDGER_BEGIN";
export const FAVORITE_LEDGER_REQUEST = "FAVORITE_LEDGER_REQUEST";
export const FAVORITE_LEDGER_RECEIVE = "FAVORITE_LEDGER_RECEIVE";
export const FAVORITE_LEDGER_ERROR = "FAVORITE_LEDGER_ERROR";

// ENTRY DESCRIPTION
export const favoriteLedger = makeActionCreator(
  FAVORITE_LEDGER_BEGIN,
  "ledgerId",
  "userFavorite"
);

export const requestFavoriteLedger = makeActionCreator(
  FAVORITE_LEDGER_REQUEST,
  "ledgerId",
  "userFavorite"
);

export const receiveFavoriteLedger = makeActionCreator(
  FAVORITE_LEDGER_RECEIVE,
  "ledgerId"
);

export const favoriteLedgerError = makeActionCreator(
  FAVORITE_LEDGER_ERROR,
  "response",
  "error",
  "ledgerId"
);
