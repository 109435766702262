import { call, all, put } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestUploadBulkAddressLabels,
  receiveUploadBulkAddressLabels,
  uploadBulkAddressLabelsError,
  dismissModal,
  fetchAddressLabels,
} from "app.reducers/ui";

import { fetchFiles } from "app.reducers/files";

function* uploadBulkAddressLabels(action) {
  const { files } = action.payload;

  yield put(requestUploadBulkAddressLabels());

  const response = yield call(ExchangeLedgerAPI.uploadBulkAddressLabels, files);

  const { body, error } = response;
  if (error) {
    yield put(uploadBulkAddressLabelsError(body));
  } else {
    yield all([
      put(receiveUploadBulkAddressLabels()),
      put(dismissModal()),
      put(fetchAddressLabels(true)),
      put(fetchFiles()),
    ]);
  }
}

export default uploadBulkAddressLabels;
