import { makeActionCreator } from "app.actions/helpers";
// EXCHANGE_LEDGER_DO_IMPORT
export const UPDATE_EXCHANGE_LEDGERS_BEGIN = "UPDATE_EXCHANGE_LEDGERS_BEGIN";
export const UPDATE_EXCHANGE_LEDGERS_REQUEST =
  "UPDATE_EXCHANGE_LEDGERS_REQUEST";
export const UPDATE_EXCHANGE_LEDGERS_RECEIVE =
  "UPDATE_EXCHANGE_LEDGERS_RECEIVE";
export const UPDATE_EXCHANGE_LEDGERS_ERROR = "UPDATE_EXCHANGE_LEDGERS_ERROR";

export const updateExchangeAllLedgers = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_BEGIN,
  "exchangeId",
  "linkGUID"
);

export const requestUpdateExchangeAllLedgers = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_REQUEST
);
export const receiveUpdateExchangeAllLedgers = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_RECEIVE,
  "exchangeId",
  "linkGUID"
);
export const updateExchangeAllLedgersError = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_ERROR,
  "response"
);
