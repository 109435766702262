/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { ACCOUNT_STATUS } from "app.constants/loadingStates";

import {
  initialState,
  updateSecurityReducer,
  UPDATE_FIELD,
  CLEAR_TOTP,
} from "./reducer";

import InitialScreen from "./InitialScreen";
import VerifyForm from "./VerifyForm";

function ModalMFADisabled() {
  const reduxDispatch = useDispatch();
  const { securityStatus, totpSecret, securityError } = useSelector(
    (state) => state.account
  );

  const [state, localDispatch] = useReducer(
    updateSecurityReducer,
    initialState
  );

  const handleUpdate = (name, value) => {
    localDispatch({
      type: UPDATE_FIELD,
      name,
      value,
    });
  };

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  useEffect(() => {
    if (securityStatus === ACCOUNT_STATUS.UPDATED) {
      reduxDispatch(dismissModal());
    }
  }, [reduxDispatch, securityStatus]);

  // if we ever get an error, clear the totp field
  useEffect(() => {
    if (typeof securityError !== "undefined") {
      localDispatch({
        type: CLEAR_TOTP,
      });
    }
  }, [securityError]);

  if (totpSecret) {
    return (
      <VerifyForm
        state={state}
        handleUpdate={handleUpdate}
        handleDismiss={handleDismiss}
      />
    );
  }

  return (
    <InitialScreen
      state={state}
      handleUpdate={handleUpdate}
      handleDismiss={handleDismiss}
    />
  );
}

ModalMFADisabled.displayName = "ModalMFADisabled";

export default ModalMFADisabled;
