import React from "react";
import CashMoney from "app.components/Util/CashMoney";
import TableListCell from "app.components/TableList/TableListCell";
import { useTranslation } from "react-i18next";

function SaleRow({
  availableCouponId,
  availableDiscountPercent,
  availableDiscountDollars,
  fromReferral,
}) {
  const { t } = useTranslation();
  if (fromReferral) return null;
  if (availableCouponId) return null;
  if (availableDiscountPercent === 0) return null;

  return (
    <tr data-testid="sale-row">
      <TableListCell custom="pl-0" size="sm">
        {t("modals.sale.savePercent", { amount: availableDiscountPercent })}
      </TableListCell>
      <TableListCell custom="pr-0" size="sm" right>
        <CashMoney
          size="none"
          align="right"
          dollars={-1 * availableDiscountDollars}
          fiatOverride="USD"
        />
      </TableListCell>
    </tr>
  );
}

export default SaleRow;
