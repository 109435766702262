import { call, put, all } from "redux-saga/effects";
import {
  requestCreateExchangeLedgers,
  createExchangeLedgersError,
  receiveCreateExchangeLedgers,
  fetchLedgers,
} from "app.actions/exchanges";
import { doRedirect } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

function* createExchangeLedgers(action) {
  const { exchangeId, linkGUID, accountIds, accountingStrategy } = action;

  yield put(requestCreateExchangeLedgers());

  const response = yield call(
    ExchangesAPI.addBulkExchangeLedgers,
    exchangeId,
    linkGUID,
    accountIds,
    accountingStrategy
  );

  if (response.error) {
    yield put(createExchangeLedgersError(response.body, response.error));
  } else {
    yield all([
      put(receiveCreateExchangeLedgers(response.body, exchangeId)),
      put(fetchLedgers()),
      put(
        doRedirect(
          `/portfolio/add-ledger/confirm/${exchangeId}?linkGUID=${linkGUID}`
        )
      ),
    ]);
  }
}

export default createExchangeLedgers;
