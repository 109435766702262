import { takeEvery, takeLeading } from "redux-saga/effects";
import { EXCHANGE_TABBED_LEDGER_SECTION_CHANGE } from "app.actions/exchangeLedger";

import * as uiActions from "app.reducers/ui";

import { LEDGER_TYPES } from "app.constants";
import {
  BULK_DELETE_EXCHANGE_LEDGERS_RECEIVE,
  BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_RECEIVE,
  DELETE_EXCHANGE_LEDGER_RECEIVE,
  REBUILD_EXCHANGE_LEDGERS_RECEIVE,
} from "app.actions/exchanges";
import curryRemoveRecentlyViewed from "./removeRecentlyViewed";

import showApiKeyModal from "./showApiKeyModal";
import showWalletTokenModal from "./showWalletTokenModal";
import viewLedger from "./viewLedger";
import watchKeyboardChannel from "./watchKeyboardChannel";
import fetchAddressLabels from "./fetchAddressLabels";
import fetchApiKeys from "./fetchApiKeys";
import createApiKey from "./createApiKey";
import setAddressLabel from "./setAddressLabel";
import fetchCustomFieldsData from "./fetchCustomFieldsData";
import updateCustomFieldsData from "./updateCustomFieldsData";
import fetchUserTags from "./fetchUserTags";
import bulkCreateTag from "./bulkCreateTag";
import createTag from "./createTag";
import deleteTag from "./deleteTag";
import deleteApiKey from "./deleteApiKey";
import fetchTimelineLedgersRange from "./fetchTimelineLedgersRange";
import uploadBulkAddressLabels from "./uploadBulkAddressLabels";

// WATCHERS
const uiSagas = [
  takeEvery(EXCHANGE_TABBED_LEDGER_SECTION_CHANGE, viewLedger),
  takeEvery(
    DELETE_EXCHANGE_LEDGER_RECEIVE,
    curryRemoveRecentlyViewed(
      LEDGER_TYPES.EXCHANGE,
      (action) => action.ledgerId
    )
  ),
  takeEvery(
    BULK_DELETE_EXCHANGE_LEDGERS_RECEIVE,
    curryRemoveRecentlyViewed(
      LEDGER_TYPES.EXCHANGE,
      (action) => action.ledgerIds
    )
  ),
  takeEvery(
    BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_RECEIVE,
    curryRemoveRecentlyViewed(
      LEDGER_TYPES.EXCHANGE,
      (action) => action.ledgerIds
    )
  ),
  takeEvery(
    REBUILD_EXCHANGE_LEDGERS_RECEIVE,
    curryRemoveRecentlyViewed(
      LEDGER_TYPES.EXCHANGE,
      (action) => action.ledgerIds
    )
  ),
  takeLeading(uiActions.fetchCustomFieldsData, fetchCustomFieldsData),
  takeLeading(uiActions.fetchAddressLabels, fetchAddressLabels),
  takeEvery(uiActions.updateCustomFieldsData, updateCustomFieldsData),
  takeLeading(uiActions.fetchUserTagsData, fetchUserTags),
  takeLeading(uiActions.setAddressLabel, setAddressLabel),
  takeLeading(uiActions.fetchApiKeys, fetchApiKeys),
  takeLeading(uiActions.createApiKey, createApiKey),
  takeEvery(uiActions.createTag, createTag),
  takeEvery(uiActions.deleteTag, deleteTag),
  takeEvery(uiActions.deleteApiKey, deleteApiKey),
  takeEvery(uiActions.bulkCreateTag, bulkCreateTag),
  takeEvery(uiActions.fetchTimelineLedgersRange, fetchTimelineLedgersRange),
  takeEvery(uiActions.uploadBulkAddressLabels, uploadBulkAddressLabels),
  showApiKeyModal(),
  showWalletTokenModal(),
  watchKeyboardChannel(),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default uiSagas;
