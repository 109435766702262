import { put, call } from "redux-saga/effects";
import { getLoadingState } from "app.utils/selectors";
import SettingsAPI from "app.api/SettingsAPI";
import {
  requestUpdateRollForward,
  receiveUpdateRollForward,
} from "app.reducers/user";

import { refreshUserToken } from "app.actions/authentication";

function* setRollForward({ payload }) {
  const rollForwardDate = payload;
  // if we are already trying to get a new token, don't try again
  const { isLoading } = yield* getLoadingState(
    (state) => state.user.rollForwardStatus
  );

  if (isLoading) return;
  yield put(requestUpdateRollForward());

  const response = yield call(SettingsAPI.updateRollForward, rollForwardDate);

  // TODO: ADD ERROR HANDLING
  if (response.error) {
    console.error("error in setRollForward...", response.error);
  } else {
    yield put(receiveUpdateRollForward());
    yield put(refreshUserToken());
  }
}

export default setRollForward;
