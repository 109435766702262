import { call, put, select } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  enableMFARequest,
  enableMFAReceive,
  enableMFAError,
} from "app.actions/account";
import fetchUser from "app.sagas/user/fetchUser";

import { lookupTranslation } from "app.utils";

import {
  ACCOUNT_ERROR_PATH,
  ACCOUNT_RESETING_ERROR,
} from "app.constants/errorMessages";

function* handleEnableMFA(action) {
  const { currentPassword, totp } = action;
  const cleanTotp = totp.replace(/\s/g, "");

  const { username } = yield select((state) => state.account.userProfile);

  yield put(enableMFARequest());

  const response = yield call(
    TellerAPI.verifyMFA,
    username,
    currentPassword,
    cleanTotp
  );

  if (response.error) {
    yield put(
      enableMFAError(
        lookupTranslation(
          "invalid_totp",
          ACCOUNT_ERROR_PATH,
          ACCOUNT_RESETING_ERROR
        )
      )
    );
  } else {
    yield call(fetchUser);
    yield put(enableMFAReceive());
  }
}

export default handleEnableMFA;
