import { call, put } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestExchangeChangeExemptStatus,
  receiveExchangeChangeExemptStatus,
} from "app.actions/exchangeLedger";

function* changeExchangeExemptStatus(action) {
  const { transactionId, exempt, ledgerId } = action;

  yield put(requestExchangeChangeExemptStatus(transactionId));

  const response = yield call(
    ExchangeLedgerAPI.editExchangeExempt,
    ledgerId,
    transactionId,
    exempt
  );

  if (response.error) {
    console.error(
      "Error in changeExchangeExemptStatus",
      response.body,
      response.error
    );
  } else {
    yield put(
      receiveExchangeChangeExemptStatus(response.body, transactionId, exempt)
    );
  }
}

export default changeExchangeExemptStatus;
