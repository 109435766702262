import api from "../clients/tellerBalance";

/**
 * Get the current users subscription capability info, e.g. what this user is allowed to do
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getUserSubscription = () => {
  return api.get("/capability/current", {
    authenticated: true,
  });
};
