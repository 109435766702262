import React from "react";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";

const LedgerSummaryDataPlaceholder = () => (
  <div className="c_ledger-summary-data-placeholder mb-4 rounded shadow">
    <div className="flex">
      <div className="flex basis-1/3 flex-col p-4">
        <PlaceholderLine width="w-24" innerClassName="mb-4" />
        <PlaceholderLine width="w-28" innerClassName="mb-4" />
        <PlaceholderLine width="w-40" innerClassName="mb-2" height="h-2" />
        <PlaceholderLine width="w-40" innerClassName="mb-2" height="h-2" />
        <PlaceholderLine width="w-40" innerClassName="mb-2" height="h-2" />
      </div>
      <div className="basis-2/3 border-l border-zinc-100 p-4">
        <PlaceholderLine width="w-24" innerClassName="mb-4" />
        <div className="flex">
          <div className="flex flex-1 flex-col">
            <PlaceholderLine width="w-28" innerClassName="mb-4" />
            <PlaceholderLine width="w-40" innerClassName="mb-2" height="h-2" />
            <PlaceholderLine width="w-40" innerClassName="mb-2" height="h-2" />
            <PlaceholderLine width="w-40" innerClassName="mb-2" height="h-2" />
          </div>
          <div className="flex flex-1 flex-col">
            <PlaceholderLine width="w-28" innerClassName="mb-4" />
            <PlaceholderLine width="w-40" innerClassName="mb-2" height="h-2" />
            <PlaceholderLine width="w-40" innerClassName="mb-2" height="h-2" />
            <PlaceholderLine width="w-40" innerClassName="mb-2" height="h-2" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

LedgerSummaryDataPlaceholder.displayName = "LedgerSummaryDataPlaceholder";

export default LedgerSummaryDataPlaceholder;
