import { put, race, delay, take } from "redux-saga/effects";

import {
  AUTO_REFRESH_USER_TOKEN_END,
  refreshUserToken,
} from "app.actions/authentication";

const REFRESH_INTERVAL_SECONDS = 56;
const IDLE_MAX_SECONDS = 60 * 5;

function* autoRefreshUserToken() {
  let idleTimer = 0;
  let refreshTimer = 0;
  let refreshRunning = true;

  const resetTimer = () => {
    idleTimer = 0;
  };

  window.addEventListener("mousemove", resetTimer, false);
  window.addEventListener("click", resetTimer, false);
  window.addEventListener("keydown", resetTimer, false);

  while (refreshRunning) {
    const { cancelled } = yield race({
      delayed: delay(1000),
      cancelled: take(AUTO_REFRESH_USER_TOKEN_END),
    });

    if (cancelled) {
      refreshRunning = false;
    } else {
      idleTimer += 1;
      refreshTimer += 1;

      const withinRefreshWindow = idleTimer <= IDLE_MAX_SECONDS;
      const onRefreshInterval = refreshTimer % REFRESH_INTERVAL_SECONDS === 0;

      if (withinRefreshWindow && onRefreshInterval) {
        // It's been 56 seconds
        yield put(refreshUserToken());
      }
    }
  }

  window.removeEventListener("mousemove", resetTimer, false);
  window.removeEventListener("click", resetTimer, false);
  window.removeEventListener("keydown", resetTimer, false);
}

export default autoRefreshUserToken;
