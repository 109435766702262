import { select, call, put, all } from "redux-saga/effects";

import { handleApiKeyError, handleWalletTokenError } from "app.actions/helpers";
import {
  requestUpdateExchangeAllLedgers,
  receiveUpdateExchangeAllLedgers,
  updateExchangeAllLedgersError,
  fetchLedgers,
} from "app.actions/exchanges";

import {
  openModal,
  openApiKeyModal,
  openWalletTokenModal,
  dismissModal,
} from "app.reducers/ui";

import { MODAL_EXCHANGE_OAUTH_POP_UP } from "app.constants/modals";

import ExchangeAPI from "app.api/ExchangeLedgerAPI";

function* updateExchangeAllLedgers(action) {
  const { exchangeId, linkGUID } = action;

  yield put(requestUpdateExchangeAllLedgers());

  const response = yield call(
    ExchangeAPI.updateExchangeAllLedgers,
    exchangeId,
    linkGUID
  );
  const { body, error } = response;

  if (error) {
    const exchange = yield select((state) =>
      state.exchanges.supportedExchanges.find((item) => item.id === exchangeId)
    );

    if (
      typeof body.metadata !== "undefined" &&
      typeof body.metadata.code !== "undefined"
    ) {
      if (
        typeof body.metadata !== "undefined" &&
        typeof body.metadata.code !== "undefined"
      ) {
        switch (body.metadata.code) {
          case "oauthRequired":
            yield put(
              openModal(MODAL_EXCHANGE_OAUTH_POP_UP, undefined, {
                exchange,
                reLink: true,
              })
            );
            break;
          case "exchangeLinkInactive":
          case "apiKeyInvalid":
            yield put(
              handleApiKeyError({
                errorCode: body.metadata.code, // errorCode
                exchange,
                linkGUID: body.metadata.linkGUID,
                openApiKeyModal,
                errorCallback: updateExchangeAllLedgersError,
                error,
              })
            );
            break;
          case "apiTokenInvalid":
            yield put(
              handleWalletTokenError(
                body.metadata.code, // errorCode
                exchange,
                body.metadata.linkGUID,
                openWalletTokenModal,
                updateExchangeAllLedgersError,
                error,
                undefined,
                undefined
              )
            );
            break;
          default:
            break;
        }
        yield put(updateExchangeAllLedgersError(body, error));
      }
    }
  } else {
    yield all([
      put(receiveUpdateExchangeAllLedgers(exchangeId, linkGUID)),
      put(fetchLedgers()),
      put(dismissModal()),
    ]);
  }
}

export default updateExchangeAllLedgers;
