import { call, put, all } from "redux-saga/effects";
import {
  requestUpdateRollforwardOpenLotsViaUpload,
  receiveUpdateRollforwardOpenLotsViaUpload,
  updateRollforwardOpenLotsViaUploadError,
  fetchLedgers,
} from "app.actions/exchanges";
import ExchangesAPI from "app.api/ExchangesAPI";

function* updateRollforwardViaUpload(action) {
  const { file } = action;

  yield put(requestUpdateRollforwardOpenLotsViaUpload());

  const response = yield call(ExchangesAPI.uploadRollforwardOpenDebits, file);
  const { body, error } = response;
  if (error) {
    yield put(updateRollforwardOpenLotsViaUploadError(body.message));
    return;
  }

  yield all([
    put(receiveUpdateRollforwardOpenLotsViaUpload()),
    put(fetchLedgers()),
  ]);
}

export default updateRollforwardViaUpload;
