export const parseCookie = (cookieString) => {
  return cookieString
    .split(";")
    .map((c) => {
      return c.trim().split("=").map(decodeURIComponent);
    })
    .reduce((cookieProperties, [key, value]) => {
      const newCookieProperties = { ...cookieProperties };

      try {
        newCookieProperties[key] = JSON.parse(value);
      } catch (e) {
        newCookieProperties[key] = value;
      }

      return newCookieProperties;
    }, {});
};
