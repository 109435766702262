import { takeEvery, takeLeading } from "redux-saga/effects";

import * as smartWalletActions from "app.reducers/smartWallet";

import disableSmartWallet from "./disableSmartWallet";
import enableSmartWallet from "./enableSmartWallet";
import enterSmartWalletSection from "./enterSmartWalletSection";
import fetchSmartWalletList from "./fetchSmartWalletList";
import pollSmartWalletList from "./pollSmartWalletList";
import fetchSmartWalletSummary from "./fetchSmartWalletSummary";
import leaveSmartWalletSection from "./leaveSmartWalletSection";

const smartWalletSagas = [
  takeEvery(smartWalletActions.enableSmartWallet, enableSmartWallet),
  takeEvery(smartWalletActions.disableSmartWallet, disableSmartWallet),
  takeEvery(smartWalletActions.fetchSmartWalletList, fetchSmartWalletList),
  takeLeading(smartWalletActions.pollSmartWalletList, pollSmartWalletList),

  takeEvery(
    smartWalletActions.enterSmartWalletSection,
    enterSmartWalletSection
  ),
  takeLeading(
    smartWalletActions.fetchSmartWalletSummary,
    fetchSmartWalletSummary
  ),
  takeEvery(
    smartWalletActions.leaveSmartWalletSection,
    leaveSmartWalletSection
  ),
];

export default smartWalletSagas;
