import React from "react";
import { useDispatch } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import LedgerImports from "app.components/Ledger/LedgerImports";

function ModalImportHistory({ ledgerId }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleDismiss = (e) => {
    e.preventDefault();
    dispatch(dismissModal());
  };

  return (
    <>
      <ModalHeader
        title={t("ledgers.importHistory")}
        closeCallback={handleDismiss}
      />

      <div className="m-4">
        <LedgerImports ledgerId={ledgerId} />
      </div>
      <ModalControls>
        <Button
          data-testid="close-modal"
          onClick={handleDismiss}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </>
  );
}

ModalImportHistory.displayName = "ModalImportHistory";

export default ModalImportHistory;
