import React from "react";

const SIZE = {
  sm: "px-2.5 py-1",
  md: "px-4 py-1.5",
};

const TableListCell = (props) => {
  const {
    custom,
    left,
    right,
    center,
    size = "md",
    children,
    height,
    onClick,
    flushDisplay = false,
    lastDisplay = true,
    firstDisplay = true,
    secondDisplay = false,
    ...htmlAttributes
  } = props;

  const clickable = typeof onClick !== "undefined";

  const tdClassName = [
    "c_table-list-cell",
    SIZE[size],
    flushDisplay ? "first:pl-0 last:pr-0" : "",
    firstDisplay ? "first:pr-0" : "",
    lastDisplay ? "last:pl-0" : "",
    secondDisplay ? "pr-0" : "",
    "transition-all",
    left === true ? "text-left" : null,
    center === true ? "text-center" : null,
    right === true ? "text-right" : null,
    custom || "",
    clickable ? "cursor-pointer" : "",
    height || "",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <td
      role="gridcell"
      onClick={onClick}
      className={tdClassName}
      {...htmlAttributes}
    >
      {children}
    </td>
  );
};

export default TableListCell;
