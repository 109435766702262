/* eslint-disable no-param-reassign */
// this rule is disabled in reducers because the redux toolkit
// explicitly supports direct mutation of the state param
// https://redux-toolkit.js.org/usage/immer-reducers

import { createSlice } from "@reduxjs/toolkit";

import { reinitializePortfolio } from "app.reducers/portfolios";
import { STATUS } from "app.constants";

import { getNextStatus } from "app.utils";

import { formatISO } from "date-fns";

const initialState = {
  smartWalletDisableStatus: STATUS.UNINITIALIZED,
  smartWalletEnableStatus: STATUS.UNINITIALIZED,
  smartWalletEnabled: undefined,
  smartWalletRunning: undefined,
  smartWalletViolations: undefined,
  smartWalletList: undefined,
  smartWalletListStatus: STATUS.UNINITIALIZED,
  smartWalletSummary: undefined,
  smartWalletSummaryStatus: STATUS.UNINITIALIZED,
  smartWalletBalance: undefined,
  smartWalletBalanceStatus: STATUS.UNINITIALIZED,
  smartWalletDates: undefined,
  pricesLoading: false,
};

export const smartWalletSlice = createSlice({
  name: "smartWallet",
  initialState,
  reducers: {
    resetSmartWalletSection: (state) => {
      state.smartWalletSummary = undefined;
      state.smartWalletSummaryStatus = STATUS.UNINITIALIZED;
      state.smartWalletBalance = undefined;
      state.smartWalletBalanceStatus = STATUS.UNINITIALIZED;
      state.smartWalletDates = undefined;
    },
    requestSmartWalletRange: (state) => {
      state.smartWalletRangeStatus = getNextStatus(
        state.smartWalletRangeStatus
      );
    },
    receiveSmartWalletRange: (state, action) => {
      state.smartWalletRangeStatus = STATUS.LOADED;
      state.smartWalletDates = {
        earliest: action.payload.earliest,
        latest: action.payload.latest,
        earliestAsISO: formatISO(new Date(action.payload.earliest * 1000), {
          representation: "date",
        }),
        latestAsISO: formatISO(new Date(action.payload.latest * 1000), {
          representation: "date",
        }),
      };
    },
    fetchSmartWalletList: () => {},
    pollSmartWalletList: () => {},
    setSmartRunning: (state) => {
      state.smartWalletRunning = true;
    },
    requestSmartWalletList: (state) => {
      state.smartWalletListStatus = getNextStatus(state.smartWalletListStatus);
    },
    receiveSmartWalletList: (state, action) => {
      state.smartWalletListStatus = STATUS.LOADED;
      state.smartWalletList = action.payload.smartWalletList;
      state.smartWalletViolations = action.payload.smartWalletViolations;
      state.smartWalletEnabled = action.payload.smartWalletEnabled;
      state.smartWalletRunning = action.payload.smartWalletRunning;
    },
    receiveSmartWalletListError: (state, action) => {
      // this is a special case - see the saga
      // because this runs on a timer, it could trigger an error
      // as the user is being logged out - this prevents an error
      // from rendering in that case, potentially blocking the redirect
      state.smartWalletListStatus = action.payload.nextStatus;
    },
    enableSmartWallet: () => {},
    requestSmartWalletEnable: (state) => {
      state.smartWalletEnableStatus = STATUS.LOADING;
    },
    receiveSmartWalletEnable: (state) => {
      state.smartWalletEnableStatus = STATUS.SUCCESS;
    },
    receiveSmartWalletEnableError: (state) => {
      state.smartWalletEnableStatus = STATUS.ERROR;
    },
    disableSmartWallet: () => {},
    requestSmartWalletDisable: (state) => {
      state.smartWalletDisableStatus = STATUS.LOADING;
    },
    receiveSmartWalletDisable: (state) => {
      state.smartWalletEnableStatus = STATUS.UNINITIALIZED;
      state.smartWalletEnabled = false;
      state.smartWalletViolations = undefined;
      state.smartWalletList = [];
      state.smartWalletListStatus = STATUS.LOADED;
      state.smartWalletDisableStatus = STATUS.SUCCESS;
    },
    receiveSmartWalletDisableError: (state) => {
      state.smartWalletDisableStatus = STATUS.ERROR;
    },
    setSmartWalletList: (state, action) => {
      state.smartWalletList = action.payload.smartWalletList;
    },
    fetchSmartWalletSummary: () => {},
    requestSmartWalletSummary: (state) => {
      state.smartWalletSummaryStatus = getNextStatus(
        state.smartWalletSummaryStatus
      );
    },
    receiveSmartWalletSummary: (state, action) => {
      state.smartWalletSummary = action.payload.smartWalletSummary;
      state.smartWalletSummaryStatus = STATUS.LOADED;
    },
    leaveSmartWalletSection: () => {},
    enterSmartWalletSection: () => {},
    cancelPollSmartWalletList: () => {},
    setPricesLoading: (state, action) => {
      state.pricesLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reinitializePortfolio, () => initialState);
  },
});

// all actions
export const { actions } = smartWalletSlice;

// individual actions
export const {
  cancelPollSmartWalletList,
  disableSmartWallet,
  enableSmartWallet,
  enterSmartWalletSection,
  fetchSmartWalletList,
  fetchSmartWalletSummary,
  leaveSmartWalletSection,
  pollSmartWalletList,
  receiveSmartWalletDisable,
  receiveSmartWalletDisableError,
  receiveSmartWalletEnable,
  receiveSmartWalletEnableError,
  receiveSmartWalletList,
  receiveSmartWalletListError,
  receiveSmartWalletRange,
  receiveSmartWalletSummary,
  requestSmartWalletDisable,
  requestSmartWalletEnable,
  requestSmartWalletList,
  requestSmartWalletRange,
  requestSmartWalletSummary,
  resetSmartWalletSection,
  setPricesLoading,
  setSmartRunning,
  setSmartWalletList,
} = smartWalletSlice.actions;

export default smartWalletSlice.reducer;
