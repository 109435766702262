import * as apiKeys from "./apiKeys";
import * as accountingPreferences from "./accountingPreferences";
import * as coworker from "./coworker";
import * as exchangeLedger from "./exchangeLedger";
import * as organization from "./organization";
import * as profiles from "./profiles";

const OrganizationAPI = {
  ...accountingPreferences,
  ...apiKeys,
  ...coworker,
  ...exchangeLedger,
  ...organization,
  ...profiles,
};

export default OrganizationAPI;
