import { isValid, isBefore, isAfter, isSameDay } from "date-fns";

export const validateDate = (date, min, max) => {
  let isDateValid = isValid(date);
  let isDateFuture = false;
  let isDayValid = isDateValid;
  let isDatePast = false;

  if (min && isBefore(date, min)) {
    isDateValid = false;
    isDayValid = false;
    isDatePast = true;
  }

  if (max && isAfter(date, max)) {
    isDateValid = false;
    isDateFuture = true;

    if (isSameDay(date, max)) {
      isDayValid = true;
    }
  }

  return { isDateValid, isDateFuture, isDatePast, isDayValid };
};
