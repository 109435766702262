// when fetching supported exchanges or linked exchanges, we may want to adjust
// some of the fields for various reasons
// this function _SHOULD_ only ever be called when the data is retrieved, as
// it mutates the data _BEFORE_ it is stored in the Redux Store.
import { SHADOW_EXCHANGE, EXCHANGE_IDS, SOURCELESS } from "app.constants";

export const applySourceOverrides = (sourceList) => {
  return sourceList.map((exchange) => {
    const updatedExchange = { ...exchange }; // Create a copy of the exchange object

    if (exchange.name === EXCHANGE_IDS.COINBASE_UPLOAD) {
      updatedExchange.label = "Coinbase";
    }
    if (exchange.name === EXCHANGE_IDS.BITTREX_UPLOAD) {
      updatedExchange.label = "Bittrex";
    }
    if (exchange.name === EXCHANGE_IDS.GEMINI_UPLOAD) {
      updatedExchange.label = "Gemini";
    }
    if (exchange.name === EXCHANGE_IDS.GENERIC) {
      updatedExchange.label = "Custom Upload";
    }
    if (exchange.name === EXCHANGE_IDS.KOINLY) {
      updatedExchange.sourceSelectionLabel = "Migrate from Koinly";
    }
    if (exchange.type === SHADOW_EXCHANGE) {
      updatedExchange.label = "Tracking Ledgers";
    }
    if (exchange.type === SOURCELESS) {
      updatedExchange.label = "Manual Ledgers";
    }

    return updatedExchange; // Return the updated object
  });
};
