import api from "../clients/exchange";
/**
 * Submits a request to edit an exchange ledger
 * @async
 * @function
 * @param {number} userLedgerName - The name to give this ledger
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const editLedger = (userLedgerName, ledgerId) => {
  return api.put(`/ledger/${ledgerId}`, {
    data: { ledgerName: userLedgerName },
    authenticated: true,
  });
};

/**
 * Submits a request to delete an exchange ledger
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const deleteExchangeLedger = (ledgerId) => {
  return api.delete(`/ledger/${ledgerId}`, {
    authenticated: true,
  });
};

export const renameLinkedAccount = (linkedAccountId, nickname) => {
  return api.put(`/linked/${linkedAccountId}`, {
    data: { nickname },
    authenticated: true,
  });
};
