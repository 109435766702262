export const SOURCE_TYPES = {
  WALLET: "WALLET",
  EXCHANGE: "EXCHANGE",
  OTHER: "OTHER",
};

export const SOURCE_GROUPS = {
  ALL: "ALL",
  EXCHANGE: "EXCHANGE",
  WALLET: "WALLET",
  EVM: "EVM",
  OTHER: "OTHER",
};
