import React from "react";
import SvgLoader from "app.components/Util/SvgLoader";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import { PAGINATION_TYPES } from "./constants";

function NextButton({ location, currentPage, numPages, onSelect, type }) {
  const { t } = useTranslation();

  const linkBody = (
    <>
      <SvgLoader id="Chevron" className="h-4 w-4 stroke-black" />
      <span className="sr-only">{t("common.nextPage")}</span>
    </>
  );

  if (currentPage < numPages - 1) {
    const nextPage = currentPage + 1;

    if (type === PAGINATION_TYPES.BUTTON) {
      return (
        <Button
          onClick={(e) => {
            e.preventDefault();
            onSelect(nextPage);
          }}
          buttonType="pagination"
        >
          {linkBody}
        </Button>
      );
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.set("p", nextPage);
    const toURL = `${location.pathname}?${queryParams.toString()}`;

    return (
      <Button asAnchor to={toURL} buttonType="pagination">
        {linkBody}
      </Button>
    );
  }

  return (
    <Button disabled buttonType="pagination">
      {linkBody}
    </Button>
  );
}

NextButton.displayName = "NextButton";
export default NextButton;
