import React from "react";

export const LOGO_URL =
  "https://assets.node40.com/images/common/n40logo-white-green-word.svg";

const Header = () => (
  <div className="flex w-full justify-center">
    <img src={LOGO_URL} alt="NODE40" className="aspect-square w-28 pb-6" />
  </div>
);

export default Header;
