import api from "../clients/exchange";
/**
 * Submits a request to get the org level accounting prefs
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getAccountingPreferences = () => {
  return api.get(`/preference/profile`, {
    authenticated: true,
  });
};

/**
 * Submits a request to set the org level accounting prefs
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const setAccountingPreferences = (body) => {
  return api.post(`/preference/profile`, {
    data: body,
    authenticated: true,
  });
};
