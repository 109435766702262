import { call, put } from "redux-saga/effects";
import { getSortBy } from "app.utils";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestExchangeWorksheet,
  exchangeWorksheetError,
  receiveExchangeWorksheet,
} from "app.actions/exchangeLedger";

function* fetchExchangeWorksheet(action) {
  const { ledgerId, transactionId, entryId } = action;

  if (typeof transactionId === "undefined") return;

  yield put(requestExchangeWorksheet(entryId));

  const response = yield call(
    ExchangeLedgerAPI.getExchangeWorksheet,
    ledgerId,
    transactionId
  );

  if (response.error) {
    yield put(exchangeWorksheetError(entryId, response.body, response.error));
  } else {
    // ensure applied order is respected on worksheets
    const sortByAppliedOrder = getSortBy((x) => x.appliedOrder, true);
    response.body.sort(sortByAppliedOrder);
    yield put(receiveExchangeWorksheet(entryId, response.body));
  }
}

export default fetchExchangeWorksheet;
