import api from "../clients/teller";
import { filterEmptyParams } from "../core/util";

/**
 * Submits a request to create a profile;
 * @async
 * @function
 * @param {string} username - The user's username
 * @param {string} password - The users' password
 * @param {string} passwordConfirm - The user's new password confirmation
 * @param {string} app - The app the user is coming from
 * @returns {object}  response - The Response or Error message
 */
export const sendProfileCreate = (
  username,
  password,
  passwordConfirm,
  acceptTos
) => {
  const body = {
    username,
    email: username,
    passwordData: {
      newPassword: password,
      confirmNewPassword: passwordConfirm,
    },
    acceptTos,
  };

  return api.post("/account", {
    authenticated: false,
    data: body,
  });
};

export const setPaymentInfo = (token) => {
  return api.put("/payment", { authenticated: true, data: token });
};

export const deleteBillingInfo = () => {
  return api.delete("/payment", { authenticated: true });
};

export const checkPromoCode = (code) => {
  return api.get(`/promo/check/${code}`, { authenticated: false });
};

export const getProductQuote = (planId, couponId, zipCode, profileLimit) => {
  return api.post("/payment/product", {
    data: {
      planId,
      couponId,
      zipCode,
      profileLimit,
    },
    authenticated: true,
  });
};

export const getPaymentProductsForUser = (
  couponCode,
  zipCode,
  profileLimit
) => {
  return api.get("/payment/products", {
    authenticated: true,
    params: new URLSearchParams(
      filterEmptyParams({ couponCode, zipCode, profileLimit })
    ),
  });
};

export const getPaymentProductsForEnrollment = (
  couponCode,
  zipCode,
  profileLimit
) => {
  return api.get("/promo/products", {
    params: new URLSearchParams(
      filterEmptyParams({ couponCode, zipCode, profileLimit })
    ),
    authenticated: false,
  });
};

export const subscribe = (
  email,
  newPassword,
  confirmNewPassword,
  acceptTos,
  planId,
  token,
  zipCode,
  couponCode,
  profileLimit,
  organizationName
) => {
  const body = {
    username: email,
    email,
    acceptTos,
    passwordData: {
      newPassword,
      confirmNewPassword,
    },
    subscriptionData: planId
      ? {
          planId,
          token,
          zipCode,
          profileLimit,
          organizationName,
          couponId: couponCode,
        }
      : undefined,
  };

  return api.post("/account/subscribe", {
    data: body,
    credentials: "include",
    authenticated: false,
  });
};
