import React from "react";

import LedgerRangePlaceholder from "app.components/Placeholder/LedgerRangePlaceholder";
import LedgerHeaderPlaceholder from "app.components/Placeholder/LedgerHeaderPlaceholder";
import LedgerSummaryDataPlaceholder from "app.components/Placeholder/LedgerSummaryDataPlaceholder";
import LedgerDataPlaceholder from "app.components/Placeholder/LedgerDataPlaceholder";

const WalletPlaceholderRouter = () => {
  return (
    <div className="c_ledger_summary_placeholder">
      <LedgerHeaderPlaceholder />
      <div className="m-4 rounded bg-white p-4 shadow">
        <LedgerRangePlaceholder />
        <LedgerSummaryDataPlaceholder />
        <LedgerDataPlaceholder />
      </div>
    </div>
  );
};

WalletPlaceholderRouter.displayName = "WalletPlaceholderRouter";

export default WalletPlaceholderRouter;
