import api from "app.api/clients/exchange";

/**
 * Submits a request to manually create a transaction to a ledger
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {object} body - The body of the transaction
 * @returns {object}  response - The Response or Error message
 */
export const manualTransactionCreate = (ledgerId, data) => {
  return api.post(`/ledger/${ledgerId}/manualTransactionCreate`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to delete a manually created transaction
 *
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const manualTransactionDelete = (transactionId) => {
  return api.post(`/ledger/item/${transactionId}/manualTransactionDelete`, {
    authenticated: true,
  });
};

/**
 * Submits a request to delete a manually created transaction
 *
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const deleteSourcelessLedgerTransaction = (transactionId, ledgerId) => {
  return api.delete(`/ledger/sourceless/item/`, {
    authenticated: true,
    data: {
      ledgerId,
      ledgerItemIds: [transactionId],
    },
  });
};
