import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* connect exchange api */
/** *************************************************************************** */
export const UNLINK_EXCHANGE_BEGIN = "UNLINK_EXCHANGE_BEGIN";
export const UNLINK_EXCHANGE_RECEIVE = "UNLINK_EXCHANGE_RECEIVE";
export const UNLINK_EXCHANGE_REQUEST = "UNLINK_EXCHANGE_REQUEST";
export const UNLINK_EXCHANGE_ERROR = "UNLINK_EXCHANGE_ERROR";

export const unlinkExchange = makeActionCreator(
  UNLINK_EXCHANGE_BEGIN,
  "exchangeId",
  "linkGUID"
);
export const requestUnlinkExchange = makeActionCreator(UNLINK_EXCHANGE_REQUEST);
export const receiveUnlinkExchange = makeActionCreator(
  UNLINK_EXCHANGE_RECEIVE,
  "response"
);
export const unlinkExchangeError = makeActionCreator(
  UNLINK_EXCHANGE_ERROR,
  "response"
);
