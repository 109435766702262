import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* connect exchange api */
/** *************************************************************************** */
export const API_KEY_VAULTS_BEGIN = "API_KEY_VAULTS_BEGIN";
export const API_KEY_VAULTS_RECEIVE = "API_KEY_VAULTS_RECEIVE";
export const API_KEY_VAULTS_REQUEST = "API_KEY_VAULTS_REQUEST";
export const API_KEY_VAULTS_ERROR = "API_KEY_VAULTS_ERROR";

export const fetchApiKeyVaults = makeActionCreator(
  API_KEY_VAULTS_BEGIN,
  "exchange",
  "apiKey",
  "secretKey"
);

export const requestApiKeyVaults = makeActionCreator(API_KEY_VAULTS_REQUEST);
export const receiveApiKeyVaults = makeActionCreator(
  API_KEY_VAULTS_RECEIVE,
  "response",
  "apiKey",
  "secretKey"
);
export const receiveApiKeyVaultsError = makeActionCreator(
  API_KEY_VAULTS_ERROR,
  "response"
);
