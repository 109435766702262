import api from "../clients/exchange";
/**
 * Submits a request to get a list of exchange accounts for a user
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {String} linkGUID - The identifier of the specific echange link
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeAccounts = (exchangeId, linkGUID) => {
  return api.get(`/${exchangeId}/account/${linkGUID}`, {
    authenticated: true,
  });
};

/**
 * Submits a request to verify exchange link for a user
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {String} linkGUID - The identifier of the specific echange link
 * @returns {object}  response - The Response or Error message
 */
export const verifyExchangeLink = (exchangeId, linkGUID) => {
  return api.get(`/${exchangeId}/account/check/${linkGUID}`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get unlink an exchanges
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {String} linkGUID - The identifier of the specific echange link
 * @returns {object}  response - The Response or Error message
 */
export const unlinkExchange = (exchangeId, linkGUID) => {
  return api.put(`/unlink/${exchangeId}/${linkGUID}`, {
    authenticated: true,
  });
};
