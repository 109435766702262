import Big from "big.js";
import { call, all, select, put } from "redux-saga/effects";
import {
  LEDGER_TYPES,
  SOURCE_TYPES,
  CUSTODIAL_WALLETS,
  SHADOW_EXCHANGE,
  SOURCELESS,
} from "app.constants";
import { getSortBy, makeTokenHash } from "app.utils";

import {
  setLedgersCreating,
  setReadyLedgersCount,
  setReadyLedgers,
  setLedgersUpdating,
  setSourcesImporting,
  setLedgersWithNegativeBalance,
  setLedgersByFavorite,
} from "app.actions/computables";
import { fetchLinkedExchanges } from "app.actions/exchanges";
import computeLedgersByCurrency from "./computeLedgersByCurrency";
import computeLedgersBySource from "./computeLedgersBySource";

const WHITESPACE_MATCH = /[ ]+/g;

const SORT_BY_NAME = getSortBy(
  (x) => x.name.replace(WHITESPACE_MATCH, " ").toLowerCase(),
  true
);

function* runLedgerComputations() {
  const exchangeLedgers =
    (yield select((state) => state.exchanges.ledgers)) || [];

  const supportedExchanges = yield select(
    (state) => state.exchanges.supportedExchanges || []
  );
  // get all exchanges for grouping
  const supportedExchangesDict = supportedExchanges.reduce(
    (allEx, curr) => ({ ...allEx, [curr.id]: curr.name }),
    {}
  );

  const supportedWalletExchanges = (yield select(
    (state) => state.exchanges.supportedExchanges || []
  ))
    .filter((curr) => curr.type === SOURCE_TYPES.WALLET)
    .reduce(
      (allWallets, curr) => ({ ...allWallets, [curr.id]: curr.name }),
      {}
    );

  // .filter((led) => led.linked !== null)
  const mappedExchangeLedgers = exchangeLedgers.map((x) => {
    // if a ledger is an NFT Ledger ->

    const curExchange = supportedExchanges.find((ex) => x.exchangeId === ex.id);
    const valuation = x.nft ? 0 : x.currentPrice;
    const priceLoading = x.currentPrice === null;
    const balanceAmount = new Big(x.balanceAmount || 0);
    const balanceValue = balanceAmount.times(valuation || 0);
    const ledger = {
      ...x,
      balanceAmount: balanceAmount.toJSON(),
      balanceValue: balanceValue.toJSON(),
      priceLoading,
    };
    const tokenHash = makeTokenHash(
      x.digitalCurrency.toUpperCase(),
      x.tokenName
    );

    return {
      ledger,
      currency: x.digitalCurrency,
      tokenHash,
      type: LEDGER_TYPES.EXCHANGE,
      linkGUID: x.linked ? x.linked.linkGUID : undefined,
      source: (supportedExchangesDict || {})[x.exchangeId] || "",
      name: x.userLedgerName,
      isEVM: curExchange?.evm || undefined,
      isSmartContractPlatform: curExchange?.smartContractPlatform || undefined,
      isShadow: curExchange?.type === SHADOW_EXCHANGE,
      isSourceless: curExchange?.type === SOURCELESS,
      isDataFaucet: curExchange?.dataFaucetSource || undefined,
      isNFT: x.nft,
      isMultiToken: x.multiToken,
      isWallet:
        typeof supportedWalletExchanges[x.exchangeId] !== "undefined" &&
        CUSTODIAL_WALLETS.findIndex((c) => c === curExchange.name) < 0,
    };
  });

  const sortedFormattedLedgers = [...mappedExchangeLedgers].sort(SORT_BY_NAME);

  // get data from previous call
  const lastLedgersCreating = yield select(
    (state) => state.computables.ledgersCreating || []
  );
  // get data from previous call
  const lastSourcesImporting = yield select(
    (state) => state.computables.sourcesImporting || []
  );

  const ledgersCreating = sortedFormattedLedgers.filter(
    (x) => x.ledger.creationInProgress
  );

  const ledgersUpdating = sortedFormattedLedgers.filter(
    (x) => x.ledger.importInProgress
  );

  const readyLedgers = sortedFormattedLedgers.filter(
    (x) => !x.ledger.creationInProgress
  );

  const ledgersWithNegativeBalance = sortedFormattedLedgers.filter((x) =>
    new Big(x.ledger.balanceAmount || 0).lt(0)
  );

  const favoriteLedgers = sortedFormattedLedgers.filter(
    (x) => x.ledger.userFavorite
  );

  const sourcesImporting = {};

  ledgersCreating.forEach((l) => {
    if (typeof l.linkGUID !== "undefined") {
      sourcesImporting[l.linkGUID] = true;
    } else {
      if (l.isWallet) {
        sourcesImporting[SOURCE_TYPES.WALLET] = true;
      }
      if (l.source === "GENERIC" || l.source === "KOINLY") {
        sourcesImporting[SOURCE_TYPES.OTHER] = true;
      }
    }
  });

  ledgersUpdating.forEach((l) => {
    if (typeof l.linkGUID !== "undefined") {
      sourcesImporting[l.linkGUID] = true;
    } else if (l.isWallet) {
      sourcesImporting[SOURCE_TYPES.WALLET] = true;
    }
  });

  const numSourcesImporting = Object.keys(sourcesImporting).length;
  const numLastSourcesImporting = Object.keys(lastSourcesImporting).length;

  let updateLinkedExchanges = false;
  // sources recently finished
  if (
    numSourcesImporting !== numLastSourcesImporting &&
    numLastSourcesImporting === 0
  ) {
    updateLinkedExchanges = true;
  }
  if (
    lastLedgersCreating.length !== ledgersCreating.length &&
    ledgersCreating.length === 0
  ) {
    updateLinkedExchanges = true;
  }

  yield all(
    [
      call(computeLedgersBySource, readyLedgers),
      call(computeLedgersByCurrency, readyLedgers),
      put(setReadyLedgers(readyLedgers)),
      put(setReadyLedgersCount(readyLedgers.length || 0)),
      put(setLedgersCreating(ledgersCreating)),
      put(setLedgersUpdating(ledgersUpdating)),
      put(setSourcesImporting(sourcesImporting)),
      put(setLedgersWithNegativeBalance(ledgersWithNegativeBalance)),
      put(setLedgersByFavorite(favoriteLedgers)),
      updateLinkedExchanges ? put(fetchLinkedExchanges()) : null,
    ].filter(Boolean)
  );
}

export default runLedgerComputations;
