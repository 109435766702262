import { makeActionCreator } from "app.actions/helpers";

export const UPGRADE_QUOTE_BEGIN = "UPGRADE_QUOTE_BEGIN";
export const UPGRADE_QUOTE_REQUEST = "UPGRADE_QUOTE_REQUEST";
export const UPGRADE_QUOTE_RECEIVE = "UPGRADE_QUOTE_RECEIVE";
export const UPGRADE_QUOTE_ERROR_RECEIVE = "UPGRADE_QUOTE_ERROR_RECEIVE";
export const CLEAR_UPGRADE_QUOTE = "CLEAR_UPGRADE_QUOTE";

export const fetchUpgradeQuote = makeActionCreator(
  UPGRADE_QUOTE_BEGIN,
  "planId",
  "zipCode",
  "profileLimit",
  "couponCode",
  "referrerCode"
);

export const requestUpgradeQuote = makeActionCreator(UPGRADE_QUOTE_REQUEST);
export const receiveUpgradeQuote = makeActionCreator(
  UPGRADE_QUOTE_RECEIVE,
  "upgradeQuote"
);
export const receiveUpgradeQuoteError = makeActionCreator(
  UPGRADE_QUOTE_ERROR_RECEIVE,
  "error"
);

export const clearUpgradeQuote = makeActionCreator(CLEAR_UPGRADE_QUOTE);
