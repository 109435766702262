import { call, put } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestRelatedTransactions,
  relatedTransactionsError,
  receiveRelatedTransactions,
} from "app.actions/exchangeLedger";

import { STATUS } from "app.constants";

function* fetchRelatedTransactions(action) {
  const { itemId } = action;

  yield put(requestRelatedTransactions(itemId, STATUS.LOADING));

  const response = yield call(ExchangeLedgerAPI.getRelatedItems, itemId);

  if (response.error) {
    yield put(relatedTransactionsError(itemId, response.body, response.error));
  } else {
    yield put(receiveRelatedTransactions(itemId, response.body, STATUS.LOADED));
  }
}
export default fetchRelatedTransactions;
