import { makeActionCreator } from "app.actions/helpers";

export const ACCOUNT_TIMEZONE_UPDATE_BEGIN = "ACCOUNT_TIMEZONE_UPDATE_BEGIN";
export const ACCOUNT_TIMEZONE_UPDATE_REQUEST =
  "ACCOUNT_TIMEZONE_UPDATE_REQUEST";
export const ACCOUNT_TIMEZONE_UPDATE_RECEIVE =
  "ACCOUNT_TIMEZONE_UPDATE_RECEIVE";
export const ACCOUNT_TIMEZONE_UPDATE_ERROR = "ACCOUNT_TIMEZONE_UPDATE_ERROR";

export const updateTimeZone = makeActionCreator(
  ACCOUNT_TIMEZONE_UPDATE_BEGIN,
  "timeZone"
);
export const updateTimeZoneRequest = makeActionCreator(
  ACCOUNT_TIMEZONE_UPDATE_REQUEST
);
export const updateTimeZoneReceive = makeActionCreator(
  ACCOUNT_TIMEZONE_UPDATE_RECEIVE
);
export const updateTimeZoneError = makeActionCreator(
  ACCOUNT_TIMEZONE_UPDATE_ERROR,
  "error"
);
