import { makeActionCreator } from "app.actions/helpers";

export const CHECK_REFERRER = "CHECK_REFERRER";
export const SET_REFERRER = "SET_REFERRER";

export const checkReferrer = makeActionCreator(CHECK_REFERRER);
export const setReferrer = makeActionCreator(
  SET_REFERRER,
  "referredBy",
  "plansWithPromo"
);
