/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { updateExchangeApiToken } from "app.actions/exchanges";
import { dismissWalletTokenModal } from "app.reducers/ui";
import {
  APP_NAME,
  STATUS,
  HELP_INFO,
  EXCHANGE_IDS,
  HAS_SUPPORT_LINKS,
} from "app.constants";
import ErrorMessage from "app.components/Util/ErrorMessage";
import HelpInfo from "app.components/Help/HelpInfo";
import SvgLoader from "app.components/Util/SvgLoader";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";

const ModalExchangeWalletTokenPopUp = ({
  exchange,
  linkGUID,
  update,
  code,
  apiTokenOverScope,
  apiTokenRequired,
  apiTokenAccessDenied,
  canDismissCallback,
  walletTokenSavingStatus,
}) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const [token, setToken] = useState("");

  const { apiWalletTokenSavingStatus, apiWalletTokenError } = useSelector(
    (state) => state.exchanges
  );

  useEffect(() => {
    // prevents modal from dismissing when esc is pressed if the user is working
    canDismissCallback(walletTokenSavingStatus !== STATUS.WORKING);
  }, [canDismissCallback, walletTokenSavingStatus]);

  const updateExchangeToken = (ex, link, tok) => {
    // clean API keys of any whitespace
    reduxDispatch(updateExchangeApiToken(ex, link, tok.trim()));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setToken(e.target.value);
  };

  const disableSubmit =
    apiWalletTokenSavingStatus === STATUS.WORKING ||
    typeof exchange === "undefined" ||
    token.trim() === "";

  const label = exchange?.label || "";
  const source = exchange?.name || "";

  // default wording
  let header = update
    ? t("modals.updateYourApiToken", { label })
    : t("modals.connectWithLabel", { label });

  let message = (
    <p className="mb-4 text-lg">
      <Trans i18nKey="modals.enterYourLabelApiTokenForApiViewTransactions">
        <strong />
        {{ label }}
        {{ appName: APP_NAME }}
      </Trans>
      {source ? (
        <HelpInfo
          source={source}
          render={(entry) => {
            if (!entry) return null;

            return (
              <span>
                &nbsp;
                <Trans i18nKey="modals.helpHref">
                  <a
                    className="text-linkBlue text-linkBlueActive hover:underline"
                    href={entry}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    text
                  </a>
                </Trans>
              </span>
            );
          }}
        />
      ) : null}
    </p>
  );

  // situational wording
  if (apiTokenOverScope || code === "apiTokenOverScope") {
    header = t("modals.newApiTokenRequired");
    message = (
      <p className="mb-4 text-lg">
        {t("modals.thePermissionsForYourExistingLabelApiTokenChanged", {
          appName: APP_NAME,
          label,
        })}
        {source ? (
          <HelpInfo
            source={source}
            render={(entry) => {
              if (!entry) return null;

              return (
                <span>
                  &nbsp;
                  <Trans i18nKey="modals.helpHref">
                    <a
                      className="text-linkBlue text-linkBlueActive hover:underline"
                      href={entry}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      text
                    </a>
                  </Trans>
                </span>
              );
            }}
          />
        ) : null}
      </p>
    );
  }

  if (
    apiTokenRequired ||
    code === "apiTokenRequired" ||
    code === "apiTokenInvalid"
  ) {
    header = t("modals.apiTokenRequired");
    message = (
      <p className="mb-4 text-lg">
        {t("modals.yourExistingApiTokenHasExpiredOrBeenRemoved", { label })}
        {source ? (
          <HelpInfo
            source={source}
            render={(entry) => {
              if (!entry) return null;

              return (
                <span>
                  &nbsp;
                  <Trans i18nKey="modals.helpHref">
                    <a
                      className="text-linkBlue text-linkBlueActive hover:underline"
                      href={entry}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      text
                    </a>
                  </Trans>
                </span>
              );
            }}
          />
        ) : null}
      </p>
    );
  }

  if (apiTokenAccessDenied || code === "apiTokenAccessDenied") {
    header = t("modals.apiTokenHasInsufficientPermissions");
    message = (
      <p className="mb-4 text-lg">
        {t("modals.apiTokenHasInsufficientPermissionsMessage", { label })}
        {source ? (
          <HelpInfo
            source={source}
            render={(entry) => {
              if (!entry) return null;

              return (
                <span>
                  &nbsp;
                  <Trans i18nKey="modals.helpHref">
                    <a
                      className="text-linkBlue text-linkBlueActive hover:underline"
                      href={entry}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      text
                    </a>
                  </Trans>
                </span>
              );
            }}
          />
        ) : null}
      </p>
    );
  }

  return (
    <>
      <ModalHeader
        title={header}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissWalletTokenModal());
        }}
      />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (disableSubmit) return;
          updateExchangeToken(exchange, linkGUID, token);
        }}
      >
        <div className="m-4">
          {apiWalletTokenSavingStatus === STATUS.ERROR ? (
            <ErrorMessage {...apiWalletTokenError} />
          ) : null}
          {message}
          {exchange.name === "BITGO_WALLET" && HAS_SUPPORT_LINKS ? (
            <div className="my-4 flex items-center rounded bg-blue-50 p-3">
              <SvgLoader id="CircleInfo" className="h-6 w-6 fill-blue-600" />
              <p className="pl-3 text-xs text-blue-600">
                <Trans i18nKey="addLedger.token.BITGO">
                  <a
                    className="text-linkBlue hover:text-linkBlueActive hover:underline"
                    href={HELP_INFO[EXCHANGE_IDS.BITGO]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    text
                  </a>
                </Trans>
              </p>
            </div>
          ) : null}

          <div className="mb-4 flex flex-col">
            <Label htmlFor="token">{t("common.walletToken")}</Label>
            <Input
              data-testid="token"
              data-tabbable
              type="text"
              id="token"
              name="token"
              autoComplete="off"
              value={token}
              onChange={handleChange}
            />
          </div>
        </div>

        <ModalControls>
          <Button
            type="submit"
            data-tabbable
            buttonType="primary"
            customClasses="ml-4"
            disabled={disableSubmit}
            text={t("button.save")}
          />
          <Button
            buttonType="text"
            data-tabbable
            disabled={apiWalletTokenSavingStatus === STATUS.WORKING}
            onClick={(e) => {
              e.preventDefault();
              reduxDispatch(dismissWalletTokenModal());
            }}
            text={t("button.cancel")}
          />
        </ModalControls>
      </form>
    </>
  );
};

ModalExchangeWalletTokenPopUp.displayName = "ModalExchangeWalletTokenPopUp";

export default ModalExchangeWalletTokenPopUp;
