import React from "react";
import { Route, Routes } from "react-router";

import SvgLoader from "app.components/Util/SvgLoader";
import HeaderLogo from "app.components/Header/HeaderLogo";
import PortfolioTabsPlaceholder from "app.components/Placeholder/PortfolioTabsPlaceholder";

const HeaderPlaceholder = () => (
  <>
    <header
      data-testid="placeholder-header"
      className="c_header_placeholder bg-header-primary relative z-50 flex h-12 justify-between lg:justify-start"
    >
      <HeaderLogo />
      <div className="ml-3.5 hidden flex-1 items-center md:flex" />
      <div className="ml-auto mr-3.5 flex h-12 flex-row items-center justify-end">
        <div className="flex h-8 w-8 items-center justify-center">
          <SvgLoader
            id="CircleQuestion"
            className="fill-header-item-100/60 m-0 h-6 w-6 animate-pulse transition-all"
          />
        </div>
        <div className="flex h-8 w-8 items-center justify-center">
          <SvgLoader
            id="User"
            className="fill-header-item-100/60 m-0 h-6 w-6 animate-pulse transition-all"
          />
        </div>
      </div>
    </header>
    <Routes>
      <Route path="/portfolio">
        <Route index path="*" element={<PortfolioTabsPlaceholder />} />
        <Route index path="new" element={null} />
      </Route>
      <Route path="/organization">
        <Route index path="*" element={<PortfolioTabsPlaceholder />} />
      </Route>
      <Route index path="*" element={<PortfolioTabsPlaceholder />} />
    </Routes>
  </>
);

HeaderPlaceholder.displayName = "HeaderPlaceholder";

export default HeaderPlaceholder;
