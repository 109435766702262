import React from "react";
import { QRCodeSVG } from "qrcode.react";

const MFAQRCode = ({ totpSecret, username }) => {
  if (!totpSecret || !username) return null;

  const qrCodeValue = `otpauth://totp/NODE40:${username}?secret=${totpSecret}&issuer=NODE40`;

  return <QRCodeSVG value={qrCodeValue} size={125} />;
};

MFAQRCode.displayName = "MFAQRCode";

export default MFAQRCode;
