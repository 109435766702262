import { makeActionCreator } from "app.actions/helpers";
// Payable
export const EXCHANGE_CHANGE_PAYABLE_STATUS_BEGIN =
  "EXCHANGE_CHANGE_PAYABLE_STATUS_BEGIN";
export const EXCHANGE_CHANGE_PAYABLE_STATUS_RECEIVE =
  "EXCHANGE_CHANGE_PAYABLE_STATUS_RECEIVE";
export const EXCHANGE_CHANGE_PAYABLE_STATUS_REQUEST =
  "EXCHANGE_CHANGE_PAYABLE_STATUS_REQUEST";

export const changeExchangePayableStatus = makeActionCreator(
  EXCHANGE_CHANGE_PAYABLE_STATUS_BEGIN,
  "transactionId",
  "payable",
  "ledgerId"
);
export const requestExchangeChangePayableStatus = makeActionCreator(
  EXCHANGE_CHANGE_PAYABLE_STATUS_REQUEST,
  "transactionId"
);
export const receiveExchangeChangePayableStatus = makeActionCreator(
  EXCHANGE_CHANGE_PAYABLE_STATUS_RECEIVE,
  "response",
  "transactionId",
  "payable"
);
