import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { changeExchangePayableStatus } from "app.actions/exchangeLedger";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

export const MAKE_TAXABLE = "make-taxable";
export const MAKE_NON_TAXABLE = "make-non-taxable";

function ModalPayable({ entry, ledgerId, mode }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const payableWorking = useSelector(
    (state) => state.exchangeLedger.payableWorking
  );

  const handleDismiss = (e) => {
    e.preventDefault();
    dispatch(dismissModal());
  };

  if (mode === MAKE_TAXABLE) {
    return (
      <>
        <ModalHeader
          title={t("modals.payableTransaction.markTransactionAsTaxableEvent")}
          closeCallback={handleDismiss}
        />

        <div className="m-4">
          <p className="text-lg">
            {t("modals.payableTransaction.transactionTaxableDescription")}
          </p>
        </div>
        <ModalControls>
          <Button
            type="submit"
            disabled={payableWorking}
            buttonType="primary"
            customClasses="ml-4"
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                changeExchangePayableStatus(entry.transactionId, true, ledgerId)
              );
            }}
            text={t("ledgers.transactionTaxOptions.markAsTaxable")}
          />
          <Button
            onClick={handleDismiss}
            disabled={payableWorking}
            buttonType="text"
            text={t("common.close")}
          />
        </ModalControls>
      </>
    );
  }
  return (
    <>
      <ModalHeader
        title={t("modals.payableTransaction.unmarkTransactionAsTaxableEvent")}
        closeCallback={handleDismiss}
      />
      <div className="m-4">
        <p className="text-lg">
          {t("modals.payableTransaction.transactionTransferDescription")}
        </p>
      </div>
      <ModalControls>
        <Button
          type="submit"
          disabled={payableWorking}
          buttonType="primary"
          customClasses="ml-4"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              changeExchangePayableStatus(entry.transactionId, false, ledgerId)
            );
          }}
          text={t("ledgers.transactionTaxOptions.markAsTransfer")}
        />
        <Button
          onClick={handleDismiss}
          disabled={payableWorking}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </>
  );
}

ModalPayable.displayName = "ModalPayable";

export default ModalPayable;
