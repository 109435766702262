import { makeActionCreator } from "app.actions/helpers";

// ENTRY DESCRIPTION
export const EDIT_EXCHANGE_ENTRY_DESCR_BEGIN =
  "EDIT_EXCHANGE_ENTRY_DESCR_BEGIN";
export const EDIT_EXCHANGE_ENTRY_DESCR_REQUEST =
  "EDIT_EXCHANGE_ENTRY_DESCR_REQUEST";
export const EDIT_EXCHANGE_ENTRY_DESCR_RECEIVE =
  "EDIT_EXCHANGE_ENTRY_DESCR_RECEIVE";
export const EDIT_EXCHANGE_ENTRY_DESCR_ERROR =
  "EDIT_EXCHANGE_ENTRY_DESCR_ERROR";

// ENTRY DESCRIPTION
export const editEntryDescription = makeActionCreator(
  EDIT_EXCHANGE_ENTRY_DESCR_BEGIN,
  "transactionId",
  "ledgerId",
  "description"
);

export const requestEditEntryDescription = makeActionCreator(
  EDIT_EXCHANGE_ENTRY_DESCR_REQUEST,
  "transactionId",
  "nextStatus"
);

export const receiveEditEntryDescription = makeActionCreator(
  EDIT_EXCHANGE_ENTRY_DESCR_RECEIVE,
  "transactionId",
  "description",
  "nextStatus"
);

export const editEntryDescriptionError = makeActionCreator(
  EDIT_EXCHANGE_ENTRY_DESCR_ERROR,
  "response",
  "error",
  "ledgerId"
);
