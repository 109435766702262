import { makeActionCreator } from "app.actions/helpers";

// 1. Account Information
export const EMAIL_SET = "EMAIL_SET";
export const PASSWORD_SET = "PASSWORD_SET";
export const CONFIRM_PASSWORD_SET = "CONFIRM_PASSWORD_SET";
export const TOS_SET = "TOS_SET";
export const ACCOUNT_ERROR_SET = "ACCOUNT_ERROR_SET";
export const ACCOUNT_TOS_ERROR = "ACCOUNT_TOS_ERROR";
export const ACCOUNT_EMAIL_ERROR = "ACCOUNT_EMAIL_ERROR";

export const setEmail = makeActionCreator(EMAIL_SET, "email");
export const setPassword = makeActionCreator(PASSWORD_SET, "password");
export const setConfirmPassword = makeActionCreator(
  CONFIRM_PASSWORD_SET,
  "confirmPassword"
);
export const tosSet = makeActionCreator(TOS_SET, "acceptTOS");
export const setAccountError = makeActionCreator(
  ACCOUNT_ERROR_SET,
  "accountError"
);

export const setAccountEmailError = makeActionCreator(
  ACCOUNT_EMAIL_ERROR,
  "emailError"
);

// 2. Payment Information
export const CARDHOLDER_NAME_SET = "CARDHOLDER_NAME_SET";
export const CARD_VALIDITY_SET = "CARD_VALIDITY_SET";
export const COUPON_SET = "COUPON_SET";
export const COUPON_APPLY = "COUPON_APPLY";
export const COUPON_CLEAR = "COUPON_CLEAR";
export const BILLING_ERROR_SET = "BILLING_ERROR_SET";
export const USE_CURRENT_BILLING_INFO_SET = "USE_CURRENT_BILLING_INFO_SET";

export const setCardholderName = makeActionCreator(
  CARDHOLDER_NAME_SET,
  "cardholderName"
);
export const setCardValidity = makeActionCreator(
  CARD_VALIDITY_SET,
  "cardValid"
);

export const setCoupon = makeActionCreator(COUPON_SET, "coupon");
export const applyCoupon = makeActionCreator(COUPON_APPLY, "coupon");
export const clearCoupon = makeActionCreator(COUPON_CLEAR);
export const setUseCurrentBillingInfo = makeActionCreator(
  USE_CURRENT_BILLING_INFO_SET,
  "useCurrentInfo"
);

export const setBillingError = makeActionCreator(
  BILLING_ERROR_SET,
  "billingError"
);

// 3. Location Information
export const ZIP_SET = "ZIP_SET";
export const ZIP_VALIDATE = "ZIP_VALIDATE";

export const setZipCode = makeActionCreator(ZIP_SET, "zipCode");
export const validateZipCode = makeActionCreator(ZIP_VALIDATE);

// 4. Organization Information
export const ORGANIZATION_NAME_SET = "ORGANIZATION_NAME_SET";
export const ORGANIZATION_PROFILE_LIMIT_SET = "ORGANIZATION_PROFILE_LIMIT_SET";
export const ORGANIZATION_ERROR_SET = "ORGANIZATION_ERROR_SET";

export const setOrganizationName = makeActionCreator(
  ORGANIZATION_NAME_SET,
  "organizationName"
);

export const setProfileLimit = makeActionCreator(
  ORGANIZATION_PROFILE_LIMIT_SET,
  "profileLimit"
);

export const setOrganizationError = makeActionCreator(
  ORGANIZATION_ERROR_SET,
  "organizationError"
);
