import { call, put, all } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  receiveReactivateSubscription,
  enterSubscriptionSection,
} from "app.actions/account";
import { dismissModal } from "app.reducers/ui";

function* reactivateSubscription(action) {
  const { subscriptionId } = action;

  const response = yield call(
    TellerAPI.reactivateCustomerSubscription,
    subscriptionId
  );
  if (response.error) {
    console.error(response.error);
  } else {
    yield all([
      put(dismissModal()),
      put(receiveReactivateSubscription()),
      put(enterSubscriptionSection()),
    ]);
  }
}

export default reactivateSubscription;
