import React from "react";
import TableListCell from "app.components/TableList/TableListCell";
import { useGetUserTimeZone } from "app.hooks";
import { formatInTimeZone } from "date-fns-tz";

const ImportDate = ({ importDate }) => {
  const userTimeZone = useGetUserTimeZone();

  if (!importDate) {
    return <TableListCell />;
  }

  return (
    <TableListCell firstDisplay={false}>
      <span className="text-sm">
        {formatInTimeZone(importDate * 1000, userTimeZone, "MMM d, yyyy")}
      </span>{" "}
      <span className="text-xs opacity-70">
        {formatInTimeZone(importDate * 1000, userTimeZone, "p")}
      </span>
    </TableListCell>
  );
};

ImportDate.displayName = "ImportDate";

export default ImportDate;
