import { makeActionCreator } from "app.actions/helpers";

/** *************************************************************************** */
/* bulk delete exchange ledgers */
/** *************************************************************************** */
export const BULK_DELETE_EXCHANGE_LEDGERS_BEGIN =
  "BULK_DELETE_EXCHANGE_LEDGERS_BEGIN";
export const BULK_DELETE_EXCHANGE_LEDGERS_REQUEST =
  "BULK_DELETE_EXCHANGE_LEDGERS_REQUEST";
export const BULK_DELETE_EXCHANGE_LEDGERS_RECEIVE =
  "BULK_DELETE_EXCHANGE_LEDGERS_RECEIVE";
export const BULK_DELETE_EXCHANGE_LEDGERS_ERROR =
  "BULK_DELETE_EXCHANGE_LEDGERS_ERROR";

export const bulkDeleteExchangeLedgers = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_BEGIN,
  "exchangeId",
  "linkGUID"
);

export const requestbulkDeleteExchangeLedgers = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_REQUEST,
  "nextStatus"
);

export const receiveBulkDeleteExchangeLedgers = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_RECEIVE,
  "nextStatus",
  "ledgerIds"
);

export const bulkDeleteExchangeLedgersError = makeActionCreator(
  BULK_DELETE_EXCHANGE_LEDGERS_ERROR,
  "nextStatus"
);
