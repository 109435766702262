import React from "react";
import { useTranslation } from "react-i18next";
import TableListCell from "app.components/TableList/TableListCell";

const ImportListCount = ({
  importDate,
  status,
  statusAddresses,
  records,
  pending = true,
}) => {
  const { t } = useTranslation();

  if (!importDate) {
    return <td aria-label="empty cell" />;
  }

  if (status === "AWAITING_ADDRESSES") {
    return <td aria-label="empty cell" />;
  }

  if (pending === false) {
    let content;
    if (records > 0) {
      content = (
        <p className="text-sm">
          {t("ledgers.importList.importedRecords", { count: records })}
        </p>
      );
    } else if (status === "EMPTY") {
      content = (
        <p className="text-sm">
          {t("ledgers.importList.importContainedNoRecords")}
        </p>
      );
    } else {
      content = (
        <p className="text-sm">{t("ledgers.importList.noNewRecords")}</p>
      );
    }
    return <TableListCell lastDisplay={false}>{content}</TableListCell>;
  }

  if (status === "COMPLETE") {
    return (
      <TableListCell lastDisplay={false}>
        <p className="text-sm">
          {t("ledgers.importList.importingNewRecords", { count: records })}
        </p>
      </TableListCell>
    );
  }
  if (
    status !== "PROCESSING" &&
    status !== "QUEUED" &&
    statusAddresses !== "PROCESSING_ADDRESSES"
  ) {
    return (
      <TableListCell lastDisplay={false}>
        <p className="text-sm">
          {t("ledgers.importList.willImportRecords", { count: records })}
        </p>
      </TableListCell>
    );
  }

  return <td aria-label="empty cell" />;
};

ImportListCount.displayName = "ImportListCount";

export default ImportListCount;
