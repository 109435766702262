import { call, put, all, select } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestRevertEntryValue,
  revertEntryValueError,
  receiveRevertEntryValue,
  fetchExchangeLedger,
  resetWorksheetCache,
  fetchTimeline,
} from "app.actions/exchangeLedger";

import { STATUS } from "app.constants";

function* revertEntryValue(action) {
  const { itemId, ledgerId, isTimeline = false } = action;

  const lastRequestParams = yield select(
    (state) => state.exchangeLedger.lastRequestParams
  );

  yield put(requestRevertEntryValue(itemId, STATUS.WORKING));

  const response = yield call(
    ExchangeLedgerAPI.revertValueOverride,
    ledgerId,
    itemId,
    [{ key: "revertValueOverride", value: true }]
  );

  if (response.error) {
    yield put(revertEntryValueError(response.body, response.error, itemId));
  } else {
    const itemsFetch = isTimeline
      ? fetchTimeline(...lastRequestParams, true)
      : fetchExchangeLedger(...lastRequestParams, true);

    yield all([
      put(receiveRevertEntryValue(itemId, STATUS.SUCCESS)),
      put(itemsFetch),
      put(resetWorksheetCache()),
    ]);
  }
}

export default revertEntryValue;
