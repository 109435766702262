import * as address from "./address";
import * as customFields from "./customFields";
import * as imports from "./imports";
import * as ledger from "./ledger";
import * as manualTransaction from "./manualTransaction";
import * as manualTransferLink from "./manualTransferLink";
import * as tags from "./tags";
import * as timeline from "./timeline";
import * as transaction from "./transaction";

const ExchangeLedgerAPI = {
  ...address,
  ...customFields,
  ...imports,
  ...ledger,
  ...manualTransaction,
  ...manualTransferLink,
  ...tags,
  ...timeline,
  ...transaction,
};

export default ExchangeLedgerAPI;
