import { BALANCE_SERVICE_ID, SSO_BASE } from "app.constants";

function goToLogin(action) {
  const { redirectTo } = action;

  const params = new URLSearchParams({ service: BALANCE_SERVICE_ID });
  if (redirectTo) {
    params.set("redirect", redirectTo);
  }

  // Router will just use empty strings for
  const redirectUrl = `${SSO_BASE}/login?${params.toString()}`;
  window.location = redirectUrl;
}

export default goToLogin;
