import api from "../clients/exchange";

/**
 * Submits a request to get the current users profile level settings
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getSettings = () => {
  return api.get(`/profile/settings`, { authenticated: true });
};
