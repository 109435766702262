import { makeActionCreator } from "app.actions/helpers";
// EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET
export const EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_BEGIN =
  "EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_BEGIN";
export const EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_REQUEST =
  "EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_REQUEST";
export const EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_RECEIVE =
  "EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_RECEIVE";
export const EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_ERROR =
  "EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_ERROR";

export const NO_EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET =
  "NO_EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET";

export const fetchExchangeMultiTokenWorksheet = makeActionCreator(
  EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_BEGIN,
  "ledgerId",
  "transactionId",
  "entryId"
);

export const requestExchangeMultiTokenWorksheet = makeActionCreator(
  EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_REQUEST,
  "entryId"
);
export const receiveExchangeMultiTokenWorksheet = makeActionCreator(
  EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_RECEIVE,
  "entryId",
  "response"
);
export const exchangeMultiTokenWorksheetError = makeActionCreator(
  EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET_ERROR,
  "entryId",
  "response",
  "error"
);

export const noMultiTokenWorksheetToLoad = makeActionCreator(
  NO_EXCHANGE_LEDGER_MULTITOKEN_WORKSHEET,
  "entryId"
);
