import { makeActionCreator } from "app.actions/helpers";
// EXCHANGE_LEDGER_WORKSHEET
export const EXCHANGE_LEDGER_WORKSHEET_BEGIN =
  "EXCHANGE_LEDGER_WORKSHEET_BEGIN";
export const EXCHANGE_LEDGER_WORKSHEET_REQUEST =
  "EXCHANGE_LEDGER_WORKSHEET_REQUEST";
export const EXCHANGE_LEDGER_WORKSHEET_RECEIVE =
  "EXCHANGE_LEDGER_WORKSHEET_RECEIVE";
export const EXCHANGE_LEDGER_WORKSHEET_ERROR =
  "EXCHANGE_LEDGER_WORKSHEET_ERROR";

export const RESET_WORKSHEET_CACHE = "RESET_WORKSHEET_CACHE";

export const NO_EXCHANGE_LEDGER_WORKSHEET = "NO_EXCHANGE_LEDGER_WORKSHEET";

export const fetchExchangeWorksheet = makeActionCreator(
  EXCHANGE_LEDGER_WORKSHEET_BEGIN,
  "ledgerId",
  "transactionId",
  "entryId"
);

export const requestExchangeWorksheet = makeActionCreator(
  EXCHANGE_LEDGER_WORKSHEET_REQUEST,
  "entryId"
);
export const receiveExchangeWorksheet = makeActionCreator(
  EXCHANGE_LEDGER_WORKSHEET_RECEIVE,
  "entryId",
  "response"
);
export const exchangeWorksheetError = makeActionCreator(
  EXCHANGE_LEDGER_WORKSHEET_ERROR,
  "entryId",
  "response",
  "error"
);

export const noWorksheetToLoad = makeActionCreator(
  NO_EXCHANGE_LEDGER_WORKSHEET,
  "entryId"
);

export const resetWorksheetCache = makeActionCreator(RESET_WORKSHEET_CACHE);
