import { put, select } from "redux-saga/effects";
import {
  setUiStateSessionStorageKey,
  setUiStateFromSessionStorage,
  setLogosFromSessionStorage,
} from "app.reducers/ui";
import { testSessionStorage } from "app.utils/lib/testSessionStorage";
import {
  SESSION_STORAGE_KEY_UI,
  SESSION_STORAGE_MISSING_LOGOS,
} from "app.constants";

function* getBasicSessionStorageConfig() {
  const user = yield select((state) => state.user);
  const userGUID = user.user?.userGUID || undefined;
  const uiStateSessionKey = `${SESSION_STORAGE_KEY_UI}-${userGUID}`;
  yield put(setUiStateSessionStorageKey(uiStateSessionKey));

  if (userGUID && testSessionStorage()) {
    try {
      const savedUi = sessionStorage.getItem(uiStateSessionKey);
      const savedUiState = savedUi ? JSON.parse(savedUi) : {};

      const savedLogo = sessionStorage.getItem(SESSION_STORAGE_MISSING_LOGOS);
      const savedLogoState = savedLogo ? JSON.parse(savedLogo) : [];

      if (Object.keys(savedUiState).length) {
        yield put(setUiStateFromSessionStorage({ state: savedUiState }));
      }
      if (savedLogoState?.failedCoinLogocs) {
        yield put(setLogosFromSessionStorage(...savedLogoState));
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export default getBasicSessionStorageConfig;
