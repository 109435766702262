import { call, put, all, delay, select } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import i18n from "i18next";
import { generateGUID } from "app.utils";
import { STATUS_WARNING } from "app.components/Messages/FlashMessage";
import {
  requestUpdateLedgerCustomData,
  receiveUpdateLedgerCustomData,
  updateLedgerCustomDataError,
} from "app.actions/exchangeLedger";
import { addFlashMessage, removeFlashMessage } from "app.reducers/ui";
import { WALLET_GENERIC_ERROR, ONE_MINUTE, APP_NAME } from "app.constants";

function* updateLedgerCustomData(action) {
  const { ledgerId, itemId, customData, updatingField } = action;

  const isUpdatingFields = yield select(
    (state) => state.exchangeLedger.isUpdatingFields[itemId]
  );
  if (isUpdatingFields) return;
  yield put(requestUpdateLedgerCustomData(itemId, updatingField));
  const response = yield call(
    ExchangeLedgerAPI.addLedgerCustomData,
    ledgerId,
    itemId,
    customData
  );
  const { error, body } = response;
  if (error) {
    let errorResponse =
      body?.message || i18n.t(WALLET_GENERIC_ERROR, { appName: APP_NAME });
    if (body?.type === 500) {
      errorResponse = i18n.t(WALLET_GENERIC_ERROR, { appName: APP_NAME });
    }
    const messageId = generateGUID();
    yield all([
      put(updateLedgerCustomDataError(itemId)),
      put(addFlashMessage(errorResponse, messageId, STATUS_WARNING)),
    ]);
    yield delay(ONE_MINUTE);
    yield put(removeFlashMessage(messageId));
  } else {
    const { data } = yield select((state) => state.exchangeLedger);

    const newItems = data?.items || [];

    const updatedExchangeLedgerList = newItems.map((d) =>
      d.id === itemId ? { ...d, customData } : d
    );

    yield put(receiveUpdateLedgerCustomData(itemId, updatedExchangeLedgerList));
  }
}

export default updateLedgerCustomData;
