import React, { useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFailedCoinLogo } from "app.reducers/ui";
import {
  ASSETS_BASE,
  GENERIC_ICON_PATH,
  COIN_ICONS_COLOR_PATH,
  COIN_NFT_PATH,
  NFT,
} from "app.constants";

const ICON_BASE_PATH = [ASSETS_BASE, COIN_ICONS_COLOR_PATH].join("");
const NFT_ICON_PATH = [ASSETS_BASE, COIN_NFT_PATH].join("");

const ChainLogo = memo(
  ({
    coin = "",
    xsmall = false,
    small = false,
    medium = false,
    large = false,
    xlarge = false,
    isNFT = false,
    customSize,
    customClassName = "",
    embed = false,
  }) => {
    const reduxDispatch = useDispatch();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    if (typeof coin === "undefined" || coin === null || coin === "") {
      throw new Error("Coin not provided.");
    }

    const failedCoinLogos = useSelector((state) => state.ui.failedCoinLogos);
    const isCoinAlreadyRequested = failedCoinLogos.find(
      (logo) => logo === coin
    );

    // let's test the coin name for gross characters and skip a look up
    // acceptable characters for an icon are
    // A-Za-z0-9 and $
    // if we ever add an icon that uses an additional character, we can
    // revisit this
    const coinHasBadChars = coin.match(/[^0-9A-Za-z$]/);

    let coinSrc;
    const className = [
      "c_chain-logo text-transparent transition-opacity",
      isLoaded ? null : "bg-black opacity-50 rounded-full",
      !isNFT &&
      (isError ||
        isCoinAlreadyRequested ||
        (coinHasBadChars && coinHasBadChars.length > 0))
        ? "opacity-50"
        : null,
    ];

    // if the coin is an NFT, we can skip the lookup
    if (isNFT) {
      coinSrc = NFT_ICON_PATH;
    } else if (isError || coinHasBadChars) {
      coinSrc = GENERIC_ICON_PATH;
    } else {
      coinSrc = encodeURI(`${ICON_BASE_PATH}${coin.toLowerCase()}.svg`);
    }

    function handleLoaded() {
      setIsLoaded(true);
    }

    // error handler to add a fallback image when a coin icon is not found
    function handleError() {
      reduxDispatch(setFailedCoinLogo(coin));
      setIsError(true);
    }

    switch (true) {
      case xsmall:
        className.push("w-4 h-4");
        break;
      case small:
        className.push("w-5 h-5");
        break;
      case medium:
        className.push("w-7 h-7");
        break;
      case large:
        className.push("w-10 h-10");
        break;
      case xlarge:
        className.push("w-15 h-15");
        break;
      case typeof customSize !== "undefined":
        className.push(customSize);
        break;
      default:
        className.push("w-8 h-8");
        break;
    }

    if (customClassName !== "") {
      className.push(customClassName);
    }

    const altAttribute = isNFT ? NFT : coin;

    return embed ? (
      <image
        alt={altAttribute}
        title={altAttribute}
        href={isCoinAlreadyRequested || !coinSrc ? GENERIC_ICON_PATH : coinSrc}
        width="131"
        height="131"
        x="137"
        y="87"
      />
    ) : (
      <img
        onError={!isCoinAlreadyRequested ? handleError : undefined}
        onLoad={handleLoaded}
        alt={altAttribute}
        loading="lazy"
        title={altAttribute}
        src={isCoinAlreadyRequested || !coinSrc ? GENERIC_ICON_PATH : coinSrc}
        className={className.filter(Boolean).join(" ")}
        width="30"
        height="30"
      />
    );
  }
);
ChainLogo.displayName = "ChainLogo";

export default ChainLogo;
