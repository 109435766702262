export const { USER_SELF_ENROLL } = whitelabel.config;

const TRACK_CRYPTOCURRENCY_VALUE = "TRACK_CRYPTOCURRENCY_VALUE";
const SMART = "SMART";
const CUSTOM_UPLOADER = "CUSTOM_UPLOADER";
const B2G_COMPLIANCE = "B2G_COMPLIANCE";

export const ENROLL_WELCOME_ITEMS = [
  TRACK_CRYPTOCURRENCY_VALUE,
  SMART,
  CUSTOM_UPLOADER,
  B2G_COMPLIANCE,
];

export const ENROLL_WELCOME_ITEMS_PATH = "enroll.welcome";

const THEGIVINGBLOCK = "THEGIVINGBLOCK";
const CRYPTOGIVINGTUESDAY = "CRYPTOGIVINGTUESDAY";
const CRED = "CRED";
const NODE402019 = "NODE402019";

export const ENROLL_REFERAL_CODE = {
  THEGIVINGBLOCK,
  CRYPTOGIVINGTUESDAY,
  CRED,
  NODE402019,
};
