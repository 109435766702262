import { makeActionCreator } from "app.actions/helpers";

export const UPGRADE_PLAN_BEGIN = "UPGRADE_PLAN_BEGIN";
export const UPGRADE_PLAN_REQUEST = "UPGRADE_PLAN_REQUEST";
export const UPGRADE_PLAN_RECEIVE = "UPGRADE_PLAN_RECEIVE";
export const UPGRADE_PLAN_ERROR_RECEIVE = "UPGRADE_PLAN_ERROR_RECEIVE";
export const UPGRADE_PLAN_ERROR_CLEAR = "UPGRADE_PLAN_ERROR_CLEAR";

export const upgradePlan = makeActionCreator(
  UPGRADE_PLAN_BEGIN,
  "planQuote",
  "zipCode",
  "organizationInfo",
  "stripeToken"
);

export const requestUpgradePlan = makeActionCreator(UPGRADE_PLAN_REQUEST);
export const receiveUpgradePlan = makeActionCreator(
  UPGRADE_PLAN_RECEIVE,
  "UpgradePlan"
);
export const receiveUpgradePlanError = makeActionCreator(
  UPGRADE_PLAN_ERROR_RECEIVE,
  "error"
);
export const clearUpgradePlanError = makeActionCreator(
  UPGRADE_PLAN_ERROR_CLEAR
);
