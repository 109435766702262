import React from "react";

const TableList = (props) => {
  const {
    zebra = false,
    zebraLedger = false,
    groupingLedger = false,
    highlight = false,
    children,
    className,
    ...htmlAttributes
  } = props;

  const tableClass = [
    "c_table-list",
    "w-full",
    "border-collapse",
    zebra ? "zebra" : "",
    zebraLedger ? "zebra-ledger" : "",
    groupingLedger ? "grouping-ledger" : "",
    highlight ? "highlight" : "",
    className,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <table className={tableClass} {...htmlAttributes}>
      {children}
    </table>
  );
};

export default TableList;
