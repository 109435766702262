import React from "react";
import SvgLoader from "app.components/Util/SvgLoader";
import { useTranslation } from "react-i18next";

const SortIndicator = ({ asc, hideChevron }) => {
  const { t } = useTranslation();

  const isAsc = asc || false;

  const className = [
    "transition-all",
    "ml-1",
    "h-3.5",
    "w-3.5",
    "rotate-90",
    "stroke-zinc-500",
    isAsc ? "scale-x-[-1]" : "",
    hideChevron ? "hidden" : "",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <SvgLoader
      id="Chevron"
      className={className}
      title={isAsc ? t("common.sortDescending") : t("common.sortAscending")}
    />
  );
};

SortIndicator.displayName = "SortIndicator";

export default SortIndicator;
