import * as authenticationActions from "app.actions/authentication";
import { STATUS } from "app.constants";

// intial state, eventually we'll restore this from local storage (per user)
const initialState = {
  authenticationStatus: STATUS.UNINITIALIZED,
};

// eslint-disable-next-line default-param-last
const authentication = (state = initialState, action) => {
  switch (action.type) {
    case authenticationActions.AUTHENTICATION_STATUS_CHECK_REQUEST:
      return { ...state, authenticationStatus: STATUS.LOADING };
    case authenticationActions.AUTHENTICATION_STATUS_CHECK_RECEIVE:
      return { ...state, authenticationStatus: STATUS.LOADED };
    default:
      return state;
  }
};

export default authentication;
