import { testLocalStorage } from "./testLocalStorage";
import { testSessionStorage } from "./testSessionStorage";

const LOCAL = "local";
const SESSION = "session";

function persistState(state, keys, storageKey, storageLocation) {
  const dataToPersist = {};

  // Iterate over the keys and collect their values from the state
  keys.forEach((key) => {
    if (key in state) {
      dataToPersist[key] = state[key];
    }
  });

  if (storageLocation === LOCAL) {
    localStorage[storageKey] = JSON.stringify(dataToPersist);
  } else {
    sessionStorage[storageKey] = JSON.stringify(dataToPersist);
  }

  return state;
}

export function persistToSession(state, keys, storageKey) {
  if (testSessionStorage() && storageKey) {
    return persistState(state, keys, storageKey, SESSION);
  }
  return state;
}

export function persistToLocal(state, keys, storageKey) {
  if (testLocalStorage() && storageKey) {
    return persistState(state, keys, storageKey, LOCAL);
  }
  return state;
}
