import { makeActionCreator } from "app.actions/helpers";

export const AUTO_REFRESH_USER_TOKEN_START = "AUTO_REFRESH_USER_TOKEN_START";
export const AUTO_REFRESH_USER_TOKEN_END = "AUTO_REFRESH_USER_TOKEN_END";

export const autoRefreshUserToken = makeActionCreator(
  AUTO_REFRESH_USER_TOKEN_START
);
export const stopRefreshingUserToken = makeActionCreator(
  AUTO_REFRESH_USER_TOKEN_END
);
