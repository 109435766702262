import { call, put, all, select } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestEditEntryValue,
  editEntryValueError,
  receiveEditEntryValue,
  fetchExchangeLedger,
  resetWorksheetCache,
  fetchTimeline,
} from "app.actions/exchangeLedger";

import { STATUS } from "app.constants";

function* editEntryValue(action) {
  const { itemId, ledgerId, value, reason, isTimeline = false } = action;

  const lastRequestParams = yield select(
    (state) => state.exchangeLedger.lastRequestParams
  );

  yield put(requestEditEntryValue(itemId, STATUS.WORKING));

  const response = yield call(
    ExchangeLedgerAPI.overrideValue,
    ledgerId,
    itemId,
    value,
    reason
  );

  if (response.error) {
    yield put(editEntryValueError(response.body, response.error, itemId));
  } else {
    const noLoading = true;

    const itemsFetch = isTimeline
      ? fetchTimeline(...lastRequestParams, noLoading)
      : fetchExchangeLedger(...lastRequestParams, noLoading);

    yield all([
      put(receiveEditEntryValue(itemId, value, STATUS.SUCCESS)),
      put(itemsFetch),
      put(resetWorksheetCache()),
    ]);
  }
}

export default editEntryValue;
