import { makeActionCreator } from "app.actions/helpers";
// EXEMPT
export const EXCHANGE_CHANGE_EXEMPT_STATUS_BEGIN =
  "EXCHANGE_CHANGE_EXEMPT_STATUS_BEGIN";
export const EXCHANGE_CHANGE_EXEMPT_STATUS_RECEIVE =
  "EXCHANGE_CHANGE_EXEMPT_STATUS_RECEIVE";
export const EXCHANGE_CHANGE_EXEMPT_STATUS_REQUEST =
  "EXCHANGE_CHANGE_EXEMPT_STATUS_REQUEST";

export const changeExchangeExemptStatus = makeActionCreator(
  EXCHANGE_CHANGE_EXEMPT_STATUS_BEGIN,
  "transactionId",
  "exempt",
  "ledgerId"
);
export const requestExchangeChangeExemptStatus = makeActionCreator(
  EXCHANGE_CHANGE_EXEMPT_STATUS_REQUEST,
  "transactionId"
);
export const receiveExchangeChangeExemptStatus = makeActionCreator(
  EXCHANGE_CHANGE_EXEMPT_STATUS_RECEIVE,
  "response",
  "transactionId",
  "exempt"
);
