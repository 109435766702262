import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
  STRIPE_INSTANCE,
  STRIPE_ELEMENTS_FONT_URL,
} from "app.constants/stripe";

function StripeContainer({ children }) {
  // if a white label does not define a stripe key, return null for this component.
  if (typeof whitelabel.config.STRIPE_KEY === "undefined") return null;

  return (
    <Elements
      stripe={STRIPE_INSTANCE}
      fonts={[
        {
          cssSrc: STRIPE_ELEMENTS_FONT_URL,
        },
      ]}
    >
      {children}
    </Elements>
  );
}

export default StripeContainer;
