export function getSortBy(selector, ascending = true) {
  if (typeof selector !== "function")
    throw new TypeError("Expected a function to be provided");

  return (left, right) => {
    const leftValue = selector(left);
    const rightValue = selector(right);

    if (leftValue === rightValue) return 0;

    if (leftValue > rightValue) {
      return ascending ? 1 : -1;
    }
    return ascending ? -1 : 1;
  };
}
