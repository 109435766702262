import api from "../clients/teller";

/**
 * Submits a request to get 2FA data
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const associateMFA = (username, password) => {
  return api.post("/account/associate-mfa", {
    data: { username, password },
    authenticated: true,
  });
};

/**
 * Submits a request to enable 2FA
 * @async
 * @function
 * @param {string} totp - The current TOTP
 * @returns {object}  response - The Response or Error message
 */
export const verifyMFA = (username, password, mfaSecret) => {
  return api.post("/account/verify-mfa", {
    data: { username, password, mfaSecret },
    authenticated: true,
  });
};

/**
 * Submits a request to disable 2FA
 * @async
 * @function
 * @param {string} totp - The current TOTP
 * @returns {object}  response - The Response or Error message
 */
export const disableMFA = (username, password, mfaSecret) => {
  return api.delete("/account/delete-mfa", {
    authenticated: true,
    data: { username, password, mfaSecret },
  });
};
