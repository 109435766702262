import { call, put } from "redux-saga/effects";
import {
  exchangeAccountsError,
  receiveExchangeAccounts,
  requestExchangeAccounts,
} from "app.actions/exchanges";
import { openApiKeyModal, openModal } from "app.reducers/ui";
import { handleApiKeyError } from "app.actions/helpers";
import ExchangesAPI from "app.api/ExchangesAPI";

import { MODAL_EXCHANGE_OAUTH_POP_UP } from "app.constants/modals";

function* fetchExchangeAccounts(action) {
  const {
    exchange,
    linkGUID,
    apiLinkSuccessCallback,
    apiDismissCallback,
    oauthRequiredCallback,
  } = action;

  yield put(requestExchangeAccounts());

  const response = yield call(
    ExchangesAPI.getExchangeAccounts,
    exchange.id,
    linkGUID
  );
  const { body, error } = response;

  if (error) {
    if (
      typeof body.metadata !== "undefined" &&
      typeof body.metadata.code !== "undefined"
    ) {
      switch (body.metadata.code) {
        case "oauthRequired":
          oauthRequiredCallback();
          yield put(
            openModal(MODAL_EXCHANGE_OAUTH_POP_UP, undefined, {
              exchange,
              linkGUID,
              reLink: true,
            })
          );
          break;
        case "apiKeyInvalidSignedRequest":
        case "apiKeyInvalid":
        case "apiKeyOverScope":
          yield put(
            handleApiKeyError({
              errorCode: body.metadata.code, // errorCode
              exchange,
              linkGUID,
              openApiKeyModal,
              errorCallback: exchangeAccountsError,
              error,
              apiLinkSuccessCallback,
              apiDismissCallback,
            })
          );
          break;
        default:
          yield put(exchangeAccountsError(body, error));
          break;
      }
    }
  } else {
    // sort by Balance, and then alphabetically
    const formattedResponse = {
      accounts: [...body.accounts].sort(function sort(a, b) {
        if (a.accountBalance === b.accountBalance) {
          let aName;
          let bName;
          if (a.currencyCode === a.name) {
            // if the name is the currency
            aName = a.currencyCode;
            bName = b.currencyCode;
          } else {
            // if the name is provided by the exchange (e.g. coinbase, uphold)
            aName = a.name;
            bName = b.name;
          }
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        }
        return a.accountBalance < b.accountBalance ? 1 : -1;
      }),
    };
    yield put(receiveExchangeAccounts(exchange.id, formattedResponse));
  }
}

export default fetchExchangeAccounts;
