import api from "../clients/organization";
/**
 * Submits a request to get the create a new coworker
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const addCoworker = (email, firstName, lastName, isAdmin) => {
  return api.post(`/inviteCoworker`, {
    data: { email, first: firstName, last: lastName, admin: isAdmin },
    authenticated: true,
  });
};

/**
 * Submits a request to get the remove a coworker
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const removeCoworker = (userGUID) => {
  return api.delete(`/removeCoworker/${userGUID}`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get the update a coworker's access level
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const updateCoworkerAccess = (userGUID, isAdmin) => {
  return api.put(`/updateCoworkerAccess/${userGUID}`, {
    data: { admin: isAdmin },
    authenticated: true,
  });
};

/**
 * Submits a request to get the coworker list of an organization
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const listCoworkers = () => {
  return api.get(`/listCoworkers`, { authenticated: true });
};

/**
 * Submits a request to reinvite a profile
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const reinviteTeamMember = (invitedCoworkerGUID) => {
  return api.post(`/reInviteCoworker/${invitedCoworkerGUID}`, {
    authenticated: true,
  });
};
