import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* connect exchange */
/** *************************************************************************** */
export const CONNECT_EXCHANGE_OAUTH_BEGIN = "CONNECT_EXCHANGE_OAUTH_BEGIN";
// export const CONNECT_EXCHANGE_RECEIVE = "CONNECT_EXCHANGE_RECEIVE";
export const CONNECT_EXCHANGE_OAUTH_REQUEST = "CONNECT_EXCHANGE_OAUTH_REQUEST";
export const CONNECT_EXCHANGE_OAUTH_ERROR = "CONNECT_EXCHANGE_OAUTH_ERROR";

export const connectExchangeOAuth = makeActionCreator(
  CONNECT_EXCHANGE_OAUTH_BEGIN,
  "exchange",
  "slug"
);
export const requestConnectExchangeOAuth = makeActionCreator(
  CONNECT_EXCHANGE_OAUTH_REQUEST
);
export const receiveConnectExchangeOAuthError = makeActionCreator(
  CONNECT_EXCHANGE_OAUTH_ERROR,
  "response"
);
