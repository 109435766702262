import React, { useState } from "react";
import { getPlanDetails, STEPS } from "app.constants";

import PlanCard from "./PlanCard";

function SelectPlan({
  selectPlanCallback,
  plan,
  currentlyActivePlan,
  currentSubscription,
  referrer,
}) {
  const [selectedPlan, setSelectedPlan] = useState(
    plan.subPlans?.length ? plan.subPlans[0].planId : plan.planId
  );

  const selectPlan = (p) => {
    selectPlanCallback(p, STEPS.PAYMENT);
  };

  const changeSelectedPlan = (planId) => {
    setSelectedPlan(planId);
  };

  const planDetails = getPlanDetails(selectedPlan);

  const planData = plan.subPlans?.length
    ? plan.subPlans.find(({ planId }) => planId === selectedPlan)
    : plan;

  return (
    <PlanCard
      key={plan.planId}
      plan={planData}
      selectPlanOptions={plan.subPlans}
      referrer={referrer}
      currentlyActivePlan={currentlyActivePlan}
      currentSubscription={currentSubscription}
      selectPlan={selectPlan}
      showPrice={!planDetails.seatBased}
      planDetails={planDetails}
      changeSelectedPlan={changeSelectedPlan}
      selectedPlan={selectedPlan}
    />
  );
}

export default SelectPlan;
