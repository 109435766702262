const entries = [];

const logger = {
  logRequest: (url, status, method = "") => {
    // only keep the last 10 requests
    if (entries.length >= 10) {
      entries.shift();
    }
    entries.push({ url, status, method });
  },
  getDebugEntries: () => {
    return entries;
  },
};
export const { logRequest } = logger;
export const { getDebugEntries } = logger;
export default logger;
