import React from "react";
import Button from "app.components/Util/Button";
import { useTranslation } from "react-i18next";

function RouterErrorBoundary(returnToOrgs = false) {
  const { t } = useTranslation();

  const recoveryLink = (
    <Button
      buttonType="primary"
      asAnchor
      href={returnToOrgs === true ? "/organization" : "/portfolio"}
      text={
        returnToOrgs === true
          ? t("utils.errorBoundary.returnToOrganization")
          : t("utils.errorBoundary.returnToMyPortfolio")
      }
    />
  );

  return (
    <div className="c_error-boundary flex flex-col">
      <div className="mx-auto mb-10 mt-20 rounded bg-white p-20 shadow">
        <h1 className="mb-4 text-xl">
          {t("utils.errorBoundary.somethingWentWrong")}
        </h1>
        <p className="mb-4 text-lg">
          {t("utils.errorBoundary.unexpectedErrorOccurredUnhandled")}
        </p>
        <p>{recoveryLink}</p>
      </div>
      <p className="color-secondary mb-10 text-center text-sm text-blue-200/70">
        v{import.meta.env.VITE_BALANCE_VERSION}
      </p>
    </div>
  );
}

export default RouterErrorBoundary;
