import React from "react";
import Button from "app.components/Util/Button";
import { useTranslation } from "react-i18next";
import { useGetUserLocale } from "app.hooks";

import { PAGINATION_TYPES } from "./constants";

function PageLink({
  currentPage,
  pageNumber,
  location,
  type,
  onSelect,
  showPageLabel,
}) {
  const { t } = useTranslation();
  const locale = useGetUserLocale();

  const isActive = currentPage === pageNumber;

  const linkBody = (
    <>
      {pageNumber === 0 && showPageLabel ? t("common.page") : null}
      {(pageNumber + 1).toLocaleString(locale)}
    </>
  );

  if (type === PAGINATION_TYPES.BUTTON) {
    return (
      <Button
        onClick={(e) => {
          e.preventDefault();
          onSelect(pageNumber);
        }}
        buttonSize="sm"
        buttonType={isActive ? "paginationActive" : "pagination"}
      >
        {linkBody}
      </Button>
    );
  }

  const queryParams = new URLSearchParams(location.search);
  queryParams.set("p", pageNumber);
  const toURL = `${location.pathname}?${queryParams.toString()}`;

  return (
    <Button
      asAnchor
      to={toURL}
      buttonType={isActive ? "paginationActive" : "pagination"}
    >
      {linkBody}
    </Button>
  );
}

PageLink.displayName = "PageLink";
export default PageLink;
