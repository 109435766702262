import { EXCHANGE_IDS } from "./exchanges";

export const { SUPPORT_EMAIL } = whitelabel.config;
export const { SUPPORT_TEL } = whitelabel.config;
export const HAS_SUPPORT_LINKS = whitelabel.config.SUPPORT_LINKS;

export const CUSTPOM_UPLOAD_HELP_URL =
  "https://node40.atlassian.net/wiki/x/VgDhfw";

export const XPUB_EXPORT_SUPPORT_URL =
  "https://node40.atlassian.net/wiki/x/QoDig";

export const DASH_CORE_SUPPORT_URL =
  "https://node40.atlassian.net/wiki/x/BwDEg";

export const EVM_SUPPORT_URL = "https://node40.atlassian.net/wiki/x/MQCKg";

export const FEATURE_HELP = {
  TRANSACTION_LINKING: {
    title:
      "How to Mark a Transaction as a Transfer and Manually Link Transactions",
    url: "https://node40.atlassian.net/wiki/x/AQCHiw",
  },
  OVERRIDE_VALUE: {
    title: "How to Override Incoming or Outgoing Token Values",
    url: "https://node40.atlassian.net/wiki/x/EoCLg",
  },
  ACCOUNTING_STRATEGY: {
    title: "How to Change Your Accounting Strategy",
    url: "https://node40.atlassian.net/wiki/x/IgCIg",
  },
  MANAGE_USERS: {
    title: "How to Manage Organization Admins and Users",
    url: "https://node40.atlassian.net/wiki/x/BICIg",
  },
  CUSTOM_LABLES: {
    title: "How to Create Custom Field Labels in an Organization",
    url: "https://node40.atlassian.net/wiki/x/DIB3iw",
  },
  PUBLIC_API: {
    title: "How to Create a NODE40 Public API Key",
    url: "https://node40.atlassian.net/wiki/x/FABFi",
  },
  MANAGE_ORG: {
    title: "How to Manage Organization Admins and Users",
    url: "https://node40.atlassian.net/wiki/x/BICIg",
  },
};

export const HELP_INFO = {
  [EXCHANGE_IDS.BINANCE_US]: "https://node40.atlassian.net/wiki/x/BgDGg",
  [EXCHANGE_IDS.BINANCE]: "https://node40.atlassian.net/wiki/x/BgDGg",
  [EXCHANGE_IDS.BITFINEX]: "https://node40.atlassian.net/wiki/x/MADJg",
  [EXCHANGE_IDS.BITGO_WALLET]: "https://node40.atlassian.net/wiki/x/EgCGg",
  [EXCHANGE_IDS.BITGO]: "https://node40.atlassian.net/wiki/x/EgCGg",
  [EXCHANGE_IDS.BITMART]: "https://node40.atlassian.net/wiki/x/AQCLg",
  [EXCHANGE_IDS.BITSTAMP]: "https://node40.atlassian.net/wiki/x/MQCOg",
  [EXCHANGE_IDS.BITTREX_UPLOAD]: "https://node40.atlassian.net/wiki/x/AwDjg",
  [EXCHANGE_IDS.BITTREX]: "https://node40.atlassian.net/wiki/x/AwDjg",
  [EXCHANGE_IDS.BTSE]: "https://node40.atlassian.net/wiki/x/BoCFg",
  [EXCHANGE_IDS.BYBIT]: "https://node40.atlassian.net/wiki/x/AgCKg",
  [EXCHANGE_IDS.COINBASE_API_KEY]: "https://node40.atlassian.net/wiki/x/NYD3fw",
  [EXCHANGE_IDS.COINBASE_EXCHANGE]:
    "https://node40.atlassian.net/wiki/x/LAA5iw",
  [EXCHANGE_IDS.COINBASE_UPLOAD]: "https://node40.atlassian.net/wiki/x/AwCOg",
  [EXCHANGE_IDS.COINBASEPRIME]: "https://node40.atlassian.net/wiki/x/FYCJig",
  [EXCHANGE_IDS.COINBASEPRO]: "https://node40.atlassian.net/wiki/x/BIDig",
  [EXCHANGE_IDS.CRYPTOCOM]: "https://node40.atlassian.net/wiki/x/QgCKg",
  [EXCHANGE_IDS.DERIBIT]: "https://node40.atlassian.net/wiki/x/DQCFg",
  [EXCHANGE_IDS.EXODUS]: "https://node40.atlassian.net/wiki/x/PwCMg",
  [EXCHANGE_IDS.FIREBLOCKS]: "https://node40.atlassian.net/wiki/x/AQB9hg",
  [EXCHANGE_IDS.GEMINI]: "https://node40.atlassian.net/wiki/x/JwCGg",
  [EXCHANGE_IDS.GENERIC]: "https://node40.atlassian.net/wiki/x/VgDhfw",
  [EXCHANGE_IDS.KRAKEN]: "https://node40.atlassian.net/wiki/x/KgCFg",
  [EXCHANGE_IDS.KUCOIN]: "https://node40.atlassian.net/wiki/x/BQDig",
  [EXCHANGE_IDS.POLONIEX]: "https://node40.atlassian.net/wiki/x/F4Dig",
};
