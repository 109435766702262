import React from "react";
import LoadingSpinner from "app.components/Util/LoadingSpinner";

const GenericPlaceholder = () => {
  return (
    <div className="my-16 flex w-full justify-center">
      <LoadingSpinner />
    </div>
  );
};

export default GenericPlaceholder;
