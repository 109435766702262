import { call, put, all } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";
import OrganizationAPI from "app.api/OrganizationAPI";

function* deleteProfile(action) {
  const { profileGUID } = action.payload;

  yield put(organizationActions.deleteProfileRequest());
  yield put(startModalWorking());

  const response = yield call(OrganizationAPI.deleteProfile, profileGUID);

  if (response.error) {
    yield put(organizationActions.deleteProfileError(response.error));
    yield put(endModalWorking());
  } else {
    yield all([
      put(organizationActions.deleteProfileReceive()),
      put(organizationActions.fetchOrganization()),
      put(endModalWorking()),
      put(dismissModal()),
    ]);
  }
}

export default deleteProfile;
