import { makeActionCreator } from "app.actions/helpers";
// TRANSFER
export const EXCHANGE_CHANGE_TRANSFER_STATUS_BEGIN =
  "EXCHANGE_CHANGE_TRANSFER_STATUS_BEGIN";
export const EXCHANGE_CHANGE_TRANSFER_STATUS_RECEIVE =
  "EXCHANGE_CHANGE_TRANSFER_STATUS_RECEIVE";
export const EXCHANGE_CHANGE_TRANSFER_STATUS_REQUEST =
  "EXCHANGE_CHANGE_TRANSFER_STATUS_REQUEST";
export const EXCHANGE_TRANSFER_CLEAR_ERROR = "EXCHANGE_TRANSFER_CLEAR_ERROR";

export const changeExchangeTransferStatus = makeActionCreator(
  EXCHANGE_CHANGE_TRANSFER_STATUS_BEGIN,
  "transactionId",
  "externalTransfer",
  "ledgerId"
);

export const requestExchangeChangeTransferStatus = makeActionCreator(
  EXCHANGE_CHANGE_TRANSFER_STATUS_REQUEST,
  "transactionId"
);
export const receiveExchangeChangeTransferStatus = makeActionCreator(
  EXCHANGE_CHANGE_TRANSFER_STATUS_RECEIVE,
  "response",
  "transactionId",
  "externalTransfer"
);

// TRANSFER IN FROM SOURCELESS
export const TRANSFER_IN_FROM_SOURCELESS_STATUS_BEGIN =
  "TRANSFER_IN_FROM_SOURCELESS_STATUS_BEGIN";
export const TRANSFER_IN_FROM_SOURCELESS_STATUS_RECEIVE =
  "TRANSFER_IN_FROM_SOURCELESS_STATUS_RECEIVE";
export const TRANSFER_IN_FROM_SOURCELESS_STATUS_REQUEST =
  "TRANSFER_IN_FROM_SOURCELESS_STATUS_REQUEST";
export const TRANSFER_IN_FROM_SOURCELESS_STATUS_RECEIVE_ERROR =
  "TRANSFER_IN_FROM_SOURCELESS_STATUS_RECEIVE_ERROR";

export const changeTransferInFromSourceless = makeActionCreator(
  TRANSFER_IN_FROM_SOURCELESS_STATUS_BEGIN,
  "ledgerItemId",
  "toLedgerId"
);

export const requestTransferInFromSourceless = makeActionCreator(
  TRANSFER_IN_FROM_SOURCELESS_STATUS_REQUEST
);

export const receiveTransferInFromSourceless = makeActionCreator(
  TRANSFER_IN_FROM_SOURCELESS_STATUS_RECEIVE,
  "ledgerItemId"
);

export const receiveTransferInFromSourcelessError = makeActionCreator(
  TRANSFER_IN_FROM_SOURCELESS_STATUS_RECEIVE_ERROR,
  "message"
);

// TRANSFER OUT TO SOURCELESS
export const TRANSFER_OUT_TO_SOURCELESS_STATUS_BEGIN =
  "TRANSFER_OUT_TO_SOURCELESS_STATUS_BEGIN";
export const TRANSFER_OUT_TO_SOURCELESS_STATUS_RECEIVE =
  "TRANSFER_OUT_TO_SOURCELESS_STATUS_RECEIVE";
export const TRANSFER_OUT_TO_SOURCELESS_STATUS_REQUEST =
  "TRANSFER_OUT_TO_SOURCELESS_STATUS_REQUEST";
export const TRANSFER_OUT_TO_SOURCELESS_STATUS_RECEIVE_ERROR =
  "TRANSFER_OUT_TO_SOURCELESS_STATUS_RECEIVE_ERROR";

export const changeTransferOutToSourceless = makeActionCreator(
  TRANSFER_OUT_TO_SOURCELESS_STATUS_BEGIN,
  "ledgerItemId",
  "toLedgerId",
  "newLedgerName"
);

export const requestTransferOutToSourceless = makeActionCreator(
  TRANSFER_OUT_TO_SOURCELESS_STATUS_REQUEST
);

export const receiveTransferOutToSourceless = makeActionCreator(
  TRANSFER_OUT_TO_SOURCELESS_STATUS_RECEIVE,
  "ledgerItemId"
);

export const receiveTransferOutToSourcelessError = makeActionCreator(
  TRANSFER_OUT_TO_SOURCELESS_STATUS_RECEIVE_ERROR,
  "message"
);

export const clearTransferError = makeActionCreator(
  EXCHANGE_TRANSFER_CLEAR_ERROR
);
