import { makeActionCreator } from "app.actions/helpers";

export const ACCOUNT_MFA_VERIFY_BEGIN = "ACCOUNT_MFA_VERIFY_BEGIN";
export const ACCOUNT_MFA_VERIFY_REQUEST = "ACCOUNT_MFA_VERIFY_REQUEST";
export const ACCOUNT_MFA_VERIFY_RECEIVE = "ACCOUNT_MFA_VERIFY_RECEIVE";
export const ACCOUNT_MFA_VERIFY_ERROR = "ACCOUNT_MFA_VERIFY_ERROR";

export const requestSecurityVerification = makeActionCreator(
  ACCOUNT_MFA_VERIFY_BEGIN,
  "currentPassword"
);

export const securityVerificationRequest = makeActionCreator(
  ACCOUNT_MFA_VERIFY_REQUEST
);

export const securityVerificationError = makeActionCreator(
  ACCOUNT_MFA_VERIFY_ERROR,
  "error"
);

export const securityVerificationReceive = makeActionCreator(
  ACCOUNT_MFA_VERIFY_RECEIVE,
  "totpSecret"
);
