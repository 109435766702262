import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserConfirmed } from "app.hooks";

import { STATUS } from "app.constants";

import { dismissModal } from "app.reducers/ui";

import { editEntryDescription } from "app.actions/exchangeLedger";

import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";

import TextArea from "app.components/Util/Form/TextArea";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

function ModalLedgerMemo({ entry, ledgerId, canDismissCallback }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const confirmed = useGetUserConfirmed();
  const [description, setDescription] = useState(
    entry.userProvidedDescription || ""
  );

  const entryDescription = useSelector(
    (state) => state.exchangeLedger.entryDescription
  );

  const { modalWorking } = useSelector((state) => state.ui);

  useEffect(() => {
    canDismissCallback(!modalWorking === true);
  }, [modalWorking, canDismissCallback]);

  const valueChanged = description !== entry.userProvidedDescription;

  const status = useMemo(() => {
    if (typeof entryDescription[entry.transactionId] !== "undefined") {
      return entryDescription[entry.transactionId].status;
    }
    return undefined;
  }, [entryDescription, entry.transactionId]);

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  const isWorking = status === STATUS.WORKING;

  return (
    <>
      <ModalHeader
        title={t("modals.ledgerMemo.transactionMemo")}
        closeCallback={handleDismiss}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          reduxDispatch(
            editEntryDescription(
              entry.transactionId,
              parseInt(ledgerId, 10),
              description
            )
          );
        }}
      >
        <div className="m-4">
          <TextArea
            data-testid="edit-description"
            id={`descr${entry.id}`}
            name="description"
            data-tabbable
            footer={false}
            value={description}
            className="w-full"
            onChange={(e) => setDescription(e.target.value)}
            disabled={modalWorking || isWorking || confirmed === false}
          />
        </div>

        <ModalControls>
          <Button
            type="submit"
            disabled={
              modalWorking || isWorking || !valueChanged || confirmed === false
            }
            working={modalWorking || isWorking}
            workingText={t("button.saving")}
            buttonType="primary"
            customClasses="ml-4"
            text={t("modals.ledgerMemo.saveMemo")}
          />
          <Button
            onClick={handleDismiss}
            disabled={modalWorking || isWorking}
            buttonType="text"
            text={t("common.close")}
          />
        </ModalControls>
      </form>
    </>
  );
}

ModalLedgerMemo.displayName = "ModalLedgerMemo";

export default ModalLedgerMemo;
