import { call, put } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestNftWorksheet,
  nftWorksheetError,
  receiveNftWorksheet,
  noNftWorksheetToLoad,
} from "app.actions/exchangeLedger";

function* fetchNftWorksheet(action) {
  const { exchangeId, tokenId, contract } = action;

  if (typeof tokenId === "undefined") return;

  yield put(requestNftWorksheet(tokenId));

  const response = yield call(
    ExchangeLedgerAPI.getNftWorksheet,
    exchangeId,
    tokenId,
    contract
  );

  if (response.error) {
    if (response.error === 404) {
      yield put(noNftWorksheetToLoad(tokenId));
    } else {
      yield put(nftWorksheetError(tokenId, response.body, response.error));
    }
  } else {
    yield put(receiveNftWorksheet(tokenId, response.body));
  }
}

export default fetchNftWorksheet;
