import { call, put } from "redux-saga/effects";
import SmartWalletAPI from "app.api/SmartWalletAPI";
import * as smartWalletActions from "app.reducers/smartWallet";
import { getLoadingState } from "app.utils/selectors";

function* enableSmartWallet() {
  const { isLoading } = yield* getLoadingState(
    (state) => state.smartWallet.smartWalletEnableStatus
  );

  if (isLoading) return;

  yield put(smartWalletActions.requestSmartWalletEnable());

  const response = yield call(SmartWalletAPI.enableSmartWallet);
  // handle the API response
  if (response) {
    if (response.error) {
      console.error(
        "Error in enableSmartWallet",
        response.body,
        response.error
      );
      yield put(smartWalletActions.receiveSmartWalletEnableError());
    } else {
      yield put(smartWalletActions.receiveSmartWalletEnable());
      yield put(smartWalletActions.fetchSmartWalletList());
    }
  }
}

export default enableSmartWallet;
