import { call, put, all } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import { PROFESSIONAL_PLAN_ID } from "app.constants";
import {
  receiveUpgradePlanError,
  receiveUpgradePlan,
} from "app.actions/account/";
import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";
import { checkAuthenticationStatus } from "app.actions/authentication";
import enterSubscriptionSection from "./enterSubscriptionSection";

function* upgradePlan(action) {
  const { planQuote, zipCode, organizationInfo, stripeToken } = action;

  const currentlySelectedPlanId = planQuote.planId;
  const isProfessionalPlan = currentlySelectedPlanId === PROFESSIONAL_PLAN_ID;

  // If we're making a new billing method, set the b illing method first.
  yield put(startModalWorking());
  if (typeof stripeToken !== "undefined") {
    const setPaymentInfoResponse = yield call(
      TellerAPI.setPaymentInfo,
      stripeToken
    );

    if (setPaymentInfoResponse.error) {
      if (setPaymentInfoResponse.error === 500) {
        yield put(receiveUpgradePlanError("default"));
      } else {
        yield put(receiveUpgradePlanError(setPaymentInfoResponse.body.message));
      }
      yield put(endModalWorking());
      // Can't continue. We really shouldn't get to this point, but, you know...
      return;
    }
  }

  const organizationName = isProfessionalPlan
    ? organizationInfo.organizationName
    : undefined;
  const profileLimit = isProfessionalPlan
    ? organizationInfo.profileLimit
    : undefined;

  // Now, upgrade the plan
  // 5. Make the subscribe to plan call; and show errors for the billing or org area.
  const subscribeResponse = yield call(
    TellerAPI.subscribeToPlan,
    currentlySelectedPlanId,
    planQuote.availableCouponId,
    zipCode,
    organizationName,
    profileLimit
  );

  if (subscribeResponse.error) {
    yield put(receiveUpgradePlanError(subscribeResponse.error));
    yield put(endModalWorking());
  } else {
    yield call(enterSubscriptionSection);

    yield all([
      put(receiveUpgradePlan(subscribeResponse.body)),
      put(checkAuthenticationStatus()),
      put(dismissModal()),
    ]);
  }
}

export default upgradePlan;
