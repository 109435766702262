import { STATUS } from "app.constants";

import * as exchangeActions from "app.actions/exchanges";
import * as exchangeLedgerActions from "app.actions/exchangeLedger";
import {
  reinitializePortfolio,
  resetPortfolioData,
  resetAddLedger,
} from "app.reducers/portfolios";

import { dismissModal } from "app.reducers/ui";

function updateLedgerSyncState(syncList, ledgers) {
  const updatedLedgers = [...ledgers];
  for (let i = 0; i < syncList.length; i += 1) {
    if (typeof syncList[i].source !== "undefined") {
      // set sync for these ledgers by ID
      for (let j = 0; j < updatedLedgers.length; j += 1) {
        if (
          updatedLedgers[j].exchangeId === syncList[i].source.id &&
          updatedLedgers[j].linked?.linkGUID === syncList[i].source.linkGUID
        ) {
          updatedLedgers[j].importInProgress = true;
        }
      }
    }
    if (typeof syncList[i].syncedLedgerIds !== "undefined") {
      const ledgerIds = syncList[i].syncedLedgerIds;
      // set sync for these ledgers by ID
      for (let j = 0; j < ledgerIds.length; j += 1) {
        const led = ledgers.find((l) => {
          return parseInt(l.id, 10) === parseInt(ledgerIds[j], 10);
        });

        if (led) led.importInProgress = true;
      }
    }
  }
  return updatedLedgers;
}

// initial state, eventually we'll restore this from local storage (per user)
const initialState = {
  supportedExchanges: undefined,
  supportedCurrencies: undefined,
  supportedCustodialWallets: undefined,
  supportedEvmWallets: undefined,
  supportedExchangesStatus: STATUS.UNINITIALIZED,
  loadingExchangeAccounts: false,
  linkedExchanges: undefined,
  linkedExchangesStatus: STATUS.UNINITIALIZED,
  selectedExchange: 0,
  exchangeAccounts: {},
  addingLedger: false,
  addLedgerError: undefined,
  ledgers: undefined,
  archivedLedgers: undefined,
  exchangeLedgerCurrentlyInProcess: [],
  ledgersStatus: STATUS.UNINITIALIZED,
  ledgersInitialLoad: false,
  ledgersError: undefined,
  deletingLedger: false,
  creatingViaUpload: false,
  migrateResponseId: undefined,
  migrateSources: undefined,
  creatingViaApi: false,
  creatingWallet: false,
  invalidKeys: false,
  invalidAddressMessage: undefined,
  invalidToken: false,
  contract: undefined,
  duplicate: undefined,
  unauthorized: undefined,
  createdLedgers: undefined,
  asyncCreationInProgress: undefined,
  asyncUploadExchangeInProgress: undefined,
  failedCurrencies: undefined,
  skippedCurrencies: undefined,
  uploading: false,
  updating: false,
  apiKeySavingStatus: STATUS.INITIAL,
  apiKeyError: undefined,
  apiTokenSavingStatus: STATUS.INITIAL,
  apiTokenError: undefined,
  settings: undefined,
  settingsStatus: STATUS.UNINITIALIZED,
  unlinkExchangeStatus: STATUS.UNINITIALIZED,
  userReadableErrors: undefined,
  flattenedLinkedExchanges: [],
  apiBasedExchanges: [],
  verifyingExchangeLink: {},
  renamingLinkedAccount: false,
  renamingLinkedAccountError: undefined,
  bulkUpdateStatus: STATUS.UNINITIALIZED,
  rebuildingLedgers: [],
  rollforwardCreatingViaUpload: false,
  rollforwardCreatingViaUploadError: undefined,
  rollforwardCreated: false,
  apiKeyVault: {},
};

// eslint-disable-next-line default-param-last
const exchanges = (state = initialState, action) => {
  switch (action.type) {
    case reinitializePortfolio.type: {
      return initialState;
    }
    case resetPortfolioData.type: {
      return {
        ...state,
        supportedExchangesStatus: STATUS.UNINITIALIZED,
        ledgersStatus: STATUS.UNINITIALIZED,
      };
    }
    case exchangeActions.SELECT_EXCHANGE:
      return {
        ...state,
        selectedExchange: action.exchange,
      };
    case exchangeActions.SUPPORTED_EXCHANGES_REQUEST:
      return {
        ...state,
        supportedExchangesStatus: action.nextStatus,
      };
    case exchangeActions.SUPPORTED_EXCHANGES_RECEIVE:
      return {
        ...state,
        supportedExchanges: action.sortedExchanges,
        supportedCurrencies: action.supportedCurrencies,
        supportedCustodialWallets: action.custodialWallets,
        supportedEvmWallets: action.evmWallets,
        apiBasedExchanges: action.apiBasedExchanges,
        supportedExchangesStatus: action.nextStatus,
      };
    case exchangeActions.LINKED_EXCHANGES_REQUEST:
      return {
        ...state,
        linkedExchangesStatus: action.nextStatus,
      };
    case exchangeActions.LINKED_EXCHANGES_RECEIVE:
      return {
        ...state,
        linkedExchanges: action.response.linked,
        flattenedLinkedExchanges: action.flattenedLinkedExchanges,
        linkedExchangesStatus: action.nextStatus,
      };
    case exchangeActions.LEDGERS_REQUEST:
      return { ...state, ledgersStatus: action.nextStatus };
    case exchangeActions.LEDGERS_RECEIVE:
      return {
        ...state,
        ledgersInitialLoad: true,
        ledgersStatus: action.nextStatus,
        ledgers: action.response,
        exchangeLedgerCurrentlyInProcess: action.response
          .filter((value) => value.creationInProgress || value.importInProgress)
          .map((ledger) => ledger.exchangeId),
      };
    case exchangeLedgerActions.ARCHIVED_LEDGERS_RECEIVE:
      return {
        ...state,
        archivedLedgers: action.response,
      };
    case exchangeActions.LEDGERS_ERROR:
      return {
        ...state,
        ledgersInitialLoad: true,
        ledgersStatus: STATUS.ERROR,
        ledgersError: action.response,
      };
    case exchangeActions.EXCHANGE_ACCOUNTS_REQUEST:
      return {
        ...state,
        loadingExchangeAccounts: true,
      };
    case exchangeActions.EXCHANGE_ACCOUNTS_RECEIVE:
      return {
        ...state,
        loadingExchangeAccounts: false,
        exchangeAccounts: {
          ...state.exchangeAccounts,
          [action.exchange]: action.response.accounts,
        },
      };
    case exchangeActions.RENAME_EXCHANGE_LEDGER_REQUEST:
      return {
        ...state,
        renamingLedger: true,
      };
    case exchangeActions.RENAME_EXCHANGE_LEDGER_RECEIVE: {
      const renamedLedgerIndex = state.ledgers.findIndex(
        (ledger) => action.ledgerId === ledger.id
      );
      const renamedLedger = { ...state.ledgers[renamedLedgerIndex] };
      renamedLedger.userLedgerName = action.name;
      return {
        ...state,
        renamingLedger: false,
        ledgers: [
          ...state.ledgers.slice(0, renamedLedgerIndex),
          renamedLedger,
          ...state.ledgers.slice(renamedLedgerIndex + 1),
        ],
      };
    }
    case exchangeActions.CREATE_EXCHANGE_LEDGERS_UPLOAD_REQUEST: {
      return {
        ...state,
        creatingViaUpload: true,
        asyncUploadExchangeInProgress: undefined,
        uploadError: undefined,
      };
    }
    case exchangeActions.CREATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE: {
      return {
        ...state,
        creatingViaUpload: false,
        asyncUploadExchangeInProgress: action.exchangeId,
        asyncCreationInProgress: true,
      };
    }
    case exchangeActions.CREATE_EXCHANGE_LEDGERS_UPLOAD_ERROR: {
      let uploadError;
      if (typeof action.error !== "undefined") {
        uploadError = action.response;
      }
      return {
        ...state,
        creatingViaUpload: false,
        failedCurrencies: action.response.failedCurrencies,
        userReadableErrors: action.response.userReadableErrors,
        uploadError,
      };
    }
    case exchangeActions.MIGRATE_UPLOAD_REQUEST: {
      return {
        ...state,
        creatingViaUpload: true,
        uploadError: undefined,
        migrateResponseId: undefined,
        migrateSources: undefined,
      };
    }
    case exchangeActions.MIGRATE_UPLOAD_RECEIVE: {
      return {
        ...state,
        creatingViaUpload: false,
        migrateResponseId: action.responseId,
        migrateSources: action.sources,
        uploadError: undefined,
      };
    }
    case exchangeActions.MIGRATE_UPLOAD_ERROR: {
      return {
        ...state,
        creatingViaUpload: false,
        migrateResponseId: undefined,
        migrateSources: undefined,
        uploadError: action.error,
      };
    }
    case exchangeActions.CREATE_EXCHANGE_LEDGERS_REQUEST: {
      return {
        ...state,
        creatingViaApi: true,
      };
    }
    case exchangeActions.CREATE_EXCHANGE_LEDGERS_RECEIVE: {
      return {
        ...state,
        creatingViaApi: false,
        createdLedgers: action.response.ledgersCreated,
        asyncCreationInProgress: action.response.asyncCreationInProgress,
      };
    }
    case exchangeActions.CREATE_EXCHANGE_LEDGERS_ERROR: {
      return {
        ...state,
        creatingViaApi: false,
        createdLedgers: undefined,
        asyncCreationInProgress: false,
      };
    }
    case exchangeActions.CREATE_WALLET_LEDGERS_BEGIN: {
      return {
        ...state,
        creatingWallet: true,
        asyncCreationInProgress: false,
        invalidKeys: undefined,
        invalidToken: undefined,
        contract: undefined,
        duplicate: undefined,
        unauthorized: undefined,
        invalidAddressMessage: undefined,
      };
    }
    case exchangeActions.CREATE_WALLET_LEDGERS_RECEIVE: {
      return {
        ...state,
        createdLedgers: action.response.ledgersCreated,
        creatingWallet: false,
        asyncCreationInProgress: action.response.asyncCreationInProgress,
        invalidKeys: undefined,
        invalidToken: undefined,
        contract: undefined,
        duplicate: undefined,
        unauthorized: undefined,
        invalidAddressMessage: undefined,
      };
    }
    case exchangeActions.CREATE_WALLET_LEDGERS_ERROR: {
      return {
        ...state,
        creatingWallet: false,
        createdLedgers: undefined,
        asyncCreationInProgress: false,
        invalidKeys: action.response.invalidKeys,
        invalidToken: action.response.invalidToken,
        contract: action.response.contract,
        duplicate: action.response.duplicate,
        unauthorized: action.response.unauthorized,
        invalidAddressMessage: action.response.invalidAddressMessage,
      };
    }
    case exchangeActions.CREATE_WALLET_LEDGERS_RESET: {
      return {
        ...state,
        invalidKeys: undefined,
        invalidToken: undefined,
        contract: undefined,
        duplicate: undefined,
        unauthorized: undefined,
        invalidAddressMessage: undefined,
      };
    }
    case exchangeActions.CREATE_EXCHANGE_LEDGERS_UPLOAD_RESET: {
      return {
        ...state,
        creatingViaUpload: false,
        failedCurrencies: undefined,
        userReadableErrors: undefined,
        skippedCurrencies: undefined,
        uploading: undefined,
      };
    }
    case exchangeActions.RESET_ADD_EXCHANGE_UPLOAD: {
      return {
        ...state,
        uploadError: undefined,
      };
    }
    case resetAddLedger.type: {
      return {
        ...state,
        uploadError: undefined,
        creatingViaUpload: false,
        creatingViaApi: false,
        createdLedgers: undefined,
        userReadableErrors: undefined,
        failedCurrencies: undefined,
        skippedCurrencies: undefined,
        uploading: undefined,
        asyncCreationInProgress: undefined,
        asyncUploadExchangeInProgress: undefined,
      };
    }
    case exchangeActions.UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_REQUEST: {
      return {
        ...state,
        updating: true,
      };
    }
    case exchangeActions.UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_RECEIVE: {
      return {
        ...state,
        updating: false,
      };
    }
    case exchangeActions.UPDATE_EXCHANGE_LEDGERS_UPLOAD_REQUEST: {
      return {
        ...state,
        uploading: true,
        failedCurrencies: undefined,
        userReadableErrors: undefined,
        skippedCurrencies: undefined,
        uploadError: undefined,
      };
    }
    case exchangeActions.UPDATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE: {
      return {
        ...state,
        uploading: false,
      };
    }
    case exchangeActions.UPDATE_EXCHANGE_LEDGERS_UPLOAD_ERROR: {
      let uploadError;
      if (typeof action.error !== "undefined") {
        uploadError = action.response;
      }
      return {
        ...state,
        uploadError,
        uploading: false,
        failedCurrencies: action.response.failedCurrencies,
        userReadableErrors: action.response.userReadableErrors,
      };
    }
    case exchangeActions.DELETE_EXCHANGE_LEDGER_REQUEST:
      return { ...state, deletingLedger: true };
    case exchangeActions.DELETE_EXCHANGE_LEDGER_RECEIVE: {
      const delLedgerIndex = state.ledgers.findIndex(
        (ledger) => parseInt(action.ledgerId, 10) === ledger.id
      );
      const newLedgers = [
        ...state.ledgers.slice(0, delLedgerIndex),
        ...state.ledgers.slice(delLedgerIndex + 1),
      ];

      return {
        ...state,
        deletingLedger: false,
        ledgers: newLedgers,
      };
    }
    case exchangeActions.BULK_DELETE_EXCHANGE_LEDGERS_RECEIVE:
    case exchangeActions.BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_RECEIVE: {
      const ledgersDeletedFiltered = state.ledgers.filter(
        (ledger) => action.ledgerIds.findIndex((id) => id === ledger.id) === -1
      );
      return {
        ...state,
        deletingLedger: false,
        ledgers: ledgersDeletedFiltered,
      };
    }
    case exchangeActions.DELETE_EXCHANGE_LEDGER_ERROR:
      return { ...state, deletingLedger: false };
    case exchangeActions.CONNECT_EXCHANGE_API_REQUEST:
    case exchangeActions.UPDATE_EXCHANGE_API_REQUEST:
      return {
        ...state,
        apiKeySavingStatus: STATUS.WORKING,
        apiKeyError: undefined,
      };
    case exchangeActions.CONNECT_EXCHANGE_API_RECEIVE:
    case exchangeActions.UPDATE_EXCHANGE_API_RECEIVE:
      return {
        ...state,
        apiKeySavingStatus: STATUS.SUCCESS,
      };
    case exchangeActions.CONNECT_EXCHANGE_API_ERROR:
    case exchangeActions.UPDATE_EXCHANGE_API_ERROR:
      return {
        ...state,
        apiKeySavingStatus: STATUS.ERROR,
        apiKeyError: {
          message: action.response.message,
          type: "apiKeyInvalid",
        },
      };
    case exchangeActions.UPDATE_EXCHANGE_API_TOKEN_REQUEST:
      return {
        ...state,
        apiTokenSavingStatus: STATUS.WORKING,
        apiTokenError: undefined,
      };
    case exchangeActions.UPDATE_EXCHANGE_API_TOKEN_RECEIVE:
      return {
        ...state,
        apiTokenSavingStatus: STATUS.SUCCESS,
      };
    case exchangeActions.UPDATE_EXCHANGE_API_TOKEN_ERROR:
      return {
        ...state,
        apiTokenSavingStatus: STATUS.ERROR,
        apiTokenError: {
          message: action.response.message,
          type: "apiTokenInvalid",
        },
      };
    case dismissModal.type: {
      return {
        ...state,
        addLedgerError: undefined,
        uploadError: undefined,
        uploading: false,
        apiKeySavingStatus: STATUS.INITIAL,
        apiTokenSavingStatus: STATUS.INITIAL,
        apiKeyError: undefined,
        apiTokenError: undefined,
        renamingLinkedAccount: false,
        renamingLinkedAccountError: undefined,
      };
    }
    case exchangeActions.SETTINGS_REQUEST: {
      return {
        ...state,
        settingsStatus: action.nextStatus,
      };
    }
    case exchangeActions.SETTINGS_RECEIVE: {
      return {
        ...state,
        settings: action.body,
        settingsStatus: action.nextStatus,
      };
    }
    case exchangeActions.UNLINK_EXCHANGE_REQUEST:
    case exchangeActions.UNLINK_EXCHANGE_RECEIVE: {
      return {
        ...state,
        unlinkExchangeStatus: action.nextStatus,
      };
    }
    case exchangeActions.VERIFY_EXCHANGE_LINK_REQUEST:
      return {
        ...state,
        verifyingExchangeLink: {
          ...state.verifyingExchangeLink,
          [action.linkGUID]: STATUS.LOADING,
        },
      };
    case exchangeActions.VERIFY_EXCHANGE_LINK_RECEIVE:
      return {
        ...state,
        verifyingExchangeLink: {
          ...state.verifyingExchangeLink,
          [action.linkGUID]: STATUS.SUCCESS,
        },
      };
    case exchangeActions.VERIFY_EXCHANGE_LINK_ERROR:
      return {
        ...state,
        verifyingExchangeLink: {
          ...state.verifyingExchangeLink,
          [action.linkGUID]: STATUS.ERROR,
        },
      };
    case exchangeActions.RENAME_LINKED_ACCOUNT_REQUEST: {
      return {
        ...state,
        renamingLinkedAccount: true,
        renamingLinkedAccountError: undefined,
      };
    }
    case exchangeActions.RENAME_LINKED_ACCOUNT_ERROR: {
      return {
        ...state,
        renamingLinkedAccount: false,
        renamingLinkedAccountError: action.response,
      };
    }
    case exchangeActions.RENAME_LINKED_ACCOUNT_RECEIVE: {
      return {
        ...state,
        renamingLinkedAccount: false,
        renamingLinkedAccountError: undefined,
      };
    }
    case exchangeActions.BULK_UPDATE_BY_SOURCE_REQUEST:
      return {
        ...state,
        bulkUpdateStatus: STATUS.LOADING,
      };
    case exchangeActions.BULK_UPDATE_BY_SOURCE_RECEIVE:
      return {
        ...state,
        ledgers: updateLedgerSyncState(
          action.syncList,
          structuredClone(state.ledgers)
        ),
        bulkUpdateStatus: STATUS.SUCCESS,
      };
    case exchangeActions.UPDATE_EXCHANGE_LEDGERS_RECEIVE:
      return {
        ...state,
        ledgers: updateLedgerSyncState(
          [{ source: { id: action.exchangeId, linkGUID: action.linkGUID } }],
          structuredClone(state.ledgers)
        ),
      };
    case exchangeLedgerActions.EXCHANGE_LEDGER_IMPORT_RECEIVE:
      return {
        ...state,
        deletingLedger: false,
        ledgers: updateLedgerSyncState(
          [{ syncedLedgerIds: action.ledgerIds }],
          structuredClone(state.ledgers)
        ),
      };
    case exchangeActions.REBUILD_EXCHANGE_LEDGERS_REQUEST: {
      const i = state.rebuildingLedgers.includes(action.linkGUID);
      const nextRebuild = [...state.rebuildingLedgers];
      if (!i) {
        nextRebuild.push(action.linkGUID);
      }
      return {
        ...state,
        rebuildingLedgers: nextRebuild,
      };
    }
    case exchangeActions.REBUILD_EXCHANGE_LEDGERS_RECEIVE: {
      const i = state.rebuildingLedgers.indexOf(action.linkGUID);
      const nextRebuild = [...state.rebuildingLedgers];
      if (i > -1) {
        nextRebuild.splice(i, 1);
      }

      // remove deleted ledgers before next refresh
      const nextLedgers = [...state.ledgers];
      const nextLedgersFiltered = nextLedgers.filter((e) => {
        return e.linked?.linkGUID !== action.linkGUID;
      });

      return {
        ...state,
        rebuildingLedgers: nextRebuild,
        ledgers: nextLedgersFiltered,
      };
    }
    case exchangeActions.REBUILD_EXCHANGE_LEDGERS_ERROR: {
      const i = state.rebuildingLedgers.indexOf(action.linkGUID);
      const nextRebuild = [...state.rebuildingLedgers];
      if (i > -1) {
        nextRebuild.splice(i, 1);
      }
      return {
        ...state,
        rebuildingLedgers: nextRebuild,
      };
    }
    case exchangeLedgerActions.FAVORITE_LEDGER_RECEIVE: {
      const { ledgers } = state;
      const index = ledgers.findIndex((l) => l.id === action.ledgerId);

      const updatedLedgers = [
        ...ledgers.slice(0, index),
        {
          ...ledgers[index],
          userFavorite: !ledgers[index].userFavorite,
        },
        ...ledgers.slice(index + 1),
      ];

      return {
        ...state,
        ledgers: updatedLedgers,
      };
    }
    case exchangeActions.UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_REQUEST: {
      return {
        ...state,
        rollforwardCreatingViaUpload: true,
        rollforwardCreated: false,
      };
    }
    case exchangeActions.UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_RECEIVE: {
      return {
        ...state,
        rollforwardCreatingViaUpload: false,
        rollforwardCreated: true,
      };
    }
    case exchangeActions.UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_ERROR: {
      return {
        ...state,
        rollforwardCreatingViaUpload: false,
        rollforwardCreatingViaUploadError: action.error,
        rollforwardCreated: false,
      };
    }
    case exchangeActions.API_KEY_VAULTS_REQUEST: {
      return {
        ...state,
        apiKeySavingStatus: STATUS.WORKING,
        apiKeyVault: {},
      };
    }
    case exchangeActions.API_KEY_VAULTS_RECEIVE: {
      return {
        ...state,
        apiKeySavingStatus: STATUS.WORKING,
        apiKeyVault: {
          vaults: action.response,
          apiKey: action.apiKey,
          secretKey: action.secretKey,
        },
      };
    }
    case exchangeActions.API_KEY_VAULTS_ERROR: {
      return {
        ...state,
        apiKeySavingStatus: STATUS.ERROR,
        apiKeyError: {
          message: action.response.message,
          type: "apiKeyInvalid",
        },
      };
    }
    case exchangeActions.IMPORT_API_KEY_VAULTS_REQUEST: {
      return {
        ...state,
        creatingViaApi: true,
      };
    }
    case exchangeActions.IMPORT_API_KEY_VAULTS_RECEIVE: {
      return {
        ...state,
        creatingViaApi: false,
        createdLedgers: action.response.ledgersCreated,
        asyncCreationInProgress: action.response.asyncCreationInProgress,
      };
    }
    case exchangeActions.IMPORT_API_KEY_VAULTS_ERROR: {
      return {
        ...state,
        creatingViaApi: false,
        createdLedgers: undefined,
        asyncCreationInProgress: false,
      };
    }
    case exchangeActions.MIGRATE_SOURCES_REQUEST: {
      return {
        ...state,
        creatingViaUpload: true,
      };
    }
    case exchangeActions.MIGRATE_SOURCES_RECEIVE: {
      return {
        ...state,
        creatingViaUpload: false,
        asyncUploadExchangeInProgress: action.exchangeId,
        asyncCreationInProgress: true,
      };
    }
    case exchangeActions.MIGRATE_SOURCES_ERROR: {
      return {
        ...state,
        creatingViaUpload: false,
        asyncCreationInProgress: false,
      };
    }

    default:
      return state;
  }
};

export default exchanges;
