import api from "../clients/exchange";

/**
 * Submits a request to initial an exchange connection
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {string} slug - The exchange slug
 * @returns {object}  response - The Response or Error message
 */
export const initConnectExchange = (exchangeId, slug) => {
  const params = new URLSearchParams({ slug });
  return api.get(`/${exchangeId}/oauth/initURI`, {
    params,
    authenticated: true,
  });
};

/**
 * Submits a request to set an exchange API key on the user's account
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {string} apiKey - The API Key
 * @param {string} secretKey - The secret key
 * @param {string} passphrase - The passphrase
 * @returns {object}  response - The Response or Error message
 */
export const setExchangeApiKey = (
  exchangeId,
  apiKey,
  secretKey,
  nickname,
  passphrase
) => {
  return api.post(`/${exchangeId}/apiKey`, {
    data: {
      apiKey,
      secretKey,
      passphrase,
      nickname,
    },
    authenticated: true,
  });
};

/**
 * Submits a request to set update an exchange API key on the user's account
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {String} linkGUID - The identifier of the specific echange link
 * @param {string} apiKey - The API Key
 * @param {string} secretKey - The secret key
 * @param {string} passphrase - The passphrase
 * @returns {object}  response - The Response or Error message
 */
export const updateExchangeApiKey = (
  exchangeId,
  linkGUID,
  apiKey,
  secretKey,
  passphrase
) => {
  return api.put(`/${exchangeId}/apiKey/${linkGUID}`, {
    data: {
      apiKey,
      secretKey,
      passphrase,
    },
    authenticated: true,
  });
};

/**
 * Submits a request to set an exchange API key on the user's account
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {string} toke - The token
 * @returns {object}  response - The Response or Error message
 */
export const setExchangeToken = (exchangeId, token, nickname) => {
  return api.post(`/${exchangeId}/apiKey`, {
    data: { token, nickname },
    authenticated: true,
  });
};

/**
 * Submits a request to set update an exchange API key on the user's account
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {String} linkGUID - The identifier of the specific echange link
 * @param {string} token - The API Key
 * @returns {object}  response - The Response or Error message
 */
export const updateExchangeApiToken = (exchangeId, linkGUID, token) => {
  return api.put(`/${exchangeId}/apiKey/${linkGUID}`, {
    data: { token },
    authenticated: true,
  });
};

/**
 * Submits a request to set an exchange xpub key on the user's account
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {string} xpubs - The xPub Keys
 * @returns {object}  response - The Response or Error message
 */
export const setExchangeXpubKey = (exchangeId, xpubs, nickname) => {
  return api.post(`/${exchangeId}/walletKey`, {
    data: { xpubs, nickname },
    authenticated: true,
  });
};

/**
 * Submits a request to set an exchange address key on the user's account
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {string} addresses - The addresses
 * @returns {object}  response - The Response or Error message
 */
export const setExchangeAddresses = (exchangeId, addresses, nickname) => {
  return api.post(`/${exchangeId}/walletKey`, {
    data: { addresses, nickname, createAddressLabel: true },
    authenticated: true,
  });
};

/**
 * Submits a request to set an exchange xpub key on the user's account
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {string} xpubs - The xPub Keys,
 * @param {number} signatures - The number of signatures
 * @returns {object}  response - The Response or Error message
 */
export const setExchangeMultiSig = (
  exchangeId,
  xpubs,
  signatures,
  nickname
) => {
  return api.post(`/${exchangeId}/walletKey`, {
    data: {
      xpubs,
      signatures,
      isMultiSig: true,
      nickname,
    },
    authenticated: true,
  });
};

/**
 * Submits a request to upload transactions for Dash Core
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {string} addressGroupings - A comma delimted list of addresses
 * @returns {object}  response - The Response or Error message
 */
export const createDashCoreLedger = (exchangeId, addressGroupings) => {
  return api.post(`/${exchangeId}/walletKey/upload/dashCore/create`, {
    data: addressGroupings,
    authenticated: true,
  });
};

/**
 * Submits a request to upload an import transaction file
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {number[]} ledgerIds - The ids of the ledgers to update
 * @param {string} addressGroupings - The file containing the transactions
 * @returns {object}  response - The Response or Error message
 */
export const updateDashCoreLedger = (
  exchangeId,
  ledgerIds,
  addressGroupings
) => {
  return api.post(`/${exchangeId}/walletKey/upload/dashCore/import`, {
    data: { addressGroupings, ledgerIds },
    authenticated: true,
  });
};

export const getVaultList = (exchangeId, apiKey, secretKey) => {
  return api.post(`/fireblocks/vaults`, {
    data: { apiKey, secretKey },
    authenticated: true,
  });
};

export const importVaults = (exchangeId, apiKey, secretKey, vaults) => {
  return api.post(`/fireblocks/vaults/import`, {
    data: { apiKey, secretKey, vaults },
    authenticated: true,
  });
};
