export function makeActionCreator(type, ...argNames) {
  return function creator(...args) {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

function handleNonReconnectCode(errorCode, error, exchange, errorCallback) {
  switch (errorCode) {
    case "exchangeUnavailable":
      return errorCallback(
        {
          type: "EXCHANGE_FAILURE",
          message: `${exchange.label} is currently unavailable. It may be down for maintainance.`,
        },
        error
      );
    case "exchangeAccountLocked":
      return errorCallback(
        {
          type: "EXCHANGE_FAILURE",
          message: `The account you are trying to access has been locked by ${exchange.label}. Please contact ${exchange.label} to unlock your account.`,
        },
        error
      );
    default:
      return errorCallback(
        {
          type: "EXCHANGE_FAILURE",
          message: `There was an error connecting to ${exchange.label}.`,
        },
        error
      );
  }
}

export function handleApiKeyError({
  errorCode,
  exchange,
  linkGUID,
  openApiKeyModal,
  errorCallback,
  error,
  apiLinkSuccessCallback,
  apiDismissCallback,
  callLedgerImportAfterHandleKey,
  ledgerIds,
  exchangeId,
}) {
  if (typeof openApiKeyModal === "undefined")
    throw new Error(
      "Must provide openApiKeyModal action creator to handleApiKeyError"
    );

  const reconnectCodes = [
    "apiKeyOverScope",
    "apiTokenOverScope",
    "apiKeyRequired",
    "apiKeyInvalid",
    "apiTokenInvalid",
    "apiKeyAccessDenied",
    "apiTokenAccessDenied",
    "apiKeyInvalidSignedRequest",
  ];

  if (reconnectCodes.findIndex((item) => item === errorCode) > -1) {
    const params = {
      exchange,
      linkGUID,
      update: true,
      code: errorCode,
      callLedgerImportAfterHandleKey,
      ledgerIds,
      exchangeId,
      reLink: true,
    };

    return openApiKeyModal(params, apiLinkSuccessCallback, apiDismissCallback);
  }
  return handleNonReconnectCode(errorCode, error, exchange, errorCallback);
}

export function handleWalletTokenError(
  errorCode,
  exchange,
  linkGUID,
  openWalletTokenModal,
  errorCallback,
  error,
  tokenLinkSuccessCallback,
  tokenDismissCallback
) {
  if (typeof openWalletTokenModal === "undefined") {
    throw new Error(
      "Must provide openWalletTokenModal action creator to handleApiTokenError"
    );
  }

  const reconnectCodes = [
    "apiTokenOverScope",
    "apiTokenInvalid",
    "apiTokenAccessDenied",
  ];

  if (reconnectCodes.findIndex((item) => item === errorCode) > -1) {
    const params = {
      exchange,
      linkGUID,
      update: true,
      code: errorCode,
    };

    return openWalletTokenModal(
      params,
      tokenLinkSuccessCallback,
      tokenDismissCallback
    );
  }
  return handleNonReconnectCode(errorCode, error, exchange, errorCallback);
}
