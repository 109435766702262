import { call, all, put, select } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestBulkCreateTag,
  receiveBulkCreateTag,
  bulkCreateTagError,
  fetchUserTagsData,
  updateRecentTags,
  dismissModal,
} from "app.reducers/ui";
import { fetchTimeline, fetchExchangeLedger } from "app.actions/exchangeLedger";

function* bulkCreateTags(action) {
  const { ledgerItemIds, tag, isTimeline = true } = action.payload;

  const lastRequestParams = yield select(
    (state) => state.exchangeLedger.lastRequestParams
  );

  yield put(requestBulkCreateTag());

  const response = yield call(
    ExchangeLedgerAPI.bulkCreateLedgerTag,
    ledgerItemIds,
    tag
  );

  const { body, error } = response;
  if (error) {
    yield put(bulkCreateTagError(body));
  } else {
    const noLoading = true;

    const itemsFetch = isTimeline
      ? fetchTimeline(...lastRequestParams, noLoading)
      : fetchExchangeLedger(...lastRequestParams, noLoading);

    yield all([
      put(receiveBulkCreateTag()),
      put(itemsFetch),
      put(updateRecentTags(tag)),
      put(dismissModal()),
      put(fetchUserTagsData()),
    ]);
  }
}

export default bulkCreateTags;
