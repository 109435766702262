import api from "../clients/exchange";

/**
 * Submits a request to get a list of exchange ledgers for a user
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getLedgers = () => {
  return api.get(`/ledger`, {
    authenticated: true,
  });
};

/**
 * Submits a request to rebuild all ledgers in a source
 * @async
 * @function
 * @param {string} exchangeId - The id of the exchange
 * @param {string} linkGUID - The id of the specific connection
 * @returns {object}  response - The Response or Error message
 */
export const rebuildLedgers = (exchangeId, linkGUID) => {
  return api.post(`/ledger/${exchangeId}/${linkGUID}/rebuild`, {
    authenticated: true,
  });
};
