/**
 * Extracts the response to node40 patterns
 * @param {object} status - The status code
 * @param {object} response - The fetch response
 * @private
 */
function getErrorFromResponse(status, data) {
  const type = status;

  if (status >= 500) {
    return {
      serverError: true,
      type,
      message: "An unexpected error has occurred. Our team has been notified.",
    };
  }

  if (data) return { ...data };

  switch (status) {
    case 401:
      return { message: "Unauthorized", type };
    case 403:
      return { message: "The attempted action was forbidden.", type };
    case 404:
      return { message: "The resource or action was not found.", type };
    default:
      return { message: `Error: Unhandled reponse: ${status}`, type };
  }
}

function formatApiError(response, data) {
  if (!response) {
    return {
      body: { networkError: true },
      error: "NETWORK",
      _meta: response,
    };
  }

  const { status } = response;

  // eslint-disable-next-line no-underscore-dangle
  const _meta = response;
  const error = status;
  const body = getErrorFromResponse(status, data);

  return {
    _meta,
    error,
    body,
  };
}

export default formatApiError;
