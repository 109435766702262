import api from "app.api/clients/exchange";

/**
 * Submits a request to import data from an exchange
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const doExchangeLedgerImport = (ledgerIds) => {
  const data = { ledgerIds };
  return api.post(`/ledger/import`, {
    data,
    authenticated: true,
  });
};

/**
 * Submits a request to import data from an exchange
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {string} linkGUID - The link ID of the exchange account
 * @returns {object}  response - The Response or Error message
 */
export const updateExchangeAllLedgers = (exchangeId, linkGUID) => {
  return api.post(`/${exchangeId}/${linkGUID}/ledger/import`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get an exchange ledger's import history
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeLedgerImporapitory = (ledgerId) => {
  return api.get(`/ledger/${ledgerId}/import`, {
    authenticated: true,
  });
};
