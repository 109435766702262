import { makeActionCreator } from "app.actions/helpers";

export const UPDATE_LEDGER_CUSTOM_DATA = "UPDATE_LEDGER_CUSTOM_DATA";
export const UPDATE_LEDGER_CUSTOM_DATA_REQUEST =
  "UPDATE_LEDGER_CUSTOM_DATA_REQUEST";
export const UPDATE_LEDGER_CUSTOM_DATA_RECEIVE =
  "UPDATE_LEDGER_CUSTOM_DATA_RECEIVE";
export const UPDATE_LEDGER_CUSTOM_DATA_ERROR =
  "UPDATE_LEDGER_CUSTOM_DATA_ERROR";

export const updateLedgerCustomData = makeActionCreator(
  UPDATE_LEDGER_CUSTOM_DATA,
  "ledgerId",
  "itemId",
  "customData",
  "updatingField"
);

export const requestUpdateLedgerCustomData = makeActionCreator(
  UPDATE_LEDGER_CUSTOM_DATA_REQUEST,
  "itemId",
  "updatingField"
);

export const receiveUpdateLedgerCustomData = makeActionCreator(
  UPDATE_LEDGER_CUSTOM_DATA_RECEIVE,
  "itemId",
  "data"
);

export const updateLedgerCustomDataError = makeActionCreator(
  UPDATE_LEDGER_CUSTOM_DATA_ERROR,
  "itemId"
);
