export const LEDGER_NAME_MAX_LENGTH = 64;
export const LISTBY = {
  CURRENCY: "currency",
  TABLE: "table",
  SOURCE: "source",
  FAVORITES: "favorites",
};

export const PRECISION_OPTION_NORMAL = "NORMAL";
export const PRECISION_OPTION_FULL = "FULL";
export const PRECISION_OPTION_MAX = "MAX";

export const PRECISION_OPTIONS_PATH = "constants.ledger.precision";
export const PRECISION_OPTIONS = [
  PRECISION_OPTION_NORMAL,
  PRECISION_OPTION_FULL,
  PRECISION_OPTION_MAX,
];

export const TRANSACTION_FILTER_OPTIONS_DEFAULT = "ALL";
export const TRANSACTION_FILTER_OPTION_AWAITING_REVIEW = "AWAITING_REVIEW";

export const TRANSACTION_FILTER_OPTIONS_PATH =
  "constants.ledger.newLedgerFilter";
export const TRANSACTION_FILTER_OPTIONS = [
  TRANSACTION_FILTER_OPTIONS_DEFAULT,
  TRANSACTION_FILTER_OPTION_AWAITING_REVIEW,
];

export const TRANSACTION_FILTER_LOOKUP = {
  ALL: undefined,
  AWAITING_REVIEW: "needsReviewItemsFilter",
};

export const USER_ARCHIVE = "USER";
export const SPAM_ARCHIVE = "SYSTEM";

export const LEDGER_ACTION_MODE_PATH = "constants.ledger.actionMode";

export const LEDGER_ACTION_NONE = "NONE";
export const LEDGER_ACTION_BULK_SELECT = "BULK_SELECT";
export const LEDGER_ACTION_MANUAL_TRANSFER_SELECT = "MANUAL_TRANSFER_SELECT";

export const LEDGER_ACTION_MODE = [
  LEDGER_ACTION_NONE,
  LEDGER_ACTION_BULK_SELECT,
  LEDGER_ACTION_MANUAL_TRANSFER_SELECT,
];
