import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "app.components/Util/LoadingSpinner";

import Button from "app.components/Util/Button";
import SvgLoader from "app.components/Util/SvgLoader";

import { FILES_STATUS } from "app.constants";

const DownloadButton = ({
  document,
  disabled,
  downloadLabel,
  downloadReadyLabel,
  idLabel,
  generateLabel,
  generateCallback,
  downloadCallback,
  smartWalletRunning,
  importInProgress,
  isAccountVerified,
  filesStatus = undefined,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { status } = document;

  useEffect(() => {
    setLoading(false);
  }, [status, filesStatus]);

  let completeLabel = (
    <>
      {idLabel || t("button.downloadReady")}
      {idLabel ? null : <span className="sr-only"> {downloadLabel}</span>}
    </>
  );

  if (downloadReadyLabel) {
    completeLabel = downloadReadyLabel;
  }

  switch (status) {
    case FILES_STATUS.AVAILABLE:
    case FILES_STATUS.ERROR:
      return (
        <Button
          buttonSize="sm"
          buttonType="text"
          disabled={
            smartWalletRunning ||
            importInProgress ||
            loading ||
            isAccountVerified === false ||
            disabled
          }
          onClick={(e) => {
            e.preventDefault();
            setLoading(true);
            generateCallback(document);
          }}
          customClasses="items-center"
        >
          <>
            <SvgLoader
              id="CloudArrowDown"
              className="mr-1 h-5 w-5 fill-zinc-500"
            />
            {generateLabel || t("button.generateAndDownload")}
            {downloadLabel ? (
              <span className="sr-only"> {downloadLabel}</span>
            ) : null}
          </>
        </Button>
      );
    case FILES_STATUS.COMPLETE:
      return (
        <Button
          buttonSize="sm"
          buttonType="text"
          disabled={
            smartWalletRunning ||
            importInProgress ||
            disabled ||
            isAccountVerified === false
          }
          onClick={(e) => {
            e.preventDefault();
            downloadCallback(document);
          }}
          customClasses="max-w-fit items-center"
        >
          <>
            <SvgLoader
              id="CloudArrowDown"
              className="mr-1 h-5 w-5 fill-zinc-500"
            />
            {completeLabel}
          </>
        </Button>
      );
    case FILES_STATUS.GENERATING:
      return (
        <div className="flex flex-row items-center gap-1">
          <LoadingSpinner xsmall screenReaderText="" spinnerType="text" />
          <span className="cursor-pointer whitespace-nowrap py-1 text-center text-xs uppercase text-zinc-500 hover:underline">
            {t("common.preparing", { label: idLabel })}
          </span>
        </div>
      );
    default:
      return null;
  }
};

DownloadButton.displayName = "DownloadButton";

export default DownloadButton;
