import { makeActionCreator } from "app.actions/helpers";
// LEDGER IMPORTs
export const EXCHANGE_LEDGER_IMPORTS_BEGIN = "EXCHANGE_LEDGER_IMPORTS_BEGIN";
export const EXCHANGE_LEDGER_IMPORTS_REQUEST =
  "EXCHANGE_LEDGER_IMPORTS_REQUEST";
export const EXCHANGE_LEDGER_IMPORTS_RECEIVE =
  "EXCHANGE_LEDGER_IMPORTS_RECEIVE";

export const fetchExchangeLedgerImports = makeActionCreator(
  EXCHANGE_LEDGER_IMPORTS_BEGIN,
  "ledgerId"
);
export const requestExchangeLedgerImports = makeActionCreator(
  EXCHANGE_LEDGER_IMPORTS_REQUEST,
  "ledgerId",
  "nextStatus"
);
export const receiveExchangeLedgerImports = makeActionCreator(
  EXCHANGE_LEDGER_IMPORTS_RECEIVE,
  "response",
  "ledgerId",
  "nextStatus"
);
