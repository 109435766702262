import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* Update exchange api */
/** *************************************************************************** */
export const UPDATE_EXCHANGE_API_TOKEN_BEGIN =
  "UPDATE_EXCHANGE_API_TOKEN_BEGIN";
export const UPDATE_EXCHANGE_API_TOKEN_RECEIVE =
  "UPDATE_EXCHANGE_API_TOKEN_RECEIVE";
export const UPDATE_EXCHANGE_API_TOKEN_REQUEST =
  "UPDATE_EXCHANGE_API_TOKEN_REQUEST";
export const UPDATE_EXCHANGE_API_TOKEN_ERROR =
  "UPDATE_EXCHANGE_API_TOKEN_ERROR";

export const updateExchangeApiToken = makeActionCreator(
  UPDATE_EXCHANGE_API_TOKEN_BEGIN,
  "exchange",
  "linkGUID",
  "token"
);

export const requestUpdateExchangeApiToken = makeActionCreator(
  UPDATE_EXCHANGE_API_TOKEN_REQUEST
);
export const receiveUpdateExchangeApiToken = makeActionCreator(
  UPDATE_EXCHANGE_API_TOKEN_RECEIVE,
  "response"
);
export const receiveUpdateExchangeApiTokenError = makeActionCreator(
  UPDATE_EXCHANGE_API_TOKEN_ERROR,
  "response"
);
