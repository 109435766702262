import { makeActionCreator } from "app.actions/helpers";
/** *************************************************************************** */
/* Update exchange api */
/** *************************************************************************** */
export const UPDATE_EXCHANGE_API_KEY_BEGIN = "UPDATE_EXCHANGE_API_KEY_BEGIN";
export const UPDATE_EXCHANGE_API_RECEIVE = "UPDATE_EXCHANGE_API_RECEIVE";
export const UPDATE_EXCHANGE_API_REQUEST = "UPDATE_EXCHANGE_API_REQUEST";
export const UPDATE_EXCHANGE_API_ERROR = "UPDATE_EXCHANGE_API_ERROR";

export const updateExchangeApiKey = makeActionCreator(
  UPDATE_EXCHANGE_API_KEY_BEGIN,
  "exchange",
  "linkGUID",
  "apiKey",
  "secretKey",
  "passphrase",
  "callLedgerImportAfterHandleKey",
  "ledgerIds",
  "exchangeId"
);

export const requestUpdateExchangeApiKey = makeActionCreator(
  UPDATE_EXCHANGE_API_REQUEST
);
export const receiveUpdateExchangeApiKey = makeActionCreator(
  UPDATE_EXCHANGE_API_RECEIVE,
  "response"
);
export const receiveUpdateExchangeApiKeyError = makeActionCreator(
  UPDATE_EXCHANGE_API_ERROR,
  "response"
);
