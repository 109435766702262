import React, { useState, useMemo } from "react";
import Big from "big.js";
import { useTranslation } from "react-i18next";
import BaseTable from "app.components/TableList/BaseTable";
import Button from "app.components/Util/Button";

import CardIndicator from "app.components/Util/CardIndicator";
import SearchInput from "app.components/Util/Form/SearchInput";

import CurrencyColumn from "./CurrencyColumn";
import BalanceColumn from "./BalanceColumn";
import SelectedColumn from "./SelectedColumn";

const SelectableLedgersList = ({
  ledgers,
  selectedLedgers,
  setSelectedLedgers,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const hasManyLedgers = ledgers.length > 3;
  const allLedgersSelected = selectedLedgers.length === ledgers.length;

  const selectAllLedgers = () => {
    if (allLedgersSelected) {
      setSelectedLedgers([]);
    } else {
      setSelectedLedgers(ledgers.map(({ ledger }) => ledger.id));
    }
  };

  const selectItem = (id, isSelected) => {
    setSelectedLedgers(
      isSelected
        ? selectedLedgers.filter((ledger) => ledger !== id)
        : [...selectedLedgers, id]
    );
  };

  const columns = [
    {
      label: t("common.token"),
      left: true,
      column: "currency",
      sticky: true,
      sortable: true,
      searchable: true,
      customSearch: (currency, searchString, row) =>
        currency.toLowerCase().includes(searchString) ||
        row.name.toLowerCase().includes(searchString),
      customRender: (currency, row) => CurrencyColumn({ currency, row }),
    },
    {
      label: t("common.balance"),
      right: true,
      column: "balance",
      sticky: true,
      sortable: true,
      customRender: (balance, row) => BalanceColumn({ balance, row }),
    },
    {
      label: <span className="sr-only">{t("common.selected")}</span>,
      column: "isSelected",
      sticky: true,
      customClass: "w-16",
      customRender: (isSelected) => SelectedColumn({ isSelected }),
    },
  ];

  const data = ledgers.map(({ name, ledger, currency }) => ({
    name,
    id: ledger.id,
    currency,
    balance: new Big(ledger.balanceAmount),
    isSelected: !!selectedLedgers.find((item) => item === ledger.id),
  }));

  const filteredData = useMemo(() => {
    const filterSplit = search
      .split(",")
      .filter((item) => !!item)
      .map((item) => item.trim().toLowerCase());

    if (!hasManyLedgers || !filterSplit.length) return data;

    return data.filter((item) => {
      for (let i = 0; i < filterSplit.length; i += 1) {
        const foundItem =
          item.name.toLowerCase().includes(filterSplit[i]) ||
          item.currency.toLowerCase().includes(filterSplit[i]);

        if (foundItem) return true;
      }
      return false;
    });
  }, [data, hasManyLedgers, search]);

  return (
    <div className="my-4 rounded bg-white shadow">
      {hasManyLedgers ? (
        <div className="p-2">
          <SearchInput
            fullWidth
            clearCallback={() => setSearch("")}
            placeholder={t("input.placeholder.searchForOneOrMoreCurrencies")}
            value={search}
            onChange={(e) => {
              e.preventDefault();
              setSearch(e.target.value);
            }}
          />
        </div>
      ) : null}
      <BaseTable
        tableCustomClass="min-h-[300px] max-h-[300px] overflow-y-scroll"
        columns={columns}
        data={filteredData}
        sortable
        handleColumnClick={(e, x) => {
          e.preventDefault();
          selectItem(x.id, x.isSelected);
        }}
        recordsShowing
      />
      <div className="flex items-center justify-between border-t px-4 py-2">
        <div>
          {filteredData.length !== ledgers.length ? (
            <p className="text-sm text-zinc-500">
              {t("common.showingLedgers", {
                count: ledgers.length,
                visibleCount: filteredData.length,
              })}
            </p>
          ) : null}
        </div>
        <Button buttonSize="sm" buttonType="text" onClick={selectAllLedgers}>
          <div className="justify- flex items-center">
            <p className="mr-2">
              {t("common.selectAllLedgers", { count: ledgers.length })}
            </p>
            <CardIndicator
              disabled={false}
              type="square"
              active={allLedgersSelected}
            />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default SelectableLedgersList;
