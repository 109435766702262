import api from "../clients/exchangeOrganization";
/**
 * Submits a request to get the org level accounting prefs
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getOrgAccountingPreferences = () => {
  return api.get(`/preference/org`, {
    authenticated: true,
  });
};

/**
 * Submits a request to set the org level accounting prefs
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const setOrgAccountingPreferences = (body) => {
  return api.post(`/preference/org`, {
    data: body,
    authenticated: true,
  });
};
