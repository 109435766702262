import { call, put, all } from "redux-saga/effects";
import {
  requestUpdateExchangeApiToken,
  receiveUpdateExchangeApiTokenError,
  receiveUpdateExchangeApiToken,
  fetchLinkedExchanges,
} from "app.actions/exchanges";
import { completeWalletTokenModal } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

function* updateExchangeAPIToken(action) {
  const { exchange, linkGUID, token } = action;

  yield put(requestUpdateExchangeApiToken());

  const response = yield call(
    ExchangesAPI.updateExchangeApiToken,
    exchange.id,
    linkGUID,
    token
  );

  const { body, error } = response;
  if (error) {
    if (typeof body.metadata !== "undefined") {
      yield put(
        receiveUpdateExchangeApiTokenError(
          { type: "bad-key", message: body.metadata.message },
          error
        )
      );
    } else {
      yield put(receiveUpdateExchangeApiTokenError(body, error));
    }
  } else {
    yield all([
      put(completeWalletTokenModal(linkGUID)),
      put(receiveUpdateExchangeApiToken(body)),
      put(fetchLinkedExchanges()),
    ]);
  }
}

export default updateExchangeAPIToken;
