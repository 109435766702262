import { takeEvery, put, debounce, takeLatest } from "redux-saga/effects";
import * as enrollmentActions from "app.actions/enrollment";

import enterEnrollmentSection from "./enterEnrollmentSection";
import enterEnrollmentCheckout from "./enterEnrollmentCheckout";
import runEnrollment from "./runEnrollment";

function* queryProfileLimitUpdate(action) {
  yield put(
    enrollmentActions.runEnrollmentProfileLimitUpdate(action.profileLimit)
  );
}

function* queryZipUpdate(action) {
  yield put(enrollmentActions.runEnrollmentZipCodeUpdate(action.zipCode));
}

// // WATCHERS
const enrollmentSagas = [
  takeEvery(enrollmentActions.ENROLLMENT_ENTER, enterEnrollmentSection),
  takeEvery(enrollmentActions.ENROLL_BEGIN, runEnrollment),
  takeLatest(
    enrollmentActions.ENROLLMENT_CHECKOUT_ENTER,
    enterEnrollmentCheckout
  ),
  debounce(500, enrollmentActions.ZIP_SET, queryZipUpdate),
  debounce(
    500,
    enrollmentActions.ORGANIZATION_PROFILE_LIMIT_SET,
    queryProfileLimitUpdate
  ),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default enrollmentSagas;
