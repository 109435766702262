import { call, put } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import { receiveUserTagsData, receiveUserTagsDataError } from "app.reducers/ui";

function* fetchUserTags() {
  const response = yield call(ExchangeLedgerAPI.getUserRecentlyTags);
  const { body, error } = response;
  if (error) {
    yield put(receiveUserTagsDataError([]));
  } else {
    yield put(receiveUserTagsData(body));
  }
}

export default fetchUserTags;
