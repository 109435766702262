import { put, select, race, delay, take } from "redux-saga/effects";
import {
  AUTO_REFRESH_ORGANIZATION_TOKEN_END,
  refreshOrganizationToken,
} from "app.actions/authentication";

function* autoRefreshOrganizationToken() {
  const orgState = yield select((state) => state.organizations);
  const { organizationAuthToken } = orgState;

  // If we do not have an auth token, no problem; we can just eat that, and if we get one later we will watch for it
  if (typeof organizationAuthToken === "undefined") {
    return;
  }

  let idleTimer = 0;
  let refreshTimer = 0;
  let refreshRunning = true;

  const REFRESH_INTERVAL_SECONDS = 56;
  const IDLE_MAX_SECONDS = 60 * 5;

  const resetTimer = () => {
    idleTimer = 0;
  };

  window.addEventListener("mousemove", resetTimer, false);
  window.addEventListener("click", resetTimer, false);
  window.addEventListener("keydown", resetTimer, false);

  // Pull once, then watch org in background for eternity
  // Or, until page stops; either works, really

  while (refreshRunning) {
    const { cancelled } = yield race({
      delayed: delay(1000),
      cancelled: take(AUTO_REFRESH_ORGANIZATION_TOKEN_END),
    });

    if (cancelled) {
      refreshRunning = false;
    } else {
      idleTimer += 1;
      refreshTimer += 1;

      const withinRefreshWindow = idleTimer <= IDLE_MAX_SECONDS;
      const onRefreshInterval = refreshTimer % REFRESH_INTERVAL_SECONDS === 0;

      if (withinRefreshWindow && onRefreshInterval) {
        // It's been 56 seconds
        yield put(refreshOrganizationToken());
      }
    }
  }

  window.removeEventListener("mousemove", resetTimer, false);
  window.removeEventListener("click", resetTimer, false);
  window.removeEventListener("keydown", resetTimer, false);
}

export default autoRefreshOrganizationToken;
