import { call, put } from "redux-saga/effects";

import SmartWalletAPI from "app.api/SmartWalletAPI";

import * as smartWalletActions from "app.reducers/smartWallet";

import { getLoadingState } from "app.utils/selectors";

function* fetchSmartWalletRange(action) {
  const smartWalletGUID = action.payload;

  const { isLoading } = yield* getLoadingState(
    (state) => state.smartWallet.smartWalletRangeStatus
  );

  if (isLoading) return;

  yield put(smartWalletActions.requestSmartWalletRange());

  const response = yield call(
    SmartWalletAPI.getSmartWalletRange,
    smartWalletGUID
  );

  if (response.error) {
    console.error(
      "Error in fetchSmartWalletRange",
      response.body,
      response.error
    );
  } else {
    const { earliest, latest } = response.body;
    yield put(
      smartWalletActions.receiveSmartWalletRange({
        earliest,
        latest,
      })
    );
  }
}

export default fetchSmartWalletRange;
