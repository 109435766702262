import { call, put, all, delay, select } from "redux-saga/effects";
import i18n from "i18next";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import { generateGUID } from "app.utils";

import { WALLET_GENERIC_ERROR, ONE_MINUTE, APP_NAME } from "app.constants";

import { STATUS_WARNING } from "app.components/Messages/FlashMessage";

import {
  requestExchangeLedger,
  exchangeLedgerError,
  receiveExchangeLedger,
} from "app.actions/exchangeLedger";

import { addFlashMessage, removeFlashMessage } from "app.reducers/ui";
import { parseRangeToISO } from "app.utils/dates";

function* fetchExchangeLedger(action) {
  const {
    ledgerId,
    offset,
    max,
    curSort,
    curOrder,
    rangeKey,
    filter,
    noLoading = false,
  } = action;

  const { user } = yield select((state) => state.user);

  const range = parseRangeToISO(action.range, user?.preference?.timeZone);

  // if we need to replay
  const lastRequestParams = [
    ledgerId,
    offset,
    max,
    curSort,
    curOrder,
    range,
    rangeKey,
    filter,
  ];

  yield put(requestExchangeLedger(ledgerId, noLoading, lastRequestParams));

  const response = yield call(
    ExchangeLedgerAPI.getExchangeLedger,
    ledgerId,
    offset,
    max,
    curSort,
    curOrder,
    range,
    filter
  );

  const { error, body } = response;

  if (error) {
    let errorResponse =
      body?.message || i18n.t(WALLET_GENERIC_ERROR, { appName: APP_NAME });
    if (body?.type === 500) {
      errorResponse = i18n.t(WALLET_GENERIC_ERROR, { appName: APP_NAME });
    }
    const messageId = generateGUID();
    yield all([
      put(exchangeLedgerError(errorResponse, range, rangeKey)),
      put(addFlashMessage(errorResponse, messageId, STATUS_WARNING)),
    ]);
    yield delay(ONE_MINUTE);
    yield put(removeFlashMessage(messageId));
  } else {
    delete Object.assign(body, { items: body.ledgerItems }).ledgerItems;
    yield put(
      receiveExchangeLedger(ledgerId, body, range, rangeKey, body.page)
    );
  }
}

export default fetchExchangeLedger;
