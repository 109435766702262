import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { dismissModal } from "app.reducers/ui";
import {
  addTeamMember,
  enterTeamMemberEditor,
  exitTeamMemberEditor,
} from "app.reducers/organizations";
import { isValidEmail, isLoading } from "app.utils";
import Button from "app.components/Util/Button";
import ErrorMessage from "app.components/Util/ErrorMessage";
import ToggleSwitch from "app.components/Util/ToggleSwitch";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";

const ModalAddTeamMember = ({ canDismissCallback }) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [isAdmin, setAdminAccess] = useState(false);
  const [name, setName] = useState({
    firstName: "",
    lastName: "",
  });

  const { upsertTeamMemberError, upsertTeamMemberStatus } = useSelector(
    (state) => state.organizations
  );

  const upsertTeamMemberLoading = useMemo(() => {
    return isLoading(upsertTeamMemberStatus);
  }, [upsertTeamMemberStatus]);

  useEffect(() => {
    reduxDispatch(enterTeamMemberEditor());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    canDismissCallback(!upsertTeamMemberLoading);
  }, [canDismissCallback, upsertTeamMemberLoading]);

  const disableSubmit = useMemo(() => {
    if (upsertTeamMemberLoading) return true;
    if (!email) return true;
    if (!isValidEmail(email)) return true;

    return false;
  }, [email, upsertTeamMemberLoading]);

  const submit = () => {
    if (!email) return;
    reduxDispatch(addTeamMember(email, name.firstName, name.lastName, isAdmin));
  };

  const closeModal = () => {
    reduxDispatch(dismissModal());
    reduxDispatch(exitTeamMemberEditor());
  };

  const setIsAdmin = (allowAccess) => {
    setAdminAccess(allowAccess);
  };

  const changeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const changeName = (e) => {
    e.preventDefault();
    setName((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (disableSubmit) return;
        submit();
      }}
    >
      <ModalHeader
        title={t("organization.addTeamMember")}
        closeCallback={closeModal}
      />
      <div className="m-4">
        {upsertTeamMemberError ? (
          <ErrorMessage {...upsertTeamMemberError} />
        ) : null}
        <h3 className="uppercase text-zinc-500">
          {t("organization.teamMembers.teamMemberStepInformation")}
        </h3>
        <p className="mb-2 text-xs text-zinc-700">
          {t(
            "organization.teamMembers.teamMembersViewAndManageAccountsInOrganization"
          )}
        </p>
        <div className="mb-4 flex flex-col">
          <Label htmlFor="email" required>
            {t("organization.teamMembers.email")}
          </Label>
          <p className="text-xxs">
            {t("organization.teamMembers.weWillSendAnInvitationToEmail")}
          </p>
          <Input
            maxLength="255"
            placeholder={t("input.placeholder.emailExample")}
            data-tabbable
            type="text"
            id="email"
            name="email"
            value={email}
            readOnly={upsertTeamMemberLoading}
            onChange={changeEmail}
            data-testid="email"
          />
        </div>
        <div className="flex">
          <div className="mb-4 mr-4 flex basis-1/2 flex-col">
            <Label optional htmlFor="firstName">
              {t("common.firstName")}
            </Label>
            <Input
              onChange={changeName}
              id="firstName"
              name="firstName"
              type="text"
              value={name.firstName}
              readOnly={upsertTeamMemberLoading}
              data-testid="firstName"
            />
          </div>
          <div className="mb-4 ml-4 flex basis-1/2 flex-col">
            <Label optional htmlFor="lastName">
              {t("common.lastName")}
            </Label>
            <Input
              onChange={changeName}
              id="lastName"
              name="lastName"
              type="text"
              value={name.lastName}
              readOnly={upsertTeamMemberLoading}
              data-testid="lastName"
            />
          </div>
        </div>
        <h3 className="uppercase text-zinc-500">
          {t("organization.teamMembers.teamMemberStepAccess")}
        </h3>
        <p className="mb-2 text-xs text-zinc-700">
          {t(
            "organization.teamMembers.youCanElectToAllowThisToTeamMemberToCreate"
          )}
        </p>
        <ToggleSwitch
          className="no-float"
          key="isAdmin"
          id="isAdmin"
          disabled={upsertTeamMemberLoading}
          label={t("input.label.makeThisTeamMemberAdmin")}
          currentValue={isAdmin}
          callback={(val) => setIsAdmin(val)}
        />
      </div>
      <ModalControls>
        <Button
          type="submit"
          disabled={disableSubmit}
          buttonType="primary"
          customClasses="ml-4"
          text={t("organization.addTeamMember")}
        />
        <Button
          onClick={closeModal}
          buttonType="text"
          text={t("button.close")}
        />
      </ModalControls>
    </form>
  );
};

export default ModalAddTeamMember;
