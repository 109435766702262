import { makeActionCreator } from "app.actions/helpers";

export const ENROLLMENT_CHECKOUT_ENTER = "ENROLLMENT_CHECKOUT_ENTER";
export const ENROLLMENT_CHECKOUT_LEAVE = "ENROLLMENT_CHECKOUT_LEAVE";

export const ENROLLMENT_PLAN_QUOTE_REQUEST = "ENROLLMENT_PLAN_QUOTE_REQUEST";
export const ENROLLMENT_PLAN_QUOTE_RECEIVE = "ENROLLMENT_PLAN_QUOTE_RECEIVE";
export const ENROLLMENT_PLAN_QUOTE_ERROR = "ENROLLMENT_PLAN_QUOTE_ERROR";

export const enterEnrollmentCheckout = makeActionCreator(
  ENROLLMENT_CHECKOUT_ENTER,
  "planId"
);

export const leaveEnrollmentCheckout = makeActionCreator(
  ENROLLMENT_CHECKOUT_LEAVE
);

export const requestPlanQuote = makeActionCreator(
  ENROLLMENT_PLAN_QUOTE_REQUEST
);

export const receivePlanQuote = makeActionCreator(
  ENROLLMENT_PLAN_QUOTE_RECEIVE,
  "planQuote",
  "completedQueries"
);

export const receivePlanQuoteError = makeActionCreator(
  ENROLLMENT_PLAN_QUOTE_ERROR,
  "completedQueries"
);
