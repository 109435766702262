import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoading } from "app.utils";
import { useTranslation } from "react-i18next";

import Button from "app.components/Util/Button";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import {
  WALLET,
  SOURCELESS,
  ETH_ADDRESS_SHADOW,
  FILE_UPLOAD,
  FILE_UPLOAD_MIGRATION,
  UNSUPPORTED_SYNC_INTEGRATIONS,
} from "app.constants";

import { dismissModal } from "app.reducers/ui";
import { bulkUpdateLedgersBySource } from "app.actions/exchanges";
import CardIndicator from "app.components/Util/CardIndicator";

import {
  getLinkedExchangeByGUID,
  isSourceSyncing,
  getSourceType,
} from "./helpers";

import ModalSyncAllListItem from "./ModalSyncAllListItem";

function ModalSyncAll({ canDismissCallback }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const { supportedExchanges, linkedExchanges, bulkUpdateStatus } = useSelector(
    (state) => state.exchanges
  );

  const isWorking = isLoading(bulkUpdateStatus);

  const disableButtons = useMemo(() => {
    if (isWorking) return true;
    return false;
  }, [isWorking]);

  const { ledgersBySource } = useSelector((state) => state.computables);

  // sort groups by source name
  const sourceList = useMemo(() => {
    let idCounter = 0;
    const list = [];
    for (let i = 0; i < ledgersBySource.length; i += 1) {
      const sourceType = getSourceType(
        ledgersBySource[i].source,
        supportedExchanges
      );

      const linkedExchange = getLinkedExchangeByGUID(
        linkedExchanges,
        ledgersBySource[i].linkGUID
      );

      const isSyncing = isSourceSyncing(ledgersBySource[i]);

      const unsyncableCount = ledgersBySource[i].isWallet
        ? ledgersBySource[i].ledgers
            .map((ledger) => {
              const { exchangeId } = ledger.ledger;
              const exchange = supportedExchanges.find(
                (e) => e.id === exchangeId
              );

              if (
                UNSUPPORTED_SYNC_INTEGRATIONS.find(
                  (integration) => integration === exchange?.integrationType
                )
              ) {
                return ledger.ledger.id;
              }

              return undefined;
            })
            .filter(Boolean).length
        : 0;

      const syncableWallets = ledgersBySource[i].isWallet
        ? ledgersBySource[i].ledgers
            .map((ledger) => {
              const { exchangeId } = ledger.ledger;
              const exchange = supportedExchanges.find(
                (e) => e.id === exchangeId
              );

              if (
                UNSUPPORTED_SYNC_INTEGRATIONS.find(
                  (integration) => integration !== exchange?.integrationType
                )
              ) {
                return ledger.ledger.id;
              }

              return undefined;
            })
            .filter(Boolean)
        : [];

      let isSyncable = false;
      if (
        sourceType !== FILE_UPLOAD &&
        sourceType !== FILE_UPLOAD_MIGRATION &&
        sourceType !== WALLET &&
        sourceType !== SOURCELESS &&
        sourceType !== ETH_ADDRESS_SHADOW
      ) {
        if (linkedExchange?.active === true && isSyncing === false) {
          isSyncable = true;
        }
      } else if (sourceType === WALLET) {
        isSyncable = true;
      }

      list.push({
        id: idCounter,
        source: ledgersBySource[i],
        linkGUID: ledgersBySource[i].linkGUID,
        exchange: supportedExchanges.find(
          (se) => se.name === ledgersBySource[i].source
        ),
        linkedExchange,
        isSyncing,
        type: sourceType,
        unsyncableCount,
        isSyncable,
        syncableWallets,
      });
      idCounter += 1;
    }

    return list
      .sort((a, b) => {
        const aName = a.type === WALLET ? "Wallet" : a.exchange?.label;
        const bName = b.type === WALLET ? "Wallet" : b.exchange?.label;

        if (aName > bName) {
          return 1;
        }
        if (aName < bName) {
          return -1;
        }
        return 0;
      })
      .sort((a, b) => {
        if (a.isSyncable === b.isSyncable) {
          return 0;
        }
        if (a.isSyncable) {
          return -1;
        }
        return 1;
      });
  }, [supportedExchanges, linkedExchanges, ledgersBySource]);

  const defaultSet = [];

  sourceList.forEach((s) => {
    if (
      s.type !== FILE_UPLOAD &&
      s.type !== FILE_UPLOAD_MIGRATION &&
      s.type !== WALLET &&
      s.type !== SOURCELESS &&
      s.type !== ETH_ADDRESS_SHADOW
    ) {
      if (s.linkedExchange?.active === true && s.isSyncing === false) {
        defaultSet.push(s.id);
      }
    }
    if (s.type === WALLET && s.isSyncing === false) {
      defaultSet.push(s.id);
    }
  });

  const [selectedSources, setSelectedSources] = useState(defaultSet);

  useEffect(() => {
    canDismissCallback(!disableButtons);
  }, [canDismissCallback, disableButtons]);

  function submit() {
    const sources = sourceList.filter(
      (s) => selectedSources.findIndex((ss) => ss === s.id) > -1
    );
    reduxDispatch(bulkUpdateLedgersBySource(sources));
  }

  const allSelected = selectedSources.length === defaultSet.length;

  return (
    <>
      <ModalHeader
        title={t("modals.syncAll.syncAllSources")}
        disabled={disableButtons}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (disableButtons || selectedSources.length === 0) return;
          submit();
        }}
      >
        <div className="m-4">
          <p className="text-lg">
            {t("modals.syncAll.automaticalySyncDataForAllAvailableSources")}
          </p>
          <p className="mb-4 text-xs text-zinc-600">
            {t("modals.syncAll.sourcesWithConnectionThatNeedToBeRepaired")}
          </p>
          <div className="rounded border bg-white">
            <ul className="max-h-72 overflow-y-auto">
              {sourceList.map((source) => (
                <ModalSyncAllListItem
                  key={source.id}
                  source={source}
                  selectedSources={selectedSources}
                  setSelectedSources={setSelectedSources}
                />
              ))}
            </ul>
            <div className="flex justify-end border-t px-4 py-2">
              <Button
                buttonSize="sm"
                buttonType="text"
                onClick={(e) => {
                  e.preventDefault();

                  if (allSelected) {
                    setSelectedSources([]);
                  } else {
                    setSelectedSources([...defaultSet]);
                  }
                }}
              >
                <div className="flex items-center">
                  <p className="mr-2">{t("common.selectAll")}</p>
                  <CardIndicator
                    disabled={false}
                    type="square"
                    active={allSelected}
                  />
                </div>
              </Button>
            </div>
          </div>
        </div>
        <ModalControls>
          <Button
            type="submit"
            disabled={disableButtons || selectedSources.length === 0}
            data-tabbable
            buttonType="primary"
            customClasses="ml-4"
            text={
              allSelected ? t("portfolio.syncAll") : t("portfolio.syncSelected")
            }
          />
          <Button
            disabled={disableButtons}
            onClick={(e) => {
              e.preventDefault();
              reduxDispatch(dismissModal());
            }}
            data-tabbable
            buttonType="text"
            text={t("button.close")}
          />
        </ModalControls>
      </form>
    </>
  );
}

ModalSyncAll.displayName = "ModalSyncAll";
export default ModalSyncAll;
